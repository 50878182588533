import React, { FunctionComponent, memo, useMemo } from 'react'
import { ThemeProvider as StyledComponentThemeProvider } from '@emotion/react'
import { defaultSpacings } from './default-spacings'
import { defaultFontSize } from './default-font-size'
import * as palettes from './palettes'
import { Theme, ThemeConfig } from './theme.types'

type Props = {
  theme: ThemeConfig
  children: JSX.Element | JSX.Element[] | undefined
}

export const ThemeProvider: FunctionComponent<Props> = memo(
  ({ theme, children }) => {
    const ret = useMemo(
      (): Theme => ({
        ...theme,
        spacings: defaultSpacings,
        fontSize: defaultFontSize,
        palettes,
      }),
      [theme],
    )

    return (
      <StyledComponentThemeProvider theme={ret}>
        {children}
      </StyledComponentThemeProvider>
    )
  },
)
