import { Instance, types } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import { notEmpty } from '@mtr-SDO/utils'
import { MockWorkOrderForm as WorkOrderForm } from '../../work-orders/mock-types'
import {
  formatItemNumber,
  FormDefinitionNumberDisplay,
  formItemFilterDefaultOptions,
} from '../helpers'
import {
  FormDefinitionItem,
  FormDefinitionItemModel,
} from './form-definition-item.model'

const formDefinitionItemGroupSnapshot = (snapshot: any): any => ({
  id: snapshot.id,
  groupId: snapshot.groupId,
  number: snapshot.number,
  displayOrder: snapshot.displayOrder,
  versionNo: snapshot.versionNo,
  name: snapshot.name,
  items: snapshot.items,
  status: snapshot.status,
  isReadonly: snapshot.isReadonly ?? false,
  isShown: snapshot.isShown ?? true,
  hasNext: snapshot.hasNext ?? false,
  hasPhoto: snapshot.hasPhoto ?? false,
})

export const FormDefinitionItemGroupModel = types
  .model({
    id: types.optional(types.identifier, uuid),
    groupId: types.maybe(types.string),
    number: types.maybe(types.string),
    displayOrder: types.optional(types.number, 0),
    name: types.string,
    items: types.array(FormDefinitionItemModel),
    isReadonly: types.maybe(types.boolean),
    isShown: types.maybe(types.boolean),
    hasNext: types.maybe(types.boolean),
    hasPhoto: types.maybe(types.boolean),
    needUpload:types.maybe(types.boolean),
  })
  .preProcessSnapshot(formDefinitionItemGroupSnapshot)
  .views((self) => {
    const views = {
      get numberDisplay(): FormDefinitionNumberDisplay | undefined {
        if (self.number == null) return undefined
        return formatItemNumber(self.number) ?? undefined
      },
      /**
       * Return all items under the current group
       * Use with caution: filter the return value of this getter by work order form lost parent-child relationship in filtering
       */
      get itemsRecursively(): FormDefinitionItem[] {
        return (self.items as unknown as FormDefinitionItem[])
          .reduce(
            (acc, item) => acc.concat([item]).concat(item.itemsRecursively),
            [] as FormDefinitionItem[],
          )
          .filter(notEmpty)
      },
      get displayItems(): FormDefinitionItem[] {
        return (self.items as unknown as FormDefinitionItem[])
          .slice()
          .sort((a, b) => a.displayOrder - b.displayOrder)
      },

      itemsForWorkOrderForm(workOrderForm?: WorkOrderForm) {
        return views.displayItems.filter((displayItem) =>
          displayItem.suitWorkOrderForm(workOrderForm),
        )
      },
      itemsRecursivelyForWorkOrder(
        workOrderForm: WorkOrderForm,
        opts = formItemFilterDefaultOptions,
      ) {
        return views.displayItems.reduce(
          (acc, it) => [
            ...acc,
            ...it.itemsRecursivelyWithSelfForWorkOrder(workOrderForm, opts),
          ],
          [] as FormDefinitionItem[],
        )
      },

      itemCountForWorkOrderForm(
        workOrderForm: WorkOrderForm,
        opts = formItemFilterDefaultOptions,
      ): number {
        return views.itemsRecursivelyForWorkOrder(workOrderForm, opts).length
      },
    }
    return views
  })
  .actions((self) => ({
    setIsReadonly(isReadonly: boolean) {
      self.isReadonly = isReadonly
    },
    setIsShown(isShown: boolean) {
      self.isShown = isShown
    },
    setHasNext(hasNext: boolean) {
      self.hasNext = hasNext
    },
    setHasPhoto(hasPhoto: boolean) {
      self.hasPhoto = hasPhoto
    },
    setNeedUpload(needUpload: boolean) {
      self.needUpload = needUpload
    },
  }))

export type FormDefinitionItemGroup = Instance<
  typeof FormDefinitionItemGroupModel
> & {
  inputType?: undefined
  inputId?: undefined
  suitWorkOrderForm?: undefined
}
