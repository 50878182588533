import { withRootStore } from '@mtr-SDO/models-core'
import _ from 'lodash'
import { getSnapshot, Instance, types } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import { TrainStockType } from '../../base-attunity'
import {
  MockFormDefinition as FormDefinition,
  MockPreviewFormDefinition as PreviewFormDefinition,
} from '../definition/mock-type'
import { WorkInstruction } from '../work-instructions'

export type FormVersionInformation = {
  version: number
  issueDate: moment.Moment | undefined
  publishDate: moment.Moment | undefined
  issueNumber: number
  revisionNumber: number
  formRefNumber: string | undefined
  wiRemoteId?: string | undefined
}

export const FormModel = types
  .model('Form', {
    id: types.optional(types.identifier, uuid),
    orderBookID: types.maybe(types.string),
    bookItemID: types.maybe(types.string),
    formId: types.maybe(types.string),
    number: types.string,
    title: types.string,
    remoteId: types.string,
    trainStockTypeIds: types.array(types.string),
    isRemoved: types.optional(types.boolean, false),
    wiRemoteId: types.maybe(types.string),
  })
  .extend(withRootStore)
  .volatile(() => ({
    availableVersions: [] as FormVersionInformation[],
    previewDetail: undefined as FormVersionInformation | undefined,
  }))
  .actions((self) => ({
    apply(snapshot: {}) {
      _.keys(_.omit(snapshot, 'id')).forEach((key) => {
        if (snapshot[key] == null) return
        self[key] = snapshot[key]
      })
    },
    setTrainStockTypeIds: (id: string[]) => {
      self.trainStockTypeIds.replace(id.map((it) => it))
    },
    fetchDefinition: (version: number) =>
      self.rootStore.formStore.fetchDefinition(self.remoteId, version),
    remove() {
      self.isRemoved = true
    },
    setAvailableVersions(versions: FormVersionInformation[]) {
      self.availableVersions = versions
    },
  }))
  .views((self) => {
    const views = {
      get latestVersionInfo(): FormVersionInformation {
        return self.availableVersions[self.availableVersions?.length - 1]
      },
      /** @deprecated this might return both preview and published form definition */
      get definitions(): FormDefinition[] {
        return self.rootStore.formStore.formDefinitions.filter(
          (def) => def.form.remoteId === self.remoteId,
        )
      },
      get offlineDefinitions(): FormDefinition[] {
        return self.rootStore.formStore.formDefinitions.filter(
          (def) => def.form.remoteId === self.remoteId && !def.isPreview,
        )
      },
      get latestDefinition(): FormDefinition | undefined {
        return views.definitions.length > 0
          ? views.definitions
              .filter((it) => !it.isPreview)
              .slice()
              .sort((a, b) => +(b.version ?? 0) - +(a.version ?? 0))[0]
          : undefined
      },
      get latestPreviewDefinition(): PreviewFormDefinition | undefined {
        return views.definitions.length > 0
          ? views.definitions
              .filter((it) => it.isPreview)
              .slice()
              .sort((a, b) => +(b.version ?? 0) - +(a.version ?? 0))[0]
          : undefined
      },
      getDefinition(version?: number, isPreview?: boolean) {
        // if (!version) return views.latestDefinition
        // return views.definitions.find(
        //   (def) =>
        //     def.version === version && def.isPreview === (isPreview ?? false),
        // )
        return self.rootStore.bookFormsStore.formDefinitionList.find(
          (it) => it.bookItemID === self.bookItemID,
        )
      },
      get cachePayload() {
        return _.omit(getSnapshot(self), ['id'])
      },
      get trainStockTypes(): TrainStockType[] {
        return self.trainStockTypeIds.map((it) =>
          self.rootStore.masterDataStore.findTrainStockType(it),
        )
      },
      get workInstruction(): WorkInstruction | undefined {
        return self.rootStore.formStore.workInstructions.find(
          (it: WorkInstruction) => it.remoteId === self.wiRemoteId,
        )
      },
    }
    return views
  })

export type Form = Instance<typeof FormModel>
