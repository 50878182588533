import { ApiBase } from '../api-base'
import { UserCreateType, UserInfoType, UserUpdateType, ValidateCodeImgType } from './user-operation.types'

export class ApiUserOperation {
  private api: ApiBase

  constructor(api: ApiBase) {
    this.api = api
  }

  createUser = async (
    data: UserCreateType
  ) =>
    this.api.generalApi<boolean>(
      this.api.apisauce.post('/Common/UserRegister/UserRegisterByAzureB2B', data),
    )

  updateUser = async (
      data: UserUpdateType
    ) =>
      this.api.generalApi<boolean>(
        this.api.apisauce.post('/Common/Account/UpdateUser', data),
      )

  getUserInfo = async () =>
    this.api.generalApi<UserInfoType>(
      this.api.apisauce.get('/Common/Account/GetUserInfo'),
    )

  getValidateCodeImg = async () =>
    this.api.generalApi<ValidateCodeImgType>(
      this.api.apisauce.get('/Common/UserRegister/GetValidateCodeImg'),
    )
}
