export const semantic = {
  red: '#db2828',
  orange: '#f2711c',
  yellow: '#fbbd08',
  olive: '#b5cc18',
  green: '#21ba45',
  teal: '#00b5ad',
  blue: '#2185d0',
  violet: '#6435c9',
  purple: '#a333c8',
  pink: '#e03997',
  brown: '#a5673f',
  grey: '#767676',
  black: '#1b1c1d',
  info: {
    background: '#f8ffff',
    foreground: '#276f86',
  },
  warn: {
    background: '#fffaf3',
    foreground: '#573a08',
  },
  error: {
    background: '#fff6f6',
    foreground: '#9f3a38',
  },
  success: {
    background: '#fcfff5',
    foreground: '#2c662d',
  },
}
