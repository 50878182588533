import { types, flow, Instance, detach } from 'mobx-state-tree'
import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import moment from 'moment'
import { GeneralApiResponse, BookOrderListItemPayload } from '@mtr-SDO/apis'

import { BookOrderItemModel } from '@mtr-SDO/datamodels'
import { Base64 } from 'js-base64'

export const BookItemListStoreModel = types
  .model('BookItemListStore')
  .props({
    bookItemList: types.array(BookOrderItemModel),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .views((self) => ({
    findBookOrder(objectID: string) {
      return self.bookItemList.find((it) => it.objectID === objectID)
    },
  }))
  .actions((self) => {
    const actions = {
      fetchBookItemList: flow(function* fetchBookItemList(orderBookID: string, HKID: string) {
        detach(self.bookItemList)
        self.bookItemList.clear()
        const book = self.rootStore.booksStore.findBookOrder(orderBookID)
        const code = HKID? Base64.toBase64(HKID):''
        const res: GeneralApiResponse<BookOrderListItemPayload[]> =
          yield self.environment.api.getFormDataListByOrderBookItemID(
            orderBookID,
            book.workTypeID,
            code,
          )

        if (res.kind !== 'ok') throw new Error(res.kind)
        if ((res.message?.result ?? 0) === 0)
          throw Error(res.message?.message ?? 'empty-message')
        if (res.payload == null) throw new Error('no-data')
        const items: BookOrderListItemPayload[] = res.payload

        items.map(async (item) => {
          self.bookItemList.push({
            objectID: item.objectID,
            formNo: item.formNo,
            versionNo: item.versionNo,
            formName: item.formName,
            formReferenceNo: item.formReferenceNo,
            status: item.status,
            statusName: item.statusName,
            createDate: moment(item.createDate).toDate(),
            isReadOnly: item.isReadOnly,
            isSpecialForm:item.isSpecialForm??false,
            tips: item.tips ?? '',
            
            formIssueNo: item.formIssueNo,
            formRevNo: item.formRevNo,
            formIssueDate: item.formIssueDate,
            formRefNo: item.formRefNo
          })
          if(item.isReadOnly){
            self.rootStore.bookFormsStore.removeFormByBookItemID(item.objectID)
          }
          // if (!item.isReadOnly) {
          //   const form = self.rootStore.bookFormsStore.findForm(item.objectID)
          //   if (form == null) {
          //     await self.rootStore.bookFormsStore.getFormData(
          //       item.objectID,
          //       orderBookID,
          //     )
          //   }
          // }
        })
      }),
    }
    return actions
  })
export type BookItemListStore = Instance<typeof BookItemListStoreModel>
