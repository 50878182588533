import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { useForm, Controller, SubmitHandler, useWatch } from 'react-hook-form'
import {
  FormControl,
  Button,
  Grid,
  FormHelperText,
  createFilterOptions,
  FilterOptionsState,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { DepotType, VehicleType, WorkTypeType } from '@mtr-SDO/apis'
import { DialogMessage, Input, InputType, Spinner } from '@/components'
import {
  useCreateOrderBookItem,
  useFetchDepotList,
  useFetchVehicleList,
  useFetchWorkTypeList,
} from '@/queries'

const StyledContainer = styled.div`
  width: 90%;
  min-width: 350px;
  margin: 0 auto;
`
const StyledPageTitle = styled.span`
  font-weight: bold;
  font-size: 20px;
  display: block;
  width: 100%;
  padding: 3% 0;
  text-align: center;
  margin-top: 15px;
`
const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin-top: 18px;
`
const StyledLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
  display: block;
  text-align: right;
  margin-top: 12px;
  white-space: nowrap;
`
const StyledInput = styled(Input)`
  width: 100%;
`
const StyledFormHelperText = styled(FormHelperText)`
  font-size: 12px;
  margin-left: 12px;
  margin-right: 12px;
`
const StyledDiv = styled.div`
  margin-top: 18px;
  float: right;
  margin-bottom: 15px;
`
const StyledButton = styled(Button)`
  display: inline-block;
  width: 100px;
  aspect-ratio: 7/3;
  margin-left: 15px;
  text-transform: none;
  border-radius: 8px;
  font-size: 16px;
  &.styledPrimary {
    background-color: #367fa9;
    color: #f8f3f3;
  }
  &.styledDefault {
    background-color: #a0a1a1;
    color: #f8f7f7;
    aspect-ratio: 8/3;
  }
`
const StyledSpinner = styled(Spinner)`
  margin: 20px;
`

interface IFormInput {
  depot: number
  workType: string
  vehicleNo: Array<any>
  tractNo: string
  woNo: string
  hkid: string
}

type FormInputType = {
  [propName in keyof IFormInput]: {
    inputType?: keyof InputType
    label: string
    register: { [propName: string]: any }
    [propName: string]: any
  }
}
const filter = createFilterOptions<VehicleType>()
const formInputModel: FormInputType = {
  depot: {
    selectData: [],
    inputType: 'SingleSelect',
    getItemTextRender: (item: DepotType) => item.depotName,
    getItemValueRender: (item: DepotType) => item.id,
    tabLabel: 'Depot',
    label: '-- Please Select --',
    register: {
      required: 'The Depot field is required.',
    },
  },
  workType: {
    selectData: [],
    inputType: 'SingleSelect',
    getItemTextRender: (item: WorkTypeType) => item.workTypeName,
    getItemValueRender: (item: WorkTypeType) => item.workTypeID,
    tabLabel: 'Work type',
    label: '-- Please Select --',
    register: {
      required: 'The Work type field is required.',
    },
  },
  vehicleNo: {
    selectData: [],
    inputType: 'AutoMultiple',
    getItemValueRender: (item: VehicleType) => item,
    tabLabel: 'Vehicle No.',
    label: 'Train/Unit/Car',
    register: {
      required: 'The Vehicle No. field is required.',
    },
  },

  tractNo: {
    tabLabel: 'Tract No.',
    label: '',
    register: {
      maxLength: { value: 50, message: 'The length cannot exceed 50' },
      required: 'The Tract No. field is required.',
    },
  },
  woNo: {
    tabLabel: 'WO No.',
    label: '',
    register: {
      maxLength: { value: 50, message: 'The length cannot exceed 50' },
    },
  },
  hkid: {
    tabLabel: 'HKID',
    label: '',
    register: {
      maxLength: { value: 50, message: 'The length cannot exceed 50' },
      pattern: {
        value: /^([A-Z]\d{6,10}(\(\w{1}\))?)$/,
        message: 'Non-compliant Hong Kong resident ID number',
      },
    },
  },
}

export const BookCreatePageAPP = observer(() => {
  const { t } = useTranslation()
  const { control, handleSubmit, setValue } = useForm<IFormInput>()
  const depotValue = useWatch({ control, name: 'depot' })
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [dialogMessage, SetDialogMessage] = useState([
    'You do not have related CQA qualification!',
    'You cannot register!',
  ])
  const { data: depotListData } = useFetchDepotList()
  const { data: workTypeListData } = useFetchWorkTypeList(
    depotValue as unknown as number,
    {
      enabled: !!depotValue,
    },
  )
  const { data: vehicleListData } = useFetchVehicleList(
    depotValue as unknown as number,
    {
      enabled: !!depotValue,
    },
  )
  formInputModel.depot.selectData = depotListData
  formInputModel.workType.selectData = workTypeListData
  formInputModel.vehicleNo.selectData = vehicleListData

  useEffect(() => {
    setValue('workType', '')
    setValue('vehicleNo', [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depotValue])

  const handleClose = () => {
    setOpen(false)
  }
  const { mutateAsync: createOrderBookItemAsync, isLoading } =
    useCreateOrderBookItem({
      onMutate(variables) {
        setOpen(true)
        return variables
      },
      onError(error) {
        SetDialogMessage([error.message])
      },
      onSuccess(data) {
        if (data.kind === 'ok') SetDialogMessage([data.payload.data])
      },
    })
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    try {
      await createOrderBookItemAsync({
        workTypeID: data.workType,
        depotID: data.depot,
        depotCode:
          depotListData?.find((depot) => data.depot === depot.id)?.depotCode ??
          '',
        vehicleNos: data.vehicleNo.join(';'),
        trackNo: data.tractNo,
        woNo: data.woNo,
        hkid: data.hkid,
        createBy: '',
      })
    } catch {}
  }

  const handleBack = () => {}
  return (
    <StyledContainer>
      <StyledPageTitle>{t(`bookCreatePageAPP.title`)}</StyledPageTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormControl>
          {Object.keys(formInputModel).map((i) => {
            const key: keyof IFormInput = i as keyof IFormInput
            const {
              inputType = 'TextInput',
              selectData = [],
              tabLabel,
              label,
              register,
              getItemTextRender = undefined,
              getItemValueRender = undefined,
            } = formInputModel[key]
            return (
              <Controller
                key={key}
                name={key}
                rules={register}
                control={control}
                defaultValue=""
                render={({ field, formState: { errors } }) => {
                  const inputError = errors[key]
                  return (
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <StyledLabel>
                          {tabLabel}{' '}
                          {register.required ? (
                            <span style={{ color: 'red' }}>*</span>
                          ) : (
                            ''
                          )}
                        </StyledLabel>
                      </Grid>
                      <Grid item xs={9}>
                        <StyledInput
                          inputTagName={inputType}
                          name={field.name}
                          {...(inputType === 'TextInput'
                            ? { ...field }
                            : {
                                ...(inputType === 'SingleSelect'
                                  ? { selectProps: { ...field } }
                                  : inputType === 'AutoMultiple'
                                  ? {
                                      autocompleteProps: {
                                        ...field,
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        onChange(_: any, value: Array<any>) {
                                          field.onChange(value)
                                        },
                                        filterOptions(
                                          options: any,
                                          params: FilterOptionsState<string>,
                                        ) {
                                          if (!params.inputValue.trim()) {
                                            return []
                                          }
                                          const filtered = filter(
                                            selectData ?? [],
                                            params,
                                          )
                                          return filtered.slice(0, 20)
                                        },
                                        inputValue,
                                        onInputChange(
                                          // eslint-disable-next-line @typescript-eslint/naming-convention
                                          _: any,
                                          newInputValue: string,
                                        ) {
                                          setInputValue(newInputValue)
                                        },
                                      },
                                    }
                                  : {}),
                                selectData,
                                getItemTextRender,
                                getItemValueRender,
                              })}
                          error={!!inputError}
                          label={label}
                          textHelper={inputError?.message ?? ' '}
                        />
                      </Grid>
                    </Grid>
                  )
                }}
              />
            )
          })}
          <Grid container spacing={-1}>
            <Grid item xs={3} />
            <Grid item>
              <StyledFormHelperText>
                Pls input HKID for CQA qualification check
                <br />
                For example, HKID is A123456(7), Pls input A1234567
              </StyledFormHelperText>
            </Grid>
          </Grid>
          <div>
            <StyledDiv>
              <StyledButton
                disableElevation
                variant="contained"
                className="styledDefault"
                onClick={handleBack}
              >
                {t(`back`)}
              </StyledButton>
              <StyledButton
                disableElevation
                variant="contained"
                className="styledPrimary"
                type="submit"
              >
                {t(`bookCreatePageAPP.create`)}
              </StyledButton>
            </StyledDiv>
            <DialogMessage
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
              contentTextArray={dialogMessage}
              confirmButtonProps={{ children: t('ok'), onClick: handleClose }}
              {...{
                ...(isLoading
                  ? {
                      customContent: <StyledSpinner />,
                      onClose: undefined,
                      customDialogActions: <></>,
                    }
                  : { fullWidth: true, maxWidth: 'xs' }),
              }}
            />
          </div>
        </StyledFormControl>
      </form>
    </StyledContainer>
  )
})
