import { useTheme } from '@mtr-SDO/theme-core'
import { memo, useMemo } from 'react'
import { HelmetProvider, Helmet as ReactHelmet } from 'react-helmet-async'

type Props = {
  title?: string
  description?: string
}

const sdoConfig = {
  title: 'Form System',
  description: '',
}

export const Helmet = memo<Props>((props) => {
  const config = sdoConfig
  const { description } = {
    ...sdoConfig,
    ...props,
  }

  const title = useMemo(
    () =>
      props.title != null ? `${props.title} - ${config.title}` : config.title,
    [config.title, props.title],
  )

  const {
    colors: { primary },
  } = useTheme()

  return (
    <HelmetProvider>
      <ReactHelmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="theme-color" content={primary} />
        <meta name="description" content={description} />

        <meta name="apple-mobile-web-app-title" content={title} />
        <meta name="application-name" content={title} />
      </ReactHelmet>
    </HelmetProvider>
  )
})
