import { NotificationPayload } from '@mtr-SDO/apis'
import moment from 'moment'
import { IEnvironment } from '../../../core'

type NotificationActionPayload = {
  type:
    | 'VerifierToEndorser'
    | 'EndorserRejectVerifier'
    | 'WheelCheckFormValidation'
  action: 'navigate-review' | undefined
  formGroupId: string
  formDataId: string
  workOrderNumber: string
  workGroupId?: string
  equipmentNumber: string
}

export type NotificationItem = Pick<
  NotificationPayload,
  'objectID' | 'title' | 'message'
> & {
  time: moment.Moment | undefined
  payload: NotificationActionPayload | undefined
  hasRead: boolean
}

export function processNotificationPayload(
  notificationPayload: NotificationPayload[],
  environment: IEnvironment,
): NotificationItem[] {
  return notificationPayload.map((it) => {
    const { payload: payloadString } = it
    let payload: NotificationActionPayload | undefined

    if (payloadString != null) {
      try {
        const payloadObject = JSON.parse(payloadString) as {
          [key: string]: any
        }

        if (
          [
            'VerifierToEndorser',
            'EndorserRejectVerifier',
            'WheelCheckFormValidation',
          ].includes(it.notificationType ?? '')
        ) {
          payload = {
            type: it.notificationType as
              | 'VerifierToEndorser'
              | 'EndorserRejectVerifier'
              | 'WheelCheckFormValidation',
            action:
              it.payloadAction === 'Open_Review_FormData'
                ? 'navigate-review'
                : undefined,
            formGroupId: payloadObject.FormGroupDataObjectID,
            formDataId: payloadObject.FormDataObjectID,
            workOrderNumber: payloadObject.WorkOrder,
            workGroupId: payloadObject.WorkGroupID,
            equipmentNumber: payloadObject.EquipmentNo,
          }
        }
      } catch (error) {
        environment.console.error('Failed in parsing notification payload', it)
        environment.console.reportError(error)
        payload = undefined
      }
    }

    let hasRead = true

    try {
      hasRead = JSON.parse(it.hasRead.toLowerCase())
    } catch (error) {
      environment.console.error('Failed in parsing notification hasRead', it)
      environment.console.reportError(error)
    }

    return {
      ...it,
      payload,
      hasRead,
      time: it.createDate != null ? moment(it.createDate) : undefined,
    }
  })
}
