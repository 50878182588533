export type IconProps = {
  type: 'material-community' | 'fontisto' | 'material' | 'font-awesome'
  name: string
}

export const webIcons: { [key: string]: IconProps } = {
  check: {
    type: 'material-community',
    name: 'check',
  },
  add: {
    type: 'material-community',
    name: 'plus-circle-outline',
  },
  delete: {
    type: 'material-community',
    name: 'close-circle-outline'
  },
  camera: {
    type: 'material-community',
    name: 'camera'
  },
}
