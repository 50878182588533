export const general = {
  transparent: 'rgba(0, 0, 0, 0)',
  blackTransparent: 'rgba(0,0,0,0.8)',
  greyTransparent: 'rgba(0,0,0,0.4)',
  whiteTransparent: 'rgba(255, 255, 255, 0.3)',
  lightGreyTransparent: 'rgba(0,0,0,0.2)',

  grayishBlues: ['#D2DDF3', '#F7F8FC'],
  black: '#000000',
  white: '#FFFFFF',
  offWhite: '#e6e6e6',

  lightGrey: '#939AA4',
  greys: [
    '#000000',
    '#333333',
    '#4F4F4F',
    '#828282',
    '#BDBDBD',
    '#E0E0E0',
    '#F2F2F2',
    '#F7F8FC',
    '#F4F4F4',
  ],

  background: '#F1F1F5',
  hover: '#3A5386',
  selected: '#EFF4FF',
  noSelected: '#CBD6ED',

  orange: '#FFA610',
  darkRed: '#AC383D',
  divider: '#F4F8FF',
  corporateBlue: '#07224E',
  lightBlue: '#DFEAFF',
  lightYellow: '#FEFFD6',
  lightOrange: '#FFF3CB',
}

export const wicn = {
  main: '#7C9CC9',
  light: '#A8C0E3',
  background: '#E7E8ED',
  contrastText: '#FFFFFF',
  completed: '#07224E',
  fab: '#0B53E7',
}

export const wiReview = {
  main: '#669286',
  light: '#93B7AD',
  background: '#CBE0DB',
  contrastText: '#FFFFFF',
  completed: '#08543F',
  fab: '#06B484',
}

export const taskPriority = {
  high: '#AC383D',
  medium: '#F9BF5E',
  low: '#407527',
  na: '#939AA4',
}

export const listFilterStatus = {
  pending: {
    background: '#ffa404',
  },
  history: {
    background: '#6a55de',
  },
  contrastText: '#FFFFFF',
}
