import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import {
  flow,
  Instance,
  types,
} from 'mobx-state-tree'
import { GetUserInfo, GetValidateCodeImg, PostCreateUser, PostUpdateUser, UserCreateType, UserUpdateType } from '@mtr-SDO/apis'
import { SignInStatusOption } from './types'

export const UserOperationStoreModel = types
  .model('UserOperationStore', {
    sigiInStatusState: types.optional(types.enumeration<SignInStatusOption>(SignInStatusOption.contractorUser, Object.keys(SignInStatusOption) as SignInStatusOption[]), SignInStatusOption.contractorUser)
  })
  .actions((self) => {
    const actions = {
      setSigiInRegisterState(sigiInStatusState: SignInStatusOption) {
        self.sigiInStatusState = sigiInStatusState
        return sigiInStatusState
      }
    }
    return actions
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .actions((self) => {
    const actions = {
      createUser: flow(function* (userData: UserCreateType) {
        const res: PostCreateUser = yield self.environment.api.userOperation.createUser(userData)
        if (res.kind !== 'ok') throw new Error(`create-user|${res.kind}`)
        if (!res.payload.success || res.payload.data == null)
          throw new Error(`create-user|${res.payload.errorMessage}`)
        return res
      }),
      updateUser: flow(function* (userData: UserUpdateType) {
        const res: PostUpdateUser = yield self.environment.api.userOperation.updateUser(userData)
        if (res.kind !== 'ok') throw new Error(`update-user|${res.kind}`)
        console.log(res.payload)
        if (!res.payload.success)
          throw new Error(`update-user|${res.payload.errorMessage}`)
        return res
      }),
      fetchUserInfo: flow(function* () {
        const res: GetUserInfo = yield self.environment.api.userOperation.getUserInfo()
        if (res.kind !== 'ok') throw new Error(`fetch-user-info|${res.kind}`)
        return res.payload
      }),
      fetchValidateCodeImg: flow(function* () {
        const res: GetValidateCodeImg = yield self.environment.api.userOperation.getValidateCodeImg()
        if (res.kind !== 'ok') throw new Error(`fetch-validate-code-img|${res.kind}`)
        return res.payload
      }),
    }
    return actions
  })

export type UserOperationStore = Instance<typeof UserOperationStoreModel>
