import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'

const Root = styled.div`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacings[6]}px;
`

const TaskListContainer = styled.div`
  width: 100%;
  padding: 0px ${({ theme }) => theme.spacings[5]}px
    ${({ theme }) => theme.spacings[5]}px ${({ theme }) => theme.spacings[5]}px;
`

export const HomePage = observer(() => (
  <Root>
    <TaskListContainer>
      HomePage
    </TaskListContainer>
  </Root>
))
