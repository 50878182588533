import Axios, { AxiosRequestConfig } from 'axios'
import { BlobHandler } from './blob-handler.types'
import { timeoutRequest } from './timeout-request'

export const listContainer: (params: {
  path: string
  endpoint: string
  sasToken: string
  timeout?: number
}) => Promise<any[]> = ({ path, endpoint, sasToken, timeout }) => {
  const params: [string, AxiosRequestConfig?] = [
    `${endpoint}${path}${sasToken}`,
    { timeout: timeout || 3000 },
  ]
  return timeoutRequest(Axios.get(...params), Axios.defaults, params)
}

export const createContainer: (
  container: string,
  params: {
    endpoint: string
    sasToken: string
    timeout: number
  },
  blobHandler: BlobHandler,
) => Promise<void> = (
  container,
  { endpoint, sasToken, timeout },
  blobHandler,
) => {
  const dest = `${endpoint}${container}`

  const promise: Promise<void> = blobHandler
    .put(`${dest}?restype=container&${sasToken}`, {
      Accept: 'application/json',
    })
    .then((res) => {
      if (res.respInfo.status < 200 || res.respInfo.status >= 300)
        throw res.data
    })

  console.info({
    name: 'Put container',
    preview: dest,
    value: dest,
  })

  const timeoutPromise = new Promise((resolve) =>
    setTimeout(() => resolve('timeout'), timeout || 30000),
  )

  return Promise.race([timeoutPromise, promise]).then((res: string) => {
    if (res !== dest) throw Error(res)
    // return res
  })
}

export const postFile: (
  attachment: {
    mime: string
    type: string | undefined
  } & (
    | {
        platform: 'app'
        temporaryPath: string
        title: string | undefined
      }
    | {
        platform: 'web'
        file: File
      }
  ),
  container: string,
  params: {
    endpoint: string
    sasToken: string
    timeout: number
  },
  blobHandler: BlobHandler,
  progressCallback: (written: number, total: number) => void,
) => Promise<string> = async (
  attachment,
  container,
  { endpoint, sasToken, timeout },
  blobHandler,
  progressCallback = (written: number, total: number) => {
    console.info(`Upload Progress: ${written}/${total}`)
  },
) => {
  let fileTitle: string
  if (attachment.platform === 'app') {
    fileTitle = new Date().getTime() + '' //attachment.title ?? ''
  } else {
    fileTitle = attachment.file.name
  }

  const filename =
    attachment.type === 'file'
      ? `${fileTitle}`
      : `${fileTitle}.${
          attachment.platform === 'app'
            ? attachment.temporaryPath.split('.').pop()
            : ''
        }`
  const dest = `${endpoint}${container}/${filename}`
  let promise
  let envProps

  if (attachment.platform === 'app') {
    envProps = {
      platform: attachment.platform,
      filename,
      path: attachment.temporaryPath,
      httpHeader: {
        Accept: 'application/json',
        'x-ms-blob-type': 'BlockBlob',
      },
    }
  } else {
    envProps = {
      platform: attachment.platform,
      file: attachment.file,
    }
  }
  promise = blobHandler.put(endpoint, sasToken, container, envProps)
  if (promise.uploadProgress != null) {
    promise = promise.uploadProgress(progressCallback)
  }
  promise = promise
    .then((res) => {
      if (res.respInfo.status < 200 || res.respInfo.status >= 300)
        throw res.data
    })
    .then(() => dest)
  console.info({
    name: 'Put blob',
    preview: dest,
    value: dest,
  })

  const timeoutPromise = new Promise((resolve) =>
    setTimeout(() => resolve('timeout'), timeout || 30000),
  )

  return Promise.race([timeoutPromise, promise]).then((res: string) => {
    if (res !== dest) throw Error(res)
    return res
  })
}
