import { Api, ApiConfig } from '@mtr-SDO/apis'
import { getEnv, IStateTreeNode } from 'mobx-state-tree'
import type Xml2js from 'xml2js'

type StorageOptions = {
  keyId?: string
  keepOriginalFile?: boolean
}

export type StorageHandler = {
  initialize(): Promise<void>
  clear(): Promise<void>

  text: {
    supportLargeFile: boolean
    read(key: string, opts?: StorageOptions): Promise<string>
    save(key: string, value: string, opts?: StorageOptions): Promise<void>
    remove(key: string, opts?: StorageOptions): Promise<void>
    isExist(key: string, opts?: StorageOptions): Promise<boolean>
  }

  binary?: {
    getStatus(
      filename: string,
    ): Promise<{ decrypted: boolean; encrypted: boolean }>
    getSize(filename: string): Promise<number>
    moveIn(
      filepath: string,
      keepOrigin?: boolean,
      disallowRename?: boolean,
    ): Promise<string>

    encrypt(filename: string, opts?: StorageOptions): Promise<void>
    decrypt(filename: string, opts?: StorageOptions): Promise<string>
  }

  paths?: {
    getFilepath(
      filename: string,
      directoryType: 'text' | 'encrypted' | 'decrypted',
    ): string
    getMime(filename: string): string | undefined
  }

  network?: {
    download(param: {
      url: string
      destination?: string
      onProgress?: (progress: {
        contentLength: number
        bytesWritten: number
      }) => void
    }): Promise<string>
  }
}

export interface IEnvironment {
  platform: 'mobile' | 'web'

  storageHandler?: StorageHandler
  console: {
    display: (config?: {
      name: string
      value: any
      preview: string
      important?: boolean
    }) => void
    log: (...args: any[]) => void
    warn: (...args: any[]) => void
    error: (...args: any[]) => void
    reportError(error: any): void
  }

  api: Api
  setupApi(config: ApiConfig, unauthorizedHandle?: () => void): Promise<void>

  xml2Js: typeof Xml2js
}

/**
 * Adds a environment property to the node for accessing our
 * Environment in strongly typed.
 */
export const withEnvironment = (self: IStateTreeNode | any) => ({
  views: {
    /**
     * The environment.
     */
    get environment() {
      return getEnv<IEnvironment>(self)
    },
  },
})
