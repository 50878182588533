import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import { Instance, types } from 'mobx-state-tree'

export const UiStoreModel = types
  .model('UIStore', {
    LoggedIn: types.optional(types.boolean, false),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .views((self) => ({
    get isLoggedIn() {
      return self.LoggedIn
    },
  }))
  .actions((self) => ({
    setLoggedIn(val: boolean) {
      self.LoggedIn = val
    },
  }))

export type UiStore = Instance<typeof UiStoreModel>
