import { withRootStore } from '@mtr-SDO/models-core'
import { flow, getParent, Instance, types } from 'mobx-state-tree'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { FormDefinitionItem } from '../definition'
import {
  FormDataItemValue,
  FormDefinitionAutofillResult,
  FormDefinitionValidationResult,
  NAType,
  NA_VALUE,
} from '../helpers'
import {
  fastValidate,
  formulaEvaluate,
  RuleModes,
  serializeValidationOption,
  validateFormDataItem,
  ValidationOptions,
} from '../validations'
import { MockFormData } from './mock-types'

export const FormDataItemModel = types
  .model({
    id: types.optional(types.identifier, uuid),
    inputId: types.string,
    notApplicableSelf: types.optional(types.boolean, false),
    value: types.maybe(
      types.union(types.string, types.number, types.array(types.string)),
    ),
    isReadOnly: types.maybe(types.boolean),
    createddate: types.optional(types.Date, () => moment().toDate()),
    lastmoddate: types.optional(types.Date, () => moment().toDate()),
    groupID: types.maybe(types.string),
  })
  .extend(withRootStore)
  .volatile(() => ({
    validationResult: {} as {
      [key: string]: FormDefinitionValidationResult<FormDataItemValue>[]
    },
    autofillResult: {} as FormDefinitionAutofillResult<FormDataItemValue>,
    reValidateInterrupt: 0, // incremental number indicate it need to reValidate
  }))
  .preProcessSnapshot((snapshot) => ({
    ...snapshot,
    value: snapshot.value ?? undefined,
    isReadOnly : snapshot.isReadOnly ?? false
  }))
  .views((self) => {
    const views = {
      /** Parent form data of this item */
      get formData(): MockFormData {
        return getParent(self, 2)
      },
      /** Corresponding form definition item of this item */
      get formDefinitionItem(): FormDefinitionItem {
        return views.formData.formDefinition?.findItemWithKey(self.inputId)
      },

      isItemInSameGroup(compareInputId: string): boolean {
        let isSameGroup: boolean = false
        views.formData.formDefinition.groups.some((g) => {
          const anyMatchedItems = g.itemsRecursively.filter(
            (it) =>
              it.inputId === self.inputId || it.inputId === compareInputId,
          )
          if (anyMatchedItems.length === 2) {
            isSameGroup = true
            return true
          }
          return false
        })
        return isSameGroup
      },
      findTargetFormDefinitionItem(
        inputId: string,
        acceptMerged: boolean,
      ): FormDefinitionItem {
        if (acceptMerged) {
          const currentRecord =
            views.formData.formDefinition?.findItemWithKey(inputId)
          const mergedRecord =
            views.formData.workOrderForm?.mergedFormData?.formDefinition?.findItemWithKey(
              inputId,
            )
          if (currentRecord?.value != null) return currentRecord
          return mergedRecord
        }
        return views.formData.formDefinition?.findItemWithKey(inputId)
      },

      /** Get specific form items by using input-id  */
      findTargetFormDataItem(inputId: string, acceptMerged: boolean) {
        if (acceptMerged) {
          const currentRecord = views.formData.getFieldItemById(inputId)
          const mergedRecord =
            views.formData.workOrderForm?.mergedFormData?.getFieldItemById(
              inputId,
            )

          if (currentRecord?.value != null || currentRecord?.notApplicableSelf)
            return currentRecord
          return mergedRecord
        }

        return views.formData.items?.find((item) => item.inputId === inputId)
      },
      get findMergedDataItem() {
        return views.formData.workOrderForm?.mergedFormData?.getFieldItemById(
          self.inputId,
        )
      },
      isChildItemsFilled(): boolean {
        const subItemInputIds = views.formDefinitionItem?.itemsRecursively.map(
          (it) => it.inputId,
        )
        const anyChanges =
          views.formData.items.filter(
            (it) =>
              subItemInputIds?.includes(it.inputId) &&
              (it.value != null || it.notApplicableSelf),
          )?.length > 0
        return anyChanges
      },

      /** Is this item currently not applicable, by self or inherit */
      isNotApplicable(): NAType {
        return views.formData.isItemNotApplicable(views.formDefinitionItem)
      },

      /** Is this item currently empty, either not filled, removed, empty string or empty array
       * WARNING returning false if it is marked as self N/A
       */
      get isEmpty() {
        if (self.notApplicableSelf) return false
        return self.value == null || self.value === '' || self.value === []
      },

      isFilledOrNotApplicable() {
        if (views.isNotApplicable()) return true
        return !views.isEmpty
      },

      uploadValue(): FormDataItemValue | undefined {
        if (views.isNotApplicable()) return NA_VALUE
        return self.value
      },
    }
    return views
  })
  .views((self) => {
    const views = {
      findHostItems(ruleMode: RuleModes): any[] | undefined {
        const hosts =
          ruleMode === RuleModes.validationRule
            ? self.formData.formDefinition.validateRelationMap[self.inputId]
            : self.formData.formDefinition.autofillRelationMap[self.inputId]
        if (hosts == null) return undefined
        return self.formData.items?.filter((item) =>
          hosts.includes(item.inputId),
        )
      },
      chainValidate() {
        // if I am a parameter, find all of my host to trigger Validate
        const hosts = views.findHostItems(RuleModes.validationRule)
        if (hosts == null) return
        hosts.forEach((it: FormDataItem) => {
          // same group: call ui re-validate
          if (self.isItemInSameGroup(it.inputId)) {
            it.emitReValidateInterrupt()
          } else {
            // not the same group: unmount, background validate
            fastValidate(it, undefined, true)
          }
        })
      },
    }
    return views
  })
  .actions((self) => {
    const actions = {
      /**
       * Return array of validation result, or undefined if the value has been updated after validation procedure started
       */
      validate: flow(function* validate(
        opts?: ValidationOptions,
        byPassCaching?: boolean,
      ): Generator<
        any,
        FormDefinitionValidationResult<FormDataItemValue>[] | undefined,
        FormDefinitionValidationResult<FormDataItemValue>[]
      > {
        const key = serializeValidationOption(self.formDefinitionItem, opts)

        // NOTE use cache value here improve first time expand performance
        if (!byPassCaching && self.validationResult[key])
          return self.validationResult[key]

        const validatingValue = self.value

        const result = yield validateFormDataItem(
          self,
          self.formDefinitionItem,
          self.rootStore,
          opts,
        )

        if (validatingValue !== self.value) return undefined
        self.validationResult[key] = result ?? []

        return self.validationResult[key]
      }),
      setValidationResult(key: string, result: any) {
        self.validationResult[key] = result ?? []
      },
      emitReValidateInterrupt() {
        self.reValidateInterrupt += 1
      },
      autofill: flow(function* autofill(): Generator<
        any,
        FormDefinitionAutofillResult<FormDataItemValue> | undefined,
        FormDefinitionAutofillResult<FormDataItemValue>
      > {
        // field that configured as autofill never invoke autofill() directly
        // let the paramter field invoke for you
        const result = yield formulaEvaluate(self, self.formDefinitionItem)
        if (result?.status) {
          if (result?.value == null) return undefined
          // self.value = result.value
          self.autofillResult = result
          yield self.formData.updateItemValue(
            self.formDefinitionItem,
            result.value,
          )
          return result
        }
        self.autofillResult = result ?? undefined
        return self.autofillResult
      }),
      clearAutofillResult() {
        self.autofillResult = { status: true }
      },

      setNotApplicable(value: boolean) {
        self.notApplicableSelf = value
        self.validationResult = {}
        self.lastmoddate = moment().toDate()
      },
      set(value: FormDataItemValue | undefined) {
        if (value != null) {
          self.value = value
        } else {
          self.value = undefined
        }
        self.validationResult = {}
        self.lastmoddate = moment().toDate()
      },
      trySet: flow(function* trySet(
        value: FormDataItemValue | undefined,
        validationOptions?: ValidationOptions,
      ): Generator<
        any,
        FormDefinitionValidationResult<FormDataItemValue>[] | undefined
      > {
        const originalValue = self.value
        self.value = value ?? undefined
        self.validationResult = {}
        self.lastmoddate = moment().toDate()

        const result:
          | FormDefinitionValidationResult<FormDataItemValue>[]
          | undefined = (yield actions.validate(validationOptions)) as any

        const eraseData =
          (result ?? []).filter((it) => it.eraseData ?? it.rejectSave).length >
          0
        if ((result ?? []).filter((it) => it.rejectSave).length > 0) {
          if (eraseData) {
            self.value = undefined
          } else {
            self.value = originalValue
          }
        }
        return result
      }),
    }

    return actions
  })

export type FormDataItem = Instance<typeof FormDataItemModel>
