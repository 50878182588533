import { types } from 'mobx-state-tree'

export enum WICNStatus {
  partA = 'part-a',
  partB = 'part-b',
  partC = 'part-c',
  partD = 'part-d',
  partE = 'part-e',
  partF = 'part-f',
  partG = 'part-g',
  comment = 'comment',
  completed = 'completed',
}

export enum WICNNodeId {
  partB = 2,
  partC = 3,
  partD = 4,
  partE = 5,
  partF = 6,
  partG = 7,
}

export function getWICNSnapShotStatus(code: number | undefined): WICNStatus {
  switch (code) {
    case 12:
      return WICNStatus.partA
    case 13:
      return WICNStatus.partB
    case 14:
      return WICNStatus.partC
    case 15:
      return WICNStatus.partD
    case 16:
      return WICNStatus.partE
    case 17:
      return WICNStatus.partF
    case 18:
      return WICNStatus.partG
    case 1001:
      return WICNStatus.comment
    case 188:
      return WICNStatus.completed
    default:
      return WICNStatus.partA
  }
}

export function getWICNStatusAbbreviation(status: WICNStatus): string {
  switch (status) {
    case WICNStatus.partA:
      return 'A'
    case WICNStatus.partB:
      return 'B'
    case WICNStatus.partC:
      return 'C'
    case WICNStatus.partD:
      return 'D'
    case WICNStatus.partE:
      return 'E'
    case WICNStatus.partF:
      return 'F'
    case WICNStatus.partG:
      return 'G'
    case WICNStatus.comment:
      return 'E'
    default:
      return 'A'
  }
}

export function getWICNPartEnum(status: WICNStatus): number {
  switch (status) {
    case WICNStatus.partA:
      return 1
    case WICNStatus.partB:
      return 2
    case WICNStatus.partC:
      return 3
    case WICNStatus.partD:
      return 4
    case WICNStatus.partE:
      return 5
    case WICNStatus.partF:
      return 6
    case WICNStatus.partG:
      return 7
    default:
      return 1
  }
}

export function getWICNStatusNumberByValue(
  value: WICNStatus | undefined,
): number | undefined {
  switch (value) {
    case WICNStatus.partA:
      return 12
    case WICNStatus.partB:
      return 13
    case WICNStatus.partC:
      return 14
    case WICNStatus.partD:
      return 15
    case WICNStatus.partE:
      return 16
    case WICNStatus.partF:
      return 17
    case WICNStatus.partG:
      return 18
    case WICNStatus.completed:
      return 188
    default:
      return undefined
  }
}

export const WICNStatusEnum = types.enumeration(Object.values(WICNStatus))
