import {
  AttunityWorkOrderStatus,
  GetEquipmentAssemblyChilds,
  GetEquipmentChilds,
  GetEquipmentStructure,
  GetInventoryStock,
  SearchEquipmentAssemblyChildsPayload,
  SearchEquipmentAssemblyDetail,
  SearchEquipmentAssemblyDetailPayload,
  SearchEquipmentChildsPayload,
  SearchEquipmentDetail,
  SearchEquipmentDetailPayload,
  SearchEquipmentFitmentPayloadItem,
  SearchEquipmentFitments,
  SearchEquipmentStructuralConfigPayload,
  SearchEquipmentWorkOrderPayloadItem,
  SearchEquipmentWorkOrders,
  SearchInventoryDetailPayload,
  SearchWorkOrderDetailPayload,
} from '@mtr-SDO/apis'
import { IEnvironment } from '@mtr-SDO/models-core'
import _ from 'lodash'
import { flow, IStateTreeNode } from 'mobx-state-tree'

export const withAttunitySearch = (
  self: IStateTreeNode & { environment: IEnvironment },
) => ({
  actions: {
    searchEquipmentAssemblyDetail: flow(function* searchEquipmentAssemblyDetail(
      equipmentNumber: string,
    ) {
      const res: SearchEquipmentAssemblyDetail =
        yield self.environment.api.searchEquipmentAssemblyDetail(
          equipmentNumber,
        )
      if (res.kind !== 'ok') throw new Error(res.kind)
      if (res.message?.result !== 1) throw new Error(res.message.message)

      if (res.payload == null) return null

      return res.payload as unknown as SearchEquipmentAssemblyDetailPayload
      // _.mapKeys(res.payload, (val, key) =>
      //   _.camelCase(key.toUpperCase()),
      // ) as SearchEquipmentAssemblyDetailPayload
    }),

    getEquipmentAssemblyChilds: flow(function* getEquipmentAssemblyChilds(
      equipmentNumber: string,
    ) {
      const res: GetEquipmentAssemblyChilds =
        yield self.environment.api.getEquipmentAssemblyChilds(equipmentNumber)

      if (res.kind !== 'ok') throw new Error(res.kind)
      if (res.message?.result !== 1) throw new Error(res.message.message)

      return {
        type: 'structural-config-assembly-child',
        childs: res.payload as { [key: string]: any },
        // .map(item =>
        //   _.mapKeys(item, (val, key) => _.camelCase(key.toUpperCase())),
        // ),
      } as SearchEquipmentAssemblyChildsPayload
    }),
    searchEquipmentDetail: flow(function* searchEquipmentDetail(
      equipmentNumber: string,
    ) {
      const res: SearchEquipmentDetail =
        yield self.environment.api.searchEquipmentDetail(equipmentNumber)
      if (res.kind !== 'ok') throw new Error(res.kind)
      if (res.message?.result !== 1) throw new Error(res.message.message)

      if (res.payload == null) return null

      return _.mapKeys(res.payload, (val, key) =>
        _.camelCase(key.toUpperCase()),
      ) as SearchEquipmentDetailPayload
    }),

    searchEquipmentWorkOrders: flow(function* searchEquipmentWorkOrders(
      equipmentNumber: string,
      searchCriteria: {
        status: AttunityWorkOrderStatus
        planStartDates?: string[]
        planCompleteDates?: string[]
        actualStartDates?: string[]
        actualCompleteDates?: string[]
      },
      { pageSize, pageIndex }: { pageSize: number; pageIndex: number },
    ) {
      const res: SearchEquipmentWorkOrders =
        yield self.environment.api.searchEquipmentWorkOrders(
          equipmentNumber,
          {
            status: searchCriteria.status,
            planStartDate1: searchCriteria.planStartDates?.[0],
            planStartDate2: searchCriteria.planStartDates?.[1],
            planCompleteDate1: searchCriteria.planCompleteDates?.[0],
            planCompleteDate2: searchCriteria.planCompleteDates?.[1],
            actualStartDate1: searchCriteria.actualStartDates?.[0],
            actualStartDate2: searchCriteria.actualStartDates?.[1],
            actualCompleteDate1: searchCriteria.actualCompleteDates?.[0],
            actualCompleteDate2: searchCriteria.actualCompleteDates?.[1],
          },
          pageSize,
          pageIndex,
        )
      if (res.kind !== 'ok') throw new Error(res.kind)
      if (res.message?.result !== 1) throw new Error(res.message.message)
      return res.payload.map((item) => ({
        type: 'equipment-work-order',
        ..._.mapKeys(item, (val, key) => _.camelCase(key.toUpperCase())),
      })) as SearchEquipmentWorkOrderPayloadItem[]
    }),

    searchEquipmentFitments: flow(function* searchEquipmentFitments(
      equipmentNumber: string,
      pageSize: number,
      pageIndex: number,
    ) {
      const res: SearchEquipmentFitments =
        yield self.environment.api.searchEquipmentFitments(
          equipmentNumber,
          pageSize,
          pageIndex,
        )
      if (res.kind !== 'ok') throw new Error(res.kind)
      if (res.message?.result !== 1) throw new Error(res.message.message)
      return res.payload.map((item) => ({
        type: 'equipment-fitment',
        ..._.mapKeys(item, (val, key) => _.camelCase(key.toUpperCase())),
      })) as SearchEquipmentFitmentPayloadItem[]
    }),

    getEquipmentStructure: flow(function* getEquipmentStructure(
      equipmentNumber: string,
    ) {
      const res: GetEquipmentStructure =
        yield self.environment.api.getEquipmentStructure(equipmentNumber)
      if (res.kind !== 'ok') throw new Error(res.kind)
      if (res.payload == null) return null
      if (res.message?.result !== 1) throw new Error(res.message.message)

      return {
        type: 'structural-config',
        ..._.mapKeys(res.payload, (val, key) => _.camelCase(key.toUpperCase())),
      } as SearchEquipmentStructuralConfigPayload
    }),

    getEquipmentChilds: flow(function* getEquipmentChilds(
      equipmentNumber: string,
    ) {
      const res: GetEquipmentChilds =
        yield self.environment.api.getEquipmentChilds(equipmentNumber)

      if (res.kind !== 'ok') throw new Error(res.kind)
      if (res.message?.result !== 1) throw new Error(res.message.message)
      return {
        type: 'structural-config-child',
        childs: res.payload.map((item) =>
          _.mapKeys(item, (val, key) => _.camelCase(key.toUpperCase())),
        ),
      } as SearchEquipmentChildsPayload
    }),

    searchWorkOrderDetail: flow(function* searchWorkOrderDetail(
      workOrderNumber: string,
    ) {
      const res: SearchEquipmentDetail =
        yield self.environment.api.searchWorkOrderDetail(workOrderNumber)
      if (res.kind !== 'ok') throw new Error(res.kind)
      if (res.message?.result !== 1) throw new Error(res.message.message)

      return {
        type: 'work-order',
        ..._.mapKeys(res.payload, (val, key) => _.camelCase(key.toUpperCase())),
      } as SearchWorkOrderDetailPayload
    }),

    retrieveInventoryStock: flow(function* retrieveInventoryStock(
      stockCode: string,
    ) {
      const res: GetInventoryStock =
        yield self.environment.api.getInventoryStock(stockCode)
      if (res.kind !== 'ok') throw new Error(res.kind)
      if (res.message?.result !== 1) throw new Error(res.message.message)

      if (res.payload == null) return null

      return _.mapKeys(res.payload, (val, key) =>
        _.camelCase(key.toUpperCase()),
      ) as SearchInventoryDetailPayload
    }),
  },
})
