import { Instance, types } from 'mobx-state-tree'

export const ApiConfigModel = types
  .model({
    url: types.string,
    apiKey: types.maybe(types.string),
    timeout: types.optional(types.number, 10000),
    autosaveSizeLimit: types.optional(types.number, 1572864), // assume 2MB as Hard limit. 1.5 should be save
  })
  .actions((self) => ({
    update(config: { url?: string; timeout?: number }) {
      if (config.url) self.url = config.url
      if (config.timeout) self.timeout = config.timeout
    },
  }))

export type ApiConfig = Instance<typeof ApiConfigModel>
