import { Form } from '@mtr-SDO/datamodels'
import { IEnvironment } from '@mtr-SDO/models-core'
import { SnapshotOut } from 'mobx-state-tree'

export type FormDefinitionCache = {
  formNumber: string
  formRemoteId: string
  version: number

  issueDate?: number
  publishDate?: number
  issueNumber: number
  revisionNumber: number

  checkType?: string
  formRefNumber?: string
  isUnique?: boolean
  allowMultiple?: boolean

  qualifications?: string

  xml: string
}

const FILENAME = 'forms-v3.txt'

export type LocalFormCache = {
  forms: Omit<SnapshotOut<Form>, 'id'>[]
  definitions: FormDefinitionCache[]
}

export const hydrateFormCache: (
  environment: IEnvironment,
) => Promise<LocalFormCache | undefined> = async (
  environment: IEnvironment,
) => {
  if (environment.storageHandler?.text == null) return undefined

  if (!(await environment.storageHandler.text.isExist(FILENAME))) {
    return undefined
  }

  const content = await environment.storageHandler.text.read(FILENAME)
  if (content == null || content.length === 0) return undefined
  return JSON.parse(content) as LocalFormCache
}

export const dehydrateFormCache = async (
  cache: LocalFormCache | null,
  environment: IEnvironment,
): Promise<void> => {
  if (environment.storageHandler?.text == null) return

  if (cache == null) {
    try {
      await environment.storageHandler.text.remove(FILENAME)
    } catch (error) {
      environment.console.log('Failed to remove file', FILENAME)
      environment.console.reportError(error)
    }
    return
  }
  await environment.storageHandler.text.save(FILENAME, JSON.stringify(cache))
}
