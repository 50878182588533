import { observer } from 'mobx-react-lite'
import { CssBaseline, Drawer, SwipeableDrawer, Box } from '@mui/material'
import styled from '@emotion/styled'
import { SidebarDrawer } from './sidebar-drawer'
import { useView, ScreenSize } from '../../utils'

export const drawerWidth = 350

const Root = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.palettes.general.corporateBlue};
`

const Container = styled.div`
  width: ${drawerWidth}px;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: ${({ theme }) => theme.palettes.general.corporateBlue};
`

const FixWidthDrawer = styled(Drawer)`
  width: ${drawerWidth}px;
  &.MuiDrawer-root.MuiDrawer-docked
    .MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
    border-right: 0px;
  }
`

const StyledBox = styled(Box)`
  background-color: ${({ theme }) => theme.colors.primaryDark};
  color: ${({ theme }) => theme.colors.white};
  width: 300px;
  height: 100vh;
`

type Props = {
  open: boolean
  toggleSidebar: () => void
}

export const SideBar = observer<Props>(({ open, toggleSidebar }) => {
  const view = useView()

  return (
    <Root>
      <CssBaseline />
      {view === ScreenSize.desktop && (
        <FixWidthDrawer variant="permanent">
          <Container>
            <SidebarDrawer />
          </Container>
        </FixWidthDrawer>
      )}
      {view === ScreenSize.mobile && (
        <SwipeableDrawer
          anchor="left"
          open={open}
          onClose={toggleSidebar}
          onOpen={toggleSidebar}
        >
          <StyledBox onClick={toggleSidebar}>
            <SidebarDrawer />
          </StyledBox>
        </SwipeableDrawer>
      )}
    </Root>
  )
})
