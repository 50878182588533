import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'

import { Instance, types } from 'mobx-state-tree'

import _ from 'lodash'

const bookOrderPreprocessSnapshot = (snapshot: any): any => ({
  orderBookItemID: snapshot.orderBookItemID,
  workTypeID: snapshot.workTypeID,
  workTypeName: snapshot.workTypeName,
  depotID: snapshot.depotID ?? undefined,
  depotCode: snapshot.depotCode ?? undefined,
  vehicleNos: snapshot.vehicleNos ?? undefined,
  trackNo: snapshot.trackNo ?? undefined,
  woNo: snapshot.woNo ?? undefined,
  eventID: snapshot.eventID ?? undefined,
  submitTime: snapshot.submitTime,
  isCompleted: snapshot.isCompleted ?? undefined,
  isDeleted: snapshot.isDeleted ?? undefined,
  createBy: snapshot.createBy ?? undefined,
  createDate: snapshot.createDate ?? undefined,
  updateBy: snapshot.updateBy ?? undefined,
  updateDate: snapshot.updateDate ?? undefined,
})

export const BookOrderModel = types
  .model('BookOrder', {
    orderBookItemID: types.string,
    workTypeID: types.string,
    workTypeName: types.string,
    depotID: types.maybe(types.number),
    depotCode: types.string,
    vehicleNos: types.string,
    trackNo: types.string,
    woNo: types.string,
    eventID: types.string,
    submitTime: types.string,
    isCompleted: types.boolean,
    isDeleted: types.boolean,
    createBy: types.string,
    createDate: types.Date,
    updateBy: types.string,
    updateDate: types.maybe(types.Date),
  })
  .preProcessSnapshot(bookOrderPreprocessSnapshot)
  .extend(withEnvironment)
  .extend(withRootStore)
  .volatile(() => ({
    lastMaintenanceUnixTime: undefined as number | undefined,
  }))
  .views((self) => {
    const views = {
      // get eventID(): string {
      //   return self.eventID
      // },
      // get workTypeName(): string {
      //   return self.workTypeID
      // },
      // get vehicleNos(): string {
      //   return self.vehicleNos
      // },
      // get createDate(): Date {
      //   return self.createDate
      // },
    }
    return views
  })
export type BookOrder = Instance<typeof BookOrderModel>
