export function isUpnMatched(
  targetUpn: string | undefined,
  compareUpn: string | undefined,
): boolean {
  if (targetUpn == null || compareUpn == null) {
    return false
  }

  return targetUpn.toLowerCase() === compareUpn.toLowerCase()
}

export function isUpnMatchedInList(
  upnList: (string | undefined)[],
  compareUpn: string | undefined,
): boolean {
  if (upnList.length === 0 || compareUpn == null) {
    return false
  }

  return upnList
    .slice()
    .map((upn) => upn?.toLowerCase())
    .some((it) => it === compareUpn.toLowerCase())
}
