import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { FormControl, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SystemHeader } from '@/components'

const StyledContainer = styled.div`
  width: 100%;
  display: block;
`
const StyledFormControl = styled(FormControl)`
  width: 80%;
  margin: 0 auto;
  display: block;
`
const StyledButton = styled(Button)`
  margin: 8px;
  margin-top: 200px;
  width: 100%;
  text-transform: none;
  border-radius: 12px;
  font-size: 16px;
  background-color: #367fa9;
  color: #f8f3f3;
`

export const SignInPageApp = observer(() => {
  const { t } = useTranslation()
  const handleSignIn = () => {
    window.location.href =
      'https://aadadfs.mtr.com.hk/adfs//oauth2/authorize?response_type=code&prompt=login&redirect_uri=mtr.smf-sit%3A%2F%2Fcallback&client_id=mtr.smf-sit&resource=https%3A%2F%2Fproxy201.api.mtr.com.hk&state=9602aecc-0437-4639-ae14-fe52f4cfb0ca&code_challenge_method=S256&code_challenge=iQSOqlSVdE46Set6dN9ETS2NX0UnMJImE2NxdR2uuKo'
  }

  return (
    <StyledContainer>
      <SystemHeader />
      <StyledFormControl>
        <StyledButton
          disableElevation
          variant="contained"
          onClick={handleSignIn}
        >
          {t(`signInPageApp.sgin`)}
        </StyledButton>
      </StyledFormControl>
    </StyledContainer>
  )
})
