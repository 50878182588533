import styled from '@emotion/styled'
import { ReactElement, useEffect, useRef } from 'react'
import Icon from '@mdi/react'
import { mdiRefresh } from '@mdi/js'
import { CSSProperties } from '@mui/styles'
import { useFetchValidateCodeImg } from '@/queries'

const StyledCaptcha = styled.div<{
  height: number
  width: number
  iconSize: number
}>`
  user-select: none;
  cursor: pointer;
  width: ${({ width, iconSize }) => width + 10 + iconSize}px;
  height: ${({ height }) => height}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
`
const StyledValidateCodeImg = styled.img`
  border-radius: 2px;
  height: 100%;
`
const StyledLoading = styled.div`
  background-color: #d3d3d3;
  border-radius: 2px;
  height: 100%;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`
type CaptchaProps = {
  iconSize?: number
  IconRender?: ({ size }: { size: number | string }) => ReactElement
  width?: number
  height?: number
  style?: CSSProperties
  updateHandle?: (requestID: string) => void
}

export default function Captcha({
  width = 100,
  height = 50,
  iconSize = 28,
  IconRender,
  style,
  updateHandle,
}: CaptchaProps) {
  const duration = useRef(0)
  const { data, isFetching, refetch } = useFetchValidateCodeImg(
    duration.current,
    {
      refetchOnWindowFocus: false,
    },
  )
  const NewIconRender =
    IconRender ??
    ((props) => (
      <Icon
        path={mdiRefresh}
        {...props}
        style={{ verticalAlign: 'bottom', color: 'gray' }}
        spin={isFetching && 3}
      />
    ))
  const clickHandle = () => {
    refetch()
  }
  useEffect(() => {
    updateHandle?.(data?.requestID ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  useEffect(() => {
    duration.current = 500
  }, [])
  return (
    <StyledCaptcha
      style={style}
      height={height}
      width={width}
      iconSize={iconSize}
      onClick={clickHandle}
    >
      {data?.imgBase64 ? (
        <StyledValidateCodeImg
          style={{ width: `${width}px` }}
          src={data?.imgBase64}
        />
      ) : (
        <StyledLoading style={{ width: `${width}px` }}>加载中...</StyledLoading>
      )}
      <NewIconRender size={`${iconSize}px`} />
    </StyledCaptcha>
  )
}
