import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogContentProps,
  DialogProps,
  Typography,
  TypographyProps,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { forwardRef, ReactElement } from 'react'

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 12px;
  }
`
const StyledDialogActions = styled(DialogActions)`
  justify-content: center;
`
const StyledDialogButton = styled(Button)`
  background-color: #13ac53;
  width: 120px;
  &:hover {
    background-color: #13ac53;
  }
`

type DialogMessageProps = DialogProps & {
  customContent?: ReactElement
  customDialogActions?: ReactElement
  contentTextArray?: Array<any>
  dialogContentProps?: DialogContentProps
  typographyProps?: TypographyProps
  dialogActionsProps?: DialogActionsProps
  confirmButtonProps?: ButtonProps
}

export const DialogMessage = observer(
  forwardRef<any, DialogMessageProps>(
    (
      {
        customContent,
        customDialogActions,
        contentTextArray,
        dialogContentProps,
        typographyProps,
        dialogActionsProps,
        confirmButtonProps,
        ...props
      },
      ref,
    ) => (
      <StyledDialog {...props} ref={ref}>
        <DialogContent {...dialogContentProps}>
          {customContent ??
            contentTextArray?.map((t,index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Typography key={index} {...typographyProps}>{t}</Typography>
            ))}
        </DialogContent>

        {customDialogActions ?? (
          <StyledDialogActions {...dialogActionsProps}>
            <StyledDialogButton
              disableElevation
              variant="contained"
              autoFocus
              {...confirmButtonProps}
            />
          </StyledDialogActions>
        )}
      </StyledDialog>
    ),
  ),
)
