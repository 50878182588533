import _ from 'lodash'

export enum FormDefinitionNumberFormat {
  bold = 'b',
  underline = 'u',
  italic = 'i',
  strikeThrough = 's',
}

export type FormDefinitionNumberDisplay = {
  number: string
  format: FormDefinitionNumberFormat[]
}

const regex = /([^_]*)_+([a-zA-Z]*)$/

export function formatItemNumber(
  number: string | null | undefined,
): { number: string; format: FormDefinitionNumberFormat[] } | null | undefined {
  if (number == null) return null
  const match = regex.exec(number)

  if (match == null || match.length === 0) return { number, format: [] }
  const formatString = match[2].toLowerCase()

  return {
    number: match[1],
    format: _.values(FormDefinitionNumberFormat)
      .map((it) => (formatString.includes(it) ? it : null))
      .filter((it) => it != null) as FormDefinitionNumberFormat[],
  }
}
