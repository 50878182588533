import {
  AttunityWorkOrderStatus,
  SearchEquipmentDetailPayload,
  SearchWorkOrderDetailPayload,
} from '@mtr-SDO/apis'
import { notEmpty } from '@mtr-SDO/utils'
import { isObservableArray } from 'mobx'
import moment from 'moment'
import {
  FormDefinitionExpressionParameter,
  FormDefinitionItem,
  FormDefinitionValidationRule,
  ValidationOperator,
} from './definition'
import {
  FormDataItemValue,
  FormDefinitionAutofillResult,
  FormDefinitionCheckboxOptions,
  FormDefinitionFieldType,
  FormDefinitionSecondCheckerOptions,
  FormDefinitionSICOptions,
  FormDefinitionValidationErrorType,
  FormDefinitionValidationResult,
  FormDefinitionYesNoOptions,
  NAType,
  ValidationLevel,
} from './helpers'

type FormDataItem = {
  inputId: string
  notApplicableSelf: boolean
  value: FormDataItemValue
  isNotApplicable: NAType
  findTargetFormDataItem(
    inputId: string,
    acceptMerged: boolean,
  ): FormDataItem | undefined
  findMergedDataItem: FormDataItem | undefined
  validationResult: {
    [key: string]: FormDefinitionValidationResult<FormDataItemValue>[]
  }
  setValidationResult(key: string, result: any): void
}

type ValueItem = number | string | Date
type ValueType = ValueItem | ValueItem[]
const DECIMAL_PRECISION = 5

function splitReferenceValue(
  validationRule: FormDefinitionValidationRule,
): string[] {
  return (
    (validationRule.value as string | null)
      ?.split(',')
      .map((it) => it.trim())
      .filter((it) => it.length > 0) ?? []
  )
}

function intersectArrays<T>(arr1: T[], arr2: T[]): T[] {
  return arr1.reduce<T[]>(
    (acc, val) => (arr2.includes(val) ? [...acc, val] : acc),
    [],
  )
}

function validateExistence<T>(
  checkValue: T | T[] | undefined,
  referenceValues: T[],
  rule: FormDefinitionValidationRule,
  attribute?: string,
  transformValueDisplay: (value: T) => string = (value) => `${value}`,
): FormDefinitionValidationResult<T> | null {
  const targetArray =
    checkValue == null
      ? []
      : Array.isArray(checkValue)
      ? checkValue
      : [checkValue]
  const intersections = intersectArrays(targetArray, referenceValues)

  if (
    rule.operator === ValidationOperator.equalTo &&
    intersections.length === 0
  ) {
    return {
      reason: FormDefinitionValidationErrorType.shouldEqualTo,
      value: targetArray.map(transformValueDisplay).join(' / ') as unknown as T,
      level: ValidationLevel.warn,
      referenceValue: referenceValues
        .map(transformValueDisplay)
        .join(' / ') as unknown as T,
      attribute,
    }
  }

  if (
    rule.operator === ValidationOperator.notEqualTo &&
    intersections.length > 0
  ) {
    return {
      reason: FormDefinitionValidationErrorType.shouldNotEqualTo,
      value: targetArray.map(transformValueDisplay).join(' / ') as unknown as T,
      level: ValidationLevel.warn,
      referenceValue: referenceValues
        .map(transformValueDisplay)
        .join(' / ') as unknown as T,
      attribute,
    }
  }

  if (
    ![ValidationOperator.equalTo, ValidationOperator.notEqualTo].includes(
      rule.operator,
    )
  ) {
    console.warn(
      'Unable to validate existence with operator other than equal or not equal',
      rule,
    )
  }

  return null
}

export type ValidationOptions = {
  validateWorkflow?: boolean
  referenceDate?: moment.Moment
}

export enum RuleModes {
  validationRule,
  autofillRule,
}

export function serializeValidationOption(
  definitionItem: FormDefinitionItem,
  opts: ValidationOptions | undefined,
): string {
  const items: (string | null)[] = []
  items.push(opts?.validateWorkflow ? 'workflow' : null)
  if (
    [FormDefinitionFieldType.date, FormDefinitionFieldType.datetime].includes(
      definitionItem?.inputType,
    )
  ) {
    items.push(opts?.referenceDate?.toISOString() ?? 'current')
  }
  return items.filter(notEmpty).join('|')
}

function expressionSubstituteValue<T extends ValueType>(
  expression: string | undefined,
  parameters: FormDefinitionExpressionParameter[],
  formDataItem: {
    value: T | undefined
    notApplicableSelf: boolean
    isNotApplicable(): NAType
    findTargetFormDataItem: (
      inputId: string,
      acceptMerged: boolean,
    ) => FormDataItem
    findTargetFormDefinitionItem: (
      inputId: string,
      acceptMerged: boolean,
    ) => FormDefinitionItem
  },
  missingParameters: string[],
): string | undefined {
  if (expression == null) return undefined
  let processedExpression = expression
  let isAllParametersProvided: boolean = true
  const extractedParameters = processedExpression
    .match(/\{\{(.*?)\}\}/g)
    ?.map((it) => it.replace('{{', '').replace('}}', ''))
  const autofillParameters = parameters.filter(
    (it) => it.name != null && extractedParameters?.includes(it.name),
  )

  autofillParameters.forEach((param) => {
    if (param.refInputId == null) throw new Error('ref-input-id-missing')

    const crossFieldItem = formDataItem.findTargetFormDataItem(
      param.refInputId,
      true,
    )

    processedExpression = processedExpression?.replace(
      new RegExp(`{{${param.name}}}`, 'g'),
      crossFieldItem?.value ?? 0,
    )

    if (crossFieldItem?.value == null || crossFieldItem?.notApplicableSelf) {
      // raise flag, parameters are not provided
      isAllParametersProvided = false
      if (param.name != null)
        missingParameters.push(
          formDataItem.findTargetFormDefinitionItem(param.refInputId, true)
            ?.name || param.name,
        )
    }
  })

  if (!isAllParametersProvided) {
    return undefined
  }
  return processedExpression
}

function validateNumericItem<T extends ValueType>(
  value: T,
  definitionItem: FormDefinitionItem,
  formDataItem: {
    value: T | undefined
    notApplicableSelf: boolean
    isNotApplicable(): NAType
    findTargetFormDataItem: (
      inputId: string,
      acceptMerged: boolean,
    ) => FormDataItem
    findTargetFormDefinitionItem: (
      inputId: string,
      acceptMerged: boolean,
    ) => FormDefinitionItem
  },
): FormDefinitionValidationResult<T>[] {
  const numericValue = +value

  if (Number.isNaN(numericValue)) {
    return [
      {
        value,
        level: ValidationLevel.warn,
        reason: FormDefinitionValidationErrorType.invalid,
        referenceValue: Number.NaN as T,
        rejectSave: true,
      },
    ]
  }

  const { parameters } = definitionItem

  const ret = definitionItem.validationRules
    .map((rule) => {
      // range validation, value will equal to undefined, but lower-value/upper-value should exist
      if (
        rule.value == null &&
        rule.operator !== ValidationOperator.rangeBetween
      )
        return null
      if (
        rule.lowerValue == null &&
        rule.upperValue == null &&
        rule.operator === ValidationOperator.rangeBetween
      )
        return null
      let substitutedRule = rule.value as string | undefined
      let substitutedLowerValue = rule.lowerValue as string | undefined
      let substitutedUpperValue = rule.upperValue as string | undefined
      const missingParameters: string[] = []

      // Do sub ref value of lower / upper value
      substitutedLowerValue = expressionSubstituteValue(
        substitutedLowerValue,
        parameters,
        formDataItem,
        missingParameters,
      )

      substitutedUpperValue = expressionSubstituteValue(
        substitutedUpperValue,
        parameters,
        formDataItem,
        missingParameters,
      )

      substitutedRule = expressionSubstituteValue(
        substitutedRule,
        parameters,
        formDataItem,
        missingParameters,
      )

      if (
        (rule.upperValue != null && substitutedUpperValue == null) ||
        (rule.lowerValue != null && substitutedLowerValue == null) ||
        (rule.value != null && substitutedRule == null)
      ) {
        return {
          reason: FormDefinitionValidationErrorType.missingParameters,
          undefined,
          param: missingParameters.join(','),
          level: ValidationLevel.warn,
          type: 'Validation',
        }
      }

      let reason: FormDefinitionValidationErrorType | null
      let result: boolean

      let referenceValue
      let referenceLowerValue
      let referenceUpperValue
      try {
        // eslint-disable-next-line no-eval
        referenceValue = +eval(substitutedRule ?? '')?.toFixed(
          DECIMAL_PRECISION,
        )
      } catch (ex) {
        console.error('validate eval formula error', ex)
        return {
          reason: FormDefinitionValidationErrorType.expressionEvaluateError,
          undefined,
          rule: substitutedRule,
          level: ValidationLevel.warn,
          referenceValue,
        }
      }

      try {
        // eslint-disable-next-line no-eval
        referenceLowerValue = +eval(substitutedLowerValue ?? '')?.toFixed(
          DECIMAL_PRECISION,
        )
      } catch (ex) {
        console.error('validate eval formula error', ex)
        return {
          reason: FormDefinitionValidationErrorType.expressionEvaluateError,
          undefined,
          rule: substitutedLowerValue,
          level: ValidationLevel.warn,
          referenceLowerValue,
        }
      }

      try {
        // eslint-disable-next-line no-eval
        referenceUpperValue = +eval(substitutedUpperValue ?? '')?.toFixed(
          DECIMAL_PRECISION,
        )
      } catch (ex) {
        console.error('validate eval formula error', ex)
        return {
          reason: FormDefinitionValidationErrorType.expressionEvaluateError,
          undefined,
          rule: substitutedUpperValue,
          level: ValidationLevel.warn,
          referenceUpperValue,
        }
      }

      switch (rule.operator) {
        case ValidationOperator.greaterThan:
          reason = FormDefinitionValidationErrorType.shouldGreaterThan
          result = numericValue > referenceValue
          break
        case ValidationOperator.greaterThanOrEqualTo:
          reason = FormDefinitionValidationErrorType.shouldGreaterThanOrEqualTo
          result = numericValue >= referenceValue
          break
        case ValidationOperator.equalTo:
          reason = FormDefinitionValidationErrorType.shouldEqualTo
          result = numericValue === referenceValue
          break
        case ValidationOperator.lessThan:
          reason = FormDefinitionValidationErrorType.shouldLessThan
          result = numericValue < referenceValue
          break
        case ValidationOperator.lessThanOrEqualTo:
          reason = FormDefinitionValidationErrorType.shouldLessThanOrEqualTo
          result = numericValue <= referenceValue
          break
        case ValidationOperator.notEqualTo:
          reason = FormDefinitionValidationErrorType.shouldEqualTo
          result = numericValue !== referenceValue
          break
        case ValidationOperator.rangeBetween: {
          reason = FormDefinitionValidationErrorType.shouldInRange
          result =
            (referenceLowerValue == null
              ? true
              : numericValue >= referenceLowerValue) &&
            (referenceUpperValue == null
              ? true
              : numericValue <= referenceUpperValue)
          break
        }
        default:
          reason = null
          result = false
      }

      if (result) {
        return null
      }

      return {
        reason,
        value,
        // rule,
        level: ValidationLevel.warn,
        referenceValue,
        referenceLowerValue: referenceLowerValue ?? 'No lower limit',
        referenceUpperValue: referenceUpperValue ?? 'No upper limit',
      }
    })
    .filter((it) => it != null) as FormDefinitionValidationResult<T>[]

  return ret
}

function validateCheckFlagBox<T extends ValueType>(
  value: T,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  definitionItem: FormDefinitionItem,
): FormDefinitionValidationResult<T>[] {
  if ((value as string) === FormDefinitionCheckboxOptions.flagged) {
    return [
      {
        reason: FormDefinitionValidationErrorType.flagged,
        value,
        level: ValidationLevel.warn,
        referenceValue: FormDefinitionCheckboxOptions.flagged as T,
      },
    ]
  }

  return []
}

function validateDateTime<T extends ValueType>(
  value: T,
  definitionItem: FormDefinitionItem,
  opts?: ValidationOptions,
): FormDefinitionValidationResult<T>[] {
  const date = moment(value as Date)
  const referenceDate = moment(opts?.referenceDate)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)

  if (!date.isValid()) {
    return [
      {
        value,
        level: ValidationLevel.warn,
        reason: FormDefinitionValidationErrorType.invalid,
        referenceValue: Number.NaN as T,
        rejectSave: true,
      },
    ]
  }

  const ret = definitionItem.validationRules
    .map((rule) => {
      const referenceValue = referenceDate
      // rule.value != null ? moment(rule.value) : referenceDate

      let reason: FormDefinitionValidationErrorType | null
      let result: boolean

      switch (rule.operator) {
        case ValidationOperator.greaterThan:
          reason = FormDefinitionValidationErrorType.shouldGreaterThan
          result = date.isAfter(referenceValue, 'date')
          break
        case ValidationOperator.greaterThanOrEqualTo:
          reason = FormDefinitionValidationErrorType.shouldGreaterThanOrEqualTo
          result = date.isSameOrAfter(referenceValue, 'date')
          break
        case ValidationOperator.equalTo:
          reason = FormDefinitionValidationErrorType.shouldEqualTo
          result = date.isSame(referenceValue, 'date')
          break
        case ValidationOperator.lessThan:
          reason = FormDefinitionValidationErrorType.shouldLessThan
          result = date.isBefore(referenceValue, 'date')
          break
        case ValidationOperator.lessThanOrEqualTo:
          reason = FormDefinitionValidationErrorType.shouldLessThanOrEqualTo
          result = date.isSameOrBefore(referenceValue, 'date')
          break
        case ValidationOperator.notEqualTo:
          reason = FormDefinitionValidationErrorType.shouldEqualTo
          result = !date.isSame(referenceValue, 'date')
          break
        default:
          reason = null
          result = false
      }

      if (result === true || reason == null) {
        return null
      }

      return {
        reason,
        value,
        // rule,
        level: ValidationLevel.warn,
        referenceValue: referenceValue as unknown as T,
      }
    })
    .filter(notEmpty)

  return ret
}

function validateSelection<T extends ValueType>(
  value: T,
  definitionItem: FormDefinitionItem,
): FormDefinitionValidationResult<T>[] {
  const checkValues = (
    Array.isArray(value) || isObservableArray(value) ? value : [value]
  ) as T[]

  if (definitionItem.inputType === FormDefinitionFieldType.singleSelection) {
    const failedValues = checkValues.filter(
      (val) => val?.toString()?.toLowerCase() === 'fail',
    )

    if (failedValues.length > 0) {
      return [
        {
          value,
          reason: FormDefinitionValidationErrorType.failed,
          level: ValidationLevel.warn,
        },
      ]
    }
  }

  return definitionItem.validationRules
    .map((rule) =>
      validateExistence(checkValues, splitReferenceValue(rule) as T[], rule),
    )
    .filter(notEmpty)
}

function validateWorkflow<T extends ValueType>(
  value: T,
  definitionItem: FormDefinitionItem,
  formDataItem: {
    value: T | undefined
    notApplicableSelf: boolean
    isNotApplicable(): NAType
    findTargetFormDataItem: (
      inputId: string,
      acceptMerged: boolean,
    ) => FormDataItem
    findTargetFormDefinitionItem: (
      inputId: string,
      acceptMerged: boolean,
    ) => FormDefinitionItem
    isChildItemsFilled: () => boolean
  },
  opts?: ValidationOptions,
): FormDefinitionValidationResult<T>[] {
  if (
    definitionItem.inputType === FormDefinitionFieldType.secondCheckerCheckbox
  ) {
    if (
      opts?.validateWorkflow &&
      value === FormDefinitionSecondCheckerOptions.firstChecker
    ) {
      return [
        {
          value,
          reason: FormDefinitionValidationErrorType.workflowNotCompleted,
          rejectSubmit: true,
          level: ValidationLevel.warn,
        },
      ]
    }

    // Second checker should not be able to check the “second checker field” when he/she modified the sub-item field of second checker field.
    // use reject save = true
    if (
      value === FormDefinitionSecondCheckerOptions.secondChecker &&
      formDataItem.isChildItemsFilled()
    ) {
      return [
        {
          value,
          reason:
            FormDefinitionValidationErrorType.childrenModifiedNotAllowToCheck,
          rejectSave: true,
          level: ValidationLevel.warn,
        },
      ]
    }
  }

  if (definitionItem.inputType === FormDefinitionFieldType.sic) {
    if (
      opts?.validateWorkflow &&
      value === FormDefinitionSICOptions.maintenanceDone
    ) {
      return [
        {
          value,
          reason: FormDefinitionValidationErrorType.workflowNotCompleted,
          rejectSubmit: true,
          level: ValidationLevel.warn,
        },
      ]
    }
    return []
  }
  return []
}

async function validateEquipment<T extends ValueType>(
  value: T | null | undefined,
  definitionItem: FormDefinitionItem,
  rootStore: any,
): Promise<FormDefinitionValidationResult<T>[]> {
  const equipmentNumber = value as string

  if (definitionItem.validationRules.length === 0) return []

  try {
    const res: SearchEquipmentDetailPayload | null =
      await rootStore.worksStore.searchEquipmentDetail(equipmentNumber)

    if (res == null) {
      return [
        {
          attribute: 'equipment',
          value,
          reason: FormDefinitionValidationErrorType.notExist,
          level: ValidationLevel.warn,
        },
      ]
    }

    return definitionItem.validationRules
      .map((validationRule) => {
        const referenceValues = splitReferenceValue(validationRule).map((it) =>
          it.toUpperCase(),
        )

        let checkValue: string | null | undefined = null
        switch (validationRule.attribute?.toLowerCase()) {
          case 'status':
            checkValue = res.status?.toUpperCase() ?? undefined
            break
          case 'equipment-class':
            checkValue = res.equipClassCd?.toUpperCase() ?? undefined
            break
          case 'location':
            checkValue = res.locCd?.toUpperCase() ?? undefined
            break
          default:
            checkValue = undefined
        }

        if (checkValue === null) {
          console.warn(
            'Unknown attribute requested for work order validation. Ignore',
            validationRule,
            definitionItem,
          )
          return null
        }

        return validateExistence<T>(
          checkValue as T | undefined,
          referenceValues as T[],
          validationRule,
          validationRule.attribute,
        )
      })
      .filter(notEmpty)
  } catch {
    return [
      {
        attribute: 'equipment',
        value,
        reason: FormDefinitionValidationErrorType.notExist,
        level: ValidationLevel.warn,
      },
    ]
  }
}

async function validateWorkOrder<T extends ValueType>(
  value: T | null | undefined,
  definitionItem: FormDefinitionItem,
  rootStore: any,
): Promise<FormDefinitionValidationResult<T>[]> {
  const workOrderNumber = value as string

  try {
    const res: SearchWorkOrderDetailPayload | null =
      await rootStore.worksStore.searchWorkOrderDetail(workOrderNumber)

    if (res == null) {
      return [
        {
          attribute: 'work order',
          value,
          reason: FormDefinitionValidationErrorType.notExist,
          level: ValidationLevel.warn,
        },
      ]
    }

    return definitionItem.validationRules
      .map((validationRule) => {
        const referenceValues = splitReferenceValue(validationRule).map((it) =>
          it.toUpperCase(),
        )
        let checkValue: string | undefined
        switch (validationRule.attribute?.toLowerCase()) {
          case 'status':
            checkValue = res.status?.toUpperCase()
            break
          case 'standard-job':
            checkValue = res.stdJobCd?.toUpperCase()
            break
          case 'equipment-class':
            checkValue = res.equipClassCd?.toUpperCase()
            break
          case 'work-group':
            checkValue = res.wkGrpCd?.toUpperCase()
            break
          default:
            checkValue = undefined
        }

        if (checkValue == null) {
          console.warn(
            'Unknown attribute requested for work order validation. Ignore',
            validationRule,
            definitionItem,
            validationRule.attribute,
          )
          return null
        }

        return validateExistence<T>(
          checkValue as T,
          referenceValues as T[],
          validationRule,
          `work order ${validationRule.attribute}`,
          (val) =>
            Object.entries(AttunityWorkOrderStatus).find(
              (it) => it[1] === val,
            )?.[0] ?? `${val}`,
        )
      })
      .filter(notEmpty)
  } catch {
    return [
      {
        attribute: 'work order',
        value,
        reason: FormDefinitionValidationErrorType.notExist,
        level: ValidationLevel.warn,
      },
    ]
  }
}

function validateMandatory<T extends ValueType>(
  value: T | null | undefined,
  definitionItem: FormDefinitionItem,
): FormDefinitionValidationResult<T> | null {
  if (!definitionItem.isMandatory) {
    return null
  }

  if (value != null) {
    if (typeof value === 'number' || value instanceof Date) return null
    if ((value as string | ValueItem[]).length > 0) return null
  }

  return {
    value,
    reason: FormDefinitionValidationErrorType.isMandatory,
    level: ValidationLevel.warn,
    rejectSubmit: true,
  }
}

function validateYesNoField<T extends ValueType>(
  value: T | null | undefined,
  definitionItem: FormDefinitionItem,
): FormDefinitionValidationResult<T>[] {
  const booleanValue: boolean =
    value?.toString()?.toLowerCase()?.trim() === FormDefinitionYesNoOptions.yes

  // should have only one validation rules, so pick the first one if multiple existed
  const firstRule = definitionItem.validationRules?.[0]
  // ignore validation if no rules
  if (firstRule == null) return []

  const rawReferenceValue = firstRule?.value?.toString()?.toLowerCase()?.trim()

  // validation rules error, reference value not set (expect: 'yes' or NON 'yes' value)
  if (rawReferenceValue == null || rawReferenceValue === '') {
    console.error(
      'validate yes-no field error, expected ref-value "yes" or "no", but missing.',
    )
    return [
      {
        reason: FormDefinitionValidationErrorType.validationValueMissing,
        value,
        level: ValidationLevel.warn,
      },
    ]
  }
  const referenceValue: boolean | undefined =
    rawReferenceValue === FormDefinitionYesNoOptions.yes

  let reason: FormDefinitionValidationErrorType
  let result: boolean

  switch (firstRule.operator) {
    case ValidationOperator.notEqualTo:
      reason = FormDefinitionValidationErrorType.shouldNotEqualTo
      result = booleanValue !== referenceValue
      break
    case ValidationOperator.equalTo:
      reason = FormDefinitionValidationErrorType.shouldEqualTo
      result = booleanValue === referenceValue
      break
    default:
      reason = FormDefinitionValidationErrorType.unsupportedValidationOperator
      result = false
  }

  if (result) {
    return [] // valid
  }

  return [
    {
      reason,
      value,
      level: ValidationLevel.warn,
      referenceValue: (referenceValue
        ? FormDefinitionYesNoOptions.yes
        : FormDefinitionYesNoOptions.no) as unknown as T,
      operator: firstRule.operator,
    },
  ]
}

export async function validateFormDataItem<T extends ValueType>(
  dataItem: FormDataItem,
  definitionItem: FormDefinitionItem,
  rootStore: any,
  opts?: ValidationOptions,
): Promise<FormDefinitionValidationResult<T>[]> {
  const { value } = dataItem
  // await delay(3000)

  // valdiate second checker done, child item should not change
  const { findTargetFormDataItem } = dataItem
  const secondCheckerParent = definitionItem.parents?.find(
    (it) =>
      (it as unknown as FormDefinitionItem).inputType ===
      FormDefinitionFieldType.secondCheckerCheckbox,
  ) as unknown as FormDefinitionItem
  if (secondCheckerParent?.inputId != null) {
    const secondCheckerParentFormDataItem = findTargetFormDataItem(
      secondCheckerParent.inputId,
      true,
    )
    if (
      secondCheckerParentFormDataItem?.value ===
      FormDefinitionSecondCheckerOptions.secondChecker
    ) {
      return [
        {
          value,
          reason:
            FormDefinitionValidationErrorType.secondCheckerDoneNotAllowToModify,
          level: ValidationLevel.warn,
          rejectSave: true,
        },
      ]
    }
  }

  const validationResult = validateMandatory(value, definitionItem)
  if (validationResult != null) {
    return [validationResult]
  }

  if (dataItem.isNotApplicable()) return []

  // Dont skip validation when current value is null
  // if (value == null) return []
  const filledOrMergedValue = value ?? dataItem.findMergedDataItem?.value

  if (filledOrMergedValue == null) return []

  switch (definitionItem.inputType) {
    case FormDefinitionFieldType.numeric:
    case FormDefinitionFieldType.integer:
      return validateNumericItem(filledOrMergedValue, definitionItem, dataItem)

    case FormDefinitionFieldType.checkFlagBox:
    case FormDefinitionFieldType.checkbox:
      return validateCheckFlagBox(filledOrMergedValue, definitionItem)

    case FormDefinitionFieldType.datetime:
    case FormDefinitionFieldType.date:
      return validateDateTime(filledOrMergedValue, definitionItem, opts)

    case FormDefinitionFieldType.singleSelection:
    case FormDefinitionFieldType.multipleSelection:
      return validateSelection(filledOrMergedValue, definitionItem)

    case FormDefinitionFieldType.sic:
    case FormDefinitionFieldType.secondCheckerCheckbox:
      return validateWorkflow(
        filledOrMergedValue,
        definitionItem,
        dataItem,
        opts,
      )

    case FormDefinitionFieldType.equipmentNumber:
      return validateEquipment(filledOrMergedValue, definitionItem, rootStore)

    case FormDefinitionFieldType.workOrder:
      return validateWorkOrder(filledOrMergedValue, definitionItem, rootStore)
    case FormDefinitionFieldType.yesNo:
      return validateYesNoField(filledOrMergedValue, definitionItem)
    case FormDefinitionFieldType.textField:
    case FormDefinitionFieldType.textArea:
    case FormDefinitionFieldType.none:
    case FormDefinitionFieldType.unsupported:
      return []

    default:
      return []
  }
}

export async function formulaEvaluate<T extends ValueType>(
  dataItem: {
    findTargetFormDataItem: (
      inputId: string,
      acceptMerged: boolean,
    ) => FormDataItem
    findTargetFormDefinitionItem: (
      inputId: string,
      acceptMerged: boolean,
    ) => FormDefinitionItem
  },
  definitionItem: FormDefinitionItem,
): Promise<FormDefinitionAutofillResult<T>> {
  const { findTargetFormDataItem, findTargetFormDefinitionItem } = dataItem
  const { autofillRules, parameters } = definitionItem
  const rule = autofillRules?.value
  if (rule == null) return { status: true }
  let substitutedRule = rule
  try {
    let isAllParametersProvided: boolean = true
    const missingParameters: string[] = []

    // extract autofill rules' parameters from all parameters
    const extractedParameters = rule
      .match(/\{\{(.*?)\}\}/g)
      ?.map((it) => it.replace('{{', '').replace('}}', ''))
    const autofillParameters = parameters.filter(
      (it) => it.name != null && extractedParameters?.includes(it.name),
    )

    autofillParameters.forEach((param) => {
      if (param?.refInputId == null) throw new Error('ref-input-id-missing')
      const crossFieldItem = findTargetFormDataItem(param.refInputId, true)
      substitutedRule = substitutedRule.replace(
        new RegExp(`{{${param.name}}}`, 'g'),
        crossFieldItem?.value ?? 0,
      )
      if (crossFieldItem?.value == null || crossFieldItem?.notApplicableSelf) {
        isAllParametersProvided = false
        if (param.name != null) {
          missingParameters.push(
            findTargetFormDefinitionItem(param.refInputId, true)?.name ||
              param.name,
          )
        }
      }
    })

    if (!isAllParametersProvided) {
      return {
        status: false,
        reason: FormDefinitionValidationErrorType.missingParameters,
        param: missingParameters.join(', '),
      }
    }

    // eslint-disable-next-line no-eval
    const result = +eval(substitutedRule)?.toFixed(DECIMAL_PRECISION) as T
    return {
      status: true,
      value: result,
    }
  } catch (ex) {
    console.error('eval formula error', ex)
    return {
      status: false,
      reason: FormDefinitionValidationErrorType.expressionEvaluateError,
      rule: substitutedRule,
    }
  }
}

export async function fastValidate(
  dataItem: FormDataItem,
  opts?: ValidationOptions,
  byPassCaching?: boolean,
): Promise<FormDefinitionValidationResult<any>[] | undefined> {
  if (dataItem == null) return Promise.resolve(undefined)
  const key = serializeValidationOption(dataItem.formDefinitionItem, opts)

  // NOTE use cache value here improve first time expand performance
  if (!byPassCaching && dataItem.validationResult[key] != null)
    return dataItem.validationResult[key]

  const validatingValue = dataItem.value

  const result = await validateFormDataItem(
    dataItem,
    dataItem.formDefinitionItem,
    dataItem.rootStore,
    opts,
  )

  if (validatingValue !== dataItem.value) return undefined
  // dataItem.validationResult[key] = result ?? []
  dataItem.setValidationResult(key, result ?? [])

  return dataItem.validationResult[key]
}
