import {
  GetDepotList,
  GetMasterRecord,
  GetStandardJobForCMRequest,
  GetTrainStockTypePayloadItem,
  GetTrainStockTypePayloadList,
  GetWorkNaturePayloadList,
  MasterRecordPayloadItem,
} from '@mtr-SDO/apis'
import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import {
  DepotModel,
  MasterData,
  MasterDataModel,
  TrainStockTypeModel,
  WorkNatureModel,
} from '@mtr-SDO/datamodels'
import _, { name } from 'lodash'
import { flow, Instance, types } from 'mobx-state-tree'

type MasterDataStoreStartupCacheType = {
  workNatures: any
  trainStockTypes: any
  depots: any
  workNatureLv2: any
}

export type MasterDataStoreLocalCacheType = {
  masterDatasJson: any
}

export const MasterDataStoreModel = types
  .model('MasterDataStore')
  .props({
    workNatures: types.map(WorkNatureModel),
    trainStockTypes: types.map(TrainStockTypeModel),
    depots: types.array(DepotModel),
    masterDatas: types.map(MasterDataModel),
    workNatureLv2: types.map(WorkNatureModel),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .views((self) => ({
    findTrainStockType(id: string) {
      if (id == null) return null
      return self.trainStockTypes.get(id)
    },
    findDepot(inName: string) {
      const name = inName?.toLowerCase()
      return Array.from(self.depots.values()).find(
        (it) => it.name.toLowerCase() === name,
      )
    },
    get startupCacheData() {
      const startupCache: MasterDataStoreStartupCacheType = {
        workNatures: self.workNatures.toJSON(),
        workNatureLv2: self.workNatureLv2.toJSON(),
        depots: self.depots.toJSON(),
        trainStockTypes: self.trainStockTypes.toJSON(),
      }
      return startupCache
    },
    get localCacheData() {
      const localCache: MasterDataStoreLocalCacheType = {
        masterDatasJson: self.masterDatas.toJSON(),
      }
      return localCache
    },
  }))
  .actions((self) => {
    const actions = {
      upsertMasterData(typeId: string, payload: MasterRecordPayloadItem[]) {
        self.masterDatas.set(typeId, {
          id: typeId,
          records: payload.map((item) => ({
            ...item,
            remoteId: item.id,
          })),
        })
      },

      fetchMasterDataRecords: flow(function* fetchMasterDataRecords(
        typeId: string,
      ): Generator<any, MasterData | undefined, GetMasterRecord> {
        const res: GetMasterRecord = yield self.environment.api.getMasterRecord(
          typeId,
        )
        if (res.kind !== 'ok') throw new Error(res.kind)

        actions.upsertMasterData(typeId, res.payload)

        return self.masterDatas.get(typeId)
      }),

      fetchTrainStockTypes: flow(function* fetchTrainStockTypes() {
        // try {
        //   const res: GetTrainStockTypePayloadList =
        //     yield self.environment.api.getTrainTypes()
        //   if (res.kind !== 'ok') {
        //     self.environment.console.warn('cannot fetch train types')
        //     throw new Error(res.kind)
        //   }
        //   const serverTypes: GetTrainStockTypePayloadItem[] = res.payload

        //   self.trainStockTypes.replace(
        //     _.uniqBy(serverTypes, 'TrainStockTypeID').map((it) => [
        //       it.TrainStockTypeID,
        //       {
        //         id: it.TrainStockTypeID,
        //         name: it.TrainType,
        //       },
        //     ]),
        //   )

        //   return self.trainStockTypes
        // } catch (error) {
        //   self.environment.console.warn(
        //     'master-data-store.ts: fetchTrainStockTypes() Fetch Train Type error',
        //   )
        //   self.environment.console.reportError(error)
        //   throw error
        // }
      }),

      fetchDepots: flow(function* fetchDepots() {
        try {
          const res: GetDepotList = yield self.environment.api.orderBook.getDepotList()
          if (res.kind !== 'ok') {
            self.environment.console.warn('cannot depot types')
            throw new Error(res.kind)
          }

          const depots = res.payload
          self.depots.clear()
          
          depots.map((it) => 
            self.depots.push( {
              id: it.id,
              name: it.depotName,
              depotCode: it.depotCode??''
            }),
          )
        } catch (error) {
          self.environment.console.warn(
            'master-data-store.ts: fetchDepots() Fetch depot error',
          )
          self.environment.console.reportError(error)
          throw error
        }
      }),

      fetchWorkNatures: flow(function* fetchWorkNatures() {
        // try {
        //   const res: GetWorkNaturePayloadList =
        //     yield self.environment.api.getWorkNatures()
        //   if (res.kind !== 'ok') {
        //     self.environment.console.warn('cannot fetch work natures')
        //     throw new Error(res.kind)
        //   }
        //   const natures = res.payload

        //   self.workNatures.replace(
        //     _.uniqBy(
        //       natures.map((nature) => ({
        //         ...nature,
        //         id: nature.WorkNature.toLowerCase(),
        //       })),
        //       'id',
        //     ).map((it) => [
        //       it.id,
        //       {
        //         id: it.id,
        //         name: it.WorkNature,
        //       },
        //     ]),
        //   )
        // } catch (error) {
        //   self.environment.console.warn(
        //     'master-data-store.ts: fetchWorkNatures()',
        //     error,
        //   )
        //   self.environment.console.reportError(error)
        //   throw error
        // }
      }),

      applyStartupCache(cache: MasterDataStoreStartupCacheType) {
        const { workNatures, workNatureLv2, depots, trainStockTypes } = cache
        self.workNatures.replace(workNatures)
        self.workNatureLv2.replace(workNatureLv2)
        self.depots.replace(depots)
        self.trainStockTypes.replace(trainStockTypes)
      },

      applyLocalCache(cache: MasterDataStoreLocalCacheType) {
        self.masterDatas.replace(cache.masterDatasJson)
      },

      fetchWorkNatureLv2ForCMRequest: flow(
        function* getWorkNatureLv2ForCMRequest() {
          // const res: GetStandardJobForCMRequest =
          //   yield self.environment.api.cmDetails.getStandardJobForCMRequest()
          // if (res.kind !== 'ok') throw new Error(res.kind)

          // const natures = res.payload.workNatureLevel2s

          // self.workNatureLv2.replace(
          //   _.uniqBy(
          //     natures.map((nature) => ({
          //       ...nature,
          //       id: nature.code.toUpperCase(),
          //     })),
          //     'id',
          //   ).map((it) => [
          //     it.id,
          //     {
          //       id: it.id,
          //       name: it.desp,
          //     },
          //   ]),
          // )

          // return self.workNatureLv2
        },
      ),
    }
    return actions
  })

export type MasterDataStore = Instance<typeof MasterDataStoreModel>
