import { types, flow, Instance, SnapshotIn,detach } from 'mobx-state-tree'
import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import moment from 'moment'
import {
  FormDataPayload,
  GetFormData,
  GetFormControlByFormObjectIDType,
  TabControType,
} from '@mtr-SDO/apis'

import {
  FormDefinitionModel,
  FormDefinition,
  FormDataModel,
  FormData,
  FormModel,
  Form,
  FormDataItem,
  FormDefinitionFieldType,
  NA_VALUE,
  FORMDATA_ITEM_DATETIME_FORMAT,
  FormDataRemark,
  FormDataAttachment,
  FormDataAttachmentType,
  ApprovalWorkflow,
  mapFormAttributesByPayload,
} from '@mtr-SDO/datamodels'

export const BookFormsStoreModel = types
  .model('BookFormsStore')
  .props({
    formDefinitionList: types.array(FormDefinitionModel),
    formDataList: types.array(FormDataModel),
    formList: types.array(FormModel),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .actions((self) => {
    const actions = {
      findForm(bookItemID: string) {
        const form = self.formList.find((it) => it.bookItemID === bookItemID)

        return form
      },
      removeForm(bookItemID: string) {
        const form = self.formList.find((it) => it.bookItemID === bookItemID)
        if(form !== undefined){
          detach(form)
          self.formList.remove(form)
        }
      },
      findFormDefinition(bookItemID: string) {
        return self.formDefinitionList.find(
          (it) => it.bookItemID === bookItemID,
        )
      },
      removeFormDefinition(bookItemID: string) {
        const formDef = self.formDefinitionList.find(
          (it) => it.bookItemID === bookItemID,
        )
        if(formDef !== undefined){
          detach(formDef)
          self.formDefinitionList.remove(formDef)
        }
      },
      findFormData(bookItemID: string) {
        return self.formDataList.find((it) => it.bookItemID === bookItemID)
      },
      removeFormData(bookItemID: string) {
        const formData = self.formDataList.find((it) => it.bookItemID === bookItemID)
        if(formData !== undefined){
          detach(formData)
          self.formDataList.remove(formData)
        }
      },
      removeFormByBookItemID(bookItemID: string){
        this.removeForm(bookItemID)
        this.removeFormData(bookItemID)
        this.removeFormDefinition(bookItemID)
      },
      upsertForm(snapshot: SnapshotIn<Form>) {
        self.formList.push(FormModel.create(snapshot))
      },
      upsertFormData(snapshot: SnapshotIn<FormData>) {
        self.formDataList.push(FormDataModel.create(snapshot))
      },

      async upsertFormDataApiPayload(formPayload: FormDataPayload,bookItemID:string) {
        const formDefinition = self.formDefinitionList.filter(
          (d) => d.bookItemID === bookItemID,
        )[0]
        const isMerged = false
        const { itemsRecursively: formDefinitionItems } = formDefinition

        const formDataItems: SnapshotIn<FormDataItem>[] = []

        if (formPayload.formSignatures.length > 0) {
          formPayload.formSignatures.forEach((signature) => {
            const targetItem = formDefinitionItems.find(
              (item) => item.number === signature.wiItemNo && item.level === 0,
            )
            if (!targetItem) {
              self.environment.console.display({
                name: 'Parse Form Data - Signature',
                preview: 'Cannot find corresponding definition item. Skipped',
                value: [signature, formDefinitionItems],
                important: true,
              })
              return
            }

            formDataItems.push({
              inputId: targetItem.inputId,
              isReadOnly: false,
              value:
                signature.status === 2
                  ? 'flagged'
                  : signature.status === 1
                  ? 'checked'
                  : undefined,
            })
          })
        }

        if (formPayload.formJsonData) {
          try {
            if (!formDefinition.xml && formDefinitionItems.length === 0) {
              self.environment.console.display({
                name: 'Parse Form Data - JSON',
                preview: 'DefinitionXML is missing, skipped',
                value: { formDefinitionItems, formDefinition },
                important: true,
              })
            } else {
              const data = JSON.parse(formPayload.formJsonData.trim())
              Object.keys(data).forEach((key) => {
                const targetItem = formDefinitionItems.find(
                  (item) => item.inputId === key,
                )
                // if (targetItem?.inputId == null) {
                //   self.environment.console.display({
                //     name: 'Parse Form Data - JSON',
                //     preview:
                //       'Cannot find corresponding definition item. Skipped',
                //     value: [key, data[key], formDefinitionItems],
                //     important: true,
                //   })
                //   return
                // }

                let value = data[key]
                if(targetItem!== undefined){
                  if (
                    [
                      FormDefinitionFieldType.date,
                      FormDefinitionFieldType.datetime,
                    ].includes(targetItem.inputType) &&
                    value != null &&
                    value !== NA_VALUE
                  ) {
                    value = moment(value, FORMDATA_ITEM_DATETIME_FORMAT).toISOString()
                  }
                }
                
                formDataItems.push({
                  inputId: key,
                  value: value === NA_VALUE ? undefined : value,
                  notApplicableSelf: value === NA_VALUE,
                  isReadOnly: false,
                })
              })
            }
          } catch (error) {
            self.environment.console.reportError(error)
            self.environment.console.display({
              name: 'Parse Form Data - JSON',
              preview: 'Invalid JSON data',
              value: [formPayload.formJsonData, error],
              important: true,
            })
            // throw error
          }
        }
        let specialValidateControlStr
        try {
          const formControlRes: GetFormControlByFormObjectIDType =
            await self.environment.api.orderBook.getFormControlByFormObjectID(
              formPayload.formObjectID,
              formPayload.status
            )
          const { kind } = formControlRes

          if (kind !== 'ok') throw new Error(kind)
          const { payload } = formControlRes

          if (payload == null) {
            throw new Error('empty-payload')
          }
          if (
            payload.formItemIsReadOnlyControl != null &&
            payload.formItemIsReadOnlyControl.length > 0
          ) {
            const data = JSON.parse(payload.formItemIsReadOnlyControl)

            const userInfo = self.rootStore.userProfileStore.userInfo
            data.map(async (item) => {
              if (item.userData != null) {
                item.userData.map(async (u) => {
                  const formDataItem = formDataItems.find((fd)=>fd.inputId ===  u.inputID)
                  if(formDataItem !== undefined){
                    formDataItem.isReadOnly = u.isReadOnly
                  }else {
                    const userValue = userInfo[u.defaultValueFields]?userInfo[u.defaultValueFields]:''
                      formDataItems.push({
                        inputId: u.inputID,
                        value: userValue,
                        notApplicableSelf: false,
                        isReadOnly: u.isReadOnly,
                        groupID: item.groupID,
                      })
                    }
                })   
              }
              if(item.orderBookItemData !== undefined) {
                const bookOrder = self.rootStore.booksStore.findBookOrder(formDefinition.orderBookID)
                item.orderBookItemData.map(async (o) => {
                  const formDataItem = formDataItems.find((fd)=>fd.inputId ===  o.inputID)
                  if(formDataItem !== undefined){
                    formDataItem.isReadOnly = o.isReadOnly
                  }else{
                    formDataItems.push({
                      inputId: o.inputID,
                      value: bookOrder === undefined?'':bookOrder[o.defaultValueFields],
                      notApplicableSelf: false,
                      isReadOnly: o.isReadOnly,
                      groupID: item.groupID,
                    })
                  }
                })
              }
            })
          }
  
          if (
            payload.formDefaultValuesControlJson != null &&
            payload.formDefaultValuesControlJson.length > 0
          ) {
            const data = JSON.parse(payload.formDefaultValuesControlJson)
            data.map(async (d)=>{

              let triggered = true

              for (let i =0 ; i< d.trigger.length; i+=1){
                const triggerItem = formDataItems.find(fd=>fd.inputId === d.trigger[i].inputID)
                if(triggerItem === undefined || triggerItem.value !== d.trigger[i].value){
                  triggered = false
                  break
                }
              }

              if(triggered){
                for (let i =0 ; i< d.binDing.length; i+=1){
                  const formDataItem = formDataItems.find((fd)=>fd.inputId ===  d.binDing[i].inputID)
                  if(formDataItem === undefined){
                    formDataItems.push({
                      inputId: d.binDing[i].inputID,
                      value: d.binDing[i].value,
                      notApplicableSelf: false,
                      isReadOnly: d.binDing[i].isReadOnly,
                    })
                  }else{
                    formDataItem.isReadOnly = d.binDing[i].isReadOnly
                  }
                  if(d.binDing[i].inputID === 'Key_1_ckeck2_1bc2e801-22aa-41ef-bb0b-232c64eb3e3e'){
                    const formDataItem2 = formDataItems.find((fd)=>fd.inputId === 'Key_1_ckeck1_1bc2e801-22aa-41ef-bb0b-232c64eb3e3e')
                    if(formDataItem2 === undefined){
                      formDataItems.push({
                        inputId: 'Key_1_ckeck1_1bc2e801-22aa-41ef-bb0b-232c64eb3e3e',
                        value: undefined,
                        notApplicableSelf: false,
                        isReadOnly: d.binDing[i].isReadOnly,
                      })
                    }else{
                      formDataItem2.isReadOnly = d.binDing[i].isReadOnly
                    }
                  }
                }
              }
            })
          }
          specialValidateControlStr = payload.specialValidateControlJson??undefined
        } catch (error) {
          self.environment.console.reportError(error)
          self.environment.console.display({
            name: 'GetFormControlByFormObjectIDType',
            preview: 'Get FormControl By FormObjectID failed',
            value: [formPayload.formDataObjectID, error],
            important: true,
          })
        }
       
        let remarks: SnapshotIn<FormDataRemark>[] = []
        if(formPayload.formRemarkHistoryList){
          remarks = formPayload.formRemarkHistoryList.map((fr)=>({
             content : fr.remarkText??undefined,
             createby:fr.remarkUserName??undefined,
             createddate: moment(fr.createDate).toDate()
          }))
        }
        const remark: SnapshotIn<FormDataRemark> = { content: undefined }
        let attachments: SnapshotIn<FormDataAttachment>[] = []
        if (!isMerged) {
          const remarkObject =
            formPayload.formRemarks &&
            formPayload.formRemarks.length > 0 &&
            formPayload.formRemarks[0]
          if (remarkObject) {
            // remark.content = remarkObject.remarkText || undefined
            attachments = (remarkObject.formAttachments || [])
              .map((attachment) => ({
                title: attachment.attachmentName,
                remoteId: attachment.attachmentPath,
                mime: attachment.attachmentFormat,
                type:
                  attachment.attachmentType === 1
                    ? FormDataAttachmentType.photo
                    : attachment.attachmentType === 2
                    ? FormDataAttachmentType.video
                    : FormDataAttachmentType.file,
                itemInputId: attachment.itemInputId ?? undefined,
              }))
              .filter((snapshot) => snapshot.mime)
          }
        } else {
          // * before code is remark.content = payload.teamLeaderRemark || undefined
          // * 现在不会把verifer填写的remark填充到quick-action-remark-screen显示
          remark.content = undefined
        }

        const formDataSnapshot: SnapshotIn<FormData> = {
          orderBookID: formDefinition.orderBookID,
          bookItemID: formDefinition.bookItemID,
          isSpecialForm: formDefinition.isSpecialForm,
          workOrderNumber: formPayload.formNo,
          remoteFormGroupId: formPayload.objectID,
          remoteFormId: formPayload.formDataObjectID,
          formVersion: formPayload.versionNo,
          isOptional: formPayload.isOptional,
          equipmentNumber: formPayload.equipmentNo
            ? formPayload.equipmentNo
            : '',

          workNatureId: '',

          isMerged: false,

          createddate: moment(formPayload.startDate || undefined).toDate(),
          lastmoddate: moment(
            formPayload.endDate || formPayload.startDate || undefined,
          ).toDate(),
          uploaddate: moment(formPayload.createDate || undefined).toDate(),

          remoteId: formPayload.formDataObjectID,
          remoteHistoryId: undefined,
          referenceNumber: formPayload.formReferenceNo,

          items: formDataItems,

          approvalWorkflow:
            formPayload.workflow === 2
              ? ApprovalWorkflow.endorseAndApprove
              : formPayload.workflow === 1
              ? ApprovalWorkflow.approveOnly
              : undefined,

          uploaderName:
            formPayload.updateDisplayName == null
              ? undefined
              : formPayload.updateDisplayName,
          uploaderUpn: formPayload.updateBy || undefined,
          updateTime: formPayload.updateDate || undefined,

          approverName:
            formPayload.approverName == null
              ? undefined
              : formPayload.approverName,
          approverUpn: formPayload.approverUPN || undefined,
          teamLeaderName:
            formPayload.teamLeaderName == null
              ? undefined
              : formPayload.teamLeaderName,
          teamLeaderUpn: formPayload.teamLeaderUPN || undefined,
          endorserName:
            formPayload.endoserName == null
              ? undefined
              : formPayload.endoserName,
          endorserUpn: formPayload.endoserUPN || undefined,

          submissionTime: formPayload.submitDate
            ? moment(formPayload.submitDate).toDate()
            : undefined,
          approvalTime: formPayload.approveDate
            ? moment(formPayload.approveDate).toDate()
            : undefined,
          endorsementTime: formPayload.endorseDate
            ? moment(formPayload.endorseDate).toDate()
            : undefined,

          approvalRejectUsername:
            formPayload.approveRejectByDisplayName ?? undefined,
          approvalRejectUpn: formPayload.approveRejectBy ?? undefined,
          approvalRejectReason: formPayload.approveRejectReason ?? undefined,
          approvalRejectTime: formPayload.approveRejectDate
            ? moment(formPayload.approveRejectDate).toDate()
            : undefined,

          endorseRejectUsername:
            formPayload.endorseRejectByDisplayName ?? undefined,
          endorseRejectUpn: formPayload.endorseRejectBy ?? undefined,
          endorseRejectReason: formPayload.endorseRejectReason ?? undefined,
          endorseRejectTime: formPayload.endorseRejectDate
            ? moment(formPayload.endorseRejectDate).toDate()
            : undefined,

          voidUsername: formPayload.voidByDisplayName ?? undefined,
          voidUpn: formPayload.voidBy ?? undefined,
          voidReason: formPayload.voidReason ?? undefined,
          voidTime: formPayload.voidDate
            ? moment(formPayload.voidDate).toDate()
            : undefined,

          remark,
          remarks,
          tags:
            formPayload.formDataTags?.map((tag) => ({
              tag: tag.tag,
              objectid: tag.dataTagObjectID,
              createdby: tag.createBy,
              createddate: moment(tag.createDate).toDate(),
            })) ?? [],
          attachments,

          instruments: formPayload.formDataIMTEs.map((imte) => ({
            imteNumber: imte.code,
            expiryDate: imte.nextExamDate
              ? moment(imte.nextExamDate).toDate()
              : undefined,
            createdDate: moment(imte.recordDate).toDate(),
          })),
          // 判断是否为非doer upload formData
          isManager: formPayload.isManager ?? false,
          oldFormJsonData : formPayload.formJsonData?? undefined,
          specialValidateControlStr
        }
        actions.upsertFormData(formDataSnapshot)
      },

      async upsertFormDefinition(bookItemID: string) {

        const formDefinition = self.formDefinitionList.find(
          (d) => d.bookItemID === bookItemID,
        )
        await formDefinition.expandXml()
        const bookItem = self.rootStore.bookItemListStore.findBookOrder(
          formDefinition.bookItemID,
        )
        const tabControlRes: TabControType =
          await self.environment.api.orderBook.GetTabControlInWorkflow(
            formDefinition.bookItemID,
            bookItem.status,
          )
        const { kind } = tabControlRes

        if (kind !== 'ok') throw new Error(kind)
        const { payload } = tabControlRes

        if (payload == null) {
          throw new Error('empty-payload')
        }
        payload.mobileControl.map(async (item) => {
          const group = formDefinition?.groups.find(
            (f) => f.groupId === item.groupID.toUpperCase(),
          )
          if (group !== undefined) {
            group.setIsReadonly(item.value === 'readonly')
            group.setIsShown(item.value !== 'hidden')
            group.setHasNext(item.hasNext)
            group.setHasPhoto(item.hasNext)
            group.setNeedUpload(item.value !== 'readonly' && item.value !== 'hidden')
          }
        })
      },

      async upsertFormDefinitionPayload(
        payload: FormDataPayload,
        orderBookID: string,
        bookItemID: string,
      ) {
        actions.upsertForm({
          orderBookID,
          bookItemID,
          number: payload.formNo,
          title: payload.formName,
          remoteId: payload.formObjectID,
          trainStockTypeIds: payload.formStructure.trainStockTypeIDs.map((it) =>
            it.toString(),
          ),
        })
        const bookItem = self.rootStore.bookItemListStore.findBookOrder(bookItemID )
        const snapshot: SnapshotIn<FormDefinition> = {
          orderBookID,
          bookItemID,
          formId: payload.formObjectID,
          version: +payload.versionNo,
          isSpecialForm: bookItem.isSpecialForm??false,
          issueDate:
            payload.issueDate != null
              ? moment(payload.issueDate).toDate()
              : undefined,
          publishDate:
            payload.publishDate != null
              ? moment(payload.publishDate).toDate()
              : undefined,
          issueNumber: payload.issueNo ? payload.issueNo : 0,
          revisionNumber: payload.revNo ? payload.revNo : 0,

          checkType: payload.checkType ?? undefined,
          formRefNumber: payload.formReferenceNo ?? undefined,

          qualifications: payload.formStructure.qualificationCodes ?? undefined,
          isPreview: false,
          xml: payload.formStructure.formXML,
        }
        const formDefinition = FormDefinitionModel.create(snapshot)
        if (payload.formDataType != null) {
          const attributes = mapFormAttributesByPayload({
            formDataType: payload.formDataType,
          })

          if (formDefinition.isUnique == null) {
            formDefinition.setUnique(attributes.isUnique)
          }

          if (formDefinition.allowMultiple == null) {
            formDefinition.setAllowMultiple(attributes.allowMultiple)
          }
        }
        self.formDefinitionList.push(formDefinition)
      },

      getFormData: flow(function* getFormData(
        objectID: string,
      ) {
        try {
          if (objectID == null) {
            self.environment.console.log(
              'No remote form data id. Will not fetch',
              self,
            )
            throw new Error('remote-id-not-available')
          }
          const res: GetFormData = yield self.environment.api.getFormData(
            objectID,
          )

          if (res.kind !== 'ok') throw new Error(res.kind)

          const { payload } = res
          if (payload == null) {
            throw new Error('empty-payload')
          }
          // yield actions.upsertFormDefinitionPayload(
          //   payload,
          //   orderBookID,
          //   objectID,
          // )
          return payload
          //yield actions.upsertFormDataApiPayload(payload)
        } catch (err) {
          self.environment.console.reportError(err)
          throw err
        }
      }),
      next(bookItemID: string){
        const formDefinition =  self.formDefinitionList.find(
          (d) => d.bookItemID === bookItemID,
        )
        if(formDefinition!==undefined){
         const currentGroup= formDefinition.groups.find((g)=>g.hasNext === true)
         if(currentGroup!==undefined){
          const nextGroup = formDefinition.groups.find((g)=>g.displayOrder === currentGroup?.displayOrder+10)
          nextGroup?.setIsShown (true) 
          nextGroup?.setIsReadonly (false) 
          nextGroup?.setNeedUpload(true)
         }
         currentGroup?.setHasNext(false)
         currentGroup?.setIsReadonly(true)
        }
      }
    }
    return actions
  })
export type BookFormsStore = Instance<typeof BookFormsStoreModel>
