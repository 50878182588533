const ACCESS_TOKEN_KEY = 'accessToken'

export const storage = {
  set: (key: string, value: string): void => localStorage.setItem(key, value),

  get: (key: string): string => localStorage.getItem(key) ?? '',

  clear: (key: string): void => localStorage.removeItem(key),

  getAccessToken: (): { accessToken: string; tokenType: string } => {
    const value = localStorage.getItem(ACCESS_TOKEN_KEY)?.split(' ') ?? []
    return {
      accessToken: value[1] ?? '',
      tokenType: value[0] ?? '',
    }
  },

  setAccessToken: (value: string): void =>
    localStorage.setItem(ACCESS_TOKEN_KEY, value),

  clearAccessToken: (): void => {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
  },
}
