import { createTheme, ThemeProvider } from '@mui/material'
import { StylesProvider } from '@mui/styles'
import { useTheme } from '@mtr-SDO/theme-core'
import '@fontsource/open-sans'
import { memo, useEffect, useMemo, useState } from 'react'
import { QueryClientProvider } from 'react-query'
import { Global, css } from '@emotion/react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ErrorBoundary, Helmet } from './components'
import { init, InitResult } from './init'
import { RootStoreProvider } from './models'
import { RootRouter } from './routers'
import { LoadingPage } from './pages'


export const App = memo(() => {
  const theme = useTheme()
  
  const [initResult, setInitResult] = useState<InitResult | undefined>()

  useEffect(() => {
    ;(async () => {
      setInitResult(undefined)
      setInitResult(await init())
    })()
  }, [])

  const materialUiTheme = useMemo(
    () =>
      createTheme(
        {
          ...theme,
          ...{
            palette: {
              primary: {
                light: theme.colors.primaryLight,
                main: theme.colors.primary,
                dark: theme.colors.primaryDark,
                contrastText: theme.palettes.general.white,
              },
              secondary: {
                light: theme.colors.secondaryLight,
                main: theme.colors.secondary,
                dark: theme.colors.secondaryDark,
                contrastText: theme.palettes.general.white,
              },
              action: {
                selected: theme.palettes.general.greyTransparent,
                hover: theme.palettes.general.lightGreyTransparent,
              },
            },
            typography: {
              fontSize: 12,
              fontFamily: 'Open Sans',
            },
          },
        }

      ),
    [theme],
  )

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={materialUiTheme}>
        <Global
          styles={css`
            * {
              box-sizing: border-box;
            }
            body{
              margin:0;
            }
          `}
        />
        <Helmet />
        <ErrorBoundary>
          {initResult != null ? (
            <QueryClientProvider client={initResult.queryClient}>
              <RootStoreProvider value={initResult.rootStore}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                >
                  <RootRouter />
                </LocalizationProvider>
              </RootStoreProvider>
            </QueryClientProvider>
          ) : (
            <LoadingPage />
          )}
        </ErrorBoundary>
      </ThemeProvider>
    </StylesProvider>
  )
})
