import { memo } from 'react'
import { Icon as MDIcon } from '@mdi/react'
import { IconProps as MDIconProps } from '@mdi/react/dist/IconProps'
import * as MaterialCommunityIcons from '@mdi/js'
import { IconProps } from '../../theme'

export const Icon = memo((props: IconProps & Omit<MDIconProps, 'path'>) => {
  const { type } = props

  if (type === 'material-community') {
    // Convert 'abc-def-ghi' to 'mdiAbcDefGhi'
    const mdiIconName = `mdi${props.name
      .split('-')
      .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
      .join('')}`

    return (
      <MDIcon
        // @ts-ignore
        path={MaterialCommunityIcons[mdiIconName]}
        {...props}
      />
    )
  }

  console.warn('Unknown icon type')
  return null
})
