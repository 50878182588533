import { Api, ApiConfig, BlobHandler } from '@mtr-SDO/apis'
import { IEnvironment, StorageHandler } from '@mtr-SDO/models-core'
import Xml2Js from 'xml2js'
import { QueryClient } from 'react-query'
import { ContainerClient, BlobServiceClient } from '@azure/storage-blob'

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment implements IEnvironment {
  platform = 'web' as 'mobile' | 'web'

  console = {
    ...window.console,
    display: window.console.log,
    reportError: window.console.warn,
  }

  xml2Js = Xml2Js

  storageHandler?: StorageHandler

  queryClient: QueryClient

  api!: Api

  blobHandler: BlobHandler = {
    get() // url: string,
    // httpHeader?: { [key: string]: string },
    // downloadPath?: string,
    {
      throw new Error('not-supported')
    },

    async put(
      endpoint: string,
      sasToken: string,
      containerName: string,
      content,
    ) {
      if (content.platform === 'app') {
        throw new Error('Incorrect Platform (app)')
      }

      const blobSasUrl = `${endpoint}${containerName}${sasToken}`

      // Create a new BlobServiceClient
      const blobServiceClient = new BlobServiceClient(blobSasUrl)

      // Get a container client from the BlobServiceClient
      const containerClient = new ContainerClient(blobSasUrl)
      blobServiceClient.getContainerClient(containerName)

      if (content.file == null) {
        throw new Error('Unknown file')
      }

      const blockBlobClient = containerClient.getBlockBlobClient(
        content.file.name,
      )
      await blockBlobClient.uploadData(content.file)

      const path = `${endpoint}${containerName}/${content.file.name}`

      return {
        type: 'path',
        data: path,
        base64: () => {
          throw new Error('unsupported')
        },
        path: () => path,
        respInfo: { headers: {}, status: 201 },
      }
    },
  }

  async setupApi(config: ApiConfig, unauthorizedHandle: () => void) {
    this.api.setUnauthorizedHandler(unauthorizedHandle)
  }

  constructor(apiConfig: ApiConfig, queryClient: QueryClient) {
    // this.storageHandler?.initialize()
    this.queryClient = queryClient
    this.api = new Api(apiConfig, this.blobHandler)
  }
}
