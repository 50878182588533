import { getParent, Instance, types } from "mobx-state-tree"
import { BuilderDomNodeModel } from "./builder-dom-node.model"
import { BuilderDomNodeEnums } from "./types"

// all custom tag item model
export const BuilderDomItemModel = types
  .model('BuilderItem', {
    id: types.optional(types.string, ''),
    tag: types.maybe(types.enumeration(Object.keys(BuilderDomNodeEnums))),
    disabledCondition: types.optional(types.string, ''),
    domNodes: types.array(BuilderDomNodeModel)
  }).
  views(self=>{
    const views = {
      get formData() {
        // @ts-ignore
        if(!getParent(self, 2).formData){
          return false
        }
        // @ts-ignore
        const temp = getParent(self, 2).formData
        return Object.keys(temp).length > 0 ? temp : false
      },
      isHadDisable(): boolean{
        // 
        if(!views.formData){
          return false
        }
        return self.domNodes.filter(i=>i.isDisabled).length > 0
      }
    }
    return views
  })

export type BuilderDomItem = Instance<typeof BuilderDomItemModel>
export type BuilderDomItemSnapshotIn = Instance<typeof BuilderDomItemModel>
export type BuilderDomItemSnapshotOut = Instance<typeof BuilderDomItemModel>
