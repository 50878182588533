import { CircularProgress, CircularProgressProps } from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'

const StyledSpinner = styled(CircularProgress)`
  margin: auto;
`

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Spinner = observer((props: CircularProgressProps) => (
  <SpinnerContainer>
    <StyledSpinner {...props} />
  </SpinnerContainer>
))
