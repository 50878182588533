import { ApiBase } from '../api-base'

export class ApiWorkOrders {
  private api: ApiBase

  constructor(api: ApiBase) {
    this.api = api
  }

  getIsWorkOrderFormGroupCreated = async (
    workOrderNumber: string,
    depotId: string,
    standardJobCode: string,
    equipmentNumber: string,
    workNatureName: string,
    workGroupId: string,
  ) =>
    this.api.generalApi<boolean>(
      this.api.apisauce.get('/Form/FormData/IsFormGroupDataExist', {
        standardJobCode,
        workOrder: workOrderNumber,
        equipmentNo: equipmentNumber,
        depotID: depotId,
        workNature: workNatureName,
        workGroupID: workGroupId,
      }),
    )

  fetchWorkOrderByNumber = async (workOrderNumber: string) =>
    this.api.generalApi<boolean>(
      this.api.apisauce.get('/Form/FormData/GetFormGroupDataByWorkOrder', {
        workOrder: workOrderNumber,
      }),
    )
}
