import {
  FormDataPayload,
  GetFormDataTag,
  GetIMTEStatus,
  PostWithdrawal,
  UploadFormData,
} from '@mtr-SDO/apis'
import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import { notEmpty } from '@mtr-SDO/utils'
import _ from 'lodash'
import { observable, runInAction } from 'mobx'
import { flow, Instance, SnapshotOut, types, detach } from 'mobx-state-tree'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { Equipment } from '../../base-attunity'
import {
  MockWorkOrder as WorkOrder,
  MockWorkOrderForm as WorkOrderForm,
  WorkOrderFormStatus,
} from '../../work-orders/mock-types'
import { Form } from '../base'
import { FormDefinition, FormDefinitionItem } from '../definition'
import { constructFieldDataMap } from '../definition/validate-chain-map'
import {
  ApprovalWorkflow,
  FormDataItemValue,
  FORMDATA_ITEM_DATETIME_FORMAT,
  FormDefinitionFieldType,
  FormDefinitionSecondCheckerOptions,
  FormDefinitionSICOptions,
  NAType,
  FillStatus,
  DisplayFillStatus,
  FormDataPayloadMetadataItem,
  FormDefinitionCheckboxOptions,
  NA_VALUE,
} from '../helpers'
import {
  serializeValidationOption,
  ValidationOptions,
  validateFormDataItem,
  fastValidate,
} from '../validations'
import { combineFillStatus } from './fill-status'
import {
  FormDataAttachment,
  FormDataAttachmentModel,
  FormDataAttachmentType,
} from './form-data-attachment.model'
import { FormDataInstrumentModel } from './form-data-instrument.model'
import { FormDataItem, FormDataItemModel } from './form-data-item.model'
import { FormDataRemarkModel } from './form-data-remark.model'
import { FormDataTag, FormDataTagModel } from './form-data-tag.model'

const ApprovalWorkflowEnum = types.enumeration(Object.values(ApprovalWorkflow))

export type DisplayFillStatusOptions = {
  escalateSubitem?: boolean
  suppressMandatoryWarningWithUnfilledParent?: boolean
  warnOnUnfilled?: boolean
}

export enum FormDataStatus {
  empty = 'empty',
  filling = 'filling',
  frozen = 'freezed',
  uploaded = 'uploaded',
}

export const FormDataModel = types
  .model({
    id: types.optional(types.identifier, uuid),
    orderBookID: types.maybe(types.string),
    bookItemID: types.maybe(types.string),
    formId: types.maybe(types.string),
    remoteFormGroupId: types.maybe(types.string), // work order / form group id
    workOrderNumber: types.maybe(types.string),

    remoteFormId: types.string, // form / form object id
    formVersion: types.number,
    isOptional: types.boolean,

    remoteId: types.maybe(types.string), // formDataObjectID. Empty if it is independent form and not yet upload
    remoteHistoryId: types.maybe(types.string), // fill in after it is uploaded, or downloaded from server

    workNatureId: types.string,

    equipmentNumber: types.string,
    // TODO equipment info

    referenceNumber: types.maybe(types.string),

    isMerged: types.optional(types.boolean, false),

    items: types.array(FormDataItemModel),

    remark: types.optional(FormDataRemarkModel, {}),
    remarks:types.array(FormDataRemarkModel),
    tags: types.array(FormDataTagModel),
    attachments: types.array(FormDataAttachmentModel),
    instruments: types.array(FormDataInstrumentModel),

    createddate: types.optional(types.Date, () => moment().toDate()),
    lastmoddate: types.optional(types.Date, () => moment().toDate()),
    uploaddate: types.maybe(types.Date),

    approvalWorkflow: types.maybe(ApprovalWorkflowEnum),

    uploaderName: types.maybe(types.string),
    uploaderUpn: types.maybe(types.string),
    updateTime: types.maybe(types.string),

    freezeTime: types.maybe(types.Date),

    approverName: types.maybe(types.string),
    approverUpn: types.maybe(types.string),
    endorserName: types.maybe(types.string),
    endorserUpn: types.maybe(types.string),
    teamLeaderName: types.maybe(types.string),
    teamLeaderUpn: types.maybe(types.string),

    submissionTime: types.maybe(types.Date),
    approvalTime: types.maybe(types.Date),
    endorsementTime: types.maybe(types.Date),

    approvalRejectUsername: types.maybe(types.string),
    approvalRejectUpn: types.maybe(types.string),
    approvalRejectReason: types.maybe(types.string),
    approvalRejectTime: types.maybe(types.Date),

    endorseRejectUsername: types.maybe(types.string),
    endorseRejectUpn: types.maybe(types.string),
    endorseRejectReason: types.maybe(types.string),
    endorseRejectTime: types.maybe(types.Date),

    voidUsername: types.maybe(types.string),
    voidUpn: types.maybe(types.string),
    voidReason: types.maybe(types.string),
    voidTime: types.maybe(types.Date),

    cmRequests: types.array(types.string),
    isPreview: types.optional(types.boolean, false),

    // 判断是否为非doer upload formData
    isManager: types.optional(types.boolean, false),
    // 是否为非doer submitFormData
    isManagerSubmitFormData: types.optional(types.boolean, false),
    specialValidateControlStr: types.maybe(types.string),
    oldFormJsonData: types.maybe(types.string),
    isSpecialForm: types.boolean,
  })
  .preProcessSnapshot((snapshot) =>
    snapshot
      ? {
          ...snapshot,
          createddate: snapshot.createddate || undefined,
          uploaddate: snapshot.uploaddate || undefined,
          lastmoddate: snapshot.lastmoddate || undefined,
        }
      : snapshot,
  )
  .extend(withRootStore)
  .extend(withEnvironment)
  .volatile(() => ({
    fieldItemMap: undefined as { [key: string]: FormDataItem } | undefined,
    get itemLastModifiedTime() {
      return observable.map<string, moment.Moment>()
    },
  }))
  .actions((self) => ({
    apply(snapshot: any) {
      _.keys(_.omit(snapshot, 'id')).forEach((key) => {
        if (snapshot[key] == null) return
        ;(self as any)[key] = snapshot[key]
      })
      if (snapshot.items != null) {
        self.fieldItemMap = undefined
      }
    },
    touch() {
      self.lastmoddate = moment().toDate()
    },
  }))
  .views((self) => {
    const views = {
      get lastUpdateTime(): moment.Moment {
        return moment(self.uploaddate ?? self.updateTime)
      },
      get isMine(): boolean {
        if (self.isMerged) return false
        return (
          self.rootStore.userProfileStore.isMe(self.uploaderUpn) ||
          self.freezeTime != null
        )
      },
      get isEmpty() {
        if (views.includeFilled) return false
        if ((self.remark.content ?? '').length > 0) return false
        if (self.attachments.length > 0) return false
        if (self.instruments.length > 0) return false

        return true
      },
      get status() {
        if (self.isMerged) return FormDataStatus.uploaded
        if (self.remoteHistoryId != null) return FormDataStatus.uploaded
        if (self.freezeTime != null) return FormDataStatus.frozen
        if (!views.isEmpty) return FormDataStatus.filling
        return FormDataStatus.empty
      },
      get workOrder(): WorkOrder | undefined {
        return self.rootStore.worksStore.workOrders.find((it: WorkOrder) =>
          self.remoteFormGroupId != null
            ? it.remoteId === self.remoteFormGroupId
            : it.number === self.workOrderNumber,
        )
      },
      get equipment(): Equipment | null | undefined {
        if (views.workOrder == null) return null
        return views.workOrder.equipment
      },
      get form(): Form {
        // return self.rootStore.formStore.forms.find(
        //   (it: Form) => it.remoteId === self.remoteFormId,
        // )
        return self.rootStore.bookFormsStore.formList.find((it) => it.bookItemID === self.bookItemID)
      },
      get formDefinition(): FormDefinition {
        return views.form.getDefinition(
          self.formVersion,
          self.isPreview,
        ) as unknown as FormDefinition
      },
      get workOrderForm(): WorkOrderForm | undefined {
        return (views.workOrder?.forms ?? []).find(
          (it) =>
            it.form.remoteId === self.remoteFormId &&
            (it.remoteFormDataId === self.remoteId ||
              (self.remoteId == null &&
                it.isUnique &&
                (it.allowMultiple
                  ? [
                      WorkOrderFormStatus.pending,
                      WorkOrderFormStatus.uploaded,
                    ].includes(it.status)
                  : true))),
        )
        // return self.rootStore.bookFormsStore.findForm(self.bookItemID)
      },
      /** Form definition items for current form data associated work order form */
      get availableDefinitionItems(): FormDefinitionItem[] {
        if(self.isSpecialForm){
          return views.formDefinition.itemsRecursivelyNeedUpload(
            views.workOrderForm,
          )
        }   

        return views.formDefinition.itemsRecursivelyForWorkOrderForm(
            views.workOrderForm,
        )  
      },

      /** Fillable form definition items for current form data associated work order form */
      get fillableDefinitionItems(): FormDefinitionItem[] {
        return views.availableDefinitionItems.filter((it) => it.fillable)
      },

      get startDate() {
        return self.createddate
      },
      get endDate() {
        return self.uploaddate
      },

      get approver() {
        if (self.approverUpn == null) return undefined
        return {
          username: self.approverName ?? '',
          upn: self.approverUpn,
        }
      },
      set approver(info: { username: string; upn: string } | undefined) {
        self.approverName = info?.username
        self.approverUpn = info?.upn
      },
      get endorser() {
        if (self.endorserUpn == null) return undefined
        return {
          username: self.endorserName ?? '',
          upn: self.endorserUpn,
        }
      },
      set endorser(info: { username: string; upn: string } | undefined) {
        self.endorserName = info?.username
        self.endorserUpn = info?.upn
      },
      get teamLeader() {
        if (self.teamLeaderUpn == null) return undefined
        return {
          username: self.teamLeaderName ?? '',
          upn: self.teamLeaderUpn,
        }
      },
      set teamLeader(info: { username: string; upn: string } | undefined) {
        self.teamLeaderName = info?.username
        self.teamLeaderUpn = info?.upn
      },

      get uploader() {
        if (self.isMerged) return views.teamLeader
        if (self.uploaderUpn == null) return undefined
        return {
          username: self.uploaderName ?? '',
          upn: self.uploaderUpn,
        }
      },
      get isUploader() {
        return (
          views.uploader &&
          views.uploader.upn.toLowerCase() ===
            self.rootStore.userProfileStore.userInfo.upn.toLowerCase()
        )
      },

      /** Is this form data include a filled item */
      get includeFilled() {
        // ? use isEmpty memoized value of data item will be good enough, as at least one item will be marked as self N/A if there is inherit N/A
        return self.items.filter((it) => !it.isEmpty).length > 0
      },

      /** Find existing data item with matching inputId, return undefined if not existing */
      getFieldItem(
        item: FormDefinitionItem | undefined,
      ): FormDataItem | undefined {
        if (item == null) return undefined
        const { inputId } = item
        if (inputId == null) return undefined
        const itemMap = self.fieldItemMap ?? {}
        if (Object.keys(itemMap).length === self.items.length) {
          return itemMap[inputId]
        }
        console.tron.log('getFieldItem: fallback slow')
        return self.items.find((it) => it.inputId === inputId)
      },
      getFieldItemById(inputId: string | undefined): FormDataItem | undefined {
        if (inputId == null) return undefined
        const itemMap = self.fieldItemMap ?? {}
        if (Object.keys(itemMap).length === self.items.length) {
          return itemMap[inputId]
        }
        console.tron.log('getFieldItemById: fallback slow')
        return self.items.find((it) => it.inputId === inputId)
      },
      /** Return whether the item is currently N/A, by self or by inherit
       *  NOTE Unfillable item ignores allow N/A flag and could be N/A = inherit if it's parent is N/A
       */
      isItemNotApplicable(item?: FormDefinitionItem): NAType {
        if (item == null) return false

        if (!item.naAllowed && item.fillable) {
          // ? allow unfillable item to be inherit N/A
          return false
        }

        if (
          views.isItemNotApplicable(
            item.parent as unknown as FormDefinitionItem,
          ) !== false
        ) {
          return 'inherit'
        }

        const dataItem = views.getFieldItem(item)
        if (dataItem?.notApplicableSelf) return 'self'

        return false
      },

      /** Determine whether an item is filled (or N/A) */
      isItemFilled(item: FormDefinitionItem): boolean {
        if (!item.fillable) return false
        if (views.isItemNotApplicable(item)) return true
        return views.getFieldItem(item)?.isFilledOrNotApplicable() ?? false
      },

      /**
       * Get the fill status of the item itself
       * @return fille status of the item, or null if the item is not fillable
       */
      async fillStatusSelf(
        item: FormDefinitionItem,
        opts?: ValidationOptions,
      ): Promise<FillStatus> {
        if (!item.fillable) return null

        if (views.isItemNotApplicable(item)) return 'notApplicable'

        if (!views.isItemFilled(item))
          return item.isMandatory ? 'missing' : 'unfilled'

        const dataItem = views.getFieldItem(item)!

        // const validationResult = await dataItem.validate(opts)

        const validationResult = await fastValidate(dataItem, opts)
        return (validationResult ?? []).length > 0 ? 'flagged' : 'passed'
      },

      async bulkFillStatusSelf(
        items: { item: FormDefinitionItem; opts?: ValidationOptions }[],
      ) {
        const status = await Promise.all(
          items.map(async (it): Promise<FillStatus> => {
            if (!it.item.fillable) return null

            if (views.isItemNotApplicable(it.item)) return 'notApplicable'

            if (!views.isItemFilled(it.item))
              return it.item.isMandatory ? 'missing' : 'unfilled'

            const dataItem = views.getFieldItem(it.item)!

            // const validationResult = await dataItem.validate(it.opts)
            let validationResult = null
            // validation
            const key = serializeValidationOption(it.item, it.opts)

            if (dataItem.validationResult[key])
              validationResult = dataItem.validationResult[key]

            if (validationResult == null) {
              const validatingValue = dataItem.value

              const result = await validateFormDataItem(
                dataItem,
                dataItem.formDefinitionItem,
                dataItem.rootStore,
                it.opts,
              )

              if (validatingValue !== dataItem.value)
                validationResult = undefined
              else {
                dataItem.validationResult[key] = result ?? []

                validationResult = dataItem.validationResult[key]
              }
            }

            return (validationResult ?? []).length > 0 ? 'flagged' : 'passed'
          }),
        )
        return status?.reduce((acc, it) => combineFillStatus(acc, it), null)
      },

      async fillStatusSubitem(
        item: FormDefinitionItem,
        validationOptions?: ValidationOptions,
      ): Promise<FillStatus> {
        const childs = item
          .itemsRecursivelyWithSelfForWorkOrder(views.workOrderForm, {
            fillableOnly: true,
            level: Infinity,
          })
          .filter((it) => it.id !== item.id)

        // ! performance issue here
        // const status = await Promise.all(
        //   childs.map((it) => views.fillStatusSelf(it, validationOptions)),
        // )
        // return status.reduce((acc, it) => combineFillStatus(acc, it), null)

        const taskParamList = childs.map((it) => ({
          item: it,
          opts: validationOptions,
        }))
        const status = views.bulkFillStatusSelf(taskParamList)
        return status
      },

      async getDisplayFillStatus(
        item: FormDefinitionItem,
        validationOptions: ValidationOptions,
        displayOptions: DisplayFillStatusOptions,
      ): Promise<{
        itemStatus: DisplayFillStatus
        subitemStatus: DisplayFillStatus | undefined
      }> {
        let itemStatus: DisplayFillStatus = await views.fillStatusSelf(
          item,
          validationOptions,
        )
        let subitemStatus: DisplayFillStatus | undefined =
          await views.fillStatusSubitem(item, validationOptions)

        if (
          displayOptions.suppressMandatoryWarningWithUnfilledParent &&
          subitemStatus === 'missing'
        ) {
          // ? allow potential grace on mandatory missing, if none of the items has been filled

          if (
            itemStatus === 'unfilled' &&
            (views?.filledSubitemCount(item) ?? 0) === 0
          ) {
            subitemStatus = 'unfilled'
          }
        }

        if (displayOptions.warnOnUnfilled) {
          itemStatus =
            itemStatus === 'unfilled' ? 'unfilled-warning' : itemStatus
          subitemStatus =
            subitemStatus === 'unfilled' ? 'unfilled-warning' : subitemStatus
        }

        if (
          displayOptions.escalateSubitem &&
          itemStatus == null &&
          subitemStatus != null
        ) {
          itemStatus = subitemStatus
          subitemStatus = undefined
        }
        return { itemStatus, subitemStatus }
      },

      /** List of filled items. Excluding inherit N/A items */
      filledItems(): FormDataItem[] {
        return self.items.filter(
          (item) =>
            // (item.formDefinitionItem?.fillable ?? false) &&
            views
              .getFieldItem(item.formDefinitionItem)
              ?.isFilledOrNotApplicable() ?? false,
        )
      },

      /** Number of filled items, including N/A or inherit N/A */
      filledItemNumber(): number {
        return (views.fillableDefinitionItems ?? []).filter((it) =>
          views.isItemFilled(it),
        ).length
      },

      notApplicableItemNumber() {
        return (views.fillableDefinitionItems ?? []).filter((it) =>
          views.isItemNotApplicable(it),
        ).length
      },

      filledSubitemCount(item: FormDefinitionItem): number {
        return item
          .itemsRecursivelyWithSelfForWorkOrder(views.workOrderForm)
          .filter((it) => it.id !== item.id)
          .map((child) => views.isItemFilled(child)).length
      },

      async flaggedItemNumber(
        validationOptions?: ValidationOptions,
      ): Promise<number> {
        return (
          await Promise.all(
            views
              .filledItems()
              .map((it) => fastValidate(it, validationOptions)),
          )
        ).filter((it) => (it ?? []).length > 0).length
      },

      async summary(validationOptions?: ValidationOptions) {
        if (views.formDefinition == null) return undefined
        return {
          total: views.fillableDefinitionItems?.length ?? 0,
          filled: views.filledItemNumber(),
          flagged: await views.flaggedItemNumber(validationOptions),
        }
      },

      get sicCompleted() {
        return views.fillableDefinitionItems.reduce((acc, item) => {
          if (!acc) return false
          if (item.inputType !== FormDefinitionFieldType.sic) return true
          if (!item.subitemsForWorkOrderForm(views.workOrderForm)) return true

          const dataItem = views.getFieldItem(item)
          if (dataItem == null) return true
          if (dataItem.value == null) return true
          if (dataItem.isNotApplicable() !== false) return true
          if (dataItem.value === FormDefinitionSICOptions.sicChecked)
            return true
          return false
        }, true)
      },
      get secondCheckerCompleted() {
        return views.fillableDefinitionItems.reduce((acc, item) => {
          if (!acc) return false
          if (item.inputType !== FormDefinitionFieldType.secondCheckerCheckbox)
            return true
          if (!item.subitemsForWorkOrderForm(views.workOrderForm)) return true

          const dataItem = views.getFieldItem(item)
          if (dataItem == null) return true
          if (dataItem.value == null) return true
          if (dataItem.isNotApplicable() !== false) return true
          if (
            dataItem.value === FormDefinitionSecondCheckerOptions.secondChecker
          )
            return true
          return false
        }, true)
      },
      get defaultValidationOptions(): Pick<ValidationOptions, 'referenceDate'> {
        return {
          referenceDate: moment(views.endDate),
        }
      },

      get fileList() {
        return self.attachments.filter(
          (att) => att.type === FormDataAttachmentType.file,
        )
      },
      get mediaList() {
        return self.attachments.filter(
          (att) => att.type !== FormDataAttachmentType.file,
        )
      },
    }
    return views
  })
  .views((self) => {
    const views = {
      async payloadMetadata(
        action: 'upload' | 'submit',
      ): Promise<{ [key: string]: FormDataPayloadMetadataItem }> {
        const availableItems = self.availableDefinitionItems
        const formMetadataJson: { [key: string]: FormDataPayloadMetadataItem } =
          {}

        await Promise.all(
          availableItems.map(async (item) => {
            const { inputId } = item
            const dataItem = self.getFieldItem(item)
            const isNotApplicable = self.isItemNotApplicable(item)

            if (inputId == null) return

            const { itemStatus, subitemStatus } =
              await self.getDisplayFillStatus(
                item,
                {
                  ...self.defaultValidationOptions,
                  validateWorkflow: action === 'submit',
                },
                {
                  escalateSubitem: true,
                  suppressMandatoryWarningWithUnfilledParent:
                    action === 'upload',
                  warnOnUnfilled: action === 'submit',
                },
              )

            formMetadataJson[inputId] = {
              wIItemNo: item.number ?? undefined,
              value:
                dataItem?.value != null &&
                [
                  FormDefinitionFieldType.date,
                  FormDefinitionFieldType.datetime,
                ].includes(item.inputType)
                  ? moment(dataItem.value).toISOString()
                  : dataItem?.value,
              notApplicable: isNotApplicable,
              start_date: dataItem?.createddate?.toISOString(),
              end_date: (
                dataItem?.lastmoddate ?? dataItem?.createddate
              )?.toISOString(),
              itemStatus,
              subitemStatus,
            }
          }),
        )
        return formMetadataJson
      },
      async payload(
        action: 'upload' | 'submit' | 'preview',
      ): Promise<Omit<FormDataPayload, 'formDataType'>> {
        const formSignatures: { wiItemNo: string; status: number }[] = []
        const formJsonData: { [key: string]: FormDataItemValue | undefined } =
          {}
        const availableItems = self.availableDefinitionItems
        const validationOptions: ValidationOptions = {
          referenceDate: moment(self.endDate),
          validateWorkflow: action === 'submit',
        }

        availableItems.forEach(async (item) => {
          if (!item.fillable) return

          const { inputId } = item
          const dataItem = self.getFieldItem(item)
          const isNotApplicable = self.isItemNotApplicable(item)

          // --- form data json
          if (dataItem != null || isNotApplicable !== false) {
            if (self.formDefinition?.parserVersion === 0 && item.level === 0) {
              // ? Legacy form definition type
              if (item.number != null) {
                formSignatures.push({
                  wiItemNo: item.number,
                  status:
                    dataItem?.value === FormDefinitionCheckboxOptions.flagged
                      ? 2
                      : dataItem?.value ===
                        FormDefinitionCheckboxOptions.checked
                      ? 1
                      : 0,
                })
              }
            } else if (inputId != null) {
              let uploadValue =
                isNotApplicable !== false ? NA_VALUE : dataItem?.uploadValue()

              if (
                !isNotApplicable &&
                [
                  FormDefinitionFieldType.date,
                  FormDefinitionFieldType.datetime,
                ].includes(item.inputType)
              ) {
                uploadValue = moment(uploadValue).format(
                  FORMDATA_ITEM_DATETIME_FORMAT,
                )
              }

              formJsonData[inputId] = uploadValue
            }
          }
        })

        const formGroupDataObjectID =
          self.remoteFormGroupId ?? self.workOrder?.remoteId

        if (formGroupDataObjectID == null && action !== 'preview')
          throw new Error('empty-form-group-data')

        return {
          workOrder: (self.workOrder?.number ?? self.workOrderNumber) as string,
          formGroupDataObjectID: formGroupDataObjectID ?? '',
          formDataObjectID: self.remoteId,
          formObjectID: self.remoteFormId,
          versionNo: self.formVersion,
          standardJobCode: self.workOrder?.standardJob.code,
          isSubmit: action === 'submit',
          isOptional: self.isOptional,
          equipmentNo: self.equipmentNumber,

          workGroupID: self.workOrder?.workGroupId,

          formRemarks: [
            {
              remarkText: self.remark.content,
              formAttachments: self.attachments
                .map((attachment) => attachment.formUploadPayload)
                .filter(notEmpty),
            },
          ],
          formDataItems: self.instruments.map((instrument) => ({
            code: instrument.imteNumber,
            nextExamDate: instrument.expiryDate
              ? moment(instrument.expiryDate).format(
                  moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
                )
              : undefined,
            recordDate: moment(instrument.createdDate).format(
              moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
            ),
          })),
          formDataTags: self.tags.map((tag) => ({
            tag: tag.tag,
            dataTagObjectID: tag.objectid ?? '',
            createBy: tag.createdby ?? '',
            createDate: moment(tag.createddate).format(
              moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
            ),
          })),
          formSignatures,
          formJsonData: JSON.stringify(formJsonData),
          inputMetadata: JSON.stringify(
            await views.payloadMetadata(
              action === 'preview' ? 'upload' : action,
            ),
          ),
          oldFormJsonData: self.oldFormJsonData,
          summary: (await self.summary(validationOptions)) ?? {},

          submitUserName: self.rootStore.userProfileStore
            ? self.rootStore.userProfileStore.userInfo.username
            : undefined,
          submitUserUPN: self.rootStore.userProfileStore
            ? self.rootStore.userProfileStore.userInfo.upn
            : undefined,

          startDate: moment(self.startDate).format(
            moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
          ),
          endDate: moment(self.endDate).format(
            moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
          ),

          CmRequests: self.cmRequests,
          // upload状态IsManager为true，不对form Item generate history record
          IsManager: self.isManagerSubmitFormData,
        }
      },
    }
    return views
  })
  .actions((self) => {
    const getMappedFieldItem = (item: FormDefinitionItem) => {
      let dataItem: FormDataItem | undefined
      try {
        // construct or update volatile map from self.items
        if (self.fieldItemMap == null) {
          const map: { [inputId: string]: FormDataItem } = {}
          self.items.forEach((fieldItem: FormDataItem) => {
            map[fieldItem.inputId] = fieldItem
          })
          self.fieldItemMap = map
        }
        if (Object.keys(self.fieldItemMap).length !== self.items.length) {
          self.environment.console.log(
            'Form-data model: FieldItemMap is outdated. Reconstruct',
            self.fieldItemMap,
            [...self.items],
          )
          if (Object.keys(self.fieldItemMap).length > self.items.length) {
            self.fieldItemMap = {}
          }
          self.items.forEach((fieldItem: FormDataItem) => {
            if (!self.fieldItemMap![fieldItem.inputId])
              self.fieldItemMap![fieldItem.inputId] = fieldItem
          })
        }
        if (item.inputId != null) {
          dataItem = self.fieldItemMap[item.inputId]
        }
      } catch (error) {
        self.environment.console.reportError(error)
        self.environment.console.warn(
          'Error!, cannot find field item from map',
          error.message,
        )
        // fall back to find, which is slow
        dataItem = self.getFieldItem(item)
      }
      return dataItem
    }

    const handleItemUpdate = (item: FormDefinitionItem) => {
      const currentTime = moment()
      self.itemLastModifiedTime.set(item.id, currentTime)
      item.parents.forEach((parent) => {
        self.itemLastModifiedTime.set(parent.id, currentTime)
      })
    }

    const actions = {
      // 只有doer会传入false
      setIsManagerSubmitFormData(isDoer: boolean) {
        self.isManagerSubmitFormData = isDoer
      },
      constructFieldDataMapping: flow(
        function* constructFieldDataMapping(): Generator<any, void, any> {
          const mapping = yield constructFieldDataMap(self)
          self.fieldItemMap = mapping
        },
      ),
      decryptAllAttachments: flow(function* decrypt() {
        yield Promise.all(
          self.attachments.map((attachment) => attachment.decrypt()),
        )
      }),
      async updateItemValue(
        item: FormDefinitionItem,
        value: FormDataItemValue | undefined,
        validate?: boolean,
        validationOptions?: ValidationOptions,
        groupID?: string,
      ) {
        let dataItem: FormDataItem | undefined
        dataItem = getMappedFieldItem(item)
        if (dataItem == null) {
          if (item.inputId == null) {
            self.environment.console.warn('Empty input id', item)
            return Promise.resolve(undefined)
          }

          dataItem = FormDataItemModel.create({ inputId: item.inputId , isReadOnly:false, groupID})
          self.items.push(dataItem)
          if (self.fieldItemMap != null) {
            self.fieldItemMap[item.inputId] = dataItem
          }
        }
        if(item.inputId === 'Key_1_ckeck1_1bc2e801-22aa-41ef-bb0b-232c64eb3e3e'){
          if(value === 'checked'){
            let dataItem2: FormDataItem | undefined
            dataItem2 = self.getFieldItemById("Key_1_ckeck2_1bc2e801-22aa-41ef-bb0b-232c64eb3e3e")
            if (dataItem2 == null) {
          
              dataItem2 = FormDataItemModel.create({ inputId: "Key_1_ckeck2_1bc2e801-22aa-41ef-bb0b-232c64eb3e3e" , isReadOnly:false, groupID})
              self.items.push(dataItem2)
              if (self.fieldItemMap != null) {
                self.fieldItemMap["Key_1_ckeck2_1bc2e801-22aa-41ef-bb0b-232c64eb3e3e"] = dataItem2
              }
            }
            dataItem2.set(undefined)
          }
        }else if(item.inputId === 'Key_1_ckeck2_1bc2e801-22aa-41ef-bb0b-232c64eb3e3e'){
          if(value === 'checked'){
            let dataItem2: FormDataItem | undefined
            dataItem2 = self.getFieldItemById("Key_1_ckeck1_1bc2e801-22aa-41ef-bb0b-232c64eb3e3e")
            if (dataItem2 == null) {
          
              dataItem2 = FormDataItemModel.create({ inputId: "Key_1_ckeck1_1bc2e801-22aa-41ef-bb0b-232c64eb3e3e" , isReadOnly:false, groupID})
              self.items.push(dataItem2)
              if (self.fieldItemMap != null) {
                self.fieldItemMap["Key_1_ckeck1_1bc2e801-22aa-41ef-bb0b-232c64eb3e3e"] = dataItem2
              }
            }
            dataItem2.set(undefined)
          }
        }

        if (validate) {
          return dataItem.trySet(value, validationOptions).then((ret) => {
            // ? the stack is different and may not be in action scope anymore
            runInAction(() => handleItemUpdate(item))
            self.touch()
            return {
              dataItem,
              validationResults: ret,
            }
          })
        }

        dataItem.set(value)

        handleItemUpdate(item)

        self.touch()

        return Promise.resolve({ dataItem, validationResults: undefined })
      },
      async bulkUpdateItemValue(
        items: {
          item: FormDefinitionItem
          value: FormDataItemValue | undefined
          validate?: boolean
          validationOptions?: ValidationOptions
          groupID?:string
        }[],
      ) {
        const taskList = items.map((it) => {
          let dataItem: FormDataItem | undefined
          dataItem = getMappedFieldItem(it.item)
          if (dataItem == null) {
            if (it.item.inputId == null) {
              self.environment.console.warn('Empty input id', it.item)
              return Promise.resolve(undefined)
            }

            dataItem = FormDataItemModel.create({ inputId: it.item.inputId, groupID:it.groupID})
            self.items.push(dataItem)
            if (self.fieldItemMap != null) {
              self.fieldItemMap[it.item.inputId] = dataItem
            }
          }

          // dataItem.set(it.value)
          if (it.value != null) {
            dataItem.value = it.value
          } else {
            dataItem.value = undefined
          }
          dataItem.validationResult = {}
          dataItem.lastmoddate = moment().toDate()
          handleItemUpdate(it.item)
          return Promise.resolve({ dataItem, validationResults: undefined })
        })
        await Promise.all(taskList)
      },
      async validateItem(
        item: FormDefinitionItem,
        validationOptions?: ValidationOptions,
      ) {
        const dataItem = getMappedFieldItem(item)

        if (dataItem == null) return undefined

        return {
          dataItem,
          validationResults: await dataItem.validate(validationOptions),
        }
      },

      setItemNotApplicable(item: FormDefinitionItem, na: boolean) {
        let dataItem = getMappedFieldItem(item)
        if (!item.naAllowed) return dataItem

        if (dataItem == null) {
          if (item.inputId == null) {
            self.environment.console.warn('Empty input id', item)
            return null
          }

          dataItem = FormDataItemModel.create({ inputId: item.inputId })
          self.items.push(dataItem)
          if (self.fieldItemMap != null) {
            self.fieldItemMap[item.inputId] = dataItem
          }
        }

        dataItem.setNotApplicable(na)
        handleItemUpdate(item)

        self.touch()
        return dataItem
      },

      addAttachment: flow(function* addAttachment(
        path: string,
        metadata: {
          type: FormDataAttachmentType
          title?: string
          mime?: string
          itemInputId?: string
        },
        autoUpload: boolean = true,
      ): Generator<any, FormDataAttachment> {
        if (
          self.environment.storageHandler?.binary == null ||
          self.environment.storageHandler?.paths == null
        ) {
          throw new Error('unsupported')
        }

        const fileName: string =
          (yield self.environment.storageHandler?.binary?.moveIn(path)) as any

        const filesize: number =
          (yield self.environment.storageHandler.binary.getSize(
            fileName,
          )) as any

        if (filesize > self.rootStore.configStore.maxAttachmentByteSize) {
          throw new Error('too-large')
        }

        const mime =
          metadata.mime ??
          self.environment.storageHandler.paths.getMime(fileName) ??
          ''

        const fileCount = self.attachments.filter(
          (media: FormDataAttachment) => media.type === metadata.type,
        ).length

        const title =
          metadata.title ?? `${_.capitalize(metadata.type)} ${fileCount + 1}`

        const ret = FormDataAttachmentModel.create({
          title,
          type: metadata.type,
          mime,
          itemInputId: metadata.itemInputId,
        })

        ret.bindNetworkFileWithLocalFile(fileName)
        self.attachments.push(ret)
        ret.networkFile?.initialize({ filename: fileName })

        self.touch()

        if (autoUpload) {
          // ? perform background upload and avoid blocking
          ret.upload().catch((error) => {
            self.environment.console.reportError(error)
          })
        }

        return ret
      }),

      removeAttachment(file: FormDataAttachment) {
        // TODO delete the file in local
        detach(self.attachments.find((it) => it === file))
        self.attachments.remove(file)
        self.touch()
      },

      uploadAllAttachments: flow(function* upload(
        type?: 'media' | 'file',
      ): Generator<any, any, any> {
        yield Promise.all(
          (type === 'media'
            ? self.mediaList
            : type === 'file'
            ? self.fileList
            : self.attachments
          ).map(async (attachment) => {
            if (attachment.remoteId != null)
              return { ok: true, result: 'uploaded' }
            try {
              await attachment.upload()
              return { ok: true, result: 'done' }
            } catch (error) {
              return { ok: false, error }
            }
          }),
        )
      }),

      updateRemark(remark: string) {
        if (!self.remark) {
          self.remark = FormDataRemarkModel.create({ content: remark })
        } else {
          self.remark.content = remark
          self.remark.lastmoddate = moment().toDate()
        }
        self.touch()
      },
      validateInstrument: flow(function* validateInstrument(code: string) {
        const res: GetIMTEStatus = yield self.environment.api.getIMTEStatus(
          code,
        )
        if (res.kind !== 'ok') throw new Error(res.kind)
        const valid = res.payload.status === 2
        if (!valid) return res.payload.message
        return null
      }),
      getInstrument: (code: string) =>
        self.instruments.find((instrument) => instrument.imteNumber === code),
      insertInstrument: (code: string, examDate?: moment.Moment) => {
        const instrument = FormDataInstrumentModel.create({
          imteNumber: code,
          createdDate: moment().toDate(),
          expiryDate: examDate ? moment(examDate).toDate() : undefined,
        })
        self.instruments.push(instrument)
        self.lastmoddate = moment().toDate()
      },
      updateInstrument(code: string, examDate: moment.Moment) {
        const instrument = self.instruments.find(
          (inst) => inst.imteNumber === code,
        )
        if (!instrument) return false
        const newInstrument = FormDataInstrumentModel.create({
          imteNumber: instrument.imteNumber,
          createdDate: instrument.createdDate,
          expiryDate: moment(examDate).toDate(),
        })
        self.instruments.remove(instrument)

        self.instruments.push(newInstrument)
        self.lastmoddate = moment().toDate()
        return true
      },
      removeInstrument(code: string) {
        const instrument = self.instruments.find(
          (inst) => inst.imteNumber === code,
        )
        if (!instrument) return false
        self.instruments.remove(instrument)
        self.lastmoddate = moment().toDate()
        return true
      },
      setEndorser(endorser: { username: string; upn: string } | undefined) {
        self.endorser = endorser
        // self.endorsementTime = moment().toDate()
      },
      setApprover(approver: { username: string; upn: string } | undefined) {
        self.approver = approver
        // self.approvalTime = moment().toDate()
      },
      setTeamLeader(teamLeader: { username: string; upn: string } | undefined) {
        self.teamLeader = teamLeader
        self.submissionTime = moment().toDate()
      },

      recordCMRequest(cmRequestId: string) {
        self.cmRequests.push(cmRequestId)
      },
      upload: flow(function* upload(): Generator<any, void, any> {
        const payload = yield self.payload('submit')
        const res: UploadFormData = yield self.environment.api.uploadFormData(
          payload,
        )
        if (res.kind !== 'ok') throw new Error(res.kind)
        if (!res.payload.success || res.payload.data == null)
          throw new Error(JSON.stringify(res.payload))
        self.freezeTime = undefined
        self.remoteId = res.payload.data.formDataObjectID
        self.remoteHistoryId = res.payload.data.formDataHistoryObjectID
        self.referenceNumber = res.payload.data.formReferenceNo
        self.uploaddate = moment().toDate()
      }),
      withdraw: flow(function* withdraw(): Generator<any, void, any> {
        if (self.remoteId == null) throw new Error('NOT-UPLOADED')
        const res: PostWithdrawal = yield self.environment.api.withdrawFormData(
          self,
          self.rootStore.userProfileStore.userInfo,
        )
        if (res.kind !== 'ok') throw new Error(res.kind)
        const { payload } = res
        if (!payload.success) throw new Error(res.payload.errorMessage)
        self.remoteHistoryId = undefined
        self.uploaddate = undefined
        yield self.workOrderForm?.onWithdrawn?.()
      }),
      didUploaded(workOrderForm: WorkOrderForm) {
        self.remoteId = workOrderForm.remoteFormDataId
      },
      discard() {
        self.rootStore.formStore.discardFormData(self)
      },
      freeze() {
        self.freezeTime = moment().toDate()
      },
      unfrozen() {
        self.freezeTime = undefined
        self.touch()
      },
      onRemoteFormGroupIdAssigned({
        formGroupDataId,
        formDataId,
      }: {
        formGroupDataId: string
        formDataId: string
      }) {
        self.remoteFormGroupId = formGroupDataId
        self.remoteId = formDataId
      },
      getFormDataTag: flow(function* getFormDataTag() {
        const res: GetFormDataTag = yield self.environment.api.getFormDataTag()
        if (res.kind !== 'ok') throw new Error(res.kind)
        return res.payload
      }),
      insertTag: (item: FormDataTag) => {
        const tag = FormDataTagModel.create({
          tag: item.tag,
          objectid: item.objectid,
          createddate: moment().toDate(),
          createdby: item.createdby,
        })
        self.tags.push(tag)
        self.lastmoddate = moment().toDate()
      },
      removeTag(objectid: string) {
        const tag = self.tags.find((item) => item.objectid === objectid)
        if (!tag) return false
        self.tags.remove(tag)
        self.lastmoddate = moment().toDate()
        return true
      },
      insertTagList: (items: FormDataTag[]) => {
        items.forEach((item) => {
          const tag = FormDataTagModel.create({
            tag: item.tag,
            objectid: item.objectid,
            createddate: moment().toDate(),
            createdby: item.createdby,
          })
          self.tags.push(tag)
        })
        self.lastmoddate = moment().toDate()
      },
      clearAllTags() {
        self.tags.clear()
        self.lastmoddate = moment().toDate()
      },
    }
    return actions
  })
  .views((self) => {
    const views = {
      get mandatoryUnfulfilledItems() {
        // if (self.workOrderForm == null) return []
        return (self.fillableDefinitionItems ?? []).filter((item) => {
          if (!item.isMandatory) return false
          return self.getFieldItem(item)?.isEmpty ?? true
        })
      },
      get specialUnValidateItems(){
        const items = []
        if(self.specialValidateControlStr !== undefined){
          const specialValidateControlJson = JSON.parse(self.specialValidateControlStr)
          if(specialValidateControlJson.atLeastOneRequired){
            specialValidateControlJson.atLeastOneRequired.forEach((item)=>{
             const inputItem = self.items.find((it)=> item.itemID.find((id)=>id===it.inputId) && it.value!==undefined)
             if(!inputItem){
                items.push(item.message)
             }
            })
          }
          if(specialValidateControlJson.requiredControl){
            specialValidateControlJson.requiredControl.forEach((item)=>{
              let isTrigger = true
              for (let i = 0; i < item.triggerControlID.length; i += 1) {
                const inputItem = self.items.find((it)=> it.inputId === item.triggerControlID[i].id)
                if(inputItem === undefined){
                  isTrigger =false
                  break
                }
                if(inputItem.value !== item.triggerControlID[i].value){
                  isTrigger =false
                  break
                }
              }
             
              if(isTrigger){
                let isValidate = true
                for (let i = 0; i < item.requiredControlID.length; i += 1) {
                  const inputItem = self.items.find((it)=> it.inputId === item.requiredControlID[i])
                  if(inputItem === undefined){
                    isValidate = false
                    break
                  }
                  if(inputItem.value === undefined || inputItem.value ===''){
                    isValidate = false
                    break
                  }
                }
                if(!isValidate){
                  items.push(item.message)
                }
              }
            })
          }
        }
        return items
      },
      get mandatoryFulfilled() {
        if(self.specialValidateControlStr === undefined){
          return (views.mandatoryUnfulfilledItems ?? []).length === 0
        }

        if ((views.mandatoryUnfulfilledItems ?? []).length === 0){
          return (views.specialUnValidateItems ?? []).length === 0
        }

        return false
      }
    }
    return views
  })

export type FormData = Instance<typeof FormDataModel>
// export interface FormData extends Instance<typeof FormDataModel> {}
export interface FormDataSnapshot extends SnapshotOut<typeof FormDataModel> {}
