import { observer } from 'mobx-react-lite'
import { Grid, Paper, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { env } from '../../utils'

const Item = styled(Paper)<{ $type: 'version' | 'environment' }>`
  padding: ${({ theme }) => theme.spacings[2]}px
    ${({ theme }) => theme.spacings[4]}px;
  text-align: center;
  background-color: ${({ theme, $type }) =>
    $type === 'version'
      ? theme.palettes.general.hover
      : theme.palettes.general.lightBlue};
  color: ${({ theme, $type }) =>
    $type === 'version'
      ? theme.palettes.general.white
      : theme.palettes.general.hover};
  justify-content: center;
  border: solid;
  border-color: ${({ theme, $type }) =>
    $type === 'version'
      ? theme.palettes.wicn.main
      : theme.palettes.general.hover};
  border-width: ${({ $type }) => ($type === 'version' ? 1 : 0)}px;
  border-radius: 10px;
  margin: 0px ${({ theme }) => theme.spacings[2]}px;
`

const Root = styled(Grid)`
  justify-content: center;
  align-items: flex-end;
  margin-bottom: ${({ theme }) => theme.spacings[7]}px;
`

const Text = styled(Typography)`
  font-size: ${({ theme }) => theme.fontSize[3]}px;
`

export const SidebarFooter = observer(() => (
  <Root container>
    <Grid item>
      <Item $type="version">
        <Text>{env.version}</Text>
      </Item>
    </Grid>
    <Grid item>
      <Item $type="environment">
        <Text>{env.environment}</Text>
      </Item>
    </Grid>
  </Root>
))
