import { types, Instance } from 'mobx-state-tree'

export const FormDefinitionItemOptionModel = types.model({
  label: types.string,
  value: types.string,
})

export type FormDefinitionItemOption = Instance<
  typeof FormDefinitionItemOptionModel
>
