import { WIReviewTaskPayloadItem } from '@mtr-SDO/apis'
import { withRootStore } from '@mtr-SDO/models-core'
import _ from 'lodash'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import {
  getPriorityByDeadline,
  mapPayloadToWIWorkflowPart,
  TaskPriority,
  UserModel,
} from '../wi-workflow'
import {
  stringToDate,
  stringToNumber,
  stringToString,
} from '../common-function'
import { WIReviewWorkflowModel } from './wi-review-workflow.model'
import {
  getWIReviewSnapShotStatus,
  WIReviewStatusEnum,
} from './wi-review-status'

export const WIReviewTaskModel = types
  .model('WI-review-Task', {
    wiReviewTaskId: types.optional(types.identifier, uuid),
    status: WIReviewStatusEnum,
    currentUser: types.maybe(UserModel),
    currentUserGroup: types.maybe(types.string),
    refNo: types.maybe(types.string),
    wiNo: types.maybe(types.string),
    wiTitle: types.maybe(types.string),

    targetCompletionDate: types.maybe(types.Date),
    createdDate: types.maybe(types.Date),
    createdBy: types.maybe(types.string),
    updatedDate: types.maybe(types.Date),
    updatedBy: types.maybe(types.string),

    isFinished: types.boolean,
    isForwarded: types.boolean,
    wiReviewWorkflowData: WIReviewWorkflowModel,
  })
  .extend(withRootStore)
  .actions((self) => ({
    apply(snapshot: any) {
      _.keys(_.omit(snapshot, ['wiReviewTaskId'])).forEach((key) => {
        if (snapshot[key] == null) return
        self[key] = snapshot[key]
      })
    },
  }))
  .views((self) => {
    const views = {
      get lastUpdatedDate(): moment.Moment {
        if (self.wiReviewWorkflowData == null) {
          return moment(self.createdDate)
        }
        const dates: moment.Moment[] = [
          moment(self.createdDate),
          moment(self.wiReviewWorkflowData.partAWorkflow.operationDate),
          moment(self.wiReviewWorkflowData.partBWorkflow.operationDate),
          moment(self.wiReviewWorkflowData.partCWorkflow.operationDate),
          moment(self.wiReviewWorkflowData.partDWorkflow.operationDate),
          moment(self.wiReviewWorkflowData.partEWorkflow.operationDate),
          moment(self.wiReviewWorkflowData.partFWorkflow.operationDate),
          moment(self.wiReviewWorkflowData.partGWorkflow.operationDate),
          moment(self.wiReviewWorkflowData.partHWorkflow.operationDate),
          moment(self.wiReviewWorkflowData.partIWorkflow.operationDate),
          moment(self.wiReviewWorkflowData.partJWorkflow.operationDate),
          moment(self.wiReviewWorkflowData.partKWorkflow.operationDate),
        ]
        return moment.max(dates)
      },
      get priority(): TaskPriority {
        return getPriorityByDeadline(moment(self.targetCompletionDate))
      },
      get wiList(): string[] {
        return (self.wiNo ?? '').split(',')
      },
    }
    return views
  })

export type WIReviewTask = Instance<typeof WIReviewTaskModel>

export function mapPayloadToWIReviewTask(
  payload: WIReviewTaskPayloadItem,
): SnapshotIn<WIReviewTask> {
  return {
    wiReviewTaskId: payload.objectID,

    status: getWIReviewSnapShotStatus(stringToNumber(payload.currentStep)),
    currentUser: {},
    refNo: stringToString(payload.refNo),
    wiNo: stringToString(payload.wiNo),
    wiTitle: stringToString(payload.wiTitle),

    targetCompletionDate: stringToDate(payload.targetCompletionDate),
    createdDate: stringToDate(payload.createDate),
    createdBy: stringToString(payload.createBy),
    updatedDate: stringToDate(payload.createDate),
    updatedBy: stringToString(payload.createBy),
    isFinished: payload.isFinished,
    isForwarded: payload.isForWardUser,
    wiReviewWorkflowData: {
      partAWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partAWorkflow,
      ),
      partBWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partBWorkflow,
      ),
      partCWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partCWorkflow,
      ),
      partDWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partDWorkflow,
      ),
      partEWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partEWorkflow,
      ),
      partFWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partFWorkflow,
      ),
      partGWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partGWorkflow,
      ),
      partHWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partHWorkflow,
      ),
      partIWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partIWorkflow,
      ),
      partJWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partJWorkflow,
      ),
      partKWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partKWorkflow,
      ),
    },
  }
}
