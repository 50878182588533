import { SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import { IEnvironment } from '@mtr-SDO/models-core'
import { UserSessionInfo } from './user-session-info.model'

const FILENAME = 'session.txt'

export const hydrateSession: (
  environment: IEnvironment,
) => Promise<SnapshotIn<UserSessionInfo> | undefined> = async (
  environment: IEnvironment,
) => {
  if (environment.storageHandler?.text == null) return undefined

  try {
    const content = await environment.storageHandler.text.read(FILENAME)
    if (content == null || content.length === 0) return undefined
    return JSON.parse(content) as SnapshotIn<UserSessionInfo>
  } catch (error) {
    return undefined
  }
}

export const dehydrateSession = async (
  sessionInfo: SnapshotOut<UserSessionInfo> | null | undefined,
  environment: IEnvironment,
) => {
  if (environment.storageHandler?.text == null) return

  if (sessionInfo == null) {
    try {
      await environment.storageHandler.text.remove(FILENAME)
    } catch (error) {
      environment.console.log('Failed to remove file', FILENAME)
      environment.console.reportError(error)
    }
    return
  }
  await environment.storageHandler.text.save(
    FILENAME,
    JSON.stringify(sessionInfo),
  )
}
