import { ADUser, PostWIProcess, WICNDataPayload, formats } from '@mtr-SDO/apis'
import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import _ from 'lodash'
import { flow, Instance, SnapshotIn, types } from 'mobx-state-tree'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { isUpnMatched } from '@mtr-SDO/utils'
import {
  getFormTypeByCode,
  getPriorityByLastUpdateDate,
  mapPayloadToWIWorkflowPart,
  mapPayloadToWorkflowHistory,
  TaskPriority,
  WorkflowHistory,
  WorkflowHistoryModel,
  Attachment,
  mapFormDataAttachmentTypeToNumber,
  mapPayloadToCurrentUserUpnList,
} from '../wi-workflow'
import {
  stringToDate,
  stringToNumber,
  stringToString,
} from '../common-function'
import { WICNDetailModel, mapPayloadToWICNDetail } from './wicn-detail.model'
import { getWICNSnapShotStatus, WICNNodeId, WICNStatus } from './wicn-status'
import { WICNWIItem } from './wicn-wi-item.model'
import { UserInfo } from '../user-info.model'
import {
  getCodeByWICNQualityCheck,
  getCodeByWICNQualityCheckType,
  WICNQualityCheck,
  WICNQualityCheckType,
} from './wicn-selection-item'
import { WICNWorkflowModel } from './wicn-workflow.model'

export const WICNModel = types
  .model({
    wicnId: types.optional(types.identifier, uuid),
    isCancelled: types.boolean,

    wicnDetail: WICNDetailModel,

    currentStatus: types.number,
    currentUser: types.maybe(types.string),
    currentUserUpnList: types.array(types.string),
    currentUserGroup: types.maybe(types.string),

    createdDate: types.Date,
    createdBy: types.maybe(types.string),
    updatedDate: types.maybe(types.Date),
    updatedBy: types.maybe(types.string),

    workflowData: WICNWorkflowModel,
    workflowHistory: types.array(WorkflowHistoryModel),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .actions((self) => ({
    apply(snapshot: any) {
      _.keys(_.omit(snapshot, 'wicnId')).forEach((key) => {
        if (snapshot[key] == null) {
          return
        }
        ;(self as any)[key] = snapshot[key]
      })
    },
    reject: flow(function* rejectWICN(
      currentPartEnum: number,
      rejectComment: string,
    ) {
      const res: PostWIProcess =
        yield self.environment.api.wiWorkflow.rejectWICN(
          self.wicnId,
          currentPartEnum,
          rejectComment,
          self.currentStatus,
        )
      if (res.kind !== 'ok') throw new Error(res.kind)

      if (res.payload.success !== true)
        throw new Error(res.payload.errorMessage)
    }),
    submitPartB: flow(function* submitPartB(
      wicnWorkflowAction: number,
      userInfo?: UserInfo,
      decision?: string,
      comment?: string,
    ) {
      const res: PostWIProcess =
        yield self.environment.api.wiWorkflow.submitWICN(
          JSON.stringify({
            Name: userInfo?.displayName,
            Title: userInfo?.title,
            Comment: comment,
            Decision: decision,
          }),
          JSON.stringify({
            CurrentActionBy: {
              DisplayName: userInfo?.displayName,
              Title: userInfo?.title,
              UPN: userInfo?.upn,
              Email: userInfo?.email,
            },
          }),
          self.wicnId,
          WICNNodeId.partB,
          wicnWorkflowAction,
          self.currentStatus,
          false,
        )
      if (res.kind !== 'ok') throw new Error(res.kind)

      if (res.payload.success !== true)
        throw new Error(res.payload.errorMessage)
    }),
    submitPartC: flow(function* submitPartC(
      wicnWorkflowAction: number,
      partCData: {
        wiList: WICNWIItem[]
        decision?: string
        issue?: string
        rev?: string
        qualityCheck?: WICNQualityCheck
        qualityCheckType?: WICNQualityCheckType
        comment?: string
      },
      userInfo?: UserInfo,
    ) {
      const res: PostWIProcess =
        yield self.environment.api.wiWorkflow.submitWICN(
          JSON.stringify({
            ApproverModel: {
              Name: userInfo?.displayName,
              Title: userInfo?.title,
              Comment: partCData.comment,
            },
            Decision: partCData.decision,
            Issue: partCData.issue,
            Rev: partCData.rev,
            RevisedWIList: partCData.wiList.map((wi) => ({
              VersionNo: wi.version,
              WicNo: wi.wiNo,
              Title: wi.title,
              Year: wi.year,
              Month:
                wi.month == null
                  ? undefined
                  : parseInt(moment().month(wi.month).format('M'), 10), // in order to prevent moment returning month from 0-11
            })),
            QualityCheck: getCodeByWICNQualityCheck(partCData.qualityCheck),
            DueToChange: getCodeByWICNQualityCheckType(
              partCData.qualityCheckType,
            ),
            Comment: partCData.comment,
          }),
          JSON.stringify({
            CurrentActionBy: {
              DisplayName: userInfo?.displayName,
              Title: userInfo?.title,
              UPN: userInfo?.upn,
              Email: userInfo?.email,
            },
          }),
          self.wicnId,
          WICNNodeId.partC,
          wicnWorkflowAction,
          self.currentStatus,
          false,
        )
      if (res.kind !== 'ok') throw new Error(res.kind)

      if (res.payload.success !== true)
        throw new Error(res.payload.errorMessage)
    }),
    submitPartD: flow(function* submitPartD(
      wicnWorkflowAction: number,
      selectedUserList: ADUser[],
      decision?: string,
      comment?: string,
      userInfo?: UserInfo,
    ) {
      const res: PostWIProcess =
        yield self.environment.api.wiWorkflow.submitWICN(
          JSON.stringify({
            ReviewUser: {
              DisplayName: userInfo?.displayName,
              Title: userInfo?.title,
            },
            RequireComment: _.capitalize(decision),
            PartDWorkflowComment: comment,
            Comment: comment,
          }),
          JSON.stringify({
            Comment: comment,
            ChildNodeActionByList:
              selectedUserList.map((user) => ({
                Upn: user.upn,
                DisplayName: user.displayName,
                Title: user.jobTitle,
                Email: user.email,
              })) ?? [],
          }),
          self.wicnId,
          WICNNodeId.partD,
          wicnWorkflowAction,
          self.currentStatus,
          false,
        )
      if (res.kind !== 'ok') throw new Error(res.kind)

      if (res.payload.success !== true)
        throw new Error(res.payload.errorMessage)
    }),
    submitPartF: flow(function* submitPartF(
      wicnWorkflowAction: number,
      decision?: string,
      temporaryInstruction?: string,
      comment?: string,
      userInfo?: UserInfo,
    ) {
      const res: PostWIProcess =
        yield self.environment.api.wiWorkflow.submitWICN(
          JSON.stringify({
            ApprovalUser: {
              DisplayName: userInfo?.displayName,
              Title: userInfo?.title,
            },
            Decision: decision,
            ApprovalDate: moment().format(formats.dateTimeLocalSeconds),
            PartFWorkflowComment: comment,
            Comment: comment,
            TemporaryInstruction: _.capitalize(temporaryInstruction),
          }),
          JSON.stringify({
            Comment: comment,
          }),
          self.wicnId,
          WICNNodeId.partF,
          wicnWorkflowAction,
          self.currentStatus,
          false,
        )
      if (res.kind !== 'ok') throw new Error(res.kind)

      if (res.payload.success !== true)
        throw new Error(res.payload.errorMessage)
    }),
    submitPartG: flow(function* submitPartG(
      wicnWorkflowAction: number,
      uploadDate?: moment.Moment,
      comment?: string,
      userInfo?: UserInfo,
    ) {
      const res: PostWIProcess =
        yield self.environment.api.wiWorkflow.submitWICN(
          JSON.stringify({
            ApprovalUser: {
              DisplayName: userInfo?.displayName,
              Title: userInfo?.title,
            },
            UploadDate: uploadDate?.format(formats.dateTimeLocalSeconds),
            Remarks: comment,
          }),
          JSON.stringify({}),
          self.wicnId,
          WICNNodeId.partG,
          wicnWorkflowAction,
          self.currentStatus,
          false,
        )
      if (res.kind !== 'ok') throw new Error(res.kind)

      if (res.payload.success !== true)
        throw new Error(res.payload.errorMessage)
    }),
    updateWICNComment: flow(function* commentWICN(
      subFormId: string,
      formId?: number,
      comment?: string,
      userInfo?: UserInfo,
      attachmentList?: Attachment[],
    ) {
      const res: PostWIProcess =
        yield self.environment.api.wiWorkflow.updateWICNComment(
          JSON.stringify({
            subFormModel: {
              'UserModel.DisplayName': userInfo?.displayName,
              'UserModel.Title': userInfo?.title,
              isComment: 1,
              Comment: comment ?? '',
              uploadFile: '',
            },
            SubformId: subFormId,
            Id: formId,
            attachLists: attachmentList?.map((attachment) => ({
              AttachmentObjectID: attachment.attachmentId,
              AttachmentFormat: attachment.format,
              AttachmentName: attachment.name,
              AttachmentPath: attachment.path,
              AttachmentType: mapFormDataAttachmentTypeToNumber(
                attachment.type,
              ),
              UserUpn: attachment.userUpn,
              UpdateDate: moment(attachment.updateDate).format(
                formats.dateTimeLocalSeconds,
              ),
            })),
          }),
          self.wicnId,
        )
      if (res.kind !== 'ok') throw new Error(res.kind)

      if (res.payload.success !== true)
        throw new Error(res.payload.errorMessage)
    }),
  }))
  .views((self) => ({
    get priority(): TaskPriority {
      return getPriorityByLastUpdateDate(moment(self.updatedDate))
    },
    get status(): WICNStatus {
      return getWICNSnapShotStatus(self.currentStatus)
    },
    getLatestAction(step: WICNStatus): WorkflowHistory {
      const historyNodes = self.workflowHistory.map((history) =>
        getWICNSnapShotStatus(history.status),
      )
      const idx = historyNodes.lastIndexOf(step)

      return self.workflowHistory[idx]
    },
    get commentingUsers(): ADUser[] | [] {
      if (self.workflowData.partDWorkflow == null) {
        return []
      }
      return self.workflowData.partDWorkflow.childNodeActionByList.map(
        (user) => ({
          upn: user.upn,
          displayName: user.displayName,
          email: user.email,
          jobTitle: user.jobTitle,
        }),
      )
    },
    get commentingWorkflows(): WorkflowHistory[] | [] {
      return self.workflowHistory.filter(
        (history) => getWICNSnapShotStatus(history.status) === WICNStatus.partE,
      )
    },
    get wicnNo(): string {
      return self.wicnDetail.partAData.wicnNo
    },
    get wiList(): WICNWIItem[] {
      return (
        self.wicnDetail.partCData.revisedWIList ??
        self.wicnDetail.partAData.wiList ??
        []
      )
    },
    getCommentIdByUser(upn?: string): string {
      if (upn == null) {
        return ''
      }

      // Do not return commentId if it is not Part E
      if (getWICNSnapShotStatus(self.currentStatus) !== WICNStatus.partE) {
        return ''
      }

      const assignedUpnList = (
        self.workflowData.partEWorkflow.commentFormList ?? []
      ).find(
        (form) =>
          isUpnMatched(form.user?.upn, upn) &&
          self.wicnDetail.partEData.commentForms.some(
            (it) => it.subFormId === form.formId && !it.isApprove,
          ),
      )

      if (assignedUpnList != null) {
        return assignedUpnList.formId
      }

      const forwardedForm =
        self.workflowData.partEWorkflow.commentFormForwardList.find(
          (form) =>
            form.forwardList.find((it) => isUpnMatched(it.upn, upn)) != null &&
            self.wicnDetail.partEData.commentForms.some(
              (it) => it.subFormId === form.formId && !it.isApprove,
            ),
        )

      if (forwardedForm != null) {
        return forwardedForm.formId
      }

      return ''
    },
    getCommentIdByConsultee(upn?: string): string {
      if (upn == null) {
        return ''
      }

      // Do not return commentId if it is not Part E
      if (getWICNSnapShotStatus(self.currentStatus) !== WICNStatus.partE) {
        return ''
      }

      const consultedForm =
        self.workflowData.partEWorkflow.commentFormList.find(
          (form) =>
            form.consultList.find((it) => it.user?.upn && !it.isReturned) !=
            null,
        )

      if (consultedForm != null) {
        return consultedForm.formId
      }

      return ''
    },
    isCommentApproved(subFormId: string): boolean {
      return self.wicnDetail.partEData.commentForms.some(
        (it) => it.subFormId === subFormId && it.isApprove,
      )
    },
    isConsultingCommentForm(subFormId: string): boolean {
      const targetForm = self.workflowData.partEWorkflow.commentFormList.find(
        (it) => it.formId === subFormId,
      )

      if (targetForm != null) {
        return !targetForm.isAllConsultedCommentReturned()
      }

      return false
    },
    getCommentIdBySubFormId(subFormId: string | null): number | undefined {
      if (subFormId == null) {
        return undefined
      }

      const subForm = self.wicnDetail.partEData.commentForms.find(
        (form) => form.subFormId === subFormId,
      )

      if (subForm != null) {
        return subForm.id
      }

      return undefined
    },
    isCurrentWorkFlowConsulting(): boolean {
      const workFlowList = [
        self.workflowData.partAWorkflow,
        self.workflowData.partBWorkflow,
        self.workflowData.partCWorkflow,
        self.workflowData.partDWorkflow,
        self.workflowData.partEWorkflow,
        self.workflowData.partFWorkflow,
        self.workflowData.partGWorkflow,
      ]
      const currentWorkFlow = workFlowList.find(
        (it) => it.currentStatusId === self.currentStatus,
      )
      if (currentWorkFlow == null) return false

      const currentConsultingList = currentWorkFlow.consultUserList.find(
        (it) => !it.isReturned,
      )
      return currentConsultingList != null
    },
    isCurrentWorkFlowForwarded(): boolean {
      const workFlowList = [
        self.workflowData.partAWorkflow,
        self.workflowData.partBWorkflow,
        self.workflowData.partCWorkflow,
        self.workflowData.partDWorkflow,
        self.workflowData.partEWorkflow,
        self.workflowData.partFWorkflow,
        self.workflowData.partGWorkflow,
      ]
      const currentWorkFlow = workFlowList.find(
        (it) => it.currentStatusId === self.currentStatus,
      )
      if (currentWorkFlow == null) return false

      const currentForwardList = currentWorkFlow.forwardList
      return currentForwardList.length !== 0
    },
    isAllSubformApproved(): boolean {
      return (
        self.wicnDetail.partEData.commentForms.find(
          (form) => !form.isApprove,
        ) == null
      )
    },
  }))

export type WICN = Instance<typeof WICNModel>

export function mapPayloadToWICN(payload: WICNDataPayload): SnapshotIn<WICN> {
  return {
    wicnId: payload.objectId,
    isCancelled: payload.isCancel,

    wicnDetail: mapPayloadToWICNDetail(payload.wicnChangeNoticeFormData),
    currentStatus: stringToNumber(payload.currentStatus),
    currentUser: stringToString(payload.currentUserUPN),
    currentUserUpnList: mapPayloadToCurrentUserUpnList(
      payload.currentUserUPNList,
    ),
    currentUserGroup: stringToString(payload.currentUserGroup),

    formType: getFormTypeByCode(payload.formType),
    createdDate: stringToDate(payload.createDate),
    createdBy: stringToString(payload.createBy),
    updatedDate: stringToDate(payload.updateDate),
    updatedBy: stringToString(payload.updateBy),

    workflowData: {
      partAWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partAWorkflow,
      ),
      partBWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partBWorkflow,
      ),
      partCWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partCWorkflow,
      ),
      partDWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partDWorkflow,
      ),
      partEWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partEWorkflow,
      ),
      partFWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partFWorkflow,
      ),
      partGWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partGWorkflow,
      ),
    },
    workflowHistory: mapPayloadToWorkflowHistory(payload.workflowHistoryList),
  }
}
