import { ApiBase } from '../api-base'
import { AttunityStandardJobPayload } from '../api.types'
import { WorkflowHistoryPayload } from './work-order-forms.types'

export class ApiWorkOrderForms {
  private api: ApiBase

  constructor(api: ApiBase) {
    this.api = api
  }

  getFormRelatedWorkOrders = async (
    parentWorkOrderNumber: string,
    formNumbers: string[],
  ) =>
    this.api.generalApi<AttunityStandardJobPayload[]>(
      this.api.apisauce.get('/Common/Attunity/GetFormRelateWorkOrder', {
        workOrder: parentWorkOrderNumber,
        formNos: formNumbers,
      }),
    )

  getWorkOrderFormWorkflowHistory = async (formGroupDataId: string) =>
    this.api.generalApi<WorkflowHistoryPayload>(
      this.api.apisauce.get('/Workflow/Workflow/GetFormDataWorkflowHistory', {
        formDataObjectID: formGroupDataId,
      }),
    )
}
