export function shouldAttachmentOpenWithBrowser(
  inUrl: string,
): boolean | undefined {
  const url = new URL(inUrl)
  const parsedUrlHost = url?.host
  if (!parsedUrlHost) return undefined
  if (Array.isArray(parsedUrlHost) || typeof parsedUrlHost === 'number') {
    return undefined
  }
  return (
    parsedUrlHost.includes('mtr.com.hk') ||
    parsedUrlHost.includes('opinfomall') ||
    parsedUrlHost.includes('mtube')
  )
}

export function isAttachmentCachable(inUrl: string): boolean {
  if (inUrl == null) return false
  return !shouldAttachmentOpenWithBrowser(inUrl)
}
