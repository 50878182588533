import {
  FormStoreModel,
  MasterDataStoreModel,
  UserProfileStoreModel,
  WorksStoreModel,
  UserOperationStoreModel,
  OrderBookStoreModel,
} from '@mtr-SDO/datastores'
import { withEnvironment } from '@mtr-SDO/models-core'
import { delay } from '@mtr-SDO/utils'
import {
  applySnapshot,
  flow,
  Instance,
  SnapshotOut,
  types,
} from 'mobx-state-tree'
import { ConfigStoreModel } from './config-store'
import { UiStoreModel } from './ui-store'
import { AttachmentStoreModel } from './attachment-store'

export const RootStoreModel = types
  .model('RootStore')
  .props({
    configStore: ConfigStoreModel,
    userProfileStore: types.optional(UserProfileStoreModel, {}),
    masterDataStore: types.optional(MasterDataStoreModel, {}),
    worksStore: types.optional(WorksStoreModel, {}),
    formStore: types.optional(FormStoreModel, {}),
    attachmentStore: types.optional(AttachmentStoreModel, {}),
    uiStore: types.optional(UiStoreModel, {}),
    userOperationStore: types.optional(UserOperationStoreModel, {}),
    orderBookStore: types.optional(OrderBookStoreModel,{}),
  })
  .extend(withEnvironment)
  .actions((self) => {
    const actions = {
      reInitialize() {
        try {
          self.worksStore.reInitialize()
          applySnapshot(self.formStore, {})
        } catch (err) {
          self.environment.console.reportError(err)
          self.environment.console.log('MobX State tree cannot re-initialize')
          throw err
        }
      },
      logout: flow(function* logout(popToLanding?: boolean) {

        self.userProfileStore.logout()

        if (popToLanding) {
          self.uiStore.setLoggedIn(false)
          yield delay(750) // ? ensure transition is done
        }

        actions.reInitialize()
        ;(self.environment as any).queryClient?.clear()
      }),
    }
    return actions
  })
  .actions((self) => ({
    afterCreate() {
      self.environment.setupApi(self.configStore.apiConfig, async () => {
        if (self.uiStore.isLoggedIn) {
          console.log('will handle this(by log out user)')
          const message: string = 'Please log in first'

          // eslint-disable-next-line no-alert
          alert(message)

          self.logout(true)
        }
      })
    },
  }))

export type RootStore = Instance<typeof RootStoreModel>
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>
