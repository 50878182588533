import {
  GeneralApiResponse,
  GetADUser,
  GetAuthToken,
  GetUser,
  GetIsNeedHKID,
  GetWorkGroups,
  NotificationPayload,
  PatchUserInfo,
  UserPayload,
} from '@mtr-SDO/apis'
import { UserInfoModel, WorkGroupModel } from '@mtr-SDO/datamodels'
import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import { AuthToken } from '@mtr-SDO/oauth'
import { delay } from '@mtr-SDO/utils'
import { autorun, observable } from 'mobx'
import {
  addDisposer,
  applySnapshot,
  flow,
  getSnapshot,
  Instance,
  SnapshotIn,
  types,
} from 'mobx-state-tree'
import moment from 'moment'
import { Base64 } from 'js-base64'
import {
  AutosavePayloadWrapper,
  constructAutosavePatchPayload,
} from '../autosave'
import { dehydrateSession } from './hydration'
import { NotificationItem, processNotificationPayload } from './notifications'
import {
  UserSessionInfo,
  UserSessionInfoModel,
} from './user-session-info.model'

export const UserProfileStoreModel = types
  .model('UserProfileStore', {
    userInfo: types.maybe(UserInfoModel),
    sessionInfo: types.maybe(UserSessionInfoModel),
    permittedOperations: types.array(types.string),
    workGroups: types.array(WorkGroupModel),
    qualifications: types.array(types.string),
    qualificationCodeSyncTime: types.maybe(types.Date),
    combinedWorkGroups: types.array(WorkGroupModel),
    hasHKID: false,
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .views((self) => {
    const views = {
      get roles(): string[] {
        if (self.sessionInfo == null) return []
        return (
          self.sessionInfo.userRole?.split(',').map((role) => role.trim()) ?? []
        )
      },
      isMe(upn: string | undefined | null) {
        if (upn == null) return false
        return self.userInfo?.upn.toLowerCase() === upn.toLowerCase()
      },
      get allowedActions() {
        return {
          maintenance: self.permittedOperations.includes('maintenance'),
          review: self.permittedOperations.includes('review'),
          previewFormDefinition:
            self.permittedOperations.includes('previewform'),
          sicCheck: self.permittedOperations.includes('siccheck'),
          submitForm: self.permittedOperations.includes('submitform'),
        }
      },
      get accessToTab() {
        return {
          maintenance:
            views.allowedActions.maintenance || views.allowedActions.sicCheck,
          review:
            views.allowedActions.review || views.allowedActions.submitForm,
        }
      },
      get startupCacheData() {
        return getSnapshot(self)
      },
      get qualificationCodeSyncTimeMoment(): moment.Moment {
        return moment(self.qualificationCodeSyncTime)
      },
    }
    return views
  })
  .extend(() => {
    const lastSnapshotPosted = observable.box(
      undefined as moment.Moment | undefined,
    )
    const lastSnapshotStep = observable.box(0)
    const isPostingSnapshot = observable.box(false)

    return {
      views: {
        get lastSnapshotPosted() {
          return lastSnapshotPosted.get()
        },
        get lastSnapshotStep() {
          return lastSnapshotStep.get()
        },
        get isPostingSnapshot() {
          return isPostingSnapshot.get()
        },

        set lastSnapshotPosted(val: moment.Moment) {
          lastSnapshotPosted.set(val)
        },
        set lastSnapshotStep(val: number) {
          lastSnapshotStep.set(val)
        },
        set isPostingSnapshot(val: boolean) {
          isPostingSnapshot.set(val)
        },
      },
    }
  })
  .volatile(() => ({
    workGroupsIsRefreshing: false,
  }))
  .actions((self) => {
    const actions = {
      setHasHKID(value: boolean) {
        self.hasHKID = value
      },
      // ! should be deprecated
      applyHydratedSessionInfo(sessionInfo: SnapshotIn<UserSessionInfo>) {
        if (sessionInfo == null) return
        try {
          if (self.sessionInfo == null) {
            self.sessionInfo = UserSessionInfoModel.create(sessionInfo)
          } else {
            applySnapshot(self.sessionInfo, sessionInfo)
          }
        } catch (error) {
          self.environment.console.reportError(error)
          self.environment.console.warn(
            'Failed in applying session info',
            sessionInfo,
          )
          self.sessionInfo = undefined
        }
      },
      afterCreate() {
        addDisposer(
          self,
          autorun(() =>
            dehydrateSession(
              self.sessionInfo ? getSnapshot(self.sessionInfo) : null,
              self.environment,
            ),
          ),
        )
      },
      fetchUserProfile: flow(function* fetchUserInfo() {
        const res: GetUser = yield self.environment.api.getUser()
        if (res.kind !== 'ok') throw new Error(res.kind)
        return res.payload
      }),
      getIsNeedHKID: flow(function* getIsNeedHKID() {
        const res: GetIsNeedHKID = yield self.environment.api.getIsNeedHKID()
        if (res.kind !== 'ok') throw new Error(res.kind)
        return res.payload
      }),
      CompareQualification: flow(function* CompareQualification(HKID: string) {
        // const res: CompareQualification = yield self.environment.api.CompareQualification(Base64.toBase64(HKID))
        // if (res.kind !== 'ok') throw new Error(res.kind)
        // return res.payload
      }),
      applyUserInfo(userProfileJson: UserPayload) {
        self.userInfo = UserInfoModel.create({
          id: userProfileJson.userID,
          username: userProfileJson.userName ?? undefined,
          email: userProfileJson.email ?? undefined,
          upn: userProfileJson.upn,
          trainStockTypeIds:
            userProfileJson.trainStockTypeIDs?.map((id) => id.toString()) ?? [],
          title: userProfileJson.title ?? undefined,
          wiProcessGroup: userProfileJson.wiProcessGroup ?? undefined,
          department: userProfileJson.department ?? undefined,
          contactNumber: userProfileJson.contactNumber ?? undefined,
          HKIDFirst3: userProfileJson.hkidFirst3 ?? undefined,
          sntn: userProfileJson.sntn ?? undefined,
          roleName: userProfileJson.roleName ?? undefined,
          companyName: userProfileJson.companyName ?? undefined,
          userType: userProfileJson.userType ?? undefined,
        })
        self.hasHKID =
          userProfileJson.isNeedHKID === undefined
            ? false
            : !userProfileJson.isNeedHKID
        // self.qualifications = qualCode.map((it) => it.trim())
        // self.qualificationCodeSyncTime =
        //   qualCodeSyncDateTime == null
        //     ? undefined
        //     : moment(qualCodeSyncDateTime).toDate()
      },
      applyStartupCache(cache: any) {
        applySnapshot(self, cache)
      },
      login: flow(function* login(
        accessTokenResponse: AuthToken,
        env: 'web' | 'app',
      ) {
        let res: GetAuthToken
        if (env === 'app') {
          res = yield self.environment.api.getAuthTokenForAAD(
            Base64.toBase64(
              JSON.stringify({
                accessToken: Base64.toBase64(accessTokenResponse.accessToken),
                upn: '',
              }),
            ),
          )
        } else {
          res = yield self.environment.api.getAuthTokenForAAD(
            accessTokenResponse.accessToken,
          )
        }
        if (res.kind !== 'ok') throw Error(res.kind)

        const { payload } = res
        if (payload == null) throw Error('empty-payload')
        if (typeof payload !== 'string') throw Error(payload.message)

        try {
          const [tokenType, accessToken] = payload.split(' ')

          self.sessionInfo = UserSessionInfoModel.create({
            azureAccount: accessTokenResponse.azureAccount,
            idToken: accessTokenResponse.idToken,
            accessToken,
            tokenType,
          })

          self.environment.console.display({
            name: 'Login',
            preview: 'Access token retrieved',
            value: [self.sessionInfo],
          })
        } catch (error) {
          throw Error('bad-format')
        }
      }),
      logout() {
        self.userInfo = undefined
        self.sessionInfo = undefined
        self.permittedOperations.clear()
        self.workGroups.clear()
      },
      // eslint-disable-next-line require-yield
      postRootStoreSnapshot: flow(function* postSnapshot(): Generator<
        any,
        void,
        any
      > {
        if (self.isPostingSnapshot) return
        self.lastSnapshotPosted = moment()
      }),
      refreshUserQualifications: flow(function* refreshUserQualifications() {
        yield delay(400)
        // if(self.hasHKID){
        //   const res: CompareQualification = yield self.environment.api.CompareQualification('')
        //   if (res.kind !== 'ok') throw new Error(res.kind)
        //   if(res.payload.isValidate){
        //     self.hasHKID = true
        //   }else{
        //     self.hasHKID = false
        //   }
        // }
        // self.workGroupsIsRefreshing = true

        // try {
        //   yield delay(400) // ? make it looks like loaded

        //   const res: GetWorkGroups = yield self.environment.api.getWorkGroups()
        //   if (res.kind !== 'ok') throw Error(res.kind)

        //   const { payload } = res
        //   if (payload == null) throw Error('empty-payload')

        //   self.workGroups.replace(
        //     payload.map((it) => ({
        //       id: it.WorkGroupID,
        //       code: it.WorkGroupCode,
        //     })),
        //   )

        //   return self.workGroups
        // } finally {
        //   self.workGroupsIsRefreshing = false
        // }
      }),
      refreshWorkGroups: flow(function* refreshWorkGroups() {
        // self.workGroupsIsRefreshing = true
        // try {
        //   yield delay(400) // ? make it looks like loaded
        //   const res: GetWorkGroups = yield self.environment.api.getWorkGroups()
        //   if (res.kind !== 'ok') throw Error(res.kind)
        //   const { payload } = res
        //   if (payload == null) throw Error('empty-payload')
        //   self.workGroups.replace(
        //     payload.map((it) => ({
        //       id: it.WorkGroupID,
        //       code: it.WorkGroupCode,
        //     })),
        //   )
        //   return self.workGroups
        // } finally {
        //   self.workGroupsIsRefreshing = false
        // }
      }),

      fetchCombinedWorkGroups: flow(function* fetchCombinedWorkGroups() {
        // const res: GetCombinedWorkGroups =
        //   yield self.environment.api.getCombinedWorkGroups()
        // if (res.kind !== 'ok') throw Error(res.kind)
        // const { payload } = res
        // if (payload == null) throw Error('empty-payload')
        // self.combinedWorkGroups.replace(
        //   payload.map((it) => ({
        //     id: it.WorkGroupID,
        //     code: it.WorkGroupCode,
        //   })),
        // )
        // return self.combinedWorkGroups
      }),

      fetchNotifications: flow(function* fetchNotifications(
        pageIndex?: number,
        pageSize?: number,
      ) {
        if (self.userInfo == null) throw new Error('not ready')
        const msg: GeneralApiResponse<{
          total: number
          rows: NotificationPayload[]
        }> = yield self.environment.api.userProfile.getNotifications(
          self.userInfo.upn,
          {
            pageSize,
            pageIndex: pageIndex == null ? undefined : pageIndex + 1,
          },
        )

        if (msg.kind !== 'ok') throw new Error(msg.kind)
        if (msg.message?.result === 0) throw new Error(msg.message?.message)

        return processNotificationPayload(
          msg.payload?.rows ?? [],
          self.environment,
        )
      }),

      postNotificationRead: flow(function* postNotificationRead(
        notification: NotificationItem,
      ) {
        if (self.userInfo == null) throw new Error('not ready')

        const msg: GeneralApiResponse<void> =
          yield self.environment.api.userProfile.postNotificationRead(
            self.userInfo.upn,
            notification.objectID,
          )

        if (msg.kind !== 'ok') throw new Error(msg.kind)
        if (msg.message?.result === 0) throw new Error(msg.message?.message)
      }),

      fetchUnreadNotificationCount: flow(
        function* fetchUnreadNotificationCount() {
          if (self.userInfo == null) throw new Error('not ready')
          const msg: GeneralApiResponse<number> =
            yield self.environment.api.userProfile.getUnreadNotificationCount(
              self.userInfo.upn,
            )

          if (msg.kind !== 'ok') throw new Error(msg.kind)
          if (msg.message?.result === 0) throw new Error(msg.message?.message)

          return msg.payload ?? 0
        },
      ),

      getADUser: flow(function* getADUser(
        search: string,
        groupName: string = '',
      ) {
        const res: GetADUser = yield self.environment.api.wiWorkflow.getADUsers(
          search,
          groupName,
        )

        if (res.kind !== 'ok') throw new Error(res.kind)
        if ((res.message?.result ?? 0) === 0)
          throw Error(res.message?.message ?? 'empty-message')
        if (res.payload == null) throw new Error('no-data')

        return res.payload
      }),
    }
    return actions
  })

export type UserProfileStore = Instance<typeof UserProfileStoreModel>
