import { Instance, types } from 'mobx-state-tree'
import moment from 'moment'

export const FormDataTagModel = types.model({
  tag: types.string,
  objectid: types.maybe(types.string),
  createddate: types.optional(types.Date, () => moment().toDate()),
  createdby: types.maybe(types.string),
})

export type FormDataTag = Instance<typeof FormDataTagModel>
