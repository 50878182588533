import styled from '@emotion/styled'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { CSSProperties, useMemo } from 'react'
import MTRlogo from '../../image/mtr-logo-long-black.png'
import { env } from '../../utils'

type Props = {
  format? : 'long' | 'longer',
  titleStyle? : CSSProperties
}

const StyledContainer = styled.div`
  width: 70%;
  min-width: 300px;
  margin: 0 auto;
`
const MTRLogo = styled.img`
  margin-top: 0%;
  width: 25%;
`
const StyledSystemTitle = styled.h1`
  font-size: 5vw;
  font-weight: bold;
  margin-top: 5%;
  white-space: nowrap;
`

export const SystemHeader = observer<Props>(({
  format,
  titleStyle
}) => {
  const { t } = useTranslation()
  const title = useMemo(()=>
    t(`${env.target.toLowerCase() as 'sdo'}.${format ?? 'long'}`),
  [format, t])
  return (
    <StyledContainer>
      <MTRLogo src={MTRlogo} alt="MTR logo" />
      <StyledSystemTitle style={titleStyle}>
        {title}
      </StyledSystemTitle>
    </StyledContainer>
  )
})
