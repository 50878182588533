import { useEffect, useState } from 'react'

function getWindowInnerHeight() {
  return window.innerHeight
}

export const useWindowHeight = (): number => {
  const [height, setHeight] = useState(getWindowInnerHeight())

  useEffect(() => {
    function handleResize() {
      setHeight(getWindowInnerHeight())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    function handleResize() {
      setHeight(getWindowInnerHeight())
    }

    window.addEventListener('orientationchange', handleResize)
    return () => window.removeEventListener('orientationchange', handleResize)
  }, [])

  return height
}
