import { en as webSystem } from "../../web-system"

export const common = {
  ...webSystem,
  ok: 'OK',
  cancel: 'Cancel',
  login: 'Login',
  clear: 'Clear',
  loading: 'Loading...',
  logout: 'Logout',
  back: 'Back',
  close: 'Close',
  confirm: 'Confirm',
  required: 'Required',
  optional: 'Optional',
  remove: 'Remove',
  withdraw: 'Withdraw',
  discard: 'Discard',
  unfrozen: 'Make Editable',
  success: 'Success',
  unselect: 'Unselect',
  detail: 'Detail',
  upload: 'Upload',
  date: 'Date',
  decision: 'Decision',
  accept: 'Accept',
  accepted: 'Accepted',
  approve: 'Approve',
  endorse: 'Endorse',
  approved: 'Approved',
  reject: 'Reject',
  rejected: 'Rejected',
  return: 'Return',
  inProgress: 'In Progress',
  search: 'Search',
  comment: 'Comment',
  sortingDialog: {
    title: 'Sort by',
    createdDate: 'Creation date',
    stage: 'Status',
    option: {
      asc: 'Ascending',
      desc: 'Descending',
    },
  },
  forbiddenByRole: 'You are not allowed to perform this operation',
  'n/a': 'N/A',
  restricted: 'RESTRICTED',
  readonly: '[READ-ONLY]',
  submit: 'Submit',
  apply: 'Apply',
  reloginAlert: {
    messageNoName:
      'You have previously logged in. Do you want to logout first?',
    message:
      'You have already logged in as {{username}}. Do you want to logout first?',
    logout: 'Logout',
    stay: 'Stay logged in',
  },
  mandatory: 'Mandatory',
  notSpecified: 'Not specified',
  unavailableInOffline: 'Unavailable in offline mode',
  proceed: 'Proceed',
  abnormalState: 'Abnormal state detected. Please restart app',
  save: 'Save',
  saveAsDraft: 'Save as draft',
  saveAsDraftSuccess: 'Draft has been saved successfully!',
  pleaseSelect: '(Please select)',
  continue: 'Continue',
  errorMessage:
    'We have encountered an error. Please try again later. If this problem persist, please contact support. ({{error}})',

  edit: 'Edit',
  delete: 'Delete',
  deleted: 'Deleted',
  restore: 'Restore',
  yes: 'Yes',
  no: 'No',
  notApplicable: 'Not applicable',
  more: 'More',
  andMore: '... and {{count}} more',
  rename: 'Rename',
  newIssue: 'New Issue',
  review: 'Review',
  noChange: 'No Change',
  update: 'Update',
  NA: 'N/A',
  title: 'Title',

  pending: 'Pending',
  history: 'History',

  viewAll: 'See all',
  viewMore: 'View More...',

  toBeApprovedBy: 'To Be Approved By',
  searchUser: 'Search user here...',
  selectedUser: 'Selected user',
  selfAssignWarning: 'You should not assign yourself!',
  emptyUser: 'We could not find any user with given keywords.',
  forwardDialog: { title: 'Forward this task to' },
  revertDialog: {
    title: 'Return this consultation',
    placeholder: 'Enter your comment on the task',
  },
  consultDialog: { title: 'Select person to consult' },
  cancelDialog: {
    title: 'Cancel this task',
    content: 'Are you sure to cancel the workflow?',
  },

  userSelectionPlaceholder: 'Action by ...',
  pendingComment: ' (Pending comment from {{userName}})',

  metadata: {
    mtr: 'MTR',
    configVersion: 'Config Version: {{version}}',
    version: 'Version {{version}}',
  },

  SDO: {
    short: 'SDO',
    long: 'Smart Depot Operation',
    full: 'Smart Depot Operation System',
  },

  homePage: {
    title: 'Home',
    wicn: { empty: 'No WICN record found' },
    wiReview: {
      empty: 'No WI E-FLOW record found',
    },
  },

  wiTaskList: {
    statusFilter: {
      open: 'Hide',
      close: 'Status',
    },
    statusMenu: {
      stage: 'Status',
    },
  },

  successPage: {
    submit: {
      title: 'Submitted successfully',
      content: 'You have submitted comment on Task {{wiNumber}}',
    },
    approve: {
      title: 'Approved successfully',
      content: 'You have approved Part {{part}} of Task {{wiNumber}}',
    },
    comment: {
      title: 'Comment submitted successfully',
      content: 'You have submitted comment over Task {{wiNumber}}',
    },
    reject: {
      title: 'Rejected successfully',
      content: 'You have rejected comment on Task {{wiNumber}}',
    },
    invite: {
      title: 'Invited successfully',
      content:
        'Invitation to comment on Task {{wiNumber}} has been sent to selected parties',
    },
    finalize: {
      title: 'Finalized successfully',
      content: 'Task {{wiNumber}} has been finalized and backup',
    },
    save: {
      title: 'Saved successfully',
      content: 'You have saved Part {{part}} of Task {{wiNumber}}',
    },
    forward: {
      title: 'Forwarded successfully',
      content: 'You have forwarded Part {{part}} of Task {{wiNumber}}',
    },
    consult: {
      title: 'Consulted successfully',
      content:
        'You have consulted others on Part {{part}} of Task {{wiNumber}}',
    },
    reroute: {
      title: 'Rerouted successfully',
      content: 'You have rerouted Part {{part}} of Task {{wiNumber}}',
    },
    cancel: {
      title: 'Canceled successfully',
      content: 'You have canceled Part {{part}} of Task {{wiNumber}}',
    },
    return: {
      title: 'Comment returned successfully',
      content: 'You have returned your comment over Task {{wiNumber}}',
    },
    back: 'Back to WI task',
    backToHome: 'Back to home',
  },

  failMessage: {
    reject: {
      title: 'Reject failed',
      content:
        'We could not reject the task as we encountered an error ({{error}}).',
    },
    approve: {
      title: 'Approve failed',
      content:
        'We could not approve the task as we encountered an error ({{error}}).',
    },
    save: {
      title: 'Save failed',
      content:
        'We could not save the task as we encountered an error ({{error}}).',
    },
  },

  commentField: {
    placeholder: 'Leave a comment',
  },

  notificationPage: {
    title: 'Notifications',
    empty: 'No notifications',
    selectNotification: 'Please select a notification on the left',

    header: {
      subject: 'Subject',
      time: 'Time',
    },
  },

  taskType: {
    wicn: 'WICN',
    wiReview: 'WI E-flow',
  },

  taskStatus: {
    wicn: {
      'part-a': {
        short: 'A',
        long: 'Request',
        fullName: 'Part A - Request Details',
      },
      'part-b': {
        short: 'B',
        long: 'R Approve',
        fullName: 'Part B - Approve by Requester Manager',
      },
      'part-c': {
        short: 'C',
        long: 'Check',
        fullName: 'Part C - Initial Checking (WI Controller)',
      },
      'part-d': {
        short: 'D',
        long: 'Review',
        fullName: 'Part D - SMSE Review',
      },
      'part-e': {
        short: 'E',
        long: 'Comment',
        fullName: 'Part E - Comment',
      },
      'part-f': {
        short: 'F',
        long: 'F Approve',
        fullName: 'Part F - SMSE Approval',
      },
      'part-g': {
        short: 'G',
        long: 'Upload',
        fullName: 'Part G - Upload Document',
      },
      comment: {
        short: 'E',
        long: 'Comment',
        fullName: 'Part E - Provide Comment',
      },
      completed: {
        short: '',
        long: 'Completed',
        fullName: 'Completed',
      },
    },

    wiReview: {
      'part-a': {
        short: 'A',
        long: 'Create',
        fullName: 'Part A - Initiate Drafting Process',
      },
      'part-b': {
        short: 'B',
        long: 'Initiate',
        fullName: 'Part B - Initiate Checking Process',
      },
      'part-c': {
        short: 'C',
        long: 'C Check',
        fullName: 'Part C - Check Draft WI Data Items',
      },
      'part-d': {
        short: 'D',
        long: 'Process',
        fullName: 'Part D - Initiate Comment Process',
      },
      'part-e': {
        short: 'E',
        long: 'Comment',
        fullName: 'Part E - Comment and Finalize WI',
      },
      'part-f': {
        short: 'F',
        long: 'F Check',
        fullName: 'Part F - Final Check',
      },
      'part-g': {
        short: 'G',
        long: '1st Endorse',
        fullName: 'Part G - 1st Endorse',
      },
      'part-h': {
        short: 'H',
        long: '2nd Endorse',
        fullName: 'Part H - 1st Endorse',
      },
      'part-i': {
        short: 'I',
        long: 'Approve',
        fullName: 'Part I - 2nd Endorse',
      },
      'part-j': {
        short: 'J',
        long: 'Finalize',
        fullName: 'Part J - Approve Result',
      },
      'part-k': {
        short: 'K',
        long: 'Upload',
        fullName: 'Part K - Upload Document',
      },
      comment: {
        short: 'E',
        long: 'Comment',
        fullName: 'Part E - Provide Comment',
      },
      completed: {
        short: '',
        long: 'Completed',
        fullName: 'Completed',
      },
    },
  },

  warningMessage: {
    decision: 'Please select one of the decision',
    outlinedTextField: 'Please fill in your comment',
    userSelection: 'Please select one of the parties',
    attachmentSelection: 'Please select at least one attachment',
    attachmentExceedFileLimitWarning:
      'File size exceeded the maximum size, please select again',
    outlinedDate: 'Please select the date',
    baseDateField: 'Please select the date',
    baseTextField: 'Please do not empty this field',
  },

  wicn: {
    empty: 'No WICN record found. (Please update status filter if necessary)',
    requestType: {
      revise: 'Revise WI',
      new: 'New WI',
      'n/a': 'N/A',
    },
    basicInfo: {
      title: 'Task Details',
      taskInfo: 'Task Info',
      wiNo: 'WI No.',
      wicnNo: 'WICN No.',
      drafter: 'Drafter',
      requestType: 'Request Type',
      managerApproval: 'Manager Approval',
      smseApproval: 'SMSE Approval',
      uploadDocument: 'Upload Document',
      uploadDate: 'This WICN is uploaded on',
    },

    requestDetail: {
      title: 'Request Details',
      detailOfChanges: 'Details of changes made/required',
      refNo: 'Ref No.',
      significantImplementation: 'Significant Implementation',
      reason: 'Brief of reason',
    },

    partA: {
      part: 'Part A',
      title: 'Request Details',
      requestType: 'Request Type',
      changedRequired:
        'Some changes are required in the existing work instruction as detailed below',
      newWI: 'There is a need for a new work instruction',
      requestTypePlaceholder: 'Leave a message',
      detailOfChangeTitle: 'Details of changes made/required',
      detailOfChangePlaceholder: 'Placeholder text',
      requiredReportDecisionTitle: 'JHA Report',
      requiredReport: 'Required and attached',
      notRequiredReport: 'Not required',
      newReport: 'New Report',
      amendedReport: 'Amended Report',
      briefOfReasonHeader: 'Brief of reason',
      briefOfReasonTitle:
        '(provide reference documents e.g. memo, report,etc if applicable)',
      briefOfReasonPlaceholder: 'Placeholder text',
      significantImplicationTitle: 'Any significant implication below area',
      implicationType: {
        trainingAndQualification: 'Training & Qualification',
        maintenanceRegime: 'Maintenance Regime',
        other: 'Others',
        implicationPlaceholder: 'Written Details',
      },
      implicationApproval: {
        header: 'Provide supporting document as the evidence for the changes',
        helperText: 'The change has been approved by:',
      },
      approvedUserTitle:
        'Provide supporting document as the evidence of changes',
      approvedUserHeader: 'The change has been approved by',
      approvedUserPlaceholder: 'select user...',
      approvedUserDialogTitle: 'Select approver',
      userSelectionHeader: 'Approver',
      userSelectionPlaceholder: 'Select user...',
      userSelectionDialogTitle: 'Select approver',
      attachmentHeader: 'File Attachment',
      attachmentTitle:
        'Details of work instruction/ JHA Report/ Approval Reference (Max file size: 20MB)',
      attachmentPlaceholder: 'Select file...',
    },

    partB: {
      part: 'Part B',
      title: 'Approve by Requester Manager',
      TaskInfo: 'Task Info',
      label: 'To be approved by Requester Manager',
      rejectReasonTitle: 'Comment',
      attachmentTitle: 'Please select attachment not exceeding 5MB',
      attachmentPlaceholder: 'Select file...',
    },

    partC: {
      part: 'Part C',
      title: 'Initial Checking (WI Controller)',
      TaskInfo: 'Task Info',
      label: 'To be approved by WI Controller',
      rejectReasonTitle: 'Comment',
      newWITitle: 'New WI is scheduled for completion by',
      attachmentTitle:
        'The Revised WI is scheduled for completion on the following schedule:',
      qualityCheckHeader: 'Quality Check',
      qualityCheckDescription:
        'This WICN is raised within 6 months from last revision :',
      imputeToNegligence:
        'Impute to negligence in compilation by AD&MS-DOC Team',
      dueToChange: 'Due to change / Improvement arise',
    },

    partD: {
      part: 'Part D',
      title: 'SMSE Review',
      label: 'To be approved by SMSE',
      decisionLabel: 'Require Comment',
      commentTitle: 'Comment',
      userSelectionHeader: 'Please select the commenting parties',
      userSelectionPlaceholder: 'Action by...',
      userSelectionDialogTitle: 'Select commenting parties',
    },

    partE: {
      part: 'Part E',
      title: 'Checklist of Comment',
      providerTab: 'Provide Comment',
      checkerTab: "Drafter's Feedback",
      label: 'Commenting Person',
      commentPlaceholder: 'Leave a message',
      attachmentTitle:
        'Attach supporting documents when necessary (Max file size: 20MB)',
      attachmentPlaceholder: 'Select attachment...',
      expandableTitle: 'Provide comment',
      noCommentDecision: 'I have no comment',
      commentDecision: 'I have the following comments:',
      dialogTitle: `Checkers's Comment`,
    },

    partF: {
      part: 'Part F',
      title: 'SMSE Approval',
      label: 'To be approved by SMSE',
      decisionLabel: 'This WICN is',
      commentTitle: 'Comment',
      issueTemporaryInstruction:
        'This WICN is admitted as a temporary instruction and will be superseded when the formal revision/new WI is issued',
      notIssueTemporaryInstruction: 'Temporary instruction will not be issued',
    },

    partG: {
      part: 'Part G',
      title: 'Upload',
      decisionLabel: 'Uploaded',
      label: 'To be completed by WI Controller',
      remarks: 'Remarks',
      remarksPlaceholder: 'Input remarks here',
    },

    history: {
      title: 'History Log',
    },

    attachment: {
      version: 'Version No.',
      completionDate: 'Scheduled completion',
    },
  },

  wiReview: {
    empty:
      'No WI E-FLOW record found (Please update status filter if necessary)',
    wicnEmpty: 'This WI E-flow does not have any related WICN.',
    basicInfo: {
      title: 'Task Details',
      taskInfo: 'Task Info',
      referenceNo: 'Ref No.',
      wiNo: 'WI No.',
      taskTitle: 'Title',
      createdDate: 'Created Date',
      updatedDate: 'Updated Date',
      statusBox: {
        title: 'Part Action by',
        partB: 'B- Initiate Checking',
        partC: 'C- Check Draft WI Data',
        partD: 'D- Initiate Comment Process',
        partE: 'E- Finalize WI',
        partF: 'F- Final Check',
        partG: 'G- 1st Endorse',
        partH: 'H- 2nd Endorse',
        partI: 'I- Approve',
        partJ: 'J- Finalize & backup',
        partK: 'K- WI Controller upload',
      },
      wicnListTitle: 'Related WICN',
    },

    partA: {
      part: 'Part A',
      title: 'Initiate Drafting Process',
      instructionTitle: 'MWI/WCN',
      issueRevTitle: 'Issue / Rev',
      recordDateTitle: 'Record Date',
      targetDateTitle: 'Target Completion',
      taskTypeTitle: 'Task Type',
      attachmentSelectionHeader:
        'Please attach work instruction or reference document',
      attachmentSelectionTitle:
        'Maximum upload 4 files, not exceeding 5MB each',
      attachmentSelectionPlaceholder: 'Select file...',
      commentTitle: 'Comments',
    },

    partB: {
      part: 'Part B',
      title: 'Initiate Checking Process',
      decisionLabel:
        'The WI has been reviewed and the result are summarized below:',
      initiateNewWi: 'New WI',
      initiateWiReview: 'Review/Amendment',
      initiateConclusion: {
        conclusion: 'Conclusion',
        noChange: 'No Change to the current WI is required.',
        completeRevision:
          'A complete revision (draft attached) is recommended to incorporate the following major change.',
        amendment:
          'The following amendment(s) to the current WI is/are recommended:',
      },
      userSelectionHeader: 'Please select checker to review the draft WI',
      tableHeader: {
        item: 'Item',
        description: 'Description',
        reviewed: 'Reviewed',
        notApplicable: 'N/A',
        remarks: 'Remarks',
      },
      commentRequired: 'Comment Required',
      remarks: 'Remarks',
      attachmentSelectionHeader:
        'Please attach work instruction or reference document',
      attachmentSelectionTitle:
        'Maximum upload 4 files, not exceeding 5MB each',
      attachmentSelectionPlaceholder: 'Select file...',
    },
    partC: {
      part: 'Part C',
      title: 'Check Draft WI Data Items',
      label: 'To be completed by Checker',
      decisionLabel: 'Ready for comment?',
      commentTitle: "Checker's comment",
    },

    partD: {
      part: 'Part D',
      title: 'Initiate Comment Process',
      label: 'To be completed by WI Controller',
      decisionLabel: 'Decision',
      commentPlaceholder: 'Leave a message',
      skipCommentProcess: 'Skip and proceed to finalize WI',
      initiateCommentProcess: 'Initiate comment process',
      userSelectionHeader: 'Please select the commenting parties',
      userSelectionPlaceholder: 'Select user...',
      userSelectionDialogTitle: 'Select commenting parties',
    },

    partE: {
      part: 'Part E',
      management: {
        title: 'Finalize WI',
        label: 'To be completed by (position)',
        commenterSelectionHeader: 'Please select the commenting parties',
        commenterSelectionPlaceholder: 'Action by...',
        commenterSelectionDialogTitle: 'Select commenting parties',
      },
      implementor: {
        title: 'Finalize WI',
        identityTitle: 'Identity',
        implementorIdentity: 'Implementor',
        nonImplementorIdentity: 'Non-implementor',

        decisionLabel: 'Decision',
        decisionPlaceholder: 'Leave a message',
        noCommentDecision: 'No objection for the WI',
        commentDecision: 'My comment(s) is/are',
        detailedCommentDecision: 'Detailed comment is attached',
        attachmentSelectionHeader: 'Finalize WI (To be completed by drafter)',
        attachmentSelectionTitle: 'Please select attachment not exceeding 5MB',
        attachmentSelectionPlaceholder: 'Select file...',
        noObjectionDecision:
          'No objection for implementation within 14 days from issue date.',
        readyDecision: 'Ready to implement by date:',
        hazardDecisionTitle: 'Implementation of JHA',
        hazardDecisionSubTitle:
          '(Click here to download a template of HIM and JHA Worksheet)',
        noHazardDecision:
          'Assessment has been completed and no hazard identified in HIM. JHA is not required and the HIM has been attached.',
        someHazardDecision:
          'Assessment has been completed and some hazards identified in HIM. The JHS has been performed and attached.',
        validHazardDecision:
          'The current HIM/JHA is still valid. (Review on the current JHA and on-site validation completed. No new/addition risk was identified.)',
        JHAAttachmentSelectionHeader:
          'JHA must to be issued or amended \n(with each file size not exceeding 5MB)',
      },
      drafter: {
        title: 'Finalize WI (Checklist of comment)',
        commentTitle: 'Comments',
        commentPlaceholder: 'Response (To be filled by drafter) ',
        attachmentSelectionTitle:
          'Please attach work instruction or reference document',
        attachmentSelectionPlaceholder: 'Select file...',
      },
      partATitle: 'A. Comment on the WI',
      partBTitle: 'B. Implementation effective date',
      partCTitle: 'C. Job Hazard Analysis',
      providerTab: 'Provide Comment',
      checkerTab: "Drafter's Feedback",
    },

    partF: {
      part: 'Part F',
      title: 'Final Check',
      reviewTitle: 'Part F - Final Check Result',
      label: 'To be completed by SMSE',
      reviewLabel: 'Completed by SMSE',
      decisionLabel: 'Decision',
      approve: 'Ready to Approval',
      reject: 'Reject',
      commentTitle: "Checker's comment",
      endorserSelectionHeader: 'Please select endorser(s)',
      endorserSelectionPlaceholder: 'Select user...',
      endorserSelectionDialogTitle: 'Select endorser(s)',
      approverSelectionTitle: 'Approver',
      approverSelectionHeader: 'Please select approver',
      approverSelectionPlaceholder: 'Select user...',
      approverSelectionDialogTitle: 'Select approver',
    },

    'part-g': {
      part: 'Part G',
      title: '1st Endorse',
      reviewTitle: 'Part G - 1st Endorse Result',
      label: 'To be completed by 1st Endorser',
      reviewLabel: 'Completed by 1st Endorser',
      decisionLabel: 'Endorsement Result',
      commentTitle: "Endorser's comment",
    },

    'part-h': {
      part: 'Part H',
      title: '2nd Endorse',
      reviewTitle: 'Part H - 2st Endorse Result',
      label: 'To be completed by 2nd Endorser',
      reviewLabel: 'Completed by 2st Endorser',
      decisionLabel: 'Endorsement Result',
      commentTitle: "Endorser's comment",
    },

    'part-i': {
      part: 'Part I',
      title: 'Approve Result',
      label: 'To be completed by Approver',
      reviewLabel: 'Completed by Approver',
      decisionLabel: 'Decision',
      commentTitle: "Checker's comment",
    },

    partJ: {
      part: 'Part J',
      title: 'Finalize and backup',
      label: 'To be completed by Drafter',
      wiDecisionLabel: 'WI Result',
      recordDecisionLabel: 'Record form Result',
      commentTitle: 'Remarks',
      remarksPlaceholder: 'Input remarks here',
      attachmentTitle: 'Please attach the latest version of WIs for backup.',
      attachmentPlaceholder: 'Select WI...',
    },

    partK: {
      part: 'Part K',
      title: 'Upload',
      label: 'To be completed by WI Controller',
      uploadDecision: {
        uploaded: 'Yes',
        noChange: 'No Change',
        deleted: 'Deleted',
      },
      decisionLabel: 'Uploaded',
    },
  },
}
