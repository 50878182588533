import {
  ApproverCode,
  GeneralApiResponse,
  GetApproverList,
  GetFormDataRemoteId,
  GetFormDataReportList,
  GetHasUploadedForm,
  PostApproveFormData,
  PostChangeApproverData,
  PostRejectFormData,
  PostSubmitFormData,
  PostVoidFormData,
  WorkflowHistoryPayload,
} from '@mtr-SDO/apis'
import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import { notEmpty } from '@mtr-SDO/utils'
import _ from 'lodash'
import {
  flow,
  getParent,
  IAnyModelType,
  Instance,
  SnapshotIn,
  types,
} from 'mobx-state-tree'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import {
  ApprovalWorkflow,
  Form,
  FormData,
  FormDataStatus,
  FormDefinition,
  FormDefinitionItem,
  FormModel,
  ValidationOptions,
} from '../forms'
import { UserInfo, UserInfoModel } from '../user-info.model'
import { MockWorkOrder } from './mock-types'
import {
  FormDataUploadedItem,
  WorkOrderFormApprovalAction,
  WorkOrderFormApprovalHistory,
  WorkOrderFormInstrumentRecord,
} from './work-order-form-history'
import {
  WorkOrderFormMaintenanceStatus,
  WorkOrderFormStatus,
} from './work-order-status'

const WorkOrderFormStatusEnum = types.enumeration(
  Object.values(WorkOrderFormStatus),
)

// remark is verifier and endorser submitFormData
type WorkflowHistoryAction = 'verify' | 'reject' | 'endorse' | 'void' | 'upload' | 'remark'
export type WorkOrderFormWorkflowHistoryItem = {
  id: string
  action: WorkflowHistoryAction
  actionDate: moment.Moment
  actionBy: string
  actionByDisplayName: string | null
  formData?: FormData
  remark?: string | '' | null
}

export const WorkOrderFormModel = types
  .model('WorkOrderForm', {
    id: types.optional(types.identifier, uuid),

    form: types.reference(types.late((): IAnyModelType => FormModel)),
    version: types.number,

    optional: types.boolean,
    isUnique: types.optional(types.boolean, true),
    allowMultiple: types.optional(types.boolean, false),
    rejected: types.optional(types.boolean, false),

    status: types.optional(
      WorkOrderFormStatusEnum,
      WorkOrderFormStatus.pending,
    ),
    remoteFormDataId: types.maybe(types.string), // form-data-object-id
    referenceNumber: types.maybe(types.string),

    author: types.maybe(types.string), // first uploader

    lastUpdateDate: types.maybe(types.Date),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .volatile(() => ({
    isRefreshing: false,
    lastUpdate: moment().toDate(),
  }))
  .views((self) => {
    const views = {
      get formDefinition(): FormDefinition {
        return self.form.getDefinition(self.version)
      },
      get formDefinitionAvailable(): boolean {
        return !!views.formDefinition
      },
      get workOrder(): MockWorkOrder {
        return getParent(self, 2)
      },
      get isOptional() {
        return self.optional
      },
      get firstUploaderIsMe(): boolean {
        return self.rootStore.userProfileStore.isMe(self.author)
      },
      get formDatas(): FormData[] {
        return self.rootStore.formStore.formDatas
          .filter((formData: FormData) => formData.workOrderForm === self)
          .slice()
          .sort(
            (a, b) => b.lastUpdateTime.valueOf() - a.lastUpdateTime.valueOf(),
          )
      },
      get mergedFormData(): FormData | undefined {
        return views.formDatas.find((formData) => formData.isMerged)
      },
      get uploadedFormDatas(): FormData[] {
        return views.formDatas.filter(
          (formData) =>
            formData.status === FormDataStatus.uploaded && !formData.isMerged,
        )
      },
      get myFormDatas(): FormData[] {
        return views.formDatas.filter((formData) => formData.isMine)
      },
      get myFrozenFormDatas(): FormData[] {
        return views.myFormDatas.filter(
          (formData) => formData.status === FormDataStatus.frozen,
        )
      },

      get myUploadedFormDatas(): FormData[] {
        // if (self.remoteFormDataId == null) return [] // if this form never been uploaded
        return views.myFormDatas.filter(
          (formData) => formData.status === FormDataStatus.uploaded,
        )
      },
      get myFillingFormDatas(): FormData[] {
        return views.myFormDatas.filter(
          (formData) => formData.status === FormDataStatus.filling,
        )
      },
      get myEmptyFormDatas(): FormData[] {
        return views.myFormDatas.filter(
          (formData) => formData.status === FormDataStatus.empty,
        )
      },
      get categorizedFormDatas() {
        return {
          all: views.formDatas,
          merged: views.mergedFormData,
          uploaded: views.uploadedFormDatas,
          mine: views.myFormDatas,
          myUploaded: views.myUploadedFormDatas,
          myFilling: views.myFillingFormDatas,
          myEmpty: views.myEmptyFormDatas,
          myFrozen: views.myFrozenFormDatas,
        }
      },

      get fillingLastUpdate() {
        return views.myFillingFormDatas.reduce(
          (acc, formData) =>
            acc == null
              ? moment(formData.lastmoddate)
              : moment.max(acc, moment(formData.lastmoddate)),
          undefined as moment.Moment | undefined,
        )
      },
      get maintenanceStatus() {
        if (self.status === WorkOrderFormStatus.voided)
          return WorkOrderFormMaintenanceStatus.voided
        if (
          [
            WorkOrderFormStatus.submitted,
            WorkOrderFormStatus.pendingForEndorse,
            WorkOrderFormStatus.pendingForApproval,
            WorkOrderFormStatus.approved,
          ].includes(self.status)
        )
          return WorkOrderFormMaintenanceStatus.submitted
        if (self.rejected || self.status === WorkOrderFormStatus.rejected)
          return WorkOrderFormMaintenanceStatus.rejected
        if (views.myFillingFormDatas.length > 0)
          return WorkOrderFormMaintenanceStatus.filling
        if (views.myFrozenFormDatas.length > 0)
          return WorkOrderFormMaintenanceStatus.frozen
        if (views.myUploadedFormDatas.length > 0)
          return WorkOrderFormMaintenanceStatus.uploaded
        return WorkOrderFormMaintenanceStatus.pending
      },
      availableForFilling(includeFillAgain?: boolean) {
        if (includeFillAgain && !self.isUnique) return true
        if (
          views.maintenanceStatus === WorkOrderFormMaintenanceStatus.submitted
        )
          return false
        // FIXME Remove temporary disabled checking for work order form filling availablity
        if (views.maintenanceStatus === WorkOrderFormMaintenanceStatus.uploaded)
          return false

        return true
      },

      get approver() {
        return views.mergedFormData?.approver
      },
      set approver(info: { username: string; upn: string } | undefined) {
        if (!views.mergedFormData) throw Error('merged-form-unavailable')
        views.mergedFormData.setApprover(info)
      },
      get endorser() {
        return views.mergedFormData?.endorser
      },
      set endorser(info: { username: string; upn: string } | undefined) {
        if (!views.mergedFormData) throw Error('merged-form-unavailable')
        views.mergedFormData.setEndorser(info)
      },
      get teamLeader() {
        return views.mergedFormData?.teamLeader
      },
      set teamLeader(info: { username: string; upn: string } | undefined) {
        if (!views.mergedFormData) throw Error('merged-form-unavailable')
        views.mergedFormData.setTeamLeader(info)
      },
      get approvalTime() {
        return views.mergedFormData?.approvalTime
      },
      get endorsementTime() {
        return views.mergedFormData?.endorsementTime
      },
      get submissionTime() {
        return views.mergedFormData?.submissionTime
      },

      get workflow(): ApprovalWorkflow | undefined {
        return views.mergedFormData?.approvalWorkflow
      },

      get instruments(): WorkOrderFormInstrumentRecord[] {
        const map = views.uploadedFormDatas.reduce(
          (acc, formData) =>
            formData.instruments.reduce(
              (accu, instrument) => ({
                ...accu,
                [instrument.imteNumber]: (
                  accu[instrument.imteNumber] || []
                ).concat({
                  createdBy: formData.uploader,
                  createdDate:
                    instrument.createdDate === null
                      ? formData.createddate === null
                        ? null
                        : moment(formData.createddate)
                      : moment(instrument.createdDate),
                  endDate: formData.endDate,
                  expiryDate:
                    instrument.expiryDate == null
                      ? null
                      : moment(instrument.expiryDate),
                }),
              }),
              acc,
            ),
          {},
        )

        return Object.keys(map).map((key) => ({
          histories: map[key],
          code: key,
        }))
      },
      get containsFilled() {
        return views.uploadedFormDatas.length > 0
      },
      get mandatoryFulfilled() {
        return views.mergedFormData?.mandatoryFulfilled ?? false
      },
      get sicCompleted() {
        return views.mergedFormData?.sicCompleted ?? false
      },
      get secondCheckerCompleted() {
        return views.mergedFormData?.secondCheckerCompleted ?? false
      },
      get canSubmit() {
        if (!views.mergedFormData) return false
        if (!views.containsFilled) return false
        if (!views.mandatoryFulfilled) return false
        if (!views.sicCompleted) return false
        if (!views.secondCheckerCompleted) return false
        return true
      },

      get isTeamLeader() {
        return (
          views.teamLeader &&
          views.teamLeader.upn.toLowerCase() ===
            self.rootStore.userProfileStore.userInfo.upn.toLowerCase()
        )
      },
      get isEndorser() {
        return (
          views.endorser &&
          views.endorser.upn.toLowerCase() ===
            self.rootStore.userProfileStore.userInfo.upn.toLowerCase()
        )
      },
      get isApprover() {
        return (
          views.approver &&
          views.approver.upn.toLowerCase() ===
            self.rootStore.userProfileStore.userInfo.upn.toLowerCase()
        )
      },

      get uploadHistories(): WorkOrderFormWorkflowHistoryItem[] {
        return views.uploadedFormDatas.map((formData) => ({
          id: formData.id,
          // type remark such as upload 只不过区分为 verifier and endorser submitForm without doer
          action: formData.isManager ? 'remark' : 'upload',
          actionDate: moment(formData.updateTime),
          actionBy: formData.uploader?.upn ?? '??',
          actionByDisplayName: formData.uploader?.username ?? '??',
          formData,
        }))
      },

      /** @deprecated */
      get histories(): WorkOrderFormApprovalHistory[] {
        const ret: WorkOrderFormApprovalHistory[] = views.uploadedFormDatas.map(
          (formData) => ({
            id: formData.id,
            type: WorkOrderFormApprovalAction.upload,
            date: moment(formData.updateTime || formData.lastmoddate),
            createdBy: formData.uploader,
            formData,
          }),
        )

        const { mergedFormData } = views

        if (mergedFormData?.submissionTime && views.teamLeader != null) {
          ret.push({
            id: 'submission',
            type: WorkOrderFormApprovalAction.submit,
            date: moment(mergedFormData.submissionTime),
            createdBy: views.teamLeader,
            formData: views.mergedFormData,
          })
        }

        if (mergedFormData?.endorsementTime && views.endorser != null) {
          ret.push({
            id: 'endorse',
            type: WorkOrderFormApprovalAction.approve,
            date: moment(mergedFormData.endorsementTime),
            createdBy: views.endorser,
            formData: undefined,
          })
        }

        if (mergedFormData?.approvalTime && views.approvalTime) {
          ret.push({
            id: 'approve',
            type: WorkOrderFormApprovalAction.approve,
            date: moment(mergedFormData.approvalTime),
            createdBy: views.approver,
            formData: undefined,
          })
        }

        if (mergedFormData?.endorsementTime && views.endorsementTime) {
          ret.push({
            id: 'endorse',
            type: WorkOrderFormApprovalAction.endorse,
            date: moment(mergedFormData.endorsementTime),
            createdBy: views.endorser,
            formData: undefined,
          })
        }

        if (mergedFormData?.approvalRejectTime) {
          ret.push({
            id: 'approval-reject',
            type: WorkOrderFormApprovalAction.reject,
            date: moment(mergedFormData.approvalRejectTime),
            createdBy: {
              username: mergedFormData.approvalRejectUsername ?? '',
              upn: mergedFormData.approvalRejectUpn!,
            },
            formData: undefined,
            detail: mergedFormData.approvalRejectReason,
          })
        }

        if (mergedFormData?.endorseRejectTime) {
          ret.push({
            id: 'endorse-reject',
            type: WorkOrderFormApprovalAction.reject,
            date: moment(mergedFormData.endorseRejectTime),
            createdBy: {
              username: mergedFormData.endorseRejectUsername ?? '',
              upn: mergedFormData.endorseRejectUpn!,
            },
            formData: undefined,
            detail: mergedFormData.endorseRejectReason,
          })
        }

        if (mergedFormData?.voidTime) {
          ret.push({
            id: 'void',
            type: WorkOrderFormApprovalAction.void,
            date: moment(mergedFormData.voidTime),
            createdBy: {
              username: mergedFormData.voidUsername ?? '',
              upn: mergedFormData.voidUpn!,
            },
            formData: undefined,
            detail: mergedFormData.voidReason,
          })
        }

        return ret.sort((a, b) => b.date.valueOf() - a.date.valueOf())
      },

      itemHistories(
        definitionItem: FormDefinitionItem,
      ): FormDataUploadedItem[] {
        return views.uploadedFormDatas
          .map((formData) => ({
            formData,
            item: formData.getFieldItem(definitionItem)!,
          }))
          .filter((item) => item.item != null)
      },

      mergedItem(definitionItem: FormDefinitionItem) {
        return views.mergedFormData?.getFieldItem(definitionItem)
      },

      get lastModifiedDate() {
        return self.lastUpdateDate ? moment(self.lastUpdateDate) : undefined
      },

      get nextReviewAction(): WorkOrderFormApprovalAction | null | undefined {
        switch (self.status) {
          case WorkOrderFormStatus.pending:
            return WorkOrderFormApprovalAction.submit
          case WorkOrderFormStatus.uploaded:
          case WorkOrderFormStatus.rejected:
          case WorkOrderFormStatus.submitted:
            return views.workflow === ApprovalWorkflow.endorseAndApprove
              ? WorkOrderFormApprovalAction.endorse
              : WorkOrderFormApprovalAction.approve
          case WorkOrderFormStatus.pendingForEndorse:
            return WorkOrderFormApprovalAction.approve
          case WorkOrderFormStatus.pendingForApproval:
          case WorkOrderFormStatus.approved:
          case WorkOrderFormStatus.voided:
            return null
          default:
            return undefined
        }
      },
    }
    return views
  })
  .preProcessSnapshot((snapshot) => ({
    ...snapshot,
    author: snapshot.author ?? undefined,
  }))
  .actions((self) => {
    const actions = {
      apply(snapshot: { [key: string]: any }) {
        _.keys(_.omit(snapshot, 'id')).forEach((key) => {
          if (snapshot[key] == null) return
          self[key] = snapshot[key]
        })
      },
      createFormData(): FormData {
        const currentUser = self.rootStore.userProfileStore.userInfo
        return self.rootStore.formStore.upsertFormData(<SnapshotIn<FormData>>{
          workOrderNumber: self.workOrder.number,
          remoteFormGroupId: self.workOrder.remoteId,
          remoteFormId: self.form.remoteId,
          formVersion: self.formDefinition.version,
          isOptional: self.optional,
          equipmentNumber: self.workOrder.equipment.number,
          workNatureId: self.workOrder.nature.id,
          remoteId: self.remoteFormDataId,
          uploaderUpn: currentUser.upn,
          uploaderName: currentUser.displayName,
          isMerged: false,
          remoteHistoryId: undefined,
        })
      },
      fetchFormDefinition: flow(function* fetchFormDefinition(force?: boolean) {
        if (!force && self.formDefinitionAvailable)
          return Promise.resolve(self.formDefinition)
        const def: FormDefinition = yield (self.form as Form).fetchDefinition(
          self.version,
        )
        if (def != null && def.isUnique == null) {
          def.setUnique(self.isUnique)
        }
        return def
      }),
      fetch: flow(function* fetch(): Generator<any, FormData[], any> {
        if (!self.formDefinitionAvailable) {
          self.environment.console.log(
            'Form Definition not available. will not fetch form data',
          )
          throw new Error('def-not-available')
        }

        self.isRefreshing = true
        self.rootStore.uiStore.showSpinner()
        try {
          const formDatas: FormData[] =
            yield self.rootStore.formStore.fetchFormData(self)
          // console.tron.display({
          //   name: 'Fetch Form Data',
          //   preview: self.form.number,
          //   value: [formDatas, self.categorizedFormDatas],
          // })

          self.lastUpdate = moment().toDate()
          return formDatas.filter(
            (formData) => !formData.isMerged && formData.isUploader,
          )
        } catch (error) {
          self.environment.console.warn(
            'Cannot verify whether have existing record',
          )
          self.environment.console.reportError(error)
          throw error
        } finally {
          self.rootStore.uiStore.hideSpinner()
          self.isRefreshing = false
        }
      }),

      getRemoteId: flow(function* getRemoteId() {
        if (self.remoteFormDataId != null) return self.remoteFormDataId
        const res: GetFormDataRemoteId =
          yield self.environment.api.getFormDataRemoteId({
            standardJobCode: self.workOrder.standardJob.code,
            workOrderNumber: self.workOrder.number,
            workGroupId: self.workOrder.workGroupId,
            equipmentNumber: self.workOrder.equipment.number,
            formId: self.form.remoteId,
            workNature: self.workOrder.nature?.id,
            depotId: self.workOrder.depot?.id,
            startDate: self.workOrder.startDate,
            completeDate: self.workOrder.completeDate,
            estimatedDuration: self.workOrder.estimatedDuration,
            description: self.workOrder.description,
            createDate: self.workOrder.createDate,
            parentWorkOrderNumber: self.workOrder.parentWorkOrderNumber,
            relation: self.workOrder.relation,
            trainTypeId:
              self.workOrder.trainStockTypeId ??
              self.workOrder.equipment.stockType?.id,
          })

        if (res.kind !== 'ok') throw Error(res.kind)
        const { payload } = res

        // if (self.remoteFormDataId != null && self.remoteFormDataId !== payload.FormDataObjectID) {
        //   console.tron.log('Mismatched remote form data id', self, payload.FormDataObjectID)
        //   throw Error('mismatch-id')
        // }
        self.remoteFormDataId = payload.FormDataObjectID
        self.referenceNumber = payload.FormGroupFormReferenceNo
        self.workOrder.onRemoteIdAssigned(payload.FormGroupDataObjectID)

        return self.remoteFormDataId
      }),
      onUploaded(formData: { remoteId: string; referenceNumber: string }) {
        if (self.remoteFormDataId == null) {
          self.remoteFormDataId = formData.remoteId
        } else if (self.remoteFormDataId !== formData.remoteId) {
          console.tron.logImportant(
            'BUG?: Mismatched remote form data id',
            self,
            formData,
          )
        }
        self.referenceNumber = formData.referenceNumber
        // self.internalStatus = WorkOrderFormMaintenanceStatus.Uploaded
      },
      fetchApproverList: flow(function* fetchApproverList(
        mode?: 'approve' | 'endorse',
      ) {
        let toEndorse: boolean = false
        if (mode) {
          if (mode === 'endorse') toEndorse = true
        } else {
          toEndorse =
            self.workflow === ApprovalWorkflow.endorseAndApprove &&
            self.status !== WorkOrderFormStatus.pendingForEndorse
        }

        if (self.remoteFormDataId == null) throw new Error('empty-remote-id')

        const res: GetApproverList = yield self.environment.api.getApproverList(
          self.remoteFormDataId,
          toEndorse ? ApproverCode.ENDORSER : ApproverCode.APPROVER,
        )
        if (res.kind !== 'ok') throw new Error(res.kind)

        const approvers: UserInfo[] = res.payload
          // .filter(item => !item.isDeleted)
          .map((item) => {
            try {
              return UserInfoModel.create({
                id: item.userID,
                username: item.userName,
                upn: item.upn ?? item.userName, // FIXME tmp workaround on invalid data
              })
            } catch {
              return undefined
            }
          })
          .filter(notEmpty)

        return _.uniqBy(approvers, 'id') as UserInfo[]
      }),

      submit: flow(function* submit(
        remark: string,
        approver: UserInfo,
      ): Generator<any, void, any> {
        // 是否是发给Enderser的状态
        const toEndorse = self.workflow === ApprovalWorkflow.endorseAndApprove

        if (self.mergedFormData == null) throw new Error('no-merged-form-data')
        if (self.mergedFormData.remoteId == null) {
          throw new Error('empty-remote-id')
        }
        // 进行uploadForm请求操作，如果mergedFormData内有Remarks or Attachments存在
        if( 
          self.mergedFormData.remark.content ||
          self.mergedFormData.attachments.length > 0 
          ){
            // 能在这里提交formData的都是verifier & endorser isManagerSubmitFormData must is true
            self.mergedFormData.setIsManagerSubmitFormData(true)
            // 开始提交submitFormData
            yield self.rootStore.maintenanceStore.completeOngoing(self.mergedFormData)
        }

        // 
        const validationOptions: ValidationOptions = {
          referenceDate: moment(self.mergedFormData.endDate),
          validateWorkflow: true,
        }
        // 
        const res: PostSubmitFormData = toEndorse
          ? yield self.environment.api.submitFormDataForEndorse(
              self.mergedFormData as { remoteId: string },
              self.rootStore.userProfileStore.userInfo,
              approver,
              yield self.mergedFormData.summary(validationOptions),
              yield self.mergedFormData.payloadMetadata('submit'),
              remark,
            )
          : yield self.environment.api.submitFormDataForApprove(
              self.mergedFormData as { remoteId: string },
              self.rootStore.userProfileStore.userInfo,
              approver,
              yield self.mergedFormData.summary(validationOptions),
              yield self.mergedFormData.payloadMetadata('submit'),
              remark,
            )

        if (res.kind !== 'ok') throw new Error(res.kind)
        if (res.payload.status !== 1) throw new Error(res.payload.message)

        self.teamLeader = self.rootStore.userProfileStore.userInfo

        if (toEndorse) {
          self.status = WorkOrderFormStatus.pendingForEndorse
          self.endorser = approver
        } else {
          self.status = WorkOrderFormStatus.pendingForApproval
          self.approver = approver
        }
      }),
      approve: flow(function* approve(
        approver?: UserInfo,
      ): Generator<any, void, any> {
        // 是否是发给Enderser的状态
        const isEndorse =
          self.workflow === ApprovalWorkflow.endorseAndApprove &&
          self.status === WorkOrderFormStatus.pendingForEndorse

        if (self.mergedFormData == null) throw new Error('no-merged-form-data')
        if (self.mergedFormData.remoteId == null) {
          throw new Error('empty-remote-id')
        }

        if (isEndorse && approver == null) {
          throw new Error('missing-approver')
        }
        // 进行uploadForm请求操作，如果mergedFormData内有Remarks or Attachments存在
        if( 
          self.mergedFormData.remark.content ||
          self.mergedFormData.attachments.length > 0 
          ){
            // 能在这里提交formData的都是verifier & endorser isManagerSubmitFormData must is true
            self.mergedFormData.setIsManagerSubmitFormData(true)
            // 开始提交submitFormData
            yield self.rootStore.maintenanceStore.completeOngoing(self.mergedFormData)
        }

        const res: PostApproveFormData | PostSubmitFormData = isEndorse
          ? yield self.environment.api.submitFormDataForApprove(
              self.mergedFormData as { remoteId: string },
              self.rootStore.userProfileStore.userInfo,
              approver,
              yield self.mergedFormData.summary({
                referenceDate: moment(self.mergedFormData.endDate),
                validateWorkflow: true,
              }),
            )
          : yield self.environment.api.approveFormData(
              self.mergedFormData as { remoteId: string },
              self.rootStore.userProfileStore.userInfo,
              approver,
            )
        if (res.kind !== 'ok') throw new Error(res.kind)

        if (res.payload.status !== 1) throw new Error(res.payload.message)

        if (isEndorse) {
          self.approver = approver
          self.status = WorkOrderFormStatus.pendingForApproval
        } else {
          self.status = WorkOrderFormStatus.approved
        }
      }),
      reject: flow(function* reject(
        reason: string,
      ): Generator<any, void, PostRejectFormData> {
        if (self.mergedFormData == null) throw new Error('no-merged-form-data')
        if (self.mergedFormData.remoteId == null) {
          throw new Error('empty-remote-id')
        }

        const res = yield self.environment.api.rejectFormData(
          self.mergedFormData as { remoteId: string },
          reason,
          self.rootStore.userProfileStore.userInfo,
        )

        if (res.kind !== 'ok') throw new Error(res.kind)

        if (res.payload.status !== 1) throw new Error(res.payload.message)
        self.status = WorkOrderFormStatus.rejected
      }),
      voidForm: flow(function* voidForm(
        reason: string,
      ): Generator<any, void, PostVoidFormData> {
        if (self.mergedFormData == null) throw new Error('no-merged-form-data')
        if (self.mergedFormData.remoteId == null) {
          throw new Error('empty-remote-id')
        }

        const res = yield self.environment.api.voidFormData(
          self.mergedFormData as { remoteId: string },
          reason,
          self.rootStore.userProfileStore.userInfo,
        )

        if (res.kind !== 'ok') throw new Error(res.kind)

        if (res.payload.status !== 1) throw new Error(res.payload.message)
        self.status = WorkOrderFormStatus.voided
      }),
      changeApprover: flow(function* changeApprover(
        approver: UserInfo,
      ): Generator<any, void, any> {
        if (self.mergedFormData == null) throw new Error('no-merged-form-data')
        if (self.mergedFormData.remoteId == null) {
          throw new Error('empty-remote-id')
        }

        const isEndorse = self.status === WorkOrderFormStatus.pendingForEndorse

        const res: PostChangeApproverData =
          yield self.environment.api.changeApprover(
            self.mergedFormData as { remoteId: string },
            self.rootStore.userProfileStore.userInfo,
            approver,
            isEndorse ? 'endorser' : 'approver',
          )
        if (res.kind !== 'ok') throw new Error(res.kind)

        if (res.payload.status !== 1) throw new Error(res.payload.message)

        if (isEndorse) {
          self.endorser = approver
        } else {
          self.approver = approver
        }
      }),
      fetchAllowToSubmit: flow(function* fetchAllowToSubmit(): Generator<
        any,
        boolean,
        any
      > {
        if (self.remoteFormDataId == null) {
          throw new Error('empty-remote-id')
        }

        const res: GetHasUploadedForm =
          yield self.environment.api.hasUploadedForm(self.remoteFormDataId)
        if (res.kind !== 'ok') throw new Error(res.kind)
        if (typeof res.payload !== 'boolean') return false

        return !(res.payload ?? true)
      }),
      fetchReviewExtraDocuments: flow(
        function* fetchReviewExtraDocuments(): Generator<
          any,
          { title: string; url: string; params: any }[],
          any
        > {
          if (self.remoteFormDataId == null) {
            throw new Error('empty-remote-id')
          }

          const res: GetFormDataReportList =
            yield self.environment.api.getFormDataReportList(
              self.remoteFormDataId,
            )
          if (res.kind !== 'ok') throw new Error(res.kind)
          const baseUrl = self.environment.api?.config?.url

          const ret = res.payload.map((result) => ({
            title: result.title,
            params: _.pick(result, [
              'formDataObjectID',
              'reportType',
              'fileName',
            ]),
            url: encodeURI(
              `${baseUrl}Form/FormData/FormApprovePrintForMobile?ObjectID=${result.formDataObjectID}&reportType=${result.reportType}&fileName=${result.fileName}`,
            ),
          }))

          return ret
        },
      ),
      fetchExtraDocument: (params: any): Promise<any> =>
        self.environment.api?.fetchFormDataReport(params),
      fetchWorkflowHistory: flow(function* fetchWorkflowHistory() {
        if (self.remoteFormDataId == null) throw new Error('empty-payload')

        const res: GeneralApiResponse<WorkflowHistoryPayload> =
          yield self.environment.api.workOrderForms.getWorkOrderFormWorkflowHistory(
            self.remoteFormDataId,
          )
        if (res.kind !== 'ok') throw new Error(res.kind)
        if (res.payload == null) throw new Error('empty-payload')

        return res.payload.workflowStatusModelList.map(
          (item): WorkOrderFormWorkflowHistoryItem => ({
            ...item,
            id: item.objectID,
            action:
              item.actionName?.toLowerCase() as unknown as WorkflowHistoryAction,
            actionDate:
              item.actionDate == null
                ? item.actionDate
                : moment(item.actionDate),
            remark: item.remark,
          }),
        )
      }),
    }

    return { ...actions, onWithdrawn: actions.fetch }
  })

export type WorkOrderForm = Instance<typeof WorkOrderFormModel>
