import type { DeepNullable, DeepPartial } from '@mtr-SDO/tsconfig-node'
import moment from 'moment'

export function stringToDate(
  date: string | undefined | null,
): Date | undefined {
  if (date == null) return undefined
  if (date === '') return undefined
  return moment(date).toDate()
}

export function stringToNumber(
  num: string | number | undefined | null,
): number | undefined {
  if (num == null) return undefined
  if (num === '') return undefined
  return +num
}

export function stringToString(
  str: string | undefined | null,
  acceptEmptyString?: boolean,
): string | undefined {
  if (str == null) return undefined
  if (!acceptEmptyString && str === '') return undefined
  return str
}

export function dateToString(
  date: number | DeepNullable<DeepPartial<Date>> | undefined | null,
): string | undefined | null {
  if (date == null) return date
  return moment(date as Date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
}
