import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import { Alert } from '@mui/lab'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { Helmet } from '../components'
import { useStores } from '../models'
import { useCommonServerHealthCheck, useServerHealthCheck } from '../queries'
import { env } from '../utils'

const Root = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding-top: ${({ theme }) => theme.spacings[4]}px;
  padding-bottom: ${({ theme }) => theme.spacings[4]}px;
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.palettes.general.background};
`

export const HealthCheckPage = observer(() => {
  const { configStore } = useStores()
  const {
    data: serverHealthCheckData,
    error: serverHealthCheckError,
    isFetching: isFetchingServerHealthCheck,
  } = useServerHealthCheck()
  const {
    data: commonServerHealthCheckData,
    error: commonServerHealthCheckError,
    isFetching: isFetchingCommonServerHealthCheck,
  } = useCommonServerHealthCheck()

  return (
    <Root>
      <Helmet title="Health check" />
      <Container fixed>
        <Typography variant="h3">Health check</Typography>
        <Grid container spacing={4}>
          <Grid item xs>
            <Card variant="outlined">
              <CardHeader
                title="Web"
                subheader={`${env.version} / ${env.environment}`}
              />
            </Card>
          </Grid>
          <Grid item xs>
            <Card variant="outlined">
              <CardHeader title="Server (AKS)" />
              <CardContent>
                {isFetchingServerHealthCheck ? (
                  <CircularProgress />
                ) : serverHealthCheckError != null ? (
                  <Alert variant="filled" severity="error">
                    {(serverHealthCheckError as Error).toString()}
                  </Alert>
                ) : (
                  <Alert severity="success">{serverHealthCheckData}</Alert>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs>
            <Card variant="outlined">
              <CardHeader title="Server (Common Server)" />
              <CardContent>
                {isFetchingCommonServerHealthCheck ? (
                  <CircularProgress />
                ) : commonServerHealthCheckError != null ? (
                  <Alert variant="filled" severity="error">
                    {(commonServerHealthCheckError as Error).toString()}
                  </Alert>
                ) : (
                  <Alert severity="success">
                    {commonServerHealthCheckData}
                  </Alert>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Typography variant="h4">Web Environment</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {Object.entries(env)
                .filter((entry) => !entry[0].includes('Key'))
                .map((entry) => (
                  <TableRow key={entry[0]}>
                    <TableCell component="th" scope="row">
                      {entry[0]}
                    </TableCell>
                    <TableCell>{entry[1]}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="h4">Config</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {Object.entries(configStore)
                .filter((entry) => !entry[0].includes('Key'))
                .map((entry) => (
                  <TableRow key={entry[0]}>
                    <TableCell component="th" scope="row">
                      {entry[0]}
                    </TableCell>
                    <TableCell>
                      {['object'].includes(typeof entry[1])
                        ? entry[1][Object.keys(entry[1])[0]]
                        : ['array'].includes(typeof entry[1])
                        ? JSON.stringify(entry[1])
                        : (entry[1] as string)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Root>
  )
})
