import { WICNTaskPayloadItem } from '@mtr-SDO/apis'
import { withRootStore } from '@mtr-SDO/models-core'
import _ from 'lodash'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import {
  stringToDate,
  stringToNumber,
  stringToString,
} from '../common-function'
import { WIReviewStatus } from '../wi-review'
import {
  getPriorityByLastUpdateDate,
  mapPayloadToWIWorkflowPart,
  TaskPriority,
  UserModel,
} from '../wi-workflow'
import { getWICNRequestType, WICNRequestTypeEnum } from './wicn-selection-item'
import {
  getWICNSnapShotStatus,
  WICNStatus,
  WICNStatusEnum,
} from './wicn-status'
import { WICNWorkflowModel } from './wicn-workflow.model'

export type WITaskSearchCriteria = {
  searchText?: string
  currentStatus?: WICNStatus | WIReviewStatus
  checkPending?: boolean
}

export const WICNTaskModel = types
  .model('WICN-Task', {
    wicnTaskId: types.optional(types.identifier, uuid),
    status: WICNStatusEnum,
    currentUser: types.maybe(UserModel),
    currentUserGroup: types.maybe(types.string),
    wicnNo: types.maybe(types.string),
    requestType: types.maybe(WICNRequestTypeEnum),
    wiNo: types.maybe(types.string),

    createdDate: types.maybe(types.Date),
    createdBy: types.maybe(types.string),
    updatedDate: types.maybe(types.Date),
    updatedBy: types.maybe(types.string),

    isFinished: types.boolean,
    isForwarded: types.boolean,
    wicnWorkflowData: WICNWorkflowModel,
  })
  .extend(withRootStore)
  .actions((self) => ({
    apply(snapshot: any) {
      _.keys(_.omit(snapshot, ['wicnTaskId'])).forEach((key) => {
        if (snapshot[key] == null) return
        self[key] = snapshot[key]
      })
    },
  }))
  .views((self) => {
    const views = {
      get lastUpdatedDate(): moment.Moment {
        if (self.wicnWorkflowData == null) {
          return moment(self.createdDate)
        }
        const dates: moment.Moment[] = [
          moment(self.wicnWorkflowData.partAWorkflow.operationDate),
          moment(self.wicnWorkflowData.partBWorkflow.operationDate),
          moment(self.wicnWorkflowData.partCWorkflow.operationDate),
          moment(self.wicnWorkflowData.partDWorkflow.operationDate),
          moment(self.wicnWorkflowData.partEWorkflow.operationDate),
          moment(self.wicnWorkflowData.partFWorkflow.operationDate),
          moment(self.wicnWorkflowData.partGWorkflow.operationDate),
        ]
        return moment.max(dates)
      },
      get wiNoForDisplay(): string | undefined {
        if (self.wiNo == null) {
          return undefined
        }
        if ((self.wiNo ?? '').split(',').length > 2) {
          return (self.wiNo ?? '')
            .split(',')
            .slice(0, 2)
            .join(', ')
            .concat('...')
        }

        return (self.wiNo ?? '').split(',').slice(0, 2).join(', ')
      },
      get priority(): TaskPriority {
        return getPriorityByLastUpdateDate(moment(self.updatedDate))
      },
      get wiList(): string[] {
        return (self.wiNo ?? '').split(',')
      },
    }
    return views
  })

export type WICNTask = Instance<typeof WICNTaskModel>

export function mapPayloadToWICNTask(
  payload: WICNTaskPayloadItem,
): SnapshotIn<WICNTask> {
  return {
    wicnTaskId: payload.objectID,

    status: getWICNSnapShotStatus(stringToNumber(payload.currentStatusNo)),
    currentUser: {},
    // currentUserGroup:
    wicnNo: stringToString(payload.wicnNo),
    requestType: getWICNRequestType(parseInt(payload.requestType, 10)),
    wiNo: stringToString(payload.wiNo),
    createdDate: stringToDate(payload.createDate),
    createdBy: stringToString(payload.createBy),
    updatedDate: stringToDate(payload.createDate),
    updatedBy: stringToString(payload.createBy),
    isFinished: payload.isFinished,
    isForwarded: payload.isForWardUser,
    wicnWorkflowData: {
      partAWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partAWorkflow,
      ),
      partBWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partBWorkflow,
      ),
      partCWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partCWorkflow,
      ),
      partDWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partDWorkflow,
      ),
      partEWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partEWorkflow,
      ),
      partFWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partFWorkflow,
      ),
      partGWorkflow: mapPayloadToWIWorkflowPart(
        payload.wicnWorkflowData.partGWorkflow,
      ),
    },
  }
}
