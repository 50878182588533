import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import moment from 'moment'
import { WIReviewApprovalData } from '@mtr-SDO/apis'
import { UserModel, AttachmentModel, mapPayloadToUser } from '../wi-workflow'
import { WIReviewCommentFormModel } from './wi-review-detail-comment.model'
import {
  WIReviewPartBCommentRequiredEnum,
  WIReviewPartBConclusionEnum,
  WIReviewPartJRecordFormResultEnum,
  WIReviewPartJWIResultEnum,
  WIReviewTaskTypeEnum,
  WIReviewUploadedEnum,
  WIReviewWITypeEnum,
} from './wi-review-selection-item'
import { WIReviewPartBItemModel } from './wi-review-item.model'
import { stringToDate, stringToString } from '../common-function'

export const WIReviewPartAModel = types.model({
  partId: types.number,
  refNo: types.maybe(types.string),
  wiNo: types.maybe(types.string),
  wiTitle: types.maybe(types.string),
  issueNo: types.maybe(types.string),
  revNo: types.maybe(types.string),
  mWiWicn: types.maybe(types.string),

  drafter: types.maybe(UserModel),
  taskType: types.maybe(WIReviewTaskTypeEnum),
  recordDate: types.optional(types.Date, () => moment().toDate()),
  targetCompletionDate: types.optional(types.Date, () => moment().toDate()),
  attachments: types.array(AttachmentModel),
})

export const WIReviewPartBModel = types.model({
  partId: types.number,
  wiType: types.maybe(WIReviewWITypeEnum),
  reviewItemList: types.array(WIReviewPartBItemModel),
  conclusion: types.maybe(WIReviewPartBConclusionEnum),
  amendmentDetail: types.maybe(types.string),
  draftWIForReviewList: types.array(types.string),
  commentRequired: types.maybe(WIReviewPartBCommentRequiredEnum),
  comment: types.maybe(types.string),
  attachments: types.array(AttachmentModel),
})

export const WIReviewPartCModel = types.model({
  partId: types.number,
  comment: types.maybe(types.string),
})

export const WIReviewPartDModel = types.model({
  partId: types.number,
  commentSkipped: types.boolean,
  presetGroups: types.maybe(types.string),
})

export const WIReviewPartEModel = types.model({
  partId: types.number,
  commentForms: types.array(WIReviewCommentFormModel),
  attachments: types.array(AttachmentModel),
})

export const WIReviewPartFModel = types.model({
  partId: types.number,
  isReadyForApproval: types.maybe(types.string),
  checkerComment: types.maybe(types.string),
  firstEndorserRequired: types.maybe(types.boolean),
  firstEndorser: types.maybe(UserModel),
  secondEndorserRequired: types.maybe(types.boolean),
  secondEndorser: types.maybe(UserModel),
  approverRequired: types.maybe(types.boolean),
})

export const WIReviewApprovalModel = types.model({
  partId: types.number,
  decision: types.maybe(types.string),
  comment: types.maybe(types.string),
  user: types.maybe(UserModel),
  date: types.optional(types.Date, () => moment().toDate()),
})

export const WIReviewPartJModel = types.model({
  partId: types.number,
  wiResult: types.maybe(WIReviewPartJWIResultEnum),
  wiResultDeleteRemarks: types.maybe(types.string),
  recordFormResult: types.maybe(WIReviewPartJRecordFormResultEnum),
  recordFormResultDeleteRemarks: types.maybe(types.string),
  remarks: types.maybe(types.string),
  attachments: types.array(AttachmentModel),
})

export const WIReviewPartKModel = types.model({
  uploaded: types.maybe(WIReviewUploadedEnum),
})

export type WIReviewApproval = Instance<typeof WIReviewApprovalModel>

export function mapPayloadToWIReviewApproval(
  payload: WIReviewApprovalData,
): SnapshotIn<WIReviewApproval> {
  return {
    partId: payload.partId,
    decision: stringToString(payload.decision),
    comment: stringToString(payload.endorserComment),
    user: mapPayloadToUser(payload.updateBy),
    date: stringToDate(payload.updateDate),
  }
}

export type WIReviewPartA = Instance<typeof WIReviewPartAModel>
