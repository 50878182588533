import moment from 'moment'

export enum TaskPriority {
  high = 'high',
  medium = 'medium',
  low = 'low',
  na = 'na',
}

export function getPriorityByLastUpdateDate(
  lastUpdateDate: moment.Moment | undefined,
): TaskPriority {
  if (moment().diff(lastUpdateDate, 'weeks') <= 2) {
    return TaskPriority.low
  }
  if (
    moment().diff(lastUpdateDate, 'weeks') > 2 &&
    moment().diff(lastUpdateDate, 'months') <= 1
  ) {
    return TaskPriority.medium
  }
  if (moment().diff(lastUpdateDate, 'months') > 1) {
    return TaskPriority.high
  }
  return TaskPriority.na
}

export function getPriorityByDeadline(
  deadline: moment.Moment | undefined,
): TaskPriority {
  if (moment(deadline).diff(moment(), 'weeks') <= 2) {
    return TaskPriority.high
  }
  if (
    moment(deadline).diff(moment(), 'weeks') > 2 &&
    moment(deadline).diff(moment(), 'months') <= 1
  ) {
    return TaskPriority.medium
  }
  if (moment(deadline).diff(moment(), 'months') > 1) {
    return TaskPriority.low
  }
  return TaskPriority.na
}
