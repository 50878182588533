import { Instance, types } from 'mobx-state-tree'
import moment from 'moment'
import { UserModel, AttachmentModel } from '../wi-workflow'
import { WICNCommentFormModel } from './wicn-detail-comment.model'
import {
  WICNJHAReportRequiredEnum,
  WICNJHAReportTypeEnum,
  WICNQualityCheckEnum,
  WICNQualityCheckTypeEnum,
  WICNRequestTypeEnum,
  WICNSignificantImplicationAreaEnum,
  WICNSignificantImplicationEnum,
} from './wicn-selection-item'
import { WICNWIItemModel } from './wicn-wi-item.model'

export const WICNPartAModel = types
  .model({
    partId: types.number,
    wicnNo: types.string,
    date: types.optional(types.Date, () => moment().toDate()),
    assignedUser: types.maybe(UserModel),
    drafter: types.maybe(UserModel),

    requestType: types.maybe(WICNRequestTypeEnum),
    wiList: types.maybe(types.array(WICNWIItemModel)),
    detailOfChanges: types.maybe(types.string),
    jhaReportRequired: types.maybe(WICNJHAReportRequiredEnum),
    jhaReportType: types.maybe(WICNJHAReportTypeEnum),
    briefOfReason: types.maybe(types.string),
    implication: types.maybe(WICNSignificantImplicationEnum),
    implicationArea: types.maybe(WICNSignificantImplicationAreaEnum),
    maintenanceContent: types.maybe(types.string),
    implicationDescription: types.maybe(types.string),
    changeApprovedBy: types.maybe(types.string),
    approver: types.maybe(types.string),
    attachments: types.array(AttachmentModel),
  })
  .views((self) => ({
    get wicnNoForMobileDisplay(): string {
      return self.wicnNo.replace('RSMD', '')
    },
  }))

export const WICNPartBModel = types.model({
  partId: types.number,
  date: types.optional(types.Date, () => moment().toDate()),
  user: types.maybe(UserModel),
  decision: types.maybe(types.string),
  comment: types.maybe(types.string),
})

export const WICNPartCModel = types.model({
  partId: types.number,
  date: types.optional(types.Date, () => moment().toDate()),
  user: types.maybe(UserModel),
  decision: types.maybe(types.string),
  comment: types.maybe(types.string),
  completedBy: types.maybe(types.string),
  issue: types.maybe(types.number),
  revision: types.maybe(types.number),
  revisedWIList: types.array(WICNWIItemModel),
  qualityCheck: types.maybe(WICNQualityCheckEnum),
  qualityCheckType: types.maybe(WICNQualityCheckTypeEnum),
})

export const WICNPartDModel = types.model({
  partId: types.number,
  reviewUser: types.maybe(UserModel),
  requireComment: types.boolean,
  comment: types.maybe(types.string),
  assignedUserList: types.array(UserModel),
})

export const WICNPartEModel = types.model({
  partId: types.number,
  commentForms: types.array(WICNCommentFormModel),
})

export const WICNPartFModel = types.model({
  partId: types.number,
  user: types.maybe(UserModel),
  date: types.maybe(types.Date),
  isAccepted: types.maybe(types.string),
  comment: types.maybe(types.string),
  isTemporaryChange: types.maybe(types.boolean),
})

export const WICNPartGModel = types.model({
  partId: types.number,
  user: types.maybe(UserModel),
  date: types.maybe(types.Date),
  remarks: types.maybe(types.string),
})

export type WICNPartA = Instance<typeof WICNPartAModel>
export type WICNPartB = Instance<typeof WICNPartBModel>
export type WICNPartF = Instance<typeof WICNPartFModel>
export type WICNPartG = Instance<typeof WICNPartGModel>
