export enum WorkOrderStatus {
  notStarted = 'not-started',
  pending = 'pending',
  readyForComplete = 'ready-for-complete',
  requestedForComplete = 'request-for-complete',
  completed = 'completed',
  cancelled = 'cancelled',
  voided = 'voided',
}

export enum WorkOrderFormMaintenanceStatus {
  pending = 'pending',
  filling = 'filling',
  frozen = 'freezed',
  uploaded = 'uploaded',
  submitted = 'submitted',
  voided = 'voided',
  rejected = 'rejected',
}

export enum WorkOrderFormStatus {
  pending = 'pending',
  uploaded = 'uploaded',
  submitted = 'submitted',
  pendingForEndorse = 'pending-endorse',
  pendingForApproval = 'pending-approval',
  approved = 'approved',
  rejected = 'rejected',
  voided = 'voided',
}

export function getWorkOrderSnapshotStatus(snapshot: {
  status: number
  isReadyToComplete: boolean
}): WorkOrderStatus {
  switch (snapshot.status) {
    case 11:
      return WorkOrderStatus.completed
    case 1:
      return WorkOrderStatus.requestedForComplete
    case 19:
      return WorkOrderStatus.voided
    case 12:
      return WorkOrderStatus.cancelled
    // no default
  }

  return snapshot.isReadyToComplete
    ? WorkOrderStatus.readyForComplete
    : WorkOrderStatus.pending
}

export function getWorkOrderFormSnapshotStatus(
  code: number | undefined,
): WorkOrderFormStatus {
  switch (code) {
    case 1:
      return WorkOrderFormStatus.pending
    case 2:
      return WorkOrderFormStatus.uploaded
    case 3:
      return WorkOrderFormStatus.pendingForEndorse
    case 4:
      return WorkOrderFormStatus.pendingForApproval
    case 5:
      return WorkOrderFormStatus.approved
    case 7:
      return WorkOrderFormStatus.voided
    default:
      return WorkOrderFormStatus.pending
  }
}

export function getWorkOrderFormStatus({
  status,
  rejected,
}: {
  status: WorkOrderFormStatus
  rejected?: boolean
}): WorkOrderFormStatus {
  if (
    rejected &&
    [
      WorkOrderFormStatus.pending,
      WorkOrderFormStatus.uploaded,
      WorkOrderFormStatus.rejected,
    ].includes(status)
  )
    return WorkOrderFormStatus.rejected

  return status
}
