import { useCallback } from 'react'
import styled from '@emotion/styled'
import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { B2BWEB } from '@mtr-SDO/oauth'
import { observer } from 'mobx-react-lite'
import url from 'url'
import { useStores } from '../../models'
import { Icon } from '../../components'
import { icons } from '../../theme'
import MTRlogo from '../../image/mtr-logo-long.png'
import SDOlogo from '../../image/logo-sdo.png'
import Divider from '../../image/decorations.png'
import Footer from '../../image/footer.png'
import Train from '../../image/train.png'
import { storage } from '../../local-storage'
import { env, ScreenSize, useView, useWindowHeight } from '../../utils'

const Background = styled.div<{ $view: ScreenSize; $innerHeight: number }>`
  width: 100vw;
  height: ${({ $view, $innerHeight }) =>
    $view === ScreenSize.desktop ? '100vh' : `${$innerHeight}px`};
  display: flex !important;
  align-items: center;
  background: linear-gradient(
    26.65deg,
    #060606,
    #006450 31.54%,
    #00755e 41.83%,
    #006064 56.19%,
    #062a65 77.21%
  );
  flex: 1;
`

const Ripple = styled.div<{ $view: ScreenSize; $innerHeight: number }>`
  width: 100vw;
  height: ${({ $view, $innerHeight }) =>
    $view === ScreenSize.desktop ? '100vh' : `${$innerHeight}px`};
  display: flex !important;
  align-items: center;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(64, 106, 125, 0.3) 27.6%,
    rgba(33, 55, 69, 0.3) 79.68%,
    rgba(14, 22, 33, 0.3) 100%
  );

  flex: 1;
  overflow: hidden;
`

const MTRLogo = styled.img<{ $view: ScreenSize }>`
  width: ${({ $view }) => ($view === ScreenSize.desktop ? `25vw` : `45vw`)};
  height: auto;
  position: absolute;
  top: ${({ $view }) => ($view === ScreenSize.desktop ? 5 : 3)}%;
  left: ${({ $view }) => ($view === ScreenSize.desktop ? 3 : 5)}%;
`

const SDOLogo = styled.img<{ $view: ScreenSize }>`
  width: ${({ $view }) => ($view === ScreenSize.desktop ? `20vw` : `50vw`)};
  height: auto;
  position: absolute;
  bottom: 50%;
  ${({ $view }) =>
    $view === ScreenSize.desktop
      ? `right: 10%;`
      : `padding-left:25%;padding-right:25%;`}
  padding-bottom: ${({ theme, $view }) =>
    $view === ScreenSize.desktop ? theme.spacings[7] : 0}px;
`

const TrainIcon = styled.img<{ $view: ScreenSize }>`
  width: ${({ $view }) => ($view === ScreenSize.desktop ? `50vw` : `75vw`)};
  height: auto;
  position: absolute;
  bottom: 35%;
  left: ${({ $view }) => ($view === ScreenSize.desktop ? -10 : -15)}%;
`

const Ellipse = styled.div<{ $view: ScreenSize }>`
  position: absolute;
  width: ${({ $view }) => ($view === ScreenSize.desktop ? `45vh` : `80vw`)};
  height: ${({ $view }) => ($view === ScreenSize.desktop ? `45vh` : `15vh`)};
  bottom: ${({ $view }) => ($view === ScreenSize.desktop ? 15 : 30)}%;
  left: ${({ $view }) => ($view === ScreenSize.desktop ? 15 : -10)}%;

  background: #03201c;
  filter: blur(${({ $view }) => ($view === ScreenSize.desktop ? 120 : 80)}px);
  transform: rotate(
    ${({ $view }) => ($view === ScreenSize.desktop ? 26.65 : 0.47)}deg
  );
`

const DividerContainer = styled.img<{ $view: ScreenSize }>`
  width: ${({ $view }) => ($view === ScreenSize.desktop ? `80vw` : `150vw`)};
  height: auto;
  position: ${({ $view }) =>
    $view === ScreenSize.desktop ? 'absolute' : 'sticky'};
  bottom: ${({ $view }) => ($view === ScreenSize.desktop ? 50 : 65)}%;
  right: 0%;
  margin-left: ${({ $view }) =>
    $view === ScreenSize.desktop ? '0px' : '35px'};
`
const FooterContainer = styled.img<{ $view: ScreenSize }>`
  width: ${({ $view }) => ($view === ScreenSize.desktop ? `70vw` : `95vw`)};
  height: auto;
  position: absolute;
  bottom: ${({ $view }) => ($view === ScreenSize.desktop ? 5 : 20)}%;
  right: 0px;
`
const LoginButton = styled(Button)<{ $view: ScreenSize }>`
  background-color: ${({ theme }) => theme.palettes.general.white} !important;
  padding: ${({ theme }) => theme.spacings[2]}px;
  box-shadow: 0px 0px 30px 0px #99c0ffb0;
  position: absolute;
  bottom: ${({ $view }) => ($view === ScreenSize.desktop ? `30%` : `10%`)};
  width: ${({ $view }) => ($view === ScreenSize.desktop ? `20vw` : `85%`)};
  left: ${({ $view }) => ($view === ScreenSize.desktop ? undefined : `0`)};
  right: ${({ $view }) => ($view === ScreenSize.desktop ? '10%' : `0`)};

  margin-left: ${({ $view }) =>
    $view === ScreenSize.desktop ? undefined : 'auto'};
  margin-right: ${({ $view }) =>
    $view === ScreenSize.desktop ? undefined : 'auto'};

  :hover {
    background-color: ${({ theme }) =>
      theme.palettes.general.greys[6]} !important;
    box-shadow: 0px 0px 30px 0px #99c0ffb0;
  }
`
const ButtonText = styled(Typography)`
  font-size: ${({ theme }) => theme.fontSize[5]}px;
  color: ${({ theme }) => theme.palettes.general.corporateBlue};
  font-weight: 700;
  text-transform: capitalize;
`

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.palettes.general.corporateBlue};
  width: ${({ theme }) => theme.spacings[5]}px;
  height: ${({ theme }) => theme.spacings[5]}px;
`

const Title = styled(Typography)<{ $view: ScreenSize }>`
  font-size: ${({ $view, theme }) =>
    $view === ScreenSize.desktop ? theme.fontSize[11] : theme.fontSize[3]}px;
  color: ${({ theme }) => theme.palettes.general.white};
  padding-bottom: ${({ theme }) => theme.spacings[3]}px;
  position: absolute;
  bottom: ${({ $view }) => ($view === ScreenSize.desktop ? 5 : 20)}%;
  right: ${({ $view, theme }) =>
    $view === ScreenSize.desktop ? theme.spacings[5] : theme.spacings[3]}px;
  text-align: right;
`

const AppVersion = styled(Typography)<{ $view: ScreenSize }>`
  display: flex;
  font-size: ${({ $view, theme }) =>
    $view === ScreenSize.desktop ? theme.fontSize[3] : theme.fontSize[2]}px;
  color: ${({ theme }) => theme.palettes.general.white};
  font-style: italic;
  flex-shrink: 1;
  padding-left: ${({ $view, theme }) =>
    $view === ScreenSize.desktop ? theme.spacings[5] : 0}px;
  padding-right: ${({ $view, theme }) =>
    $view === ScreenSize.desktop ? 0 : theme.spacings[3]}px;
  ${({ $view }) =>
    $view === ScreenSize.desktop ? `` : `justify-content: flex-end;`}
`
const ConfigVersion = styled(Typography)<{ $view: ScreenSize }>`
  display: flex;
  font-size: ${({ $view, theme }) =>
    $view === ScreenSize.desktop ? theme.fontSize[3] : theme.fontSize[2]}px;
  color: ${({ theme }) => theme.palettes.general.white};
  font-style: italic;
  flex-grow: 1;
  padding-left: ${({ $view, theme }) =>
    $view === ScreenSize.desktop ? theme.spacings[5] : theme.spacings[3]}px;
`

const VersionContainer = styled.div<{ $view: ScreenSize }>`
  display: flex;
  width: 100%;
  position: absolute;
  bottom: ${({ theme }) => theme.spacings[1]}px;
  ${({ $view }) =>
    $view === ScreenSize.desktop
      ? `flex-direction: column`
      : `flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;`}
`

export const LandingPage = observer(() => {
  const { t } = useTranslation()
  const view = useView()
  const { configStore } = useStores()
  const redirectUri = url.format({
    protocol: window.location.protocol,
    host: window.location.host,
    pathname: '/authorize',
  })

  const windowInnerHeight = useWindowHeight()

  const onLoginPressed = useCallback(() => {
    ;(async (): Promise<void> => {
      const authConfig = { 
        ...configStore.authConfig,
        host : configStore.authConfig.host,
        redirectUri 
      }
      const loginURL = await B2BWEB.login(authConfig, storage)
      window.location.href = loginURL
    })()
  }, [configStore.authConfig, redirectUri])

  return (
    <Background $view={view} $innerHeight={windowInnerHeight}>
      <Ripple $view={view} $innerHeight={windowInnerHeight}>
        <Ellipse $view={view} />
        <MTRLogo $view={view} src={MTRlogo} alt="MTR logo" />
        <DividerContainer $view={view} src={Divider} alt="divider" />
        <FooterContainer $view={view} src={Footer} alt="footer" />
        <TrainIcon $view={view} src={Train} alt="train" />
        <SDOLogo $view={view} src={SDOlogo} alt="SDO logo" />
        <LoginButton
          $view={view}
          variant="contained"
          startIcon={<StyledIcon {...icons.login} />}
          size="large"
          onClick={onLoginPressed}
        >
          <ButtonText>{t(`login`)}</ButtonText>
        </LoginButton>
        {env.environment === 'PROD' ? (
          <Title $view={view}>{t('sdo.full')}</Title>
        ) : (
          <Title $view={view}>{`${t('sdo.full')} (${env.environment})`}</Title>
        )}
        {view === ScreenSize.desktop ? (
          <VersionContainer $view={view}>
            <AppVersion $view={view}>
              {t('metadata.version', {
                version: env.version,
              })}
            </AppVersion>
            <ConfigVersion $view={view}>
              {t('metadata.configVersion', {
                version: configStore.versionText,
              })}
            </ConfigVersion>
          </VersionContainer>
        ) : (
          <VersionContainer $view={view}>
            <ConfigVersion $view={view}>
              {t('metadata.configVersion', {
                version: configStore.versionText,
              })}
            </ConfigVersion>
            <AppVersion $view={view}>
              {t('metadata.version', {
                version: env.version,
              })}
            </AppVersion>
          </VersionContainer>
        )}
      </Ripple>
    </Background>
  )
})
