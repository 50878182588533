import { WIItem } from '@mtr-SDO/apis'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { stringToNumber, stringToString } from '../common-function'

export const WICNWIItemModel = types.model({
  wiNo: types.maybe(types.string),
  version: types.maybe(types.string),
  title: types.maybe(types.string),
  year: types.maybe(types.number),
  month: types.maybe(types.number),
})

export type WICNWIItem = Instance<typeof WICNWIItemModel>

export function mapPayloadToWICNWIItem(
  payload: WIItem[] | undefined,
): SnapshotIn<WICNWIItem[] | []> {
  if (payload == null) return []
  return payload.map((it) => ({
    wiNo: stringToString(it.wicNo),
    version: stringToString(it.versionNo),
    title: stringToString(it.title),
    month: stringToNumber(it.month),
    year: stringToNumber(it.year),
  }))
}
