import { WICNFormDataPayload } from '@mtr-SDO/apis'
import { withRootStore } from '@mtr-SDO/models-core'
import _ from 'lodash'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import {
  mapPayloadToUser,
  mapPayloadToUserList,
  mapPayloadToWIAttachment,
} from '../wi-workflow'
import {
  stringToDate,
  stringToNumber,
  stringToString,
} from '../common-function'
import {
  WICNPartAModel,
  WICNPartBModel,
  WICNPartCModel,
  WICNPartDModel,
  WICNPartEModel,
  WICNPartFModel,
  WICNPartGModel,
} from './wicn-detail-part.model'
import {
  getWICNJHAReportRequired,
  getWICNJHAReportType,
  getWICNQualityCheck,
  getWICNQualityCheckType,
  getWICNRequestType,
  getWICNSignificantImplication,
  getWICNSignificantImplicationArea,
} from './wicn-selection-item'
import { mapPayloadToWICNWIItem } from './wicn-wi-item.model'
import { mapPayloadToWICNWIChildForm } from './wicn-detail-comment.model'

export const WICNDetailModel = types
  .model({
    wicnDetailId: types.optional(types.identifier, uuid),

    partAData: WICNPartAModel,
    partBData: WICNPartBModel,
    partCData: WICNPartCModel,
    partDData: WICNPartDModel,
    partEData: WICNPartEModel,
    partFData: WICNPartFModel,
    partGData: WICNPartGModel,
  })
  .extend(withRootStore)
  .actions((self) => ({
    apply(snapshot: any) {
      _.keys(_.omit(snapshot, 'wicnDetailId')).forEach((key) => {
        if (snapshot[key] == null) {
          return
        }
        ;(self as any)[key] = snapshot[key]
      })
    },
  }))

export type WICNDetail = Instance<typeof WICNDetailModel>

export function mapPayloadToWICNDetail(
  payload: WICNFormDataPayload,
): SnapshotIn<WICNDetail> {
  return {
    wicnDetailId: stringToString(payload.objectId),

    partAData: {
      partId: stringToNumber(payload.partA.partId),
      wicnNo: stringToString(payload.partA.wicnNo),
      date: stringToDate(payload.partA.date),
      assignedUser: mapPayloadToUser(payload.partA.userActionBy),
      drafter: {
        displayName: stringToString(payload.partA.staffName),
        jobTitle: stringToString(payload.partA.title),
      },

      requestType: getWICNRequestType(
        stringToNumber(payload.partA.requestType),
      ),
      wiList: mapPayloadToWICNWIItem(payload.partA.wIlist),
      detailOfChanges: stringToString(payload.partA.details),
      jhaReportRequired: getWICNJHAReportRequired(payload.partA.jhaReport),
      jhaReportType: getWICNJHAReportType(payload.partA.briefOfReason),
      briefOfReason: stringToString(payload.partA.briefOfReasonContent),
      implication: getWICNSignificantImplication(payload.partA.implication),
      implicationArea: getWICNSignificantImplicationArea(
        payload.partA.maintenance,
      ),
      maintenanceContent: stringToString(payload.partA.maintenanceComment),
      implicationDescription: stringToString(payload.partA.implicationDesc),
      changeApprovedBy: stringToString(payload.partA.changedBy),
      approver: stringToString(payload.partA.approver),
      attachments: mapPayloadToWIAttachment(payload.partA.attachments),
    },
    partBData: {
      partId: stringToNumber(payload.approverModel.partId),
      user: {
        displayName: stringToString(payload.approverModel.name),
        jobTitle: stringToString(payload.approverModel.title),
      },
      decision: stringToString(payload.approverModel.decision),
      date: stringToDate(payload.approverModel.date),
      comment: stringToString(payload.approverModel.comment),
    },
    partCData: {
      partId: stringToNumber(payload.intialCheckingModel.partId),
      date: stringToDate(payload.intialCheckingModel.date),
      user: {
        displayName: stringToString(
          payload.intialCheckingModel.approverModel.name,
        ),
        jobTitle: stringToString(
          payload.intialCheckingModel.approverModel.title,
        ),
      },
      decision: stringToString(payload.intialCheckingModel.decision),
      comment: stringToString(
        payload.intialCheckingModel.approverModel.comment,
      ),
      completedBy: stringToString(payload.intialCheckingModel.completionBy),
      issue: stringToNumber(payload.intialCheckingModel.issue),
      revision: stringToNumber(payload.intialCheckingModel.rev),
      revisedWIList: mapPayloadToWICNWIItem(
        payload.intialCheckingModel.revisedWIList,
      ),
      qualityCheck: getWICNQualityCheck(
        stringToNumber(payload.intialCheckingModel.qualityCheck),
      ),
      qualityCheckType: getWICNQualityCheckType(
        stringToNumber(payload.intialCheckingModel.dueToChange),
      ),
    },
    partDData: {
      partId: stringToNumber(payload.smseReviewModel.partId),
      reviewUser: mapPayloadToUser(payload.smseReviewModel.reviewUser),
      requireComment: payload.smseReviewModel.requireComment,
      comment: stringToString(payload.smseReviewModel.comment),
      assignedUserList: mapPayloadToUserList(payload.smseReviewModel.userList),
    },
    partEData: {
      partId: stringToNumber(payload.consolidatedCommentModel.partId),
      commentForms: mapPayloadToWICNWIChildForm(
        payload.consolidatedCommentModel.childFormList,
      ),
    },
    partFData: {
      partId: stringToNumber(payload.smseApprovalModel.partId),
      user: mapPayloadToUser(payload.smseApprovalModel.approvalUser),
      date: stringToDate(payload.smseApprovalModel.approvalDate),
      isAccepted: stringToString(payload.smseApprovalModel.decision),
      comment: stringToString(payload.smseApprovalModel.comment),
      isTemporaryChange: payload.smseApprovalModel.temporaryInstruction,
    },
    partGData: {
      partId: stringToNumber(payload.documentModel.partId),
      date: stringToDate(payload.documentModel.uploadDate),
      remarks: stringToString(payload.documentModel.remarks),
      user: mapPayloadToUser(payload.documentModel.approvalUser),
    },
  }
}
