import { ChangeEvent, useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import url from 'url'
import { B2BWEB } from '@mtr-SDO/oauth'
import { SignInStatusOption } from '@mtr-SDO/datastores'
import { useStores } from '@/models'
import { storage } from '@/local-storage'
import { SystemHeader } from '@/components'

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  padding-top: 25%;
`
const StyledFormControl = styled(FormControl)`
  display: block;
`
const StyledRadioGroup = styled(RadioGroup)`
  width: 240px;
  margin: 22px auto 14px;
  & .MuiFormControlLabel-root * {
    font-size: 16px;
    white-space: nowrap;
  }
`
const StyledButtonGroup = styled.div`
  width: 250px;
  margin: 0 auto;
`
const StyledButton = styled(Button)`
  margin: 8px auto;
  width: 100%;
  text-transform: none;
  border-radius: 20px;
  font-size: 16px;
  &.styledPrimary {
    background-color: #367fa9;
    color: #f8f3f3;
  }
  &.styledDefault {
    background-color: #f2f0f0;
    color: #222121;
  }
`

export const SignInPage = observer(() => {
  const { t } = useTranslation()
  const history = useHistory()
  const { configStore, userOperationStore } = useStores()
  const [radioValue, setRadioValue] = useState(
    userOperationStore.sigiInStatusState,
  )
  const redirectUri = url.format({
    protocol: window.location.protocol,
    host: window.location.host,
    pathname: '/authorize',
  })

  const onLoginPressed = useCallback(() => {
    ;(async (): Promise<void> => {
      const authConfig = {
        ...configStore.authConfig,
        host: configStore.authConfig.host,
        redirectUri,
      }
      const loginURL = await B2BWEB.login(authConfig, storage)
      window.location.href = loginURL
    })()
  }, [configStore.authConfig, redirectUri])

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRadioValue(
      userOperationStore.setSigiInRegisterState(
        (event.target as HTMLInputElement).value as SignInStatusOption,
      ),
    )
  }

  const handleRegister = () => history.push(`/register-page`)

  return (
    <StyledContainer>
      <SystemHeader />
      <StyledFormControl>
        <StyledRadioGroup
          aria-label="SignInStatusRadio"
          name="SignInStatusRadio"
          value={radioValue}
          onChange={handleRadioChange}
        >
          {Object.keys(SignInStatusOption).map((i) => {
            const key = i as keyof typeof SignInStatusOption
            return (
              <FormControlLabel
                key={key}
                value={SignInStatusOption[key]}
                control={<Radio size="small" />}
                label={t(
                  `signInPage.signInStatusOption.${SignInStatusOption[key]}`,
                )}
              />
            )
          })}
        </StyledRadioGroup>
        <StyledButtonGroup>
          <StyledButton
            disableElevation
            variant="contained"
            onClick={onLoginPressed}
            className="styledPrimary"
          >
            {t(`signInPage.sgin`)}
          </StyledButton>
          {radioValue === SignInStatusOption.contractorUser ? (
            <StyledButton
              disableElevation
              variant="contained"
              onClick={handleRegister}
              className="styledDefault"
            >
              {t(`signInPage.register`)}
            </StyledButton>
          ) : (
            ''
          )}
        </StyledButtonGroup>
      </StyledFormControl>
    </StyledContainer>
  )
})
