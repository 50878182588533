export const MEDIA_FOLDER = './media/'
export const ATTACHMENT_FOLDER = './attachment/'

type AllConfig = {
  [key: string]: any
}
export const allConfig: AllConfig = {
  SDO: {
    SIT: {
      apiConfig: {
        url: 'https://eaproxy211.api.mtr.com.hk/sdo-api/v1/api/',
        apiKey: '4943c8c978df42c3a162fb4ab787bcd8',
        timeout: 30000,
      },
      authConfig: {
        clientId: 'd1b9ab1f-d120-4992-96bd-fdc80b42923d',
        tenantId: '97f55d35-1929-4acf-9c11-d7aaf05b6756',
      },
      blobStorageConfig: {
        endpoint: 'https://sdomtrsa201.blob.core.windows.net/',
        timeout: 90000,
      },
      photoQuality: 0.4,
      maxAttachmentByteSize: 412467200,
      timestamp: 1663854992,
      source:
        'https://azusdoa204v-b5bnc0a8cdgcaeg0.z01.azurefd.net/configs/5b7b0005-736b-49e6-a546-ca913b999000',
    },
    UAT: {
        apiConfig: {
            url: 'https://eaproxy111.api.mtr.com.hk/sdo-api/v1/api/',
            apiKey: 'd23b943f7f5a4d9dae90f2c2353107f3',
            timeout: 30000,
        },
        authConfig: {
            clientId: 'fb70ae4c-e6bd-4167-890e-b18a9bdcdf1c',
            tenantId: '97f55d35-1929-4acf-9c11-d7aaf05b6756',
        },
        blobStorageConfig: {
            endpoint: 'https://sdomtrsa101.blob.core.windows.net/',
            timeout: 90000,
        },
        photoQuality: 0.4,
        maxAttachmentByteSize: 412467200,
        timestamp: 1663854992,
        source:
            'https://azusdoa104v-dsdhhrhwhnaxh9eb.z01.azurefd.net/configs/5b7b0005-736b-49e6-a546-ca913b999000',
    },
    PROD: {
        apiConfig: {
            url: 'https://eaproxy011.api.mtr.com.hk/sdo-api/v1/api/',
            apiKey: '78ef975b4eaa4130ae66ed66b3eb195f',
            timeout: 30000,
        },
        authConfig: {
            clientId: 'ff0dfbc4-5298-478a-9855-a19d6a69ae81',
            tenantId: '97f55d35-1929-4acf-9c11-d7aaf05b6756',
        },
        blobStorageConfig: {
            endpoint: 'https://sdomtrsa001.blob.core.windows.net/',
            timeout: 90000,
        },
        photoQuality: 0.4,
        maxAttachmentByteSize: 412467200,
        timestamp: 1663854992,
        source:
            'https://azusdoa004v-f5e9gpg9bpc3aaf0.z01.azurefd.net/configs/5b7b0005-736b-49e6-a546-ca913b999000',
    },
  },
}
