import { Card, CardContent, CardHeader } from '@mui/material'
import { Alert, AlertTitle } from '@mui/lab'
import React, { ErrorInfo } from 'react'
import styled from '@emotion/styled'

type State = {
  errorThrown:
    | { error: Error; info: ErrorInfo; stack: { [key: string]: string } }
    | undefined
}

type Props = {
  children?: JSX.Element | JSX.Element[] | undefined
}

const Root = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.secondaryDark};
`

const StyledAlert = styled(Alert)`
  max-width: 70%;
`

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: {}) {
    super(props)
    this.state = { errorThrown: undefined }
  }

  async componentDidCatch(error: Error, info: React.ErrorInfo): Promise<void> {
    const stack: { [key: string]: string } = {}
    try {
      const regexParan = /[^()]*(\([^()]*\))/g // regular expression to find strings inside param
      const source = info.componentStack.toString()
      let match = regexParan.exec(source)
      while (match) {
        const [wholeMatch, key] = match
        stack[key] = wholeMatch
        match = regexParan.exec(source)
      }
    } catch (err) {
      console.error('Error regex', (err as Error).toString())
    }

    this.setState({ errorThrown: { error, info, stack } })

    // const navStack: string[] = []

    // try {
    //   const { rootStore } = this.state
    //   let { state } = rootStore.navigationStore
    //   while (state) {
    //     console.tron.logImportant({ ...state })
    //     if (state.isTransitioning) {
    //       navStack.push('Transitioning')
    //       break
    //     }
    //     const { index } = state
    //     if (index === undefined) {
    //       navStack.push('No index')
    //       break
    //     }
    //     navStack.push(state.routes[state.index]?.key || 'No index')
    //     state = state.routes[state.index]
    //   }
    // } catch (navError) {
    //   console.tron.display({
    //     name: 'DidCatch Logging',
    //     preview: 'Cannot trace navigation stack',
    //     value: { navStack, navError },
    //   })
    //   console.tron.reportError(navError)
    //   navStack.push('Cannot trace')
    // }
  }

  render(): React.ReactNode {
    const { errorThrown } = this.state
    const { children } = this.props

    if (errorThrown != null) {
      return (
        <Root>
          <StyledAlert severity="error">
            <AlertTitle>Something went wrong.</AlertTitle>
            Please refresh the page and try again. If the issue persist, please
            contact the support with the following detail:
            <Card variant="outlined">
              <CardHeader subheader={JSON.stringify(errorThrown.error)} />
              <CardContent>
                {Object.entries(errorThrown.stack).map((entry) => (
                  <>{`${entry[0]} | ${entry[1]}`}</>
                ))}
              </CardContent>
            </Card>
          </StyledAlert>
        </Root>
      )
    }

    return children
  }
}
