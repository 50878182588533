import { WIReviewItem } from '@mtr-SDO/apis'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { stringToNumber, stringToString } from '../common-function'
import {
  getWIReviewItemType,
  WIReviewItemTypeEnum,
} from './wi-review-selection-item'

export const WIReviewPartBItemModel = types.model({
  id: types.maybe(types.number),
  title: types.maybe(types.string),
  description: types.maybe(types.string),
  itemValue: types.maybe(WIReviewItemTypeEnum),
  remarks: types.maybe(types.string),
})

export type WIReviewPartBItem = Instance<typeof WIReviewPartBItemModel>

export function mapPayloadToWIReviewItem(
  payload: WIReviewItem[] | undefined,
): SnapshotIn<WIReviewPartBItem[] | undefined> {
  if (payload == null) return undefined
  return payload.map((it) => ({
    id: stringToNumber(it.itemId),
    title: stringToString(it.title),
    description: stringToString(it.description),
    itemValue: getWIReviewItemType(it.reviewed, it.resultNA),
    remarks: stringToString(it.remarks),
  }))
}
