export enum BuilderDomNodeEnums {
  // layouts
  laytable = 'laytable',
  laythead = 'laythead',
  laytbody = 'laytbody',
  laytfoot = 'laytfoot',
  layth = 'layth',
  laytr = 'laytr',
  laytd = 'laytd',
  // fields
  fieldtext = 'fieldtext',
  fieldradio = 'fieldradio',
  fieldcheckbox = 'fieldcheckbox',
  fieldyesicon = 'fieldyesicon',
  fieldyestext = 'fieldyestext',
  fieldlist = 'fieldlist',
  fieldattachment = 'fieldattachment',
  fieldsignature = 'fieldsignature',
  // media
  mediaimg = 'mediaimg',
}
