import * as languagePacks from '@mtr-SDO/language-packs'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import _ from 'lodash'
import { initReactI18next } from 'react-i18next'
import { env } from '../../utils'

export const defaultNS = 'common'
export const resources = {
  en: { common: languagePacks.en.common },
} as const

export function initI18n() {
  return i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      ns: Object.values(resources).reduce(
        (acc, it) => _.uniq(acc.concat(Object.keys(it))),
        [] as string[],
      ),
      defaultNS,

      fallbackLng: Object.keys(resources)[0],

      resources,

      debug: env.nodeEnvironment === 'development',

      interpolation: {
        escapeValue: false,
      },
    })
}
