import { withEnvironment } from '@mtr-SDO/models-core'
import { Instance, types } from 'mobx-state-tree'
import { WIWorkflowPartModel } from '../wi-workflow'

export const WICNWorkflowModel = types
  .model({
    partAWorkflow: WIWorkflowPartModel,
    partBWorkflow: WIWorkflowPartModel,
    partCWorkflow: WIWorkflowPartModel,
    partDWorkflow: WIWorkflowPartModel,
    partEWorkflow: WIWorkflowPartModel,
    partFWorkflow: WIWorkflowPartModel,
    partGWorkflow: WIWorkflowPartModel,
  })
  .extend(withEnvironment)

export type WICNWorkflow = Instance<typeof WICNWorkflowModel>
