import { types } from 'mobx-state-tree'

export enum TaskType {
  wiReview = 'wiReview',
  wicn = 'wicn',
}

export function getFormTypeByCode(code: number | null | undefined): TaskType {
  switch (code) {
    case 1:
      return TaskType.wiReview
    case 2:
      return TaskType.wicn

    default:
      return TaskType.wiReview
  }
}

export const FormTypeEnum = types.enumeration(Object.values(TaskType))
