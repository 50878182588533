import { withRootStore } from '@mtr-SDO/models-core'
import { Instance, types } from 'mobx-state-tree'

export const FormDefinitionItemQualificationModel = types
  .model({
    cqaCode: types.array(types.string),
    value: types.union(types.string, types.array(types.string), types.number),
  })
  .extend(withRootStore)
  .views((self) => ({
    get isQualified() {
      const { qualifications } = self.rootStore.userProfileStore
      return self.cqaCode.reduce(
        (acc, cqa) => acc && qualifications.includes(cqa),
        true,
      )
    },
  }))

export type FormDefinitionItemQualification = Instance<
  typeof FormDefinitionItemQualificationModel
>
