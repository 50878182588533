import { types } from 'mobx-state-tree'

export type NullableNumber = number | null | undefined

export enum WICNRequestType {
  revise = 'revise',
  new = 'new',
}

export function getWICNRequestType(
  code: NullableNumber,
): WICNRequestType | undefined {
  switch (code) {
    case 1:
      return WICNRequestType.revise
    case 2:
      return WICNRequestType.new
    default:
      return undefined
  }
}

export enum WICNJHAReportRequired {
  required = 'required',
  notRequired = 'not-required',
}

export function getWICNJHAReportRequired(
  code: NullableNumber,
): WICNJHAReportRequired | undefined {
  switch (code) {
    case 1:
      return WICNJHAReportRequired.required
    case 2:
      return WICNJHAReportRequired.notRequired
    default:
      return undefined
  }
}

export enum WICNJHAReportType {
  newReport = 'new-report',
  amendedReport = 'amended-report',
}

export function getWICNJHAReportType(
  code: NullableNumber,
): WICNJHAReportType | undefined {
  switch (code) {
    case 1:
      return WICNJHAReportType.newReport
    case 2:
      return WICNJHAReportType.amendedReport
    default:
      return undefined
  }
}

export enum WICNSignificantImplication {
  yes = 'yes',
  no = 'no',
}

export function getWICNSignificantImplication(
  code: NullableNumber,
): WICNSignificantImplication | undefined {
  switch (code) {
    case 1:
      return WICNSignificantImplication.yes
    case 2:
      return WICNSignificantImplication.no
    default:
      return undefined
  }
}

export enum WICNSignificantImplicationArea {
  trainingQualification = 'training-and-qualification',
  maintenanceRegime = 'maintenance-regime',
  others = 'others',
}

export function getWICNSignificantImplicationArea(
  code: NullableNumber,
): WICNSignificantImplicationArea | undefined {
  switch (code) {
    case 1:
      return WICNSignificantImplicationArea.trainingQualification
    case 2:
      return WICNSignificantImplicationArea.maintenanceRegime
    case 3:
      return WICNSignificantImplicationArea.others
    default:
      return undefined
  }
}

export enum WICNQualityCheck {
  yes = 'yes',
  no = 'no',
}

export function getWICNQualityCheck(
  code: NullableNumber,
): WICNQualityCheck | undefined {
  switch (code) {
    case 1:
      return WICNQualityCheck.no
    case 2:
      return WICNQualityCheck.yes
    default:
      return undefined
  }
}

export function getCodeByWICNQualityCheck(
  qualityCheck: WICNQualityCheck | undefined,
): number | undefined {
  switch (qualityCheck) {
    case WICNQualityCheck.no:
      return 1
    case WICNQualityCheck.yes:
      return 2
    case undefined:
      return undefined
    default:
      return 1
  }
}

export enum WICNQualityCheckType {
  imputeToNegligence = 'impute-to-negligence',
  dueToChange = 'due-to-change',
}

export function getWICNQualityCheckType(
  code: NullableNumber,
): WICNQualityCheckType | undefined {
  switch (code) {
    case 1:
      return WICNQualityCheckType.imputeToNegligence
    case 2:
      return WICNQualityCheckType.dueToChange
    default:
      return undefined
  }
}

export function getCodeByWICNQualityCheckType(
  qualityCheckType: WICNQualityCheckType | undefined,
): number | undefined {
  switch (qualityCheckType) {
    case WICNQualityCheckType.imputeToNegligence:
      return 1
    case WICNQualityCheckType.dueToChange:
      return 2
    case undefined:
      return undefined
    default:
      return 1
  }
}

export const WICNRequestTypeEnum = types.enumeration(
  Object.values(WICNRequestType),
)
export const WICNJHAReportRequiredEnum = types.enumeration(
  Object.values(WICNJHAReportRequired),
)
export const WICNJHAReportTypeEnum = types.enumeration(
  Object.values(WICNJHAReportType),
)
export const WICNSignificantImplicationEnum = types.enumeration(
  Object.values(WICNSignificantImplication),
)
export const WICNSignificantImplicationAreaEnum = types.enumeration(
  Object.values(WICNSignificantImplicationArea),
)
export const WICNQualityCheckEnum = types.enumeration(
  Object.values(WICNQualityCheck),
)
export const WICNQualityCheckTypeEnum = types.enumeration(
  Object.values(WICNQualityCheckType),
)
