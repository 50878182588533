import _ from 'lodash'
import { types, Instance } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import { WorkNatureModel } from './work-nature.model'

export const StandardJobModel = types
  .model('StandardJob', {
    id: types.optional(types.identifier, uuid),
    code: types.string,
    title: types.maybeNull(types.string),
    nature: types.reference(WorkNatureModel),
  })
  .actions((self) => ({
    apply(snapshot: {}) {
      _.keys(_.omit(snapshot, 'id')).forEach((key) => {
        if (snapshot[key] == null) return
        self[key] = snapshot[key]
      })
    },
    setTitle(title: string) {
      self.title = title
    },
  }))

export type StandardJob = Instance<typeof StandardJobModel>
