export enum WorkOrderPriority {
  pending = 'pending',
  upcoming = 'upcoming',
  overdue = 'overdue',
}

export function workOrderPriorityToValue(
  priority: WorkOrderPriority | undefined,
): number {
  switch (priority) {
    case WorkOrderPriority.overdue:
      return 10000
    case WorkOrderPriority.upcoming:
      return 100
    case WorkOrderPriority.pending:
      return 1
    default:
      return 0
  }
}

export function workOrderPriorityComparator(
  a: WorkOrderPriority | undefined,
  b: WorkOrderPriority | undefined,
): number {
  return workOrderPriorityToValue(b) - workOrderPriorityToValue(a)
}
