import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { SidebarFooter } from './sidebar-footer'
import { SidebarList } from './sidebar-list'
import { SidebarHeader } from './sidebar-header'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`

const StyledSidebarFooter = styled(SidebarFooter)`
  padding-left: 0px;
  padding-right: 0px;
`

export const SidebarDrawer = observer(() => (
  <Root>
    <SidebarHeader />
    <SidebarList />
    <StyledSidebarFooter />
  </Root>
))
