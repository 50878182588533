import {
  HomePage,
  TestingPage,
  UserInformationPage,
} from './pages'

export type RouteItem = {
  key: string
  path: string
  component: React.FunctionComponent
}

export const routeItems: RouteItem[] = [
  {
    key: 'home',
    path: '/home',
    component: HomePage,
  },
  {
    key: 'testing1',
    path: '/testing1',
    component: TestingPage,
  },
  {
    key: 'user-information-page',
    path: '/user-information-page',
    component: UserInformationPage,
  },
]

export const routeDict: { [key: string]: RouteItem } = routeItems.reduce(
  (acc, it) => Object.assign(acc, { [it.key]: it }),
  {},
)
