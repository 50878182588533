import { useTheme as useThemeEmotion } from '@emotion/react'
import { Theme as ThemeEmotion } from './theme.types'

// add custom theme to web use
declare module '@emotion/react' {
  interface Theme extends ThemeEmotion {}
}

export function useTheme(){
  return useThemeEmotion()
}
