import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import { flow, Instance, types } from 'mobx-state-tree'
import path from 'path'
import { v4 as uuid } from 'uuid'

type UploadProps = {
  title: string
  mime: string
  type: string | undefined
}

export const NetworkFileModel = types
  .model('NetworkFile', {
    filename: types.maybe(types.string),
    url: types.maybe(types.string),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .volatile(() => ({
    downloadProgress: 0,
    uploadProgress: 0,
    uploading: false,
  }))
  .views((self) => ({
    status() {
      if (self.environment.storageHandler?.paths == null) {
        throw new Error('unsupported')
      }
      if (self.filename == null) return null

      return self.environment.storageHandler?.binary?.getStatus(self.filename)
    },
    get decryptedPath() {
      if (self.environment.storageHandler?.paths == null) {
        throw new Error('unsupported')
      }
      if (self.filename == null) return undefined

      return self.environment.storageHandler.paths.getFilepath(
        self.filename,
        'decrypted',
      )
    },
  }))
  .actions((self) => {
    const actions = {
      // after create to init model 
      initialize({ url, filename }: { url?: string; filename?: string }) {
        self.url = url && actions.convertUrl(url)
        self.filename = filename
      },
      // convert url replace to now client environment blob storage domain name
      convertUrl(url : string) : string{
        const reg = /^http(s)?:\/\/(.*?).blob.core.windows.net\//
        return url.replace(reg, self.rootStore.configStore.blobStorageConfig.endpoint)
      },
      setDownloadProgress(progress: number) {
        self.downloadProgress = progress
      },
      setUploadProgress(progress: number) {
        self.uploadProgress = progress
      },

      decrypt: flow(function* decrypt() {
        try {
          if (self.environment.storageHandler?.binary == null) {
            throw Error('unsupported storage')
          }

          if (!self.filename) throw new Error('filename empty')

          const { decrypted } =
            yield self.environment.storageHandler.binary.getStatus(
              self.filename,
            )

          if (decrypted) return

          yield self.environment.storageHandler.binary.decrypt(self.filename)
        } catch (error) {
          self.environment.console.reportError(error)
          self.environment.console.warn(
            'Cannot decrypt file to temp',
            {
              ...self,
            },
            error,
          )
        }
      }),
      async isLocalFileExist() {
        if (self.environment.storageHandler?.binary == null) return false
        if (self.filename == null) return false
        const { decrypted } =
          await self.environment.storageHandler.binary.getStatus(self.filename)
        return decrypted
      },
    }
    return actions
  })
  .views((self) => ({
    // attachment sas token url
    get sasTokenUrl() {
      if(self.url?.includes("?")){
        return self.url
      }
      return self.url ? 
      `${self.convertUrl(self.url)}?${self.rootStore.userProfileStore.sessionInfo.azureSasToken}`:''
    },
  }))
  .actions((self) => ({
    upload: flow(function* upload(props: UploadProps) {
      try {
        self.uploading = true
        if (self.url) return self.url // no need to re-upload

        if (self.filename == null) throw new Error('local file not exist')

        if (
          self.environment.storageHandler?.binary == null ||
          self.environment.api.blobHandler == null
        ) {
          return undefined
        }
        const { decrypted }: { decrypted: boolean } =
          yield self.environment.storageHandler.binary.getStatus(self.filename)
        if (!decrypted) yield self.decrypt()
        const res: string = yield self.environment.api.uploadAttachment(
          {
            ...self,
            ...props,
            platform: 'app',
            temporaryPath: self.decryptedPath as string,
          },
          self.rootStore.configStore.blobStorageConfig,
          (written: number, total: number) => {
            self.setUploadProgress(written / total)
          },
        )
        self.url = res
        return self.url
      } finally {
        self.uploading = false
      }
    }),
    download: flow(function* download() {
      let downloadStatus
      try {
        if (!self.url) throw Error('no-url')

        if (
          self.environment.storageHandler?.binary == null ||
          self.environment.api.blobHandler == null
        ) {
          return
        }

        if (self.filename == null) {
          self.filename = uuid() + path.extname(self.url)
        }

        const {
          decrypted,
          encrypted,
        }: { decrypted: boolean; encrypted: boolean } =
          yield self.environment.storageHandler.binary.getStatus(self.filename)

        if (!decrypted) {
          yield self.environment.api.blobHandler
          // this way need to use sasTokenUrl to download file
            .get(self.sasTokenUrl, undefined, self.decryptedPath)
            .progress!((recv: number, total: number) => self.setDownloadProgress(recv / total))
        }

        if (!encrypted) {
          yield self.environment.storageHandler.binary.encrypt(self.filename, {
            keepOriginalFile: true,
          })
        }
      } catch (error) {
        self.environment.console.warn(
          'attachment download failed',
          error,
          self,
          'download job: ',
          // job,
          'download status',
          downloadStatus,
        )
        throw error
      }
    })
  }))

export type NetworkFile = Instance<typeof NetworkFileModel>
