import { types } from 'mobx-state-tree'
import { NullableNumber } from '../wi-change-notice/wicn-selection-item'

export enum WIReviewTaskType {
  review = 'review',
  newDraft = 'new-draft',
  amendment = 'amendment',
}

export function getWIReviewTaskType(
  code: string | null | undefined,
): WIReviewTaskType | undefined {
  switch (code) {
    case '1':
      return WIReviewTaskType.newDraft
    case '2':
      return WIReviewTaskType.review
    case '3':
      return WIReviewTaskType.amendment
    default:
      return undefined
  }
}

export enum WIReviewWIType {
  review = 'review',
  newDraft = 'new-draft',
}

export function getWIReviewWIType(
  isReviewWI: boolean,
  isNewWI: boolean,
): WIReviewWIType | undefined {
  if (isReviewWI) return WIReviewWIType.review
  if (isNewWI) return WIReviewWIType.newDraft
  return undefined
}

export enum WIReviewItemType {
  review = 'review',
  na = 'na',
}

export function getWIReviewItemType(
  isReviewed: boolean,
  naResult: boolean,
): WIReviewItemType | undefined {
  if (isReviewed) return WIReviewItemType.review
  if (naResult) return WIReviewItemType.na
  return undefined
}

export enum WIReviewPartBConclusion {
  noChangeRequired = 'no-change-required',
  changeRequired = 'change-required',
  amendmentRequired = 'amendment-required',
}

export function getWIReviewPartBConclusion(
  noChangeRequired: boolean,
  changeRequired: boolean,
  amendmentRequired: boolean,
): WIReviewPartBConclusion | undefined {
  if (noChangeRequired) return WIReviewPartBConclusion.noChangeRequired
  if (changeRequired) return WIReviewPartBConclusion.changeRequired
  if (amendmentRequired) return WIReviewPartBConclusion.amendmentRequired
  return undefined
}

export enum WIReviewPartBCommentRequired {
  true = 'true',
  false = 'false',
}

export function getWIReviewPartBCommentRequired(
  code: NullableNumber,
): WIReviewPartBCommentRequired | undefined {
  switch (code) {
    case 1:
      return WIReviewPartBCommentRequired.true
    case 2:
      return WIReviewPartBCommentRequired.false
    default:
      return undefined
  }
}

export enum WIReviewCommentPartA {
  noObjection = 'no-objection',
  commentTyped = 'comment-typed',
  commentAttached = 'comment-attached',
}

export function getWIReviewCommentPartA(
  code: NullableNumber,
): WIReviewCommentPartA | undefined {
  switch (code) {
    case 1:
      return WIReviewCommentPartA.noObjection
    case 2:
      return WIReviewCommentPartA.commentTyped
    case 3:
      return WIReviewCommentPartA.commentAttached
    default:
      return undefined
  }
}

export function getCodeByWIReviewCommentPartA(
  value: WIReviewCommentPartA | undefined,
): number | undefined {
  switch (value) {
    case WIReviewCommentPartA.noObjection:
      return 1
    case WIReviewCommentPartA.commentTyped:
      return 2
    case WIReviewCommentPartA.commentAttached:
      return 3
    default:
      return undefined
  }
}

export enum WIReviewCommentPartB {
  noObjection = 'no-objection',
  readyToImplement = 'ready-to-implement',
}

export function getWIReviewCommentPartB(
  code: NullableNumber,
): WIReviewCommentPartB | undefined {
  switch (code) {
    case 1:
      return WIReviewCommentPartB.noObjection
    case 2:
      return WIReviewCommentPartB.readyToImplement
    default:
      return undefined
  }
}

export function getCodeByWIReviewCommentPartB(
  value: WIReviewCommentPartB | undefined,
): number | undefined {
  switch (value) {
    case WIReviewCommentPartB.noObjection:
      return 1
    case WIReviewCommentPartB.readyToImplement:
      return 2
    default:
      return undefined
  }
}

export enum WIReviewCommentPartC {
  completedWithoutHazard = 'completed-without-hazard',
  completedWithHazard = 'completed-with-hazard',
  valid = 'valid',
}

export function getWIReviewCommentPartC(
  code: NullableNumber,
): WIReviewCommentPartC | undefined {
  switch (code) {
    case 1:
      return WIReviewCommentPartC.completedWithoutHazard
    case 2:
      return WIReviewCommentPartC.completedWithHazard
    case 3:
      return WIReviewCommentPartC.valid
    default:
      return undefined
  }
}

export function getCodeByWIReviewCommentPartC(
  value: WIReviewCommentPartC | undefined,
): number | undefined {
  switch (value) {
    case WIReviewCommentPartC.completedWithoutHazard:
      return 1
    case WIReviewCommentPartC.completedWithHazard:
      return 2
    case WIReviewCommentPartC.valid:
      return 3
    default:
      return undefined
  }
}

export enum WIReviewReadyForApproval {
  readyForApproval = 'ready-for-approval',
  rejected = 'rejected',
}

export enum WIReviewEndorsed {
  endorsed = 'approve',
  rejected = 'reject',
}

export enum WIReviewPartJWIResult {
  newIssue = 'new-issue',
  review = 'review',
  noChange = 'no-change',
  delete = 'delete',
}

export function getWIReviewPartJWIResult(
  newIssue: boolean,
  review: boolean,
  noChange: boolean,
  deleted: boolean,
): WIReviewPartJWIResult | undefined {
  if (newIssue) return WIReviewPartJWIResult.newIssue
  if (review) return WIReviewPartJWIResult.review
  if (noChange) return WIReviewPartJWIResult.noChange
  if (deleted) return WIReviewPartJWIResult.delete
  return undefined
}

export function getWIReviewPartJWIResultByValue(
  value: string | undefined,
): WIReviewPartJWIResult | undefined {
  switch (value) {
    case 'new-issue':
      return WIReviewPartJWIResult.newIssue
    case 'review':
      return WIReviewPartJWIResult.review
    case 'no-change':
      return WIReviewPartJWIResult.noChange
    case 'delete':
      return WIReviewPartJWIResult.delete
    default:
      return undefined
  }
}

export enum WIReviewPartJRecordFormResult {
  update = 'update',
  na = 'na',
  noChange = 'no-change',
  delete = 'delete',
}

export function getWIReviewPartJRecordFormResult(
  update: boolean,
  na: boolean,
  noChange: boolean,
  deleted: boolean,
): WIReviewPartJRecordFormResult | undefined {
  if (update) return WIReviewPartJRecordFormResult.update
  if (na) return WIReviewPartJRecordFormResult.na
  if (noChange) return WIReviewPartJRecordFormResult.noChange
  if (deleted) return WIReviewPartJRecordFormResult.delete
  return undefined
}

export function getWIReviewPartJRecordFormResultByValue(
  value: string | undefined,
): WIReviewPartJRecordFormResult | undefined {
  switch (value) {
    case 'update':
      return WIReviewPartJRecordFormResult.update
    case 'na':
      return WIReviewPartJRecordFormResult.na
    case 'no-change':
      return WIReviewPartJRecordFormResult.noChange
    case 'delete':
      return WIReviewPartJRecordFormResult.delete
    default:
      return undefined
  }
}

export enum WIReviewUploaded {
  yes = 'yes',
  noChange = 'no-change',
  deleted = 'deleted',
}

export function getWIReviewUploaded(
  yes: boolean,
  noChange: boolean,
  deleted: boolean,
): WIReviewUploaded | undefined {
  if (yes) return WIReviewUploaded.yes
  if (noChange) return WIReviewUploaded.noChange
  if (deleted) return WIReviewUploaded.deleted
  return undefined
}

export function getWIReviewUploadedByValue(
  value: string | undefined,
): WIReviewUploaded | undefined {
  switch (value) {
    case 'yes':
      return WIReviewUploaded.yes
    case 'no-change':
      return WIReviewUploaded.noChange
    case 'deleted':
      return WIReviewUploaded.deleted
    default:
      return undefined
  }
}

export enum WIReviewPartDDecision {
  skipComment = 'skip-comment',
  initiateComment = 'initiate-comment',
}

export enum WIReviewPartEIdentity {
  implementor = 'implementor',
  nonImplementor = 'non-implementor',
}

export function getWIReviewPartEIdentity(
  isImplementor: boolean | undefined,
): WIReviewPartEIdentity | undefined {
  switch (isImplementor) {
    case true:
      return WIReviewPartEIdentity.implementor
    case false:
      return WIReviewPartEIdentity.nonImplementor
    default:
      return undefined
  }
}

export const WIReviewTaskTypeEnum = types.enumeration(
  Object.values(WIReviewTaskType),
)
export const WIReviewWITypeEnum = types.enumeration(
  Object.values(WIReviewWIType),
)
export const WIReviewPartBConclusionEnum = types.enumeration(
  Object.values(WIReviewPartBConclusion),
)
export const WIReviewItemTypeEnum = types.enumeration(
  Object.values(WIReviewItemType),
)
export const WIReviewPartBCommentRequiredEnum = types.enumeration(
  Object.values(WIReviewPartBCommentRequired),
)
export const WIReviewCommentPartAEnum = types.enumeration(
  Object.values(WIReviewCommentPartA),
)
export const WIReviewCommentPartBEnum = types.enumeration(
  Object.values(WIReviewCommentPartB),
)
export const WIReviewCommentPartCEnum = types.enumeration(
  Object.values(WIReviewCommentPartC),
)
export const WIReviewEndorsedEnum = types.enumeration(
  Object.values(WIReviewEndorsed),
)
export const WIReviewPartJWIResultEnum = types.enumeration(
  Object.values(WIReviewPartJWIResult),
)
export const WIReviewPartJRecordFormResultEnum = types.enumeration(
  Object.values(WIReviewPartJRecordFormResult),
)
export const WIReviewUploadedEnum = types.enumeration(
  Object.values(WIReviewUploaded),
)
export const WIReviewPartEIdentityEnum = types.enumeration(
  Object.values(WIReviewPartEIdentity),
)
