import { types, Instance } from 'mobx-state-tree'

export const MasterDataRecordModel = types.model({
  remoteId: types.string,
  value: types.string,
})

export type MasterDataRecord = Instance<typeof MasterDataRecordModel>

export const MasterDataModel = types.model({
  id: types.identifier,
  records: types.array(MasterDataRecordModel),
})

export type MasterData = Instance<typeof MasterDataModel>
