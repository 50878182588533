import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import styled from '@emotion/styled'

import MTRlogo from '../../image/mtr-logo.png'
import { env } from '../../utils'

const StyledSidebarHeader = styled.div`
  color: ${({ theme }) => theme.palettes.general.white};
  text-align: center;
  padding: ${({ theme }) => theme.spacings[6]}px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
`

const StyledImage = styled.img`
  width: 90px;
  height: auto;
`

const Title = styled(Typography)`
  font-size: ${({ theme }) => theme.fontSize[10]}px;
`

export const SidebarHeader = observer(() => {
  const { t } = useTranslation()

  return (
    <StyledSidebarHeader>
      <StyledImage src={MTRlogo} alt="MTR logo" />
      <Title noWrap>
        {t(`${env.target.toLowerCase() as 'sdo'}.short`)}
      </Title>
    </StyledSidebarHeader>
  )
})
