import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import {
  flow,
  Instance,
  types,
} from 'mobx-state-tree'
import { GetDepotList, GetVehicleList, GetWorkTypeList, OrderBookItemType, PostCreateOrderBookItem } from '@mtr-SDO/apis'

export const OrderBookStoreModel = types
  .model('OrderBookStore', {
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .actions((self) => {
    const actions = {
      fetchDepotList: flow(function* () {
        const res: GetDepotList = yield self.environment.api.orderBook.getDepotList()
        if (res.kind !== 'ok') throw new Error(`fetch-depot-list|${res.kind}`)
        return res.payload
      }),
      fetchWorkTypeListByDepotID: flow(function* (depotID: number) {
        const res: GetWorkTypeList = yield self.environment.api.orderBook.getWorkTypeListByDepotID(depotID)
        if (res.kind !== 'ok') throw new Error(`fetch-work-type-list|${res.kind}`)
        return res.payload
      }),
      fetchVehicleListByDepotID: flow(function* (depotID: number) {
        const res: GetVehicleList = yield self.environment.api.orderBook.getVehicleListByDepotID(depotID)
        if (res.kind !== 'ok') throw new Error(`fetch-vehicle-list|${res.kind}`)
        return res.payload
      }),
      createOrderBookItem: flow(function* (orderBookItem: OrderBookItemType) {
        const res: PostCreateOrderBookItem = yield self.environment.api.orderBook.createOrderBookItem(orderBookItem)
        if (res.kind !== 'ok') throw new Error(`create-order-book-item|${res.kind}`)
        if (!res.payload.success || res.payload.data == null)
          throw new Error(`create-order-book-item|${res.payload.errorMessage}`)
        return res
      }),
    }
    return actions
  })

export type OrderBookStore = Instance<typeof OrderBookStoreModel>
