import { useQuery, UseQueryOptions } from 'react-query'
import { useStores } from '../models'

const QUERY_CACHE_KEY = 'health-check'

export const useServerHealthCheck = (opts?: UseQueryOptions<string>) => {
  const rootStore = useStores()
  return useQuery(
    [QUERY_CACHE_KEY, 'server'],
    async () => {
      const res = await rootStore.environment.api.healthChecks.getServer()
      return res.payload
    },
    opts,
  )
}

export const useCommonServerHealthCheck = (opts?: UseQueryOptions<string>) => {
  const rootStore = useStores()
  return useQuery(
    [QUERY_CACHE_KEY, 'common-server'],
    async () => {
      const res = await rootStore.environment.api.healthChecks.getCommonServer()
      return res.payload
    },
    opts,
  )
}
