import { Instance, types } from 'mobx-state-tree'

export const UserInfoModel = types
  .model({
    id: types.number,
    username: types.optional(types.string, '??'),
    email: types.maybe(types.string),
    upn: types.string,
    trainStockTypeIds: types.array(types.string),
    title: types.maybe(types.string),
    wiProcessGroup: types.maybe(types.string),
    department: types.maybe(types.string),
    contactNumber: types.maybe(types.string),
    HKIDFirst3: types.maybe(types.string),
    sntn: types.maybe(types.string),
    roleName: types.maybe(types.string),
    companyName: types.maybe(types.string),
    userType: types.maybe(types.string),
  })
  .views((self) => ({
    get displayName() {
      return self.username || self.upn
    },
  }))

export type UserInfo = Instance<typeof UserInfoModel>
