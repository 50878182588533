import _ from 'lodash'
import { getSnapshot, Instance, types } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'

export const WorkInstructionModel = types
  .model('WorkInstruction', {
    id: types.optional(types.identifier, uuid),
    remoteId: types.string,
    workInstructionNumber: types.string,
    workInstructionName: types.string,
    formType: types.maybe(
      types.union(types.literal('Normal'), types.literal('Special')),
    ), // either Normal / Special
    issueDate: types.maybe(types.string),
    publishDate: types.maybe(types.string),
    issueNumber: types.maybe(types.string),
    revisionNumber: types.maybe(types.string),
  })
  .actions((self) => ({
    apply(snapshot: {}) {
      _.keys(_.omit(snapshot, 'id')).forEach((key) => {
        if (snapshot[key] == null) return
        self[key] = snapshot[key]
      })
    },
  }))
  .views((self) => ({
    get cachePayload() {
      return _.omit(getSnapshot(self), ['id'])
    },
  }))

export type WorkInstruction = Instance<typeof WorkInstructionModel>
