import { WIReviewChildForm } from '@mtr-SDO/apis'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import {
  AttachmentModel,
  mapPayloadToUser,
  mapPayloadToWIAttachment,
  UserModel,
} from '../wi-workflow'
import {
  stringToDate,
  stringToNumber,
  stringToString,
} from '../common-function'
import {
  getWIReviewCommentPartA,
  getWIReviewCommentPartB,
  getWIReviewCommentPartC,
  getWIReviewPartEIdentity,
  WIReviewCommentPartAEnum,
  WIReviewCommentPartBEnum,
  WIReviewCommentPartCEnum,
  WIReviewPartEIdentityEnum,
} from './wi-review-selection-item'

const WIReviewCommentSubFormModel = types.model({
  isImplementor: types.maybe(WIReviewPartEIdentityEnum),
  partACommentType: types.maybe(WIReviewCommentPartAEnum),
  partACommentOnWI: types.maybe(types.string),
  attachmentList: types.array(AttachmentModel),
  partBImplementationType: types.maybe(WIReviewCommentPartBEnum),
  implementationDateBy: types.maybe(types.Date),
  partCJobHazardAnalysisType: types.maybe(WIReviewCommentPartCEnum),
  partCAttachments: types.array(AttachmentModel),
})

export const WIReviewCommentFormModel = types.model({
  id: types.maybe(types.number),
  subFormId: types.optional(types.identifier, uuid),
  user: types.maybe(UserModel),
  comment: types.maybe(types.string),
  response: types.maybe(types.string),
  subForm: WIReviewCommentSubFormModel,
  isApproved: types.boolean,
})

export type WIReviewCommentForm = Instance<typeof WIReviewCommentFormModel>

export function mapPayloadToWIReviewCommentForm(
  payload: WIReviewChildForm[] | undefined,
): SnapshotIn<WIReviewCommentForm[] | undefined> {
  if (payload == null) return undefined
  return payload.map((it) => ({
    id: stringToNumber(it.id),
    subFormId: stringToString(it.subFormId),
    user: mapPayloadToUser(it.stakeholds),
    comment: stringToString(it.comments, true),
    response: stringToString(it.response, true),
    subForm: {
      isImplementor: getWIReviewPartEIdentity(
        it.partEChildFormData.isImplementor,
      ),
      partACommentType: getWIReviewCommentPartA(
        it.partEChildFormData.isCheckedRadio,
      ),
      partACommentOnWI: stringToString(it.partEChildFormData.myComments),
      attachmentList: mapPayloadToWIAttachment(
        it.partEChildFormData.attachments,
      ),
      partBImplementationType: getWIReviewCommentPartB(
        it.partEChildFormData.isCheckedRadioPartB,
      ),
      implementationDateBy: stringToDate(
        it.partEChildFormData.implementationByDate,
      ),
      partCJobHazardAnalysisType: getWIReviewCommentPartC(
        it.partEChildFormData.isCheckedRadioPartC,
      ),
      partCAttachments: mapPayloadToWIAttachment(
        it.partEChildFormData.partCAttachments,
      ),
    },
    isApproved: it.isApprove,
  }))
}
