import { types } from 'mobx-state-tree'

export enum WIReviewStatus {
  partA = 'part-a',
  partB = 'part-b',
  partC = 'part-c',
  partD = 'part-d',
  partE = 'part-e',
  partF = 'part-f',
  partG = 'part-g',
  partH = 'part-h',
  partI = 'part-i',
  partJ = 'part-j',
  partK = 'part-k',
  comment = 'comment',
  completed = 'completed',
}

export enum WiReviewNodeId {
  partB = 2,
  partC = 3,
  partD = 4,
  partE = 5,
  partF = 6,
  partG = 7,
  partH = 8,
  partI = 9,
  partJ = 10,
  partK = 11,
}

export function getWIReviewSnapShotStatus(
  code: number | undefined,
): WIReviewStatus {
  switch (code) {
    case 1:
      return WIReviewStatus.partA
    case 2:
      return WIReviewStatus.partB
    case 3:
      return WIReviewStatus.partC
    case 4:
      return WIReviewStatus.partD
    case 5:
      return WIReviewStatus.partE
    case 6:
      return WIReviewStatus.partF
    case 7:
      return WIReviewStatus.partG
    case 8:
      return WIReviewStatus.partH
    case 9:
      return WIReviewStatus.partI
    case 10:
      return WIReviewStatus.partJ
    case 11:
      return WIReviewStatus.partK
    case 111:
      return WIReviewStatus.completed
    case 1001:
      return WIReviewStatus.comment
    default:
      return WIReviewStatus.partA
  }
}

export function getWIReviewStatusAbbreviation(status: WIReviewStatus): string {
  switch (status) {
    case WIReviewStatus.partA:
      return 'A'
    case WIReviewStatus.partB:
      return 'B'
    case WIReviewStatus.partC:
      return 'C'
    case WIReviewStatus.partD:
      return 'D'
    case WIReviewStatus.partE:
      return 'E'
    case WIReviewStatus.partF:
      return 'F'
    case WIReviewStatus.partG:
      return 'G'
    case WIReviewStatus.partH:
      return 'H'
    case WIReviewStatus.partI:
      return 'I'
    case WIReviewStatus.partJ:
      return 'J'
    case WIReviewStatus.partK:
      return 'K'
    case WIReviewStatus.comment:
      return 'E'
    default:
      return 'A'
  }
}

export function getWIReviewPartEnum(status: WIReviewStatus): number {
  switch (status) {
    case WIReviewStatus.partA:
      return 1
    case WIReviewStatus.partB:
      return 2
    case WIReviewStatus.partC:
      return 3
    case WIReviewStatus.partD:
      return 4
    case WIReviewStatus.partE:
      return 5
    case WIReviewStatus.partF:
      return 6
    case WIReviewStatus.partG:
      return 7
    case WIReviewStatus.partH:
      return 8
    case WIReviewStatus.partI:
      return 9
    case WIReviewStatus.partJ:
      return 10
    case WIReviewStatus.partK:
      return 11
    default:
      return 1
  }
}

export function getWIReviewStatusNumberByValue(
  value: WIReviewStatus | undefined,
): number | undefined {
  switch (value) {
    case WIReviewStatus.partA:
      return 1
    case WIReviewStatus.partB:
      return 2
    case WIReviewStatus.partC:
      return 3
    case WIReviewStatus.partD:
      return 4
    case WIReviewStatus.partE:
      return 5
    case WIReviewStatus.partF:
      return 6
    case WIReviewStatus.partG:
      return 7
    case WIReviewStatus.partH:
      return 8
    case WIReviewStatus.partI:
      return 9
    case WIReviewStatus.partJ:
      return 10
    case WIReviewStatus.partK:
      return 11
    case WIReviewStatus.completed:
      return 111
    default:
      return undefined
  }
}

export const WIReviewStatusEnum = types.enumeration(
  Object.values(WIReviewStatus),
)
