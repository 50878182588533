import type { DeepNullable, DeepPartial } from '@mtr-SDO/tsconfig-node'
import { ApiBase } from '../api-base'
import { GeneralApiResponse } from '../api-base.types'
import {
  CMDetailPayload,
  CMDetailStatusPayload,
  CMRequestItem,
  GetCMAssociatedCodes,
  GetCMWorkOrderPersonInChargeOptions,
  GetStandardJobForCMRequest,
  GetUserStandardJobCode,
  PostCMDetail,
} from './api.types'

export class ApiCMDetails {
  private api: ApiBase

  constructor(api: ApiBase) {
    this.api = api
  }

  getStandardJobForCMRequest = async (standardJob?: string) =>
    this.api.generalApi<GetStandardJobForCMRequest>(
      this.api.apisauce.get('/Common/Attunity/GetStandardJobDefaultValue', {
        standardJob,
      }),
    )

  postCMRequest = async (
    cmRequest: DeepNullable<DeepPartial<CMDetailPayload>>,
  ): Promise<GeneralApiResponse<PostCMDetail>> =>
    this.api.generalApi<PostCMDetail>(
      this.api.apisauce.post('/Form/CMWorkOrder/SubmitCMDetail', cmRequest),
    )

  getCMWorkOrderPersonInChargeOptions = async (): Promise<
    GeneralApiResponse<GetCMWorkOrderPersonInChargeOptions>
  > =>
    this.api.generalApi<GetCMWorkOrderPersonInChargeOptions>(
      this.api.apisauce.get('/Form/CMWorkOrder/GetPersonInChargeList'),
    )

  getCMAssociatedCodes = async (
    equipmentClass: string,
  ): Promise<GeneralApiResponse<GetCMAssociatedCodes>> =>
    this.api.generalApi<GetCMAssociatedCodes>(
      this.api.apisauce.get('/Common/Attunity/GetAssocCode', {
        classCd: equipmentClass,
      }),
    )

  getUserStandardJobCode = async (
    filterByUser: boolean,
    workNatureLv1?: string,
  ): Promise<GeneralApiResponse<GetUserStandardJobCode>> =>
    this.api.generalApi<GetUserStandardJobCode>(
      this.api.apisauce.get('/Common/Attunity/GetStandardJobByUser', {
        filterByUserWorkGroup: filterByUser,
        level1: workNatureLv1 ?? 'CM',
      }),
    )

  getCMRequestsByWorkOrder = async (
    workOrderNumber: string,
  ): Promise<GeneralApiResponse<CMRequestItem[]>> =>
    this.api.generalApi<CMRequestItem[]>(
      this.api.apisauce.get('/Form/CMWorkOrder/GetCMRequests', {
        pmWorkOrder: workOrderNumber,
      }),
    )

  getCMDetails = async (
    remoteId: string,
    isMerged?: boolean,
  ): Promise<GeneralApiResponse<CMDetailPayload>> =>
    this.api.generalApi<CMDetailPayload>(
      this.api.apisauce.get('/Form/CMWorkOrder/GetCMDetail', {
        cMWorkOrderRequestObjectID: remoteId,
        isMerger: isMerged ? 1 : 0,
      }),
    )

  getShouldShowDelayFields = async (
    equipmentClass: string,
    workNatureLv1: string,
    workNatureLv2: string,
    standardJobCode: string,
  ): Promise<GeneralApiResponse<boolean>> =>
    this.api.generalApi<boolean>(
      this.api.apisauce.get('/Form/CMWorkOrder/IsShowInitialDelayField', {
        equipClass: equipmentClass,
        workNatureLv1,
        workNatureLv2,
        standardJobCode,
      }),
    )

  getCMDetailStatus = async (
    remoteId: string,
    action: 'create' | 'edit' | 'complete',
  ): Promise<GeneralApiResponse<CMDetailStatusPayload>> =>
    this.api.generalApi<CMDetailStatusPayload>(
      this.api.apisauce.get('/Form/CMWorkOrder/GetCMDetailValidationResult?', {
        cMWorkOrderRequestObjectID: remoteId,
        flag: action,
      }),
    )
}
