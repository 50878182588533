import { observer } from 'mobx-react-lite'
import {
  AppBar as MuiAppBar,
  // Typography,
  Toolbar,
  IconButton,
  // Button,
} from '@mui/material'
// import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
// import { useTranslation } from 'react-i18next'
// import { useStores } from '../../models'
import { Icon } from '../icon'
import { icons } from '../../theme'
import { useView, ScreenSize } from '../../utils'
import { drawerWidth } from '../sidebar'
import { Breadcrumb } from '../breadcrumb'

export const APPBAR_NORMAL_VIEW_HEIGHT = 64
export const APPBAR_MOBILE_VIEW_HEIGHT = 50

const StyledAppBar = styled(MuiAppBar) <{ $view: ScreenSize }>`
  font-family: "Microsoft YaHei", 微软雅黑;
  margin-left: ${({ $view }) =>
    $view === ScreenSize.mobile ? 0 : drawerWidth}px;
  color: ${({ theme }) => theme.palettes.general.white};
  background-color: ${({ theme }) => theme.colors.primary};
  width: ${({ $view }) =>
    $view === ScreenSize.mobile ? '100%' : `calc(100% - ${drawerWidth}px)`};
`

const StyledToolbar = styled(Toolbar)<{ $view: ScreenSize }>`
  justify-content: space-between;
  min-height: ${({ $view }) =>
    $view === ScreenSize.mobile
      ? APPBAR_MOBILE_VIEW_HEIGHT
      : APPBAR_NORMAL_VIEW_HEIGHT}px;
  height: ${({ $view }) =>
    $view === ScreenSize.mobile
      ? APPBAR_MOBILE_VIEW_HEIGHT
      : APPBAR_NORMAL_VIEW_HEIGHT}px;
`

const LeftContainer = styled.div`
  text-align: left;
  display: flex;
`

const CenterContainer = styled.div`
  margin: 0 auto;
`
const SystemTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSize[4]}px;
  white-space: nowrap;
`

// const RightContainer = styled.div<{ $view: ScreenSize }>`
//   text-align: right;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: flex-end;
//   gap: ${({ $view, theme }) =>
//     $view === ScreenSize.mobile ? theme.spacings[3] : theme.spacings[6]}px;

//   color: ${({ theme }) => theme.colors.primary};
// `

// const StyledIcon = styled(Icon)`
//   width: ${({ theme }) => theme.spacings[5]}px;
//   height: ${({ theme }) => theme.spacings[5]}px;
//   margin-left: 5px;
//   margin-right: 5px;
// `

const StyledMenuIcon = styled(Icon)`
  width: ${({ theme }) => theme.spacings[6]}px;
  height: ${({ theme }) => theme.spacings[6]}px;
`

// const StyledText = styled(Typography)`
//   display: inline-block;
//   font-size: ${({ theme }) => theme.fontSize[2]}px;
// `

// const StyledButton = styled(Button)`
//   color: ${({ theme }) => theme.colors.primary};
//   border-color: ${({ theme }) => theme.palettes.general.noSelected};
//   background-color: ${({ theme }) => theme.palettes.general.white};
//   padding: ${({ theme }) => theme.spacings[0]}px
//     ${({ theme }) => theme.spacings[4]}px;
//   min-width: 40px;
// `

// const UserInfoContainer = styled.div`
//   display: flex;
//   align-items: center;
// `

type Props = {
  handleMenuOnClick: () => void
}

export const AppBar = observer<Props>(({ handleMenuOnClick }) => {
  // const { userProfileStore } = useStores()
  const view = useView()
  // const { i18n } = useTranslation()

  return (
    <StyledAppBar position="fixed" $view={view} elevation={0}>
      <StyledToolbar $view={view}>
        <LeftContainer>
          {view === ScreenSize.mobile ? (
            <IconButton color="inherit" onClick={handleMenuOnClick}>
              <StyledMenuIcon {...icons.menu} />
            </IconButton>
          ) : (
            <Breadcrumb />
          )}
        </LeftContainer>
        {/* <RightContainer $view={view}>
          {view === ScreenSize.desktop && (
            <StyledButton size="small" variant="outlined">
              <Typography>
                {i18n.language.substring(0, i18n.language.indexOf('-'))}
              </Typography>
            </StyledButton>
          )}
          <IconButton color="inherit" component={Link} to="/notification">
            <StyledIcon {...icons.notification} />
          </IconButton>
          <UserInfoContainer>
            <StyledIcon {...icons.user} />
            <StyledText noWrap>
              {userProfileStore.userInfo?.displayName}
            </StyledText>
          </UserInfoContainer>
        </RightContainer> */}
        <CenterContainer>
          <SystemTitle>SDO Mobile Web</SystemTitle>
        </CenterContainer>
      </StyledToolbar>
    </StyledAppBar>
  )
})
