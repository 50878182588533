import { WIReviewFormDataPayload } from '@mtr-SDO/apis'
import { withRootStore } from '@mtr-SDO/models-core'
import _ from 'lodash'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import { mapPayloadToUserList, mapPayloadToWIAttachment } from '../wi-workflow'
import { stringToDate, stringToString } from '../common-function'
import {
  mapPayloadToWIReviewApproval,
  WIReviewApprovalModel,
  WIReviewPartAModel,
  WIReviewPartBModel,
  WIReviewPartCModel,
  WIReviewPartDModel,
  WIReviewPartEModel,
  WIReviewPartFModel,
  WIReviewPartJModel,
  WIReviewPartKModel,
} from './wi-review-detail-part.model'
import {
  getWIReviewPartBCommentRequired,
  getWIReviewPartBConclusion,
  getWIReviewPartJRecordFormResult,
  getWIReviewPartJWIResult,
  getWIReviewTaskType,
  getWIReviewUploaded,
  getWIReviewWIType,
} from './wi-review-selection-item'
import { mapPayloadToWIReviewItem } from './wi-review-item.model'
import { mapPayloadToWIReviewCommentForm } from './wi-review-detail-comment.model'

export const WIReviewDetailModel = types
  .model({
    wiReviewDetailId: types.optional(types.identifier, uuid),

    partAData: WIReviewPartAModel,
    partBData: WIReviewPartBModel,
    partCData: WIReviewPartCModel,
    partDData: WIReviewPartDModel,
    partEData: WIReviewPartEModel,
    partFData: WIReviewPartFModel,
    partGData: WIReviewApprovalModel,
    partHData: WIReviewApprovalModel,
    partIData: WIReviewApprovalModel,
    partJData: WIReviewPartJModel,
    partKData: WIReviewPartKModel,
  })
  .extend(withRootStore)
  .actions((self) => ({
    apply(snapshot: any) {
      _.keys(_.omit(snapshot, 'wiReviewDetailId')).forEach((key) => {
        if (snapshot[key] == null) {
          return
        }
        ;(self as any)[key] = snapshot[key]
      })
    },
  }))

export type WIReviewDetail = Instance<typeof WIReviewDetailModel>

export function mapPayloadToWIReviewDetail(
  payload: WIReviewFormDataPayload,
): SnapshotIn<WIReviewDetail> {
  return {
    wiReviewDetailId: stringToString(payload.objectId),

    partAData: {
      partId: payload.partA.partId,
      refNo: stringToString(payload.partA.refNo),
      wiNo: stringToString(payload.partA.wiNo),
      wiTitle: stringToString(payload.partA.wiTitle),
      issueNo: stringToString(payload.partA.issueNo),
      revNo: stringToString(payload.partA.revNo),
      mWiWicn: stringToString(payload.partA.mwiwicn),
      drafter: {
        displayName: stringToString(payload.partA.actionBy),
      },
      taskType: getWIReviewTaskType(payload.partA.taskType),
      recordDate: stringToDate(payload.partA.recordDate),
      targetCompletionDate: stringToDate(payload.partA.targetCompletionDate),
      attachments: mapPayloadToWIAttachment(payload.partA.attachments),
    },
    partBData: {
      partId: payload.partB.partId,
      wiType: getWIReviewWIType(payload.partB.review, payload.partB.newWI),
      reviewItemList: mapPayloadToWIReviewItem(payload.partB.reviewList),
      conclusion: getWIReviewPartBConclusion(
        payload.partB.noChangeVal,
        payload.partB.changeVal,
        payload.partB.aamendment,
      ),
      amendmentDetail: stringToString(payload.partB.aamendmentContent),
      draftWIForReviewList: payload.partB.reviewDraftWFIList.map((wi) => wi),
      commentRequired: getWIReviewPartBCommentRequired(
        payload.partB.isRequiredComment,
      ),
      comment: stringToString(payload.partB.requiredComment),
      attachments: mapPayloadToWIAttachment(payload.partB.attachments),
    },
    partCData: {
      partId: payload.partC.partId,
      comment: stringToString(payload.partC.checkerComment),
    },
    partDData: {
      partId: payload.partD.partId,
      commentSkipped: payload.partD.isSipCommnet,
      presetGroups: stringToString(payload.partD.presetGroups),
    },
    partEData: {
      partId: payload.partE.partId,
      commentForms: mapPayloadToWIReviewCommentForm(
        payload.partE.childFormList,
      ),
      attachments: mapPayloadToWIAttachment(payload.partE.attachments),
    },
    partFData: {
      partId: payload.partF.partId,
      isReadyForApproval: stringToString(payload.partF.decision),
      checkerComment: stringToString(payload.partF.checkerComment),
      firstEndorserRequired:
        payload.partF.requireOne == null ? undefined : payload.partF.requireOne,
      firstEndorser: mapPayloadToUserList(payload.partF.requireOneList),
      secondEndorserRequired:
        payload.partF.requireTwo == null ? undefined : payload.partF.requireTwo,
      secondEndorser: mapPayloadToUserList(payload.partF.requireTwoList),
      approverRequired:
        payload.partF.requireDefault == null
          ? undefined
          : payload.partF.requireDefault,
    },
    partGData: mapPayloadToWIReviewApproval(payload.partG),
    partHData: mapPayloadToWIReviewApproval(payload.partH),
    partIData: mapPayloadToWIReviewApproval(payload.partI),
    partJData: {
      partId: payload.partJ.partId,
      wiResult: getWIReviewPartJWIResult(
        payload.partJ.newIssue,
        payload.partJ.review,
        payload.partJ.noChange,
        payload.partJ.delete,
      ),
      wiResultDeleteRemarks: stringToString(payload.partJ.deltetRemark),
      recordFormResult: getWIReviewPartJRecordFormResult(
        payload.partJ.update,
        payload.partJ.na,
        payload.partJ.noChangeRecord,
        payload.partJ.deleteRecord,
      ),
      recordFormResultDeleteRemarks: stringToString(
        payload.partJ.deleteRemarkRecord,
      ),
      remarks: stringToString(payload.partJ.remarks),
      attachments: mapPayloadToWIAttachment(payload.partJ.attachments),
    },
    partKData: {
      uploaded: getWIReviewUploaded(
        payload.partK.yes,
        payload.partK.noChange,
        payload.partK.deleted,
      ),
    },
  }
}
