import { Instance, types } from "mobx-state-tree"
import { BuilderDomItemModel, BuilderDomItemSnapshotIn, BuilderDomItemSnapshotOut } from "./builder-dom-item.model"

// all of builder tree model
export const BuilderDomTreeModel = types
  .model('BuilderDomTree', {
    id: types.optional(types.string,''),
    domItems: types.array(BuilderDomItemModel),
    formData: types.frozen()
  }).
  actions(self=>({
    createDomItem(snapshot:BuilderDomItemSnapshotIn | any): void{
      // create model push into model array
      const newModel = BuilderDomItemModel.create(snapshot)
      self.domItems.push(newModel)
    },
    // cleaning model when re-build form
    cleanChildrenModels(){
      self.domItems.clear()
    },
    // set id as params search domItems
    findDomItem(domItemId:string): BuilderDomItemSnapshotOut | undefined{
      return self.domItems.find(i=>i.id === domItemId)
    },
    // update form data
    setFormData(object:any){
      self.formData = object
    }
  }))

export type BuilderDomTree = Instance<typeof BuilderDomTreeModel>
