import { icons, IconProps } from '../../theme'
import { RouteItem } from '../../route-items'

export const LOGOUT_KEY = 'Logout'

export type MenuItem = (
  | {
      key: string
      path?: string
      onClick?(): void
      content?: Omit<MenuItem, 'content'>[]
    }
  | (RouteItem & { onClick: undefined })
) & {
  icon?: IconProps
  subPath?: string[]
}

export const sidebarItems: MenuItem[] = [
  // {
  //   key: 'Home',
  //   path: '/home',
  //   icon: icons.home,
  // },
  {
    key: 'User Info',
    path: '/user-information-page',
    icon: icons.user,
  },
  {
    key: LOGOUT_KEY,
    icon: icons.logout,
  },
]
