import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { useView, ScreenSize } from '../utils'
import {
  APPBAR_NORMAL_VIEW_HEIGHT,
  APPBAR_MOBILE_VIEW_HEIGHT,
  drawerWidth,
} from '../components'

const Root = styled.div<{ $view: ScreenSize }>`
  margin-left: ${({ $view }) =>
    $view === ScreenSize.mobile ? 0 : drawerWidth}px;
  margin-right: 0px;

  height: 100%;
  /* overflow-y: hidden; */
  overflow-y: scroll;
  display: flex;
`

const Main = styled.main<{ $view: ScreenSize }>`
  margin-top: ${({ $view }) =>
    $view === ScreenSize.mobile
      ? APPBAR_MOBILE_VIEW_HEIGHT
      : APPBAR_NORMAL_VIEW_HEIGHT}px;

  max-height: 100%;
  width: 100%;
  display: flex;
`

type Props = {
  children?: JSX.Element | JSX.Element[]
}

export const BasePage = observer<Props>(({ children }) => {
  const view = useView()

  return (
    <Root $view={view}>
      <Main $view={view}>{children}</Main>
    </Root>
  )
})
