import { formats, WIAttachment } from '@mtr-SDO/apis'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { stringToDate, stringToString } from '../common-function'
import { FormDataAttachmentTypeEnum, FormDataAttachmentType } from '../forms'

type AttachmentPayload = {
  AttachmentObjectID: string
  AttachmentFormat: string | undefined
  AttachmentName: string | undefined
  AttachmentPath: string | undefined
  AttachmentType: number
  UserUpn: string | undefined
  UpdateDate: string
}

export const AttachmentModel = types.model({
  userUpn: types.maybe(types.string),
  attachmentId: types.optional(types.identifier, uuid),
  type: types.maybe(FormDataAttachmentTypeEnum),
  path: types.maybe(types.string),
  name: types.maybe(types.string),
  format: types.maybe(types.string),
  updateDate: types.optional(types.Date, () => moment().toDate()),
})

export type Attachment = Instance<typeof AttachmentModel>

export function mapAttachmentTypeToFormDataAttachmentType(
  type: number,
): FormDataAttachmentType | undefined {
  if (type === 1) {
    return FormDataAttachmentType.photo
  }
  if (type === 2) {
    return FormDataAttachmentType.video
  }
  if (type === 3) {
    return FormDataAttachmentType.file
  }

  return undefined
}

export function mapFormDataAttachmentTypeToNumber(
  type: FormDataAttachmentType | undefined,
): number {
  if (type === FormDataAttachmentType.photo) {
    return 1
  }

  if (type === FormDataAttachmentType.video) {
    return 2
  }

  if (type === FormDataAttachmentType.file) {
    return 3
  }

  return 0
}

export function mapPayloadToWIAttachment(
  payload: WIAttachment[] | undefined,
): SnapshotIn<Attachment[] | undefined> {
  if (payload == null) return undefined
  return payload.map((it) => ({
    userUpn: stringToString(it.userUpn),
    attachmentId: stringToString(it.attachmentObjectID),
    type: mapAttachmentTypeToFormDataAttachmentType(it.attachmentType),
    path: stringToString(it.attachmentPath),
    name: stringToString(it.attachmentName),
    format: stringToString(it.attachmentFormat),
    updateDate: stringToDate(it.updateDate),
  }))
}

export function buildAttachmentPayload(
  data: Attachment[] | undefined,
): AttachmentPayload[] | undefined {
  if (data == null) return undefined
  return data.map((attachment) => ({
    AttachmentObjectID: attachment.attachmentId,
    AttachmentFormat: attachment.format,
    AttachmentName: attachment.name,
    AttachmentPath: attachment.path,
    AttachmentType: mapFormDataAttachmentTypeToNumber(attachment.type),
    UserUpn: attachment.userUpn,
    UpdateDate: moment(attachment.updateDate).format(
      formats.dateTimeLocalSeconds,
    ),
  }))
}
