import { FormData, FormDataItem } from '../forms'

export enum WorkOrderFormApprovalAction {
  upload = 'upload',
  submit = 'submit',
  endorse = 'endorse',
  approve = 'approve',
  reject = 'reject',
  void = 'void',
}

export type WorkOrderFormApprovalHistory = {
  id: string
  type: WorkOrderFormApprovalAction
  createdBy:
    | {
        upn: string
        username: string
      }
    | undefined
  date: moment.Moment
  formData?: FormData
  detail?: string
}

export type WorkOrderFormInstrumentRecord = {
  code: string
  histories: {
    createdBy: {
      upn: string
      username: string
    }
    createdDate?: moment.Moment
    endDate: moment.Moment
    expiryDate: moment.Moment
    recordDate: moment.Moment
  }[]
}

export interface FormDataUploadedItem {
  formData: FormData
  item: FormDataItem
}
