import { observer } from 'mobx-react-lite'
import { useCallback, useState, useMemo, useEffect } from 'react'
import styled from '@emotion/styled'
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { useStores } from '../../models'
import { MenuItem, sidebarItems, LOGOUT_KEY } from './sidebar-items'
import { storage } from '../../local-storage'
import { Icon } from '../icon'
import { icons } from '../../theme'

const Root = styled(List)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.palettes.general.white};
  width: 28px;
  height: 28px;
`

const StyledText = styled(ListItemText)`
  color: ${({ theme }) => theme.palettes.general.white};
  font-size: 20px;
  font-weight: 600;
  padding-left: ${({ theme }) => theme.spacings[4]}px;
  margin-bottom: 0px;
  max-width: 150px;

  .MuiTypography-body1 {
    font-size: ${({ theme }) => theme.fontSize[5]}px;
  }
`

const StyledItemLink = styled(Link)`
  min-height: ${({ theme }) => theme.spacings[8] - 10}px;
  padding-left: 30px;
  padding-right: 30px;
`

const StyledSubitemLink = styled(Link)`
  height: ${({ theme }) => theme.spacings[6]}px;
`

const ItemIcon = styled(ListItemIcon)`
  padding-left: ${({ theme }) => theme.spacings[7]}px;
`

const ItemContainer = styled.div`
  .ListItem.Mui-selected {
    background-color: ${({ theme }) => theme.palettes.general.hover};
  }
  .ListItem.Mui-selected:hover {
    background-color: ${({ theme }) => theme.palettes.wicn.main};
  }
  .ListItem.MuiListItem-button:hover {
    background-color: ${({ theme }) => theme.palettes.wicn.main};
  }
  :last-child {
    margin-top: auto;
  }
`

export const SidebarList = observer(() => {
  const { userProfileStore, uiStore } = useStores()
  const location = useLocation()
  const currentMenuItem = useMemo(() => {
    const root = '/'.concat(location.pathname.split('/')[1])

    return sidebarItems.find(
      (obj) => obj.path === root || obj.subPath?.includes(root),
    )?.key
  }, [location])

  const [clickedMenuItem, setClickedMenuItem] = useState<string | undefined>(
    currentMenuItem,
  )

  useEffect(() => {
    setClickedMenuItem(currentMenuItem)
  }, [currentMenuItem])

  const isSubitemClicked = useCallback(
    (key: string): boolean => {
      const subItems =
        sidebarItems.find((obj) => obj.key === key) === undefined
          ? []
          : (
              sidebarItems.find((obj) => obj.key === key) as {
                content?: Omit<MenuItem, 'content'>[]
              }
            ).content ?? []
      return subItems.length !== 0
        ? subItems.some((i) => i.key === clickedMenuItem)
        : false
    },
    [clickedMenuItem],
  )

  const hasSubItem = (key: string): boolean =>
    sidebarItems.some(
      (item) =>
        item.key === key &&
        (item as { content?: Omit<MenuItem, 'content'>[] }).content !==
          undefined,
    )

  const isExpendedMenuItem = useCallback(
    (key: string) =>
      (key === clickedMenuItem && hasSubItem(key)) || isSubitemClicked(key),
    [clickedMenuItem, isSubitemClicked],
  )

  const logout = useCallback(() => {
    userProfileStore.logout()
    uiStore.setLoggedIn(false)
    storage.clearAccessToken()
  }, [uiStore, userProfileStore])

  const handleMenuItemOnClick = useCallback(
    (key: string): void => {
      if (key === LOGOUT_KEY) {
        logout()
        return
      }
      setClickedMenuItem(isExpendedMenuItem(key) ? undefined : key)
    },
    [isExpendedMenuItem, logout],
  )

  return (
    <Root>
      {sidebarItems.map((item) => {
        const subItem =
          (item as { content?: Omit<MenuItem, 'content'>[] }).content ?? []
        return (
          <ItemContainer>
            <ListItem
              button
              component={StyledItemLink}
              to={item.path ?? window.location.pathname}
              onClick={() => handleMenuItemOnClick(item.key)}
              selected={
                clickedMenuItem === item.key ||
                (subItem.length !== 0
                  ? subItem.some((i) => i.key === clickedMenuItem)
                  : false)
              }
              key={item.key}
              divider
              className="ListItem"
            >
              <ItemIcon>
                <StyledIcon {...(item.icon ?? icons.home)} />
              </ItemIcon>
              <StyledText disableTypography primary={item.key} />
              {subItem.length !== 0 &&
                (clickedMenuItem === item.key ||
                subItem.some((i) => i.key === clickedMenuItem) ? (
                  <StyledIcon {...icons.rightArrow} />
                ) : (
                  <StyledIcon {...icons.downArrow} />
                ))}
            </ListItem>
            <Collapse
              in={
                // (isWideScreenMode || open) &&
                clickedMenuItem === item.key ||
                (subItem.length !== 0 &&
                  subItem.some((i) => i.key === clickedMenuItem))
              }
              timeout="auto"
              unmountOnExit
            >
              {subItem.map((sItem) => (
                <List key={sItem.key}>
                  <ListItem
                    button
                    component={StyledSubitemLink}
                    to={sItem.path ?? '/'}
                    onClick={(): void => handleMenuItemOnClick(sItem.key)}
                    selected={clickedMenuItem === sItem.key}
                    divider
                  >
                    <ListItemIcon>
                      <StyledIcon {...icons.home} />
                    </ListItemIcon>
                    <ListItemText primary={sItem.key} />
                  </ListItem>
                </List>
              ))}
            </Collapse>
          </ItemContainer>
        )
      })}
    </Root>
  )
})
