import {
  Autocomplete,
  AutocompleteProps,
  Box,
  Chip,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  InputLabel,
  InputLabelProps,
  ListItemText,
  MenuItem,
  MenuItemProps,
  OutlinedInput,
  OutlinedInputProps,
  Select,
  SelectChangeEvent,
  SelectProps,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { forwardRef, useState } from 'react'
import Icon from '@mdi/react'
import { mdiCheckBold, mdiClose } from '@mdi/js'

const StyledFormControl = styled(FormControl)<{
  showinputlabel: number
}>`
  margin-top: ${({ showinputlabel }) => (showinputlabel ? '3' : `0`)}px;
`
const StyledInputLabel = styled(InputLabel)``
const StyledOutlinedInput = styled(OutlinedInput)<{
  showshadow: number
}>`
  .MuiOutlinedInput-notchedOutline {
    box-shadow: ${({ showshadow }) =>
      showshadow ? `0px 0px 5px 2px #58585833` : `none`};
  }
`
const StyledTextField = styled(TextField)<{
  showshadow: number
}>`
  .MuiOutlinedInput-notchedOutline {
    box-shadow: ${({ showshadow }) =>
      showshadow ? `0px 0px 5px 2px #58585833` : `none`};
  }
`
const StyledFormHelperText = styled(FormHelperText)`
  white-space: pre-wrap;
`

// type FilterKeys<T, U> = {
//   [key in keyof T]: key extends U ? never : key
// }[keyof T]

// type Fix<T, U extends keyof T> = Pick<T, FilterKeys<T, U>>

// type TextInputProps = Fix<InputProps, "selectData" | "selectProps">

type TextInputProps = {
  inputLabelProps?: InputLabelProps
  outlinedInputProps?: OutlinedInputProps
  formHelperTextProps?: FormHelperTextProps
  showInputLabel?: boolean
  showShadow?: boolean
  label?: string
  name?: string
  textHelper?: string
} & FormControlProps

type SingleSelectProps<T = any> = {
  inputLabelProps?: InputLabelProps
  outlinedInputProps?: OutlinedInputProps
  formHelperTextProps?: FormHelperTextProps
  selectProps?: SelectProps
  menuItemProps?: MenuItemProps
  showInputLabel?: boolean
  showShadow?: boolean
  label?: string
  name?: string
  textHelper?: string
  selectData?: Array<T>
  getItemValueRender?: (item: T) => any
  getItemTextRender?: (item: T) => any
} & FormControlProps

type MultipleSelectProps<T = any> = {
  inputLabelProps?: InputLabelProps
  outlinedInputProps?: OutlinedInputProps
  formHelperTextProps?: FormHelperTextProps
  selectProps?: {
    valueIsDisplay?: boolean
    allowSelect?: boolean
    allowDelete?: boolean
    onDelete?: (value: any, currentValues: Array<any>) => Array<any> | void
  } & SelectProps
  menuItemProps?: MenuItemProps
  showInputLabel?: boolean
  showShadow?: boolean
  label?: string
  name?: string
  textHelper?: string
  selectData?: Array<any>
  getItemValueRender?: (item: T) => any
  getItemTextRender?: (item: T) => any
} & FormControlProps

type AutoMultipleProps<T = any> = {
  textFieldProps?: TextFieldProps
  formHelperTextProps?: FormHelperTextProps
  autocompleteProps?: AutocompleteProps<any, any, any, any> | any
  showInputLabel?: boolean
  showShadow?: boolean
  label?: string
  name?: string
  textHelper?: string
  selectData?: Array<any>
  getItemValueRender?: (item: T) => any
  getItemTextRender?: (item: T) => any
} & FormControlProps

type InputProps = TextInputProps &
  SingleSelectProps &
  MultipleSelectProps &
  AutoMultipleProps & { inputTagName: keyof InputType }

export const TextInput = observer(
  forwardRef<any, TextInputProps>(
    (
      {
        inputLabelProps,
        outlinedInputProps,
        formHelperTextProps,
        showInputLabel = true,
        showShadow = true,
        label = '',
        name = '',
        textHelper = '',
        ...props
      },
      ref,
    ) => (
      <StyledFormControl showinputlabel={+showInputLabel} {...props}>
        {showInputLabel ? (
          <StyledInputLabel htmlFor={name} {...inputLabelProps}>
            {inputLabelProps?.children ?? label}
          </StyledInputLabel>
        ) : (
          ''
        )}
        <StyledOutlinedInput
          inputRef={ref}
          showshadow={+showShadow}
          id={name}
          aria-describedby={`${name}-text`}
          {...(showInputLabel ? { label } : { placeholder: label })}
          {...outlinedInputProps}
        />
        <StyledFormHelperText id={`${name}-text`} {...formHelperTextProps}>
          {textHelper}
        </StyledFormHelperText>
      </StyledFormControl>
    ),
  ),
)

export const SingleSelect = observer(
  forwardRef<any, SingleSelectProps>(
    (
      {
        inputLabelProps,
        outlinedInputProps,
        formHelperTextProps,
        selectProps,
        menuItemProps,
        showInputLabel = true,
        showShadow = true,
        label = '',
        name = '',
        textHelper = '',
        selectData,
        getItemValueRender = (item: any) => item,
        getItemTextRender = getItemValueRender,
        ...props
      },
      ref,
    ) => (
      <StyledFormControl showinputlabel={+showInputLabel} {...props}>
        {showInputLabel ? (
          <StyledInputLabel htmlFor={name} {...inputLabelProps}>
            {inputLabelProps?.children ?? label}
          </StyledInputLabel>
        ) : (
          ''
        )}
        <Select
          inputRef={ref}
          // ref={selectRef}
          onClose={() => {
            setTimeout(() => {
              ;(document.activeElement as HTMLElement).blur()
            }, 0)
          }}
          input={
            <StyledOutlinedInput
              showshadow={+showShadow}
              id={name}
              aria-describedby={`${name}-text`}
              {...(showInputLabel ? { label } : { placeholder: label })}
              {...outlinedInputProps}
            />
          }
          {...selectProps}
        >
          {selectData?.map((item) => (
            <MenuItem
              key={getItemValueRender(item)}
              value={getItemValueRender(item)}
              {...menuItemProps}
            >
              {getItemTextRender(item)}
            </MenuItem>
          ))}
        </Select>
        <StyledFormHelperText id={`${name}-text`} {...formHelperTextProps}>
          {textHelper}
        </StyledFormHelperText>
      </StyledFormControl>
    ),
  ),
)

const StyledBox = styled(Box)`
  & {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    /* max-height: 20vw; */
    max-height: 50px;
    overflow-y: scroll;
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
`
const StyledIcon = styled(Icon)`
  & {
    pointer-events: auto;
    cursor: pointer;
    background-color: #f0f0f0;
    border-radius: 50%;
    transform: none;
  }
`
const StyledChip = styled(Chip)`
  & {
    border-radius: 2px;
    background-color: #f0f0f0;
  }
`
const StyledMenuItem = styled(MenuItem)`
  .checkedIcon {
    opacity: 0;
  }
  &.Mui-selected > .checkedIcon {
    opacity: 1;
  }
`
// 这个很卡，建议使用下面的AutoMultiple
export const MultipleSelect = observer(
  forwardRef<any, MultipleSelectProps>(
    (
      {
        inputLabelProps,
        outlinedInputProps,
        formHelperTextProps,
        selectProps: allSelectProps,
        menuItemProps,
        showInputLabel = true,
        showShadow = true,
        label = '',
        name = '',
        textHelper = '',
        selectData,
        getItemValueRender = (item: any) => item,
        getItemTextRender = getItemValueRender,
        ...props
      },
      ref,
    ) => {
      const selectAll = 'select-all'
      const {
        allowSelect,
        allowDelete,
        onDelete,
        valueIsDisplay = true,
        ...selectProps
      } = allSelectProps ?? {}
      const multipleSelectValue =
        typeof selectProps.value === 'string'
          ? selectProps.value
            ? selectProps.value.split(',')
            : []
          : Array.isArray(selectProps.value)
          ? selectProps.value
          : []
      const [multipleValue, setMultipleValue] =
        useState<Array<any>>(multipleSelectValue)
      // const selectRef = createRef<HTMLElement>()
      // useImperativeHandle(selectProps.ref, () => selectRef)
      return (
        <StyledFormControl showinputlabel={+showInputLabel} {...props}>
          {showInputLabel ? (
            <StyledInputLabel htmlFor={name} {...inputLabelProps}>
              {inputLabelProps?.children ?? label}
            </StyledInputLabel>
          ) : (
            ''
          )}
          <Select
            inputRef={ref}
            multiple
            {...selectProps}
            // ref={selectRef}
            onClose={() => {
              setTimeout(() => {
                ;(document.activeElement as HTMLElement).blur()
              }, 0)
            }}
            {...(allowDelete && !selectProps.renderValue
              ? {
                  value: multipleValue,
                  onChange: (event, ...onChangeProps) => {
                    let allEvent = event
                    const value = event.target.value as Array<any>
                    if (allowSelect && value[value.length - 1] === selectAll) {
                      allEvent = {
                        ...event,
                        target: {
                          ...event.target,
                          value:
                            selectData?.map((obj) => getItemValueRender(obj)) ??
                            [],
                        },
                      } as SelectChangeEvent<unknown>
                    }
                    selectProps.onChange?.(allEvent, ...onChangeProps)
                    setMultipleValue(allEvent.target.value as Array<any>)
                  },
                  ...(multipleValue.length > 0
                    ? {
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        IconComponent: (iconProps) => (
                          <StyledIcon
                            onMouseDown={(event: MouseEvent) => {
                              event.stopPropagation()
                              const { target } = event
                              if (target)
                                (target as HTMLElement).onmouseup = () => {
                                  const values: Array<any> = []
                                  selectProps.onChange?.(
                                    {
                                      target: {
                                        name: selectProps.name as string,
                                        value: values,
                                      },
                                    } as SelectChangeEvent<unknown>,
                                    null,
                                  )
                                  setMultipleValue(values)
                                  ;(target as HTMLElement).onmouseup = null
                                }
                              ;(target as HTMLElement).onmouseout = () => {
                                ;(target as HTMLElement).onmouseup = null
                              }
                            }}
                            path={mdiClose}
                            size={0.7}
                            {...iconProps}
                          />
                        ),
                      }
                    : {}),
                  renderValue: (selected) => (
                    <StyledBox>
                      {(selected as Array<any>)?.map?.((value) => (
                        <StyledChip
                          clickable={false}
                          deleteIcon={<Icon path={mdiClose} size={0.7} />}
                          size="small"
                          key={value}
                          label={
                            valueIsDisplay
                              ? value
                              : getItemTextRender(
                                  selectData?.find(
                                    (item) =>
                                      getItemValueRender(item) === value,
                                  ),
                                )
                          }
                          onDelete={() => {
                            const values =
                              onDelete?.(value, multipleValue) ??
                              multipleValue.filter((i) => i !== value)
                            selectProps.onChange?.(
                              {
                                target: {
                                  name: selectProps.name as string,
                                  value: values,
                                },
                              } as SelectChangeEvent<unknown>,
                              null,
                            )
                            setMultipleValue(values)
                          }}
                          onMouseDown={(event) => {
                            event.stopPropagation()
                          }}
                          onFocus={(event) => {
                            event.stopPropagation()
                          }}
                        />
                      )) ?? selected}
                    </StyledBox>
                  ),
                }
              : {
                  value: multipleSelectValue,
                  renderValue:
                    selectProps.renderValue ??
                    ((selected) => (
                      <StyledBox>
                        {(selected as Array<any>)?.map?.((value) => (
                          <StyledChip
                            clickable={false}
                            size="small"
                            key={value}
                            label={
                              valueIsDisplay
                                ? value
                                : getItemTextRender(
                                    selectData?.find(
                                      (item) =>
                                        getItemValueRender(item) === value,
                                    ),
                                  )
                            }
                          />
                        )) ?? selected}
                      </StyledBox>
                    )),
                })}
            input={
              <StyledOutlinedInput
                showshadow={+showShadow}
                id={name}
                aria-describedby={`${name}-text`}
                {...(showInputLabel ? { label } : { placeholder: label })}
                {...outlinedInputProps}
              />
            }
          >
            {allowSelect ? (
              <MenuItem {...menuItemProps} value={selectAll}>
                <ListItemText>Select All</ListItemText>
              </MenuItem>
            ) : null}
            {selectData?.map((item) => (
              <StyledMenuItem
                {...menuItemProps}
                key={getItemValueRender(item)}
                value={getItemValueRender(item)}
              >
                <ListItemText>{getItemTextRender(item)}</ListItemText>
                <Icon className="checkedIcon" path={mdiCheckBold} size={0.7} />
              </StyledMenuItem>
            ))}
          </Select>
          <StyledFormHelperText id={`${name}-text`} {...formHelperTextProps}>
            {textHelper}
          </StyledFormHelperText>
        </StyledFormControl>
      )
    },
  ),
)

// const StyledLi = styled.li`
//   .checkedIcon {
//     opacity: 0;
//     float: right;
//   }
//   &.MuiAutocomplete-option[aria-selected="true"] > .checkedIcon {
//     opacity: 1;
//   }
//   justify-content: space-between;
// `
export const AutoMultiple = observer(
  forwardRef<any, AutoMultipleProps>(
    (
      {
        textFieldProps,
        formHelperTextProps,
        autocompleteProps,
        showInputLabel = true,
        showShadow = true,
        label = '',
        name = '',
        textHelper = '',
        selectData,
        getItemValueRender = (item: any) => item,
        getItemTextRender = getItemValueRender,
        ...props
      },
      ref,
    ) => (
      <StyledFormControl showinputlabel={+showInputLabel} {...props}>
        <Autocomplete
          {...autocompleteProps}
          ref={ref}
          openOnFocus
          forcePopupIcon={false}
          clearIcon={<StyledIcon path={mdiClose} size={0.7} />}
          multiple
          disableCloseOnSelect
          value={
            typeof autocompleteProps.value === 'string'
              ? autocompleteProps.value
                ? autocompleteProps.value.split(',')
                : []
              : Array.isArray(autocompleteProps.value)
              ? autocompleteProps.value
              : []
          }
          options={selectData ?? []}
          getOptionLabel={getItemTextRender}
          // open
          renderInput={(params) => (
            <StyledTextField
              error={props.error}
              showshadow={+showShadow}
              aria-describedby={`${name}-text`}
              {...(showInputLabel ? { label } : { placeholder: label })}
              {...params}
              {...textFieldProps}
            />
          )}
          renderOption={(optionProps, option, { selected }) => (
            <li style={{ justifyContent: 'space-between' }} {...optionProps}>
              {getItemTextRender(option)}
              {selected ? (
                <Icon className="checkedIcon" path={mdiCheckBold} size={0.7} />
              ) : (
                ''
              )}
            </li>
          )}
          renderTags={(tagValue, getTagProps) => (
            <StyledBox>
              {tagValue.map((option, index) => (
                <StyledChip
                  size="small"
                  deleteIcon={<Icon path={mdiClose} size={0.7} />}
                  label={getItemTextRender(option)}
                  {...getTagProps({ index })}
                />
              ))}
            </StyledBox>
          )}
        />
        <StyledFormHelperText id={`${name}-text`} {...formHelperTextProps}>
          {textHelper}
        </StyledFormHelperText>
      </StyledFormControl>
    ),
  ),
)

export const AllInput = {
  TextInput,
  SingleSelect,
  MultipleSelect,
  AutoMultiple,
}

export type InputType = typeof AllInput

export const Input = observer(
  forwardRef<any, InputProps>(({ inputTagName, ...props }, ref) => {
    const CurrentInput = AllInput[inputTagName] ?? AllInput.TextInput
    return <CurrentInput {...props} ref={ref} />
  }),
)
