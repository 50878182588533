import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { FormControl, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AllInput, SystemHeader } from '@/components'

const StyledContainer = styled.div`
  width: 100%;
  display: block;
`
const Styledbody = styled.div`
  width: 70%;
  min-width: 300px;
  margin: 0 auto;
`
const StyledPageTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  background-color: rgb(238, 238, 238);
  display: block;
  padding: 6px;
  margin: 4px auto;
`
const StyledFormControl = styled(FormControl)`
  width: 70%;
  min-width: 200px;
  margin-top: 14px;
`
const StyledTextInput = styled(AllInput.TextInput)`
  /* width: 60%; */
`
const StyledDiv = styled.div`
  position: absolute;
  left: 0;
  margin-top: 20px;
`
const StyledButton = styled(Button)`
  width: 100px;
  aspect-ratio: 7/3;
  text-transform: none;
  border-radius: 8px;
  font-size: 16px;
  background-color: #367fa9;
  color: #f8f3f3;
`
interface IFormInput {
  lanidOnly: string
  password: string
}

type FormInputType = {
  [propName in keyof IFormInput]: {
    label: string
    register: { [propName: string]: any }
    [propName: string]: any
  }
}

const formInputModel: FormInputType = {
  lanidOnly: {
    label: 'LANID ONLY',
    register: {
      required: 'The LANID ONLY field is required.',
      maxLength: { value: 50, message: 'The length cannot exceed 50' },
    },
  },
  password: {
    label: 'Password',
    register: {
      required: 'The Password field is required.',
      maxLength: { value: 50, message: 'The length cannot exceed 50' },
    },
  },
}

export const MfaSignInPageApp = observer(() => {
  const { t } = useTranslation()
  const { control, handleSubmit } = useForm<IFormInput>()

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    console.log(data)
  }

  return (
    <StyledContainer>
      <SystemHeader />
      <Styledbody>
        <StyledPageTitle>{t(`mfaSignInPageApp.title`)}</StyledPageTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledFormControl>
            {Object.keys(formInputModel).map((i) => {
              const key: keyof IFormInput = i as keyof IFormInput
              const { label, register } = formInputModel[key]
              return (
                <Controller
                  key={key}
                  name={key}
                  rules={register}
                  control={control}
                  render={({ field, formState: { errors } }) => {
                    const inputError = errors[key]
                    return (
                      <StyledTextInput
                        label={label}
                        error={!!inputError}
                        textHelper={inputError?.message ?? ' '}
                        {...field}
                      />
                    )
                  }}
                />
              )
            })}

            <div>
              <StyledDiv>
                <StyledButton
                  disableElevation
                  variant="contained"
                  type="submit"
                >
                  {t(`login`)}
                </StyledButton>
              </StyledDiv>
            </div>
          </StyledFormControl>
        </form>
      </Styledbody>
    </StyledContainer>
  )
})
