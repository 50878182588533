import { ThemeConfig } from '@mtr-SDO/theme-core'
import { colors } from './colors'
import { formats } from './formats'
import { icons } from './icons'

export const sdoTheme: ThemeConfig = {
  colors,
  formats,
  icons,
}
