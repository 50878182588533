import { isNull, isUndefined } from "lodash"
import { getParent , Instance, types } from "mobx-state-tree"
import template from 'template_js'
import { BuilderDomNodeEnums } from "./types"

// outside to inside dom item node
export const BuilderDomNodeModel = types
  .model('BuilderDomNode', {
    id: types.optional(types.string, ''),
    tag: types.maybe(types.enumeration(Object.keys(BuilderDomNodeEnums))),
    disabledCondition: types.optional(types.string, ''),
  }).
  views(self=>{
    const views = {
      get formData() {
        // @ts-ignore
        if(!getParent(self, 2).formData){
          return false
        }
        // @ts-ignore
        const temp = getParent(self, 2).formData
        return Object.keys(temp).length > 0 ? temp : false
      },
      get isDisabled(): boolean{
        // 
        if(!views.formData || !self.disabledCondition){
          return false
        }
        // this way change the undefined and null to false
        // use to run condition 
        const snapshotFormData = {...views.formData}
        Object.keys(snapshotFormData).forEach(key=>{
          if(isUndefined(snapshotFormData[key]) || isNull(snapshotFormData[key])){
            snapshotFormData[key] = false
          }
        })
        // 
        const condition = template(self.disabledCondition,snapshotFormData)
        // 
        if( condition === '' || !condition ){
          return false
        }
        // eslint-disable-next-line no-eval
        const result : boolean = eval(`(${condition})`)
        
        return result
      }
    }
    return views
  })

export type BuilderDomNode = Instance<typeof BuilderDomNodeModel>
export type BuilderDomNodeSnapshotIn = Instance<typeof BuilderDomNodeModel>
