import {
  PartApproveUser,
  WIWorkflowAction,
  WIWorkflowPartNode,
} from '@mtr-SDO/apis'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import {
  stringToDate,
  stringToNumber,
  stringToString,
} from '../common-function'
import { mapPayloadToUser, mapPayloadToUserList, UserModel } from './user.model'

export const WIWorkflowActionDescriptionModel = types.model({
  user: types.maybe(UserModel),
  action: types.maybe(types.string),
  actionGroup: types.maybe(types.string),
  nodeTitle: types.maybe(types.string),
  nodeId: types.maybe(types.number),
  date: types.maybe(types.Date),
  comment: types.maybe(types.string),
  decision: types.maybe(types.string),
  currentStatus: types.maybe(types.number),
  displayName: types.maybe(types.string),
})

export const WIWorkflowApproveUserModel = types.model({
  partId: types.number,
  user: types.maybe(UserModel),
  groupName: types.maybe(types.string),
})

const WICommentFormConsultModel = types.model({
  isReturned: types.boolean,
  originalUser: types.maybe(UserModel),
  user: types.maybe(UserModel),
})

export const WICommentFormStatusModel = types
  .model({
    formId: types.optional(types.identifier, uuid),
    user: types.maybe(UserModel),
    consultList: types.array(WICommentFormConsultModel),
  })
  .views((self) => ({
    isAllConsultedCommentReturned(): boolean {
      if (self.consultList == null) {
        return true
      }
      return !self.consultList.some((it) => it.isReturned === false)
    },
  }))

export const CommentFormForwardListModel = types.model({
  formId: types.optional(types.identifier, uuid),
  forwardList: types.array(UserModel),
})

export const WIWorkflowPartModel = types
  .model({
    nodeId: types.number,
    nodeTitle: types.maybe(types.string),
    preNodeId: types.maybe(types.number),
    nextNodeId: types.maybe(types.number),
    currentStatusId: types.number,
    currentStatusName: types.maybe(types.string),
    currentUserGroup: types.maybe(types.string),

    originalActionBy: types.maybe(UserModel),
    currentActionBy: types.maybe(UserModel),
    next1NodeActionBy: types.maybe(UserModel),
    next2NodeActionBy: types.maybe(UserModel),
    next3NodeActionBy: types.maybe(UserModel),
    forwardList: types.array(UserModel),
    childNodeActionByList: types.array(UserModel),

    operationDate: types.optional(types.Date, () => moment().toDate()),
    comment: types.maybe(types.string),
    currentNodeFinished: types.boolean,
    nextNodeChanged: types.boolean,
    needWaitSubWorkflow: types.maybe(types.string),
    actionDescription: types.array(WIWorkflowActionDescriptionModel),

    emailTemplateId: types.maybe(types.string),
    rejectEmailTemplateId: types.maybe(types.string),
    rejectToNodeId: types.number,
    actionUser: types.array(UserModel),
    approveUserList: types.array(WIWorkflowApproveUserModel),
    consultUserList: types.array(WICommentFormConsultModel),
    commentFormForwardList: types.array(CommentFormForwardListModel),
    commentFormForwarded: types.maybe(UserModel),
    commentFormList: types.array(WICommentFormStatusModel),
  })
  .views((self) => ({
    isSubformBeingConsulted(
      subformId: string,
      upn?: string,
    ): boolean | undefined {
      // it will check whether it is consulted to target user if upn provided
      const targetSubform = self.commentFormList.find(
        (form) => form.formId === subformId,
      )

      if (targetSubform == null) {
        return undefined
      }

      return (
        targetSubform.consultList.find((it) => {
          if (upn == null) {
            return !it.isReturned
          }

          return (
            !it.isReturned && it.user?.upn?.toLowerCase() === upn.toLowerCase()
          )
        }) != null
      )
    },
  }))

export type WIWorkflowActionDescription = Instance<
  typeof WIWorkflowActionDescriptionModel
>
export type WIWorkflowApproveUser = Instance<typeof WIWorkflowApproveUserModel>
export type WIWorkflowPart = Instance<typeof WIWorkflowPartModel>
export type WICommentFormStatus = Instance<typeof WICommentFormStatusModel>
export type CommentFormForwardList = Instance<
  typeof CommentFormForwardListModel
>

export function mapPayloadToWICommentFormStatus(
  payload: WIWorkflowPartNode | undefined,
): SnapshotIn<WICommentFormStatus[] | undefined> {
  if (payload == null || payload.partESubFormList == null) return undefined
  return payload.partESubFormList.map((form) => ({
    formId: stringToString(form.formId),
    user: mapPayloadToUser(form.partAWorkFlowModel.currentActionBy),
    consultList: form.partAWorkFlowModel.consultUserList.map((it) => ({
      isReturned: it.isReturned,
      originalUser: mapPayloadToUser(it.previousActionBy),
      user: mapPayloadToUser(it.user),
    })),
  }))
}

export function mapPayloadToActionDescription(
  payload: WIWorkflowAction[] | undefined,
): SnapshotIn<WIWorkflowActionDescription[] | undefined> {
  if (payload == null) return undefined
  return payload.map((it) => ({
    user: mapPayloadToUser(it.actionUser),
    action: stringToString(it.action),
    actionGroup: stringToString(it.actionGroup),
    nodeTitle: stringToString(it.nodeTitle),
    nodeId: stringToNumber(it.nodeId),
    date: stringToDate(it.date),
    comment: stringToString(it.comment),
    decision: stringToString(it.decision),
    currentStatus: stringToNumber(it.currentStatus),
    displayName: stringToString(it.displayName),
  }))
}

export function mapPayloadToApproveUser(
  payload: PartApproveUser[] | undefined,
): SnapshotIn<WIWorkflowApproveUser[] | undefined> {
  if (payload == null) return undefined
  return payload.map((it) => ({
    partId: stringToNumber(it.partId),
    user: mapPayloadToUser(it.user),
    groupName: stringToString(it.groupName),
  }))
}

export function mapPayloadToCommentFormForwardList(
  payload: WIWorkflowPartNode | undefined,
): SnapshotIn<CommentFormForwardList[] | undefined> {
  if (payload == null) return undefined
  return payload.partESubFormList.map((it) => ({
    formId: stringToString(it.formId),
    forwardList: it.partAWorkFlowModel.forwardList?.map((user) =>
      mapPayloadToUser(user),
    ),
  }))
}

export function mapPayloadToCurrentUserUpnList(
  currentUserUPNList: string[] | undefined,
): string[] {
  if (currentUserUPNList == null) return []

  return currentUserUPNList
}

export function mapPayloadToWIWorkflowPart(
  payload: WIWorkflowPartNode,
): SnapshotIn<WIWorkflowPart> {
  return {
    nodeId: payload.nodeId,
    nodeTitle: stringToString(payload.nodeTitle),
    preNodeId: stringToNumber(payload.preNodeId),
    nextNodeId: stringToNumber(payload.nextNodeId),
    currentStatusId: payload.currentStatusId,
    currentStatusName: stringToString(payload.currentStatusName),
    currentUserGroup: stringToString(payload.currentUserGroup),
    originalActionBy: mapPayloadToUser(payload.originalActionBy),
    currentActionBy: mapPayloadToUser(payload.currentActionBy),
    next1NodeActionBy: mapPayloadToUser(payload.next1NodeActionBy),
    next2NodeActionBy: mapPayloadToUser(payload.next2NodeActionBy),
    next3NodeActionBy: mapPayloadToUser(payload.next3NodeActionBy),
    forwardList: mapPayloadToUserList(payload.forwardList),
    childNodeActionByList: mapPayloadToUserList(payload.childNodeActionByList),
    operationDate: stringToDate(payload.opeaterDateTime),
    comment: stringToString(payload.comment),
    currentNodeFinished: payload.currentNodeIsFinished,
    nextNodeChanged: payload.nextNodeIsChange,
    needWaitSubWorkflow: stringToString(payload.needWaitSubWorkflow),
    actionDescription: mapPayloadToActionDescription(payload.actionDescription),
    emailTemplateId: stringToString(payload.emailTemplateId),
    rejectEmailTemplateId: stringToString(payload.rejectEmailTemplateId),
    rejectToNodeId: stringToNumber(payload.rejectToNodeId),
    actionUser: mapPayloadToUserList(payload.actionUser),
    approveUserList: mapPayloadToApproveUser(payload.partApproveUserList),
    consultUserList: payload.consultUserList.map((it) => ({
      isReturned: it.isReturned,
      originalUser: mapPayloadToUser(it.previousActionBy),
      user: mapPayloadToUser(it.user),
    })),
    commentFormForwardList: mapPayloadToCommentFormForwardList(payload),
    commentFormForwarded:
      payload.partESubFormForword == null
        ? undefined
        : mapPayloadToUser(payload.partESubFormForword),
    commentFormList: mapPayloadToWICommentFormStatus(payload),
  }
}
