export type FillStatus =
  | 'passed'
  | 'flagged'
  | 'missing'
  | 'unfilled'
  | 'notApplicable'
  | null
export type DisplayFillStatus = FillStatus | 'unfilled-warning'

export enum FormDefinitionFieldType {
  none = 'none',
  checkbox = 'check-box',
  checkFlagBox = 'check-flag-box',
  yesNo = 'yes-no',
  numeric = 'numeric',
  textField = 'text-box',
  textArea = 'text-area',
  datetime = 'datetime',
  date = 'date',
  sic = 'check-sic-box',
  singleSelection = 'single-selection',
  multipleSelection = 'multiple-selection',
  integer = 'integer',
  unsupported = 'unsupported',
  secondCheckerCheckbox = '2nd-checker',
  equipmentNumber = 'equip-no',
  workOrder = 'work-order',
}

export enum FormDefinitionCheckboxOptions {
  checked = 'checked',
  flagged = 'flagged',
  notApplicable = 'n/a',
}

export enum FormDefinitionSICOptions {
  maintenanceDone = 'maintenance',
  sicChecked = 'sic-checked',
  notApplicable = 'n/a',
}

export enum FormDefinitionSecondCheckerOptions {
  firstChecker = '1st-checker',
  secondChecker = '2nd-checker',
  notApplicable = 'n/a',
}

export enum FormDefinitionYesNoOptions {
  yes = 'yes',
  no = 'no',
}

export enum ValidationLevel {
  // info,
  warn = 3,
  // error,
}

export enum FormDefinitionValidationErrorType {
  isMandatory = 'mandatory',
  notQualified = 'notQualified',

  shouldGreaterThan = '>',
  shouldGreaterThanOrEqualTo = '>=',
  shouldLessThan = '<',
  shouldLessThanOrEqualTo = '<=',
  shouldEqualTo = '=',
  shouldNotEqualTo = '!=',
  invalid = 'invalid',
  shouldInRange = 'range',

  flagged = 'flagged',
  failed = 'failed',

  workflowNotCompleted = 'workflowNotCompleted',

  notExist = 'notExist',
  expressionEvaluateError = 'expressionEvaluateError',
  missingParameters = 'missingParameters',
  validationValueMissing = 'validationValueMissing',
  unsupportedValidationOperator = 'unsupportedValidationOperator',
  secondCheckerDoneNotAllowToModify = 'secondChecker.secondCheckerDoneNotAllowToModify',
  childrenModifiedNotAllowToCheck = 'secondChecker.childrenModifiedNotAllowToCheck',
}

export type FormDefinitionValidationResult<T> = {
  reason: FormDefinitionValidationErrorType
  value: T | null | undefined
  attribute?: string
  referenceValue?: T
  level: ValidationLevel

  // rule?: FormDefinitionValidationRule
  rejectSave?: boolean
  rejectSubmit?: boolean
  eraseData?: boolean
}

export type FormDefinitionAutofillResult<T> = {
  status: boolean
  reason?: FormDefinitionValidationErrorType
  param?: string
  rule?: string
  value?: T | null | undefined
}

export type NAType = 'self' | 'inherit' | false
export const NA_VALUE = 'ZsXUXQT1Ti'
export type FormDataItemValue = string | number | string[]

export enum ApprovalWorkflow {
  approveOnly = 'approve',
  endorseAndApprove = 'endorse-approve',
}

export const FORMDATA_ITEM_DATE_FORMAT = 'DD/MM/YYYY'
export const FORMDATA_ITEM_DATETIME_FORMAT = 'DD/MM/YYYY HH:mm'

export type FormDataPayloadMetadataItem = {
  wIItemNo?: string
  value?: FormDataItemValue
  notApplicable?: NAType

  itemStatus: DisplayFillStatus
  subitemStatus: DisplayFillStatus | undefined

  start_date?: string
  end_date?: string
}
