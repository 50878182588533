export enum AttunityWorkOrderStatus {
  open = 'OP',
  completed = 'CM',
  cancelled = 'CL',
  // PartiallyCompleted = 'PC',
  // Planning = 'PL',
  // Referred = 'RF',
  // Skipped = 'SK',
  superseded = 'SP',
  voided = 'VD',
}
