import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import { flow, types } from 'mobx-state-tree'

type UploadProps = {
  title: string
  mime: string
  type: string | undefined
  file: File
}

export const AttachmentStoreModel = types
  .model('AttachmentStore')
  .extend(withEnvironment)
  .extend(withRootStore)
  .actions((self) => ({
    upload: flow(function* upload(props: UploadProps) {
      if (self.environment.api.blobHandler == null) {
        return undefined
      }

      const res: string = yield self.environment.api.uploadAttachment(
        {
          ...self,
          ...props,
          platform: 'web',
        },
        self.rootStore.configStore.blobStorageConfig,
      )
      return res
    }),
  }))
