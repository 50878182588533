import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

const mobileWidthBreakPoint = 1024

export enum ScreenSize {
  desktop = 'desktop',
  mobile = 'mobile',
}

function getWindowInnerWidth() {
  return window.innerWidth
}

// function getWindowInnerHeight() {

//   return window.outerHeight
// }

export const useView = (): ScreenSize => {
  const [width, setWidth] = useState(getWindowInnerWidth())
  // const [height, setHeight] = useState(getWindowInnerHeight())

  useEffect(() => {
    function handleResize() {
      setWidth(getWindowInnerWidth())
      // setHeight(getWindowInnerHeight())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return width <= mobileWidthBreakPoint && width < 1024 || isMobile
    ? ScreenSize.mobile
    : ScreenSize.desktop
}
