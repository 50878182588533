import {
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { Icon } from '../icon'
import { icons } from '../../theme'

const DialogTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DialogTitleText = styled(Typography)`
  min-height: 100%;
  font-size: ${({ theme }) => theme.fontSize[5]}px;
`

const StyledDialogIcon = styled(Icon)`
  width: 25px;
  height: 25px;
`

type DialogProps = {
  title: string
  onClose: () => void
  isDialogOpen: boolean
}

export const Dialog = observer<DialogProps>(
  ({ onClose, isDialogOpen, title, children }) => (
    <MuiDialog onClose={onClose} fullWidth open={isDialogOpen} maxWidth="xs">
      <DialogTitle>
        <DialogTitleContainer>
          <DialogTitleText>{title}</DialogTitleText>
          <IconButton onClick={onClose}>
            <StyledDialogIcon {...icons.remove} />
          </IconButton>
        </DialogTitleContainer>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </MuiDialog>
  ),
)
