/* eslint-disable no-param-reassign */
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import type Xml2js from 'xml2js'
import { FormDefinitionAttachment } from './form-definition-attachment.model'
import { FormDefinitionItemGroup } from './form-definition-item-group.model'
import { FormDefinitionItem } from './form-definition-item.model'

type FormXmlParsedResult = {
  referenceNumber?: string

  parserVersion: number
  groups: FormDefinitionItemGroup[]

  pdfUrl?: string
  okMallUrl?: string

  displayOptions?: {
    gridAvailable?: boolean
    gridLength?: number
    gridDirection?: string
    batchFillAvailable?: boolean
  }

  attachmentFiles?: {
    id: string
    type: string
    url: string
    cacheable: boolean
  }[]

  attachments: FormDefinitionAttachment[]
}

/**
 * 解析 XML String 为 from 对象
 *
 * @param {Xml2js.convertableToString} xml
 * @param {typeof Xml2js} xml2js
 * @return {*}
 */
async function parseFormXml(
  xml: Xml2js.convertableToString,
  xml2js: typeof Xml2js,
) {
  const options = {
    trim: true,
    mergeAttrs: true,
    explicitArray: false,
    attrNameProcessors: [_.camelCase],
    tagNameProcessors: [_.camelCase],
    valueProcessors: [xml2js.processors.parseBooleans],
    async: true,
  }
  const content = await xml2js.parseStringPromise(xml, options)
  return content.form
}

const toArray = (item: any): any[] =>
  item == null ? [] : Array.isArray(item) ? item : [item]

/**
 * 每个level的item对象组装
 *
 * @param {number} level
 * @param {*} itemJson
 * @param {{ id: string; inXmlId: string }[]} formAttachments
 * @return {*}
 */
const parseTypeTwoItem: (
  level: number,
  item: any,
  formAttachments: { id: string; inXmlId: string }[],
) => Partial<FormDefinitionItem> = (
  level: number,
  itemJson: any,
  formAttachments: { id: string; inXmlId: string }[],
) => {
  const id = uuid()

  const applyInOverrides =
    itemJson.applyInOverrides == null
      ? undefined
      : toArray(itemJson.applyInOverrides)

  const applyInSubtypes = (applyInOverrides ?? []).filter(
    (override) => override.type === 'equipment-type',
  )
  
  return {
    ...itemJson,
    id,
    level,
    displayOrder: +itemJson.displayOrder,
    gridDisplayOrder: itemJson.gridDisplayOrder
      ? +itemJson.gridDisplayOrder
      : undefined,
    inputOptions: itemJson.inputOptions
      ? Array.isArray(itemJson.inputOptions)
        ? itemJson.inputOptions
        : [itemJson.inputOptions]
      : [],
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    subitems: parseTypeTwoItems(level + 1, itemJson.items, formAttachments),
    attachments: (itemJson.attachments
      ? Array.isArray(itemJson.attachments)
        ? itemJson.attachments
        : [itemJson.attachments]
      : []
    ).map((attachment) => ({
      ...attachment,
      file: formAttachments.find((a) => a.inXmlId === attachment.id).id,
      displayOrder: +attachment.displayOrder,
    })),
    isMandatory:
      itemJson.isMandatory === true || itemJson.isMandatory === 'True'|| itemJson.isMandatory === 'true',
    isNaAllowed: !(
      itemJson.naAllowed === false || itemJson.naAllowed === 'False'|| itemJson.naAllowed === 'false'
    ),
    gridLabel: itemJson.gridLabel || undefined,
    showInGrid:
      itemJson.showInGrid == null
        ? undefined
        : itemJson.showInGrid === true || itemJson.showInGrid === 'True',
    masterDataType: itemJson.masterDataType || undefined,
    suitableEquipmentSubtypes:
      applyInSubtypes.length === 0
        ? undefined
        : applyInSubtypes.map(({ name }) => name.toLowerCase()),
    validationRules: toArray(itemJson.validationRules),
    autofillRules: itemJson.autofillRules
      ? Array.isArray(itemJson.autofillRules)
        ? itemJson.autofillRules[0]
        : itemJson.autofillRules
      : itemJson.autofillRules,
    parameters: itemJson.parameters
      ? Array.isArray(itemJson.parameters)
        ? itemJson.parameters
        : [itemJson.parameters]
      : [],
    qualifications:
      itemJson.qualification == null
        ? undefined
        : toArray(itemJson.qualification).map((it) => ({
            cqaCode: it.cqaCode.split(',').map((code) => code.trim()),
            value: it.value,
          })),
    relatedFormNumbers: (itemJson.formNo as string | undefined)
      ?.split(',')
      .map((it) => it.trim())
      .filter((it) => it.length > 0),
  }
}

/**
 * 递归解析每层的Item
 *
 * @param {number} level
 * @param {*} itemsJson
 * @param {{ id: string; inXmlId: string }[]} formAttachments
 * @return {*}
 */
const parseTypeTwoItems: (
  level: number,
  items: any,
  formAttachments: { id: string; inXmlId: string }[],
) => Partial<FormDefinitionItem>[] = (
  level: number,
  itemsJson: any,
  formAttachments: { id: string; inXmlId: string }[],
) => {
  if (!itemsJson) return []
  return (Array.isArray(itemsJson) ? itemsJson : [itemsJson]).map((item) =>
    parseTypeTwoItem(level, item, formAttachments),
  )
}
/**
 * 将 xml 解析出的 form 对象 组装成 FormDefinitionModel 所需要的格式
 *
 * @param {*} rawStructure
 * @return {*}
 */
const parseTypeTwo: (rawStructure: any) => Partial<FormXmlParsedResult> = (
  rawStructure: any,
) => {
  try {
    const formDefinition: Partial<FormXmlParsedResult> = {
      parserVersion: 1,
    }

    const rawWorkInstructions = rawStructure.workInstruction
    formDefinition.referenceNumber = rawWorkInstructions.wiNumber ?? undefined
    formDefinition.pdfUrl = rawWorkInstructions.pdfUrl ?? undefined
    formDefinition.okMallUrl = rawWorkInstructions.okMallUrl ?? undefined
    formDefinition.displayOptions = {
      gridAvailable: !!rawStructure.gridView,
      gridDirection: rawStructure.gridView
        ? rawStructure.gridView.direction
        : undefined,
      gridLength:
        rawStructure.gridView?.length == null ||
        rawStructure.gridView?.length === ''
          ? undefined
          : +rawStructure.gridView.length,
      batchFillAvailable: !(
        rawStructure.batchFillAvailable === false ||
        rawStructure.batchFillAvailable === 'False'
      ),
    }

    const attachmentJsons =
      !rawStructure.assets || !rawStructure.assets.attachments
        ? []
        : Array.isArray(rawStructure.assets.attachments)
        ? rawStructure.assets.attachments
        : [rawStructure.assets.attachments]

    const attachments = attachmentJsons.map((json) => ({
      id: uuid(),
      inXmlId: json.id,
      name: json.name ?? 'Untitled Attachment',
      type: json.type.toLowerCase(),
      cacheable: !!json.cacheable,
      url: json.url,
    }))

    formDefinition.attachmentFiles = attachments

    formDefinition.groups = (
      Array.isArray(rawWorkInstructions.groups)
        ? rawWorkInstructions.groups
        : [rawWorkInstructions.groups]
    ).map((groupJson) => {
      const id = uuid()
      return {
        ...groupJson,
        id,
        groupId: groupJson.groupId,
        number: groupJson.displayOrder,
        displayOrder: +groupJson.displayOrder,
        items: parseTypeTwoItems(0, groupJson.items, attachments),
      }
    })

    formDefinition.attachments = toArray(rawWorkInstructions.attachments)
      .map((attachment) => ({
        ...attachment,
        name:
          attachment.name ??
          attachments.find((a) => a.inXmlId === attachment.id)?.name ??
          'Untitled',
        file: attachments.find((a) => a.inXmlId === attachment.id)?.id,
        displayOrder: +attachment.displayOrder,
      }))
      .filter((it) => it.file != null)

    console.tron.display({
      name: 'XML Parse Result',
      preview: rawStructure.number,
      value: [rawStructure, formDefinition],
    })

    return formDefinition
  } catch (err) {
    console.tron.display({
      name: 'XML Parse Error',
      preview: rawStructure.number,
      important: true,
      value: rawStructure,
    })

    console.tron.reportError(err)
    throw err
  }
}

/**
 * xml 转 from 方法
 *
 * @param {*} xml
 * @param {*} xml2js
 * @return {*}
 */
export const parsePMFormXml: (
  xml: string,
  xml2js: typeof Xml2js,
) => Promise<Partial<FormXmlParsedResult>> = async (xml, xml2js) => {
  // 检查 xml 存在性
  if (!xml) {
    console.warn('No xml available for parsing')
    return {}
  }
  // 将 xml 字符串 转 form 对象
  const rawStructure = await parseFormXml(xml, xml2js)
  // 组装 form 对象 提供给 外层 模型使用
  if (rawStructure.parserType === '2') {
    return parseTypeTwo(rawStructure)
  }

  throw Error('parser-type-unsupported')
}
