import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import { Instance, types } from 'mobx-state-tree'

const bookOrderPreprocessSnapshot = (snapshot: any): any => ({
  objectID: snapshot.objectID,
  formNo: snapshot.formNo,
  workTypeName: snapshot.workTypeName,
  versionNo: snapshot.versionNo,
  formName: snapshot.formName,
  formReferenceNo: snapshot.formReferenceNo,
  status: snapshot.status,
  statusName: snapshot.statusName,
  createDate: snapshot.createDate,
  isReadOnly: snapshot.isReadOnly,
  isSpecialForm: snapshot.isSpecialForm??false,
  tips: snapshot.tips ?? '',

  formIssueNo: snapshot.formIssueNo,
  formRevNo: snapshot.formRevNo,
  formIssueDate: snapshot.formIssueDate,
  formRefNo: snapshot.formRefNo
})

export const BookOrderItemModel = types
  .model('BookOrderItem', {
    objectID: types.string,
    formNo: types.string,
    versionNo: types.number,
    formName: types.string,
    formReferenceNo: types.string,
    status: types.number,
    statusName: types.string,
    createDate: types.Date,
    isReadOnly: types.boolean,
    isSpecialForm: types.boolean,
    tips: types.string,

    formIssueNo: types.number,
    formRevNo: types.number,
    formIssueDate: types.string,
    formRefNo: types.string
  })
  .preProcessSnapshot(bookOrderPreprocessSnapshot)
  .extend(withEnvironment)
  .extend(withRootStore)
  .volatile(() => ({
    lastMaintenanceUnixTime: undefined as number | undefined,
  }))
  .views((self) => {
    const views = {
      // get eventID(): string {
      //   return self.eventID
      // },
      // get workTypeName(): string {
      //   return self.workTypeID
      // },
      // get vehicleNos(): string {
      //   return self.vehicleNos
      // },
      // get createDate(): Date {
      //   return self.createDate
      // },
    }
    return views
  })
  .actions((self)=>{
    const actions = {
      setIsReadonly(isReadonly: boolean) {
        self.isReadOnly = isReadonly
      },
    }
    return actions
  })
export type BookOrderItem = Instance<typeof BookOrderItemModel>
