import { ThemeProvider } from '@mtr-SDO/theme-core'
import { sdoTheme } from '@mtr-SDO/theme-sdo'
import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './app'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={sdoTheme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

