import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import {
  flow,
  getParent,
  IAnyModelType,
  Instance,
  SnapshotIn,
  types,
} from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import moment from 'moment'

import {
  ApprovalWorkflow,
  Form,
  FormData,
  FormDataStatus,
  FormDefinition,
  FormDefinitionItem,
  FormModel,
  ValidationOptions,
} from '../forms'

export const BookOrderFormModel = types
  .model('BookOrderForm', {
    id: types.optional(types.identifier, uuid),

    form: types.reference(types.late((): IAnyModelType => FormModel)),
    version: types.number,

    optional: types.boolean,
    isUnique: types.optional(types.boolean, true),
    allowMultiple: types.optional(types.boolean, false),
    rejected: types.optional(types.boolean, false),

    remoteFormDataId: types.maybe(types.string), // form-data-object-id
    referenceNumber: types.maybe(types.string),

    author: types.maybe(types.string), // first uploader

    lastUpdateDate: types.maybe(types.Date),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .volatile(() => ({
    isRefreshing: false,
    lastUpdate: moment().toDate(),
  }))
  .views((self) => {
    const views = {
      get formDefinition(): FormDefinition {
        return self.form.getDefinition(self.version)
      },
    }
    return views
  })
export type BookOrderForm = Instance<typeof BookOrderFormModel>
