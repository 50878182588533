import {useState,useEffect} from 'react'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { List, ListItemButton, ListItemText, Button, TextField } from '@mui/material'
import { Theme } from '@mtr-SDO/theme-core'
import { useTranslation } from 'react-i18next'
import { DialogMessage, Spinner } from '@/components'
import { useUpdateUser } from '@/queries'
import { useStores } from '../../models'

const StyledList = styled(List)`
  width: 100%;
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.palettes.general.background};
`
const StyledListItem = styled(ListItemButton)`
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.palettes.general.white};
  margin: 2px auto;
  border: 1px solid
    ${({ theme }: { theme: Theme }) => theme.palettes.general.offWhite};
`
const StyledTextDiv = styled.div`
  float: left;
`
const StyledErorDiv = styled.div`
  clear: both;
  color:red;
  margin-top: 10px;
  margin-bottom: -8px;
`
const StyledTextField = styled(TextField)`
  margin-top: -8px;
  margin-bottom: -8px;
`
const StyledButtonGroup = styled.div`
  float: right;
`
const StyledButton = styled(Button)`
  display: inline-block;
  width: 100px;
  aspect-ratio: 7/3;
  margin-left: 15px;
  text-transform: none;
  border-radius: 8px;
  font-size: 16px;
  &.styledPrimary {
    background-color: #367fa9;
    color: #f8f3f3;
  }
  &.styledDefault {
    background-color: #f2f0f0;
    color: #222121;
    border: solid 1px #367fa9;
  }
`
const StyledSpinner = styled(Spinner)`
  margin: 20px;
`
const StyledTips = styled.div`
  padding: 8px 16px;
  margin-top: 50px;
`
const StyledTipsSection = styled.p`
  white-space: pre-wrap;
`

export const UserInformationPage = observer(() => {
  const { t } = useTranslation()
  const { userOperationStore } = useStores()
  // const { data } = useFetchUserInfo()
  
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [open, setOpen] = useState(false)
  const [dialogMessage, SetDialogMessage] = useState<string[]>([])
  const [hasData, setHasData] = useState<boolean>(false)
  const [dataUserName, setDataUserName] = useState<string>()
  const [dataUserRole, setDataUserRole] = useState<string>()
  const [dataEmail, setDataEmail] = useState<string>()
  const [dataContactNumber, setDataContactNumber] = useState<string>()
  const [dataCompanyName, setDataCompanyName] = useState<string>()

  const [contactNumber, setContactNumber] = useState<string>()
  const [companyName, setCompanyName] = useState<string>()
  const [contactNumberIsValid, setContactNumberIsValid] = useState<boolean>(true)
  const [companyNameIsValid, setCompanyNameIsValid] = useState<boolean>(true)

  const changeIsEdit = () => {
    setContactNumberIsValid(true)
    setCompanyNameIsValid(true)
    setIsEdit(!isEdit)
  }

  const { mutateAsync: updateUserAsync, isLoading } = useUpdateUser({
    onMutate(variables) {
      setOpen(true)
      return variables
    },
    onError(error) {
      SetDialogMessage([error.message])
    },
    onSuccess(result) {
      if (result.kind === 'ok') {
        setDataContactNumber(contactNumber)
        setDataCompanyName(companyName)
        changeIsEdit()
        SetDialogMessage([t('userInformationPage.updateSucess')])
      }
    },
  })

  const handleClose = () => {
    setOpen(false)
  }


  const onContactNumberChaned = (value:string)=>{
    setContactNumber(value)
    const patten = /^([4-9])\d{7}$/
     if(!patten.test(value)){
      setContactNumberIsValid(false)
     }else{
      setContactNumberIsValid(true)
     }
  }

  const onCompanyNameChaned = (value:string)=>{
    setCompanyName(value)
    if(!value || value?.length === 0){
      setCompanyNameIsValid(false)
    }else{
      setCompanyNameIsValid(true)
    }
  }

  const updateUser = async () => {
    let hasError =false
    if(!contactNumber){
      hasError = true
      setContactNumberIsValid(false)
    }else{
     const patten = /^([4-9])\d{7}$/
     if(!patten.test(contactNumber)){
      hasError = true
      setContactNumberIsValid(false)
     }
    }

    if(!companyName){
      hasError = true
      setCompanyNameIsValid(false)
    }

    if(!hasError){
      try {
        const updateData = {
          contactNumber:contactNumber ?? '',
          companyName:companyName ?? ''
        }
        
        await updateUserAsync(updateData)
        
      } catch {}
     
    }   
  }

  const fetchUserInfo = async ()=>{
    const result = await userOperationStore.fetchUserInfo()
    setHasData(true)
    setDataUserName(result.userName)
    setDataUserRole(result.roleName)
    setDataEmail(result.email)
    setDataContactNumber(result.contactNumber)
    setDataCompanyName(result.companyName)
    setContactNumber(result.contactNumber)
    setCompanyName(result.companyName)
  }

  useEffect(()=>{
    fetchUserInfo()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return !hasData ? (
    <Spinner />
  ) : (
    <StyledList aria-label="mailbox folders">
      <StyledListItem key='userName'>
        <ListItemText>
            {t(`userInformationPage.userName`)}：{dataUserName}
        </ListItemText>
      </StyledListItem>
      <StyledListItem key='roleName'>
        <ListItemText>
          {t(`userInformationPage.userRole`)}：{dataUserRole}
        </ListItemText>
      </StyledListItem>
      <StyledListItem key='email'>
        <ListItemText>
        {t(`userInformationPage.email`)}：{dataEmail}
        </ListItemText>
      </StyledListItem>
      <StyledListItem key='contactNumberEdit'>
          <ListItemText>
            <StyledTextDiv>{t(`userInformationPage.contactNumber.label`)}：</StyledTextDiv>
            {isEdit && 
            <StyledTextField 
              size='small' 
              value={contactNumber}  
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onContactNumberChaned(event.target.value)
              }} 
              inputProps={{
                maxLength: 10,
              }}
              error={!contactNumberIsValid}
            />} 
            {!isEdit && <>{dataContactNumber}</>} 
            {isEdit && !contactNumberIsValid && <StyledErorDiv>
              {t(`userInformationPage.contactNumber.pattern`)}
            </StyledErorDiv>}
          </ListItemText>
      </StyledListItem>
      <StyledListItem key='companyName'>
        <ListItemText>
          <StyledTextDiv>{t(`userInformationPage.companyName.label`)}：</StyledTextDiv>
            {isEdit && 
            <StyledTextField 
              size='small' 
              value={companyName} 
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onCompanyNameChaned(event.target.value)
              }} 
              inputProps={{
                maxLength: 50,
              }}
              error={!companyNameIsValid}
             />} 
            {!isEdit && <>{dataCompanyName}</>} 
            {isEdit && !companyNameIsValid && <StyledErorDiv>
              {t(`userInformationPage.companyName.required`)}
            </StyledErorDiv>}
        </ListItemText>
      </StyledListItem>
      <StyledButtonGroup>
        {isEdit && <>
          <StyledButton
            disableElevation
            variant="contained"
            className="styledPrimary"
            onClick={changeIsEdit}
          >
            {t(`userInformationPage.back`)}
          </StyledButton>
          <StyledButton
            disableElevation
            variant="contained"
            className="styledPrimary"
            onClick={updateUser}
          >
            {t(`userInformationPage.save`)}
          </StyledButton>
        </>}
        {!isEdit && 
          <StyledButton
            disableElevation
            variant="contained"
            className="styledPrimary"
            onClick={changeIsEdit}
          >
            {t(`userInformationPage.update`)}
          </StyledButton>}
      </StyledButtonGroup>

      <StyledTips>
        <StyledTipsSection>
          {t(`userInformationPage.tips.part1`)}
        </StyledTipsSection>
        <StyledTipsSection>
          {t(`userInformationPage.tips.part2`)}
        </StyledTipsSection>
      </StyledTips>

      <DialogMessage
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        contentTextArray={dialogMessage}
        confirmButtonProps={{ children: t('ok'), onClick: handleClose }}
        {...{
          ...(isLoading
            ? {
                customContent: <StyledSpinner />,
                onClose: undefined,
                customDialogActions: <></>,
              }
            : { fullWidth: true, maxWidth: 'xs' }),
        }}
      />
    </StyledList>
  )
})
