type BreadcrumbItem = {
  path: string
  displayName: string
}

export const breadcrumbItemList: { [key: string]: BreadcrumbItem } = {
  home: { path: '/home', displayName: 'Home' },
  'wi-review': { path: '/wi-review', displayName: 'WI Review' },
  wicn: { path: '/wicn', displayName: 'WICN' },
  'wi-task-list': {
    path: '/wi-task-list',
    displayName: 'WI Workflow Task List',
  },
  notification: { path: '/notification', displayName: 'Notifications' },
}
