import {
  CMDetailPayload,
  CMDetailStatusPayload,
  CMRequestItem,
  EquipmentPayload,
  GeneralApiResponse,
  GetCMAssociatedCodes,
  GetCMWorkOrderPersonInChargeOptions,
  GetEquipmentInfo,
  GetEquipmentType,
  GetFitmentList,
  GetStandardJobs,
  GetUserStandardJobCode,
  GetWorkGroups,
  PostCMDetail,
  ReviewWorkOrderPayloadItem,
  WorkOrderMain,
  AttunityStandardJobPayload,
  WorkOrderPayload,
  WICNTaskPayloadItem,
  WICNDataPayload,
  PostWIProcess,
  ActionBy,
  WIReviewDataPayload,
  WIReviewTaskPayloadItem,
  GetRelatedWICNData,
} from '@mtr-SDO/apis'
import {
  CMDetail,
  CMDetailContent,
  CMDetailModel,
  Depot,
  Equipment,
  EquipmentModel,
  EquipmentType,
  Fitment,
  fitmentSnapshotFromPayload,
  Form,
  getWICNStatusNumberByValue,
  getWIReviewStatusNumberByValue,
  getWorkOrderSnapshotStatus,
  mapCMDetailContentToPayload,
  mapPayloadToCMDetailContent,
  mapPayloadToWICN,
  mapPayloadToWICNTask,
  mapPayloadToWIReview,
  mapPayloadToWIReviewTask,
  StandardJob,
  StandardJobModel,
  TrainStockType,
  WICN,
  WICNModel,
  WICNTask,
  WICNTaskModel,
  WITaskSearchCriteria,
  WIReview,
  WIReviewModel,
  WIReviewTask,
  WIReviewTaskModel,
  WorkNature,
  WorkOrder,
  WorkOrderForm,
  WorkOrderModel,
  WorkOrderOfflineCache,
  WorkOrderStatus,
  TaskType,
  WICNStatus,
  WIReviewStatus,
  mapPayloadToRelatedWICN,
} from '@mtr-SDO/datamodels'
import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import { notEmpty } from '@mtr-SDO/utils'
import _ from 'lodash'
import { action } from 'mobx'
import {
  flow,
  getSnapshot,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types,
} from 'mobx-state-tree'
import moment from 'moment'
import { DeepNullable, DeepPartial } from '../../../../tools/tsconfig-node'
import { withAttunitySearch } from './attunity-search'

export type WorkStoreLocalCacheType = {
  standardJobs: SnapshotOut<StandardJob>[]
  equipments: SnapshotOut<Equipment>[]
  workOrders: WorkOrderOfflineCache[]
}

export type CMAssociatedCodeItem = {
  id: number
  equipClassId: string
  equipClassCd: string
  type: string
  code: string
  desp: string
}

export type WorkOrderSearchCriteria = {
  standardJobCode?: string
  workOrderNumber?: string
  parentWorkOrder?: string
  equipmentNumber?: string
  depot?: Depot
  trainStockType?: TrainStockType
  status?: WorkOrderStatus
  maintenanceCategory?: WorkNature
  createdDate?: moment.Moment[]
  updateDate?: moment.Moment[]
  imte?: string
}

export type WorkOrderSortCriteria = {
  workOrderNumber?: string
  createdDate?: string
  updateDate?: string
}

type WorkOrderSearchReturnType = {
  standardJobs: StandardJob[]
  workOrders: WorkOrder[]
}

export type FitmentSearchCriteria = {
  equipmentNumber?: string
  createdBy?: { label: string; value: string }
  parentEquipmentNumber?: string
  position?: string
}

export type FitmentSortCriteria = {
  fitmentDate?: string
  defitmentDate?: string
}

export function processAttunityWorkOrderPayloadItem(
  workOrderPayload: AttunityStandardJobPayload['workOrders'][0],
  standardJob: StandardJob,
  equipment: Equipment,
  depot: Depot | undefined,
) {
  return {
    number: workOrderPayload.workOrderNo,
    equipment: equipment.id,
    standardJob: standardJob.id,
    startDate:
      workOrderPayload.planStartDate != null
        ? moment(workOrderPayload.planStartDate).toDate()
        : undefined,
    completeDate:
      workOrderPayload.planCompleteDate != null
        ? moment(workOrderPayload.planCompleteDate).toDate()
        : undefined,
    estimatedDuration: workOrderPayload.estimateJobDuration,
    workGroupId: workOrderPayload.workGroupID,
    workGroupCode: workOrderPayload.workGroupCode,

    status: WorkOrderStatus.pending, // FIXME work order status hardcoded
    depot: depot?.id,

    description: workOrderPayload.workOrderDescription,
    createDate:
      workOrderPayload.createDate != null
        ? moment(workOrderPayload.createDate).toDate()
        : moment().toDate(),

    relation: ['PARENT', 'CHILD'].includes(
      workOrderPayload.relationshipType ?? '',
    )
      ? workOrderPayload.relationshipType
      : undefined,
    parentWorkOrderNumber: workOrderPayload.parentWorkOrderNo ?? undefined,
    trainStockTypeId: equipment.stockType?.id,
  }
}

export const WorksStoreModel = types
  .model('WorksStore')
  .props({
    equipments: types.array(EquipmentModel),
    standardJobs: types.array(StandardJobModel),
    workOrders: types.array(WorkOrderModel),

    cmDetails: types.array(CMDetailModel),

    wicnTasks: types.array(WICNTaskModel),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .extend(withAttunitySearch)
  .views((self) => ({
    get availableStandardJobs() {
      return self.standardJobs
    },
    findEquipment(number: string) {
      return self.equipments.find((it) => it.number === number)
    },
    findWorkOrder(number: string) {
      return self.workOrders.find((it) => it.number === number)
    },
    get localCacheData() {
      const localCache: WorkStoreLocalCacheType = {
        standardJobs: self.standardJobs.map((job) => getSnapshot(job)),
        equipments: self.equipments.map((eqip) => getSnapshot(eqip)),
        workOrders: self.workOrders
          .filter(
            (workOrder: WorkOrder) =>
              workOrder.lastMaintenanceTime != null ||
              workOrder.offlineAvailable(),
          )
          .map((workOrder: WorkOrder) => workOrder.offlineCache),
      }
      return localCache
    },
  }))
  .actions((self) => {
    const upsertWorkOrder = action((snapshot: SnapshotIn<WorkOrder>) => {
      let ret: WorkOrder | undefined = self.workOrders.find(
        (workOrder) =>
          workOrder.number === snapshot.number &&
          workOrder.standardJob.id === snapshot.standardJob,
      )

      if (ret) {
        ret.apply(snapshot)
      } else {
        ret = WorkOrderModel.create(snapshot)
        self.workOrders.push(ret)
      }
      return ret
    })

    const upsertEquipment = (snapshot: SnapshotIn<Equipment>) => {
      let ret = self.equipments.find(
        (equipment) =>
          equipment.number === snapshot.number &&
          equipment.stockType?.id === snapshot.stockType,
      )

      if (ret) {
        ret.apply(snapshot)
      } else {
        ret = EquipmentModel.create(snapshot)
        self.equipments.push(ret)
      }
      return ret
    }

    const upsertEquipmentByPayload = (payload: EquipmentPayload) =>
      upsertEquipment({
        number: payload.equipmentCode,
        stockType: payload.trainStockTypeID.toString(),
        subtype: payload.cartType.trim().toLowerCase(),
      })

    const upsertStandardJob = (snapshot: SnapshotIn<StandardJob>) => {
      let ret: StandardJob | undefined = self.standardJobs.find(
        (standardJob) => standardJob.code === snapshot.code,
      )

      if (ret) {
        ret.apply(snapshot)
      } else {
        ret = StandardJobModel.create(snapshot)
        self.standardJobs.push(ret)
      }
      return ret
    }

    const fetchEquipment = flow(function* fetchEquipment(
      equipmentNumber: string,
    ): Generator<any, Equipment | null, any> {
      try {
        const res: GetEquipmentInfo =
          yield self.environment.api.getEquipmentInfo(equipmentNumber)
        if (res.kind !== 'ok') throw new Error(res.kind)

        if (res.payload == null) return null
        if (res.payload.equipmentCode !== equipmentNumber)
          throw Error('response-number-mismatch')

        return upsertEquipmentByPayload(res.payload)
      } catch (error) {
        self.environment.console.reportError(error)
        throw error
      }
    })

    const actions = {
      reInitialize() {
        self.equipments.clear()
        self.standardJobs.clear()
        self.workOrders.clear()
      },
      upsertWorkOrder,
      upsertEquipment,
      upsertEquipmentByPayload,
      upsertStandardJob,
      upsertWorkOrders(snapshots: SnapshotIn<WorkOrder>[]) {
        return snapshots.map(upsertWorkOrder)
      },
      upsertEquipments(snapshots: SnapshotIn<Equipment>[]) {
        return snapshots.map(upsertEquipment)
      },

      fetchEquipment,
      removeWorkOrder(wo: WorkOrder) {
        if (wo?.status === WorkOrderStatus.pending) {
          // eslint-disable-next-line no-param-reassign
          wo.status = WorkOrderStatus.requestedForComplete
        }
      },

      fetchStandardJob: flow(function* fetchStandardJob(
        jobCode: string,
        nature: string,
      ): Generator<any, any, any> {
        const res: any = yield self.environment.api.getStandardJob(jobCode)
        if (res.kind !== 'ok') throw new Error(res.kind)
        if (res.message.result === 0) throw Error(res.message.message)
        const standardJob = res.payload
        // self.environment.console.logImportant('Standard Job fetch result', res.payload)
        const jobSnapshot = {
          code: jobCode,
          title: standardJob.formGroupName,
          nature: self.rootStore.masterDataStore.workNatures.get(
            nature.toLowerCase(),
          ).id,
        }
        // self.environment.console.logImportant('Job Snapshot', jobSnapshot)
        return actions.upsertStandardJob(jobSnapshot)
      }),

      searchAssemblyEquipmentFitments: flow(
        function* searchAssemblyEquipmentFitments(assemblySn: string) {
          const res: GetFitmentList =
            yield self.environment.api.searchAssemblyEquipmentFitments(
              assemblySn,
            )
          if (res.kind !== 'ok') throw new Error(res.kind)
          // if (res.message?.result !== 1) throw new Error(res.message.message)

          return res.payload.map(
            (it) =>
              ({
                ...fitmentSnapshotFromPayload(it),
                workOrder: it.workOrder,
              } as SnapshotIn<Fitment> & { workOrder: string }),
          )
        },
      ),

      applyLocalCache(cache: WorkStoreLocalCacheType) {
        cache.equipments.forEach((equip) => actions.upsertEquipment(equip))
        cache.standardJobs.forEach((job) => actions.upsertStandardJob(job))
        actions.applyLocalCacheForWorkOrder(cache)
      },
      applyLocalCacheForWorkOrder(
        cache: WorkStoreLocalCacheType,
        number?: string,
      ) {
        // convert equipment, depot, and standardjob to mst IDs
        const workOrderSnapshots = cache.workOrders
          .map((woCacheData: WorkOrderOfflineCache) => {
            if (number != null && woCacheData.number !== number) return null

            let equipment: Equipment = self.equipments.find(
              (eq) => eq.number === woCacheData.equipmentNumber,
            )

            if (equipment == null) {
              const snapshot = cache.equipments.find(
                (equip) => equip.number === woCacheData.equipmentNumber,
              )
              if (snapshot != null) {
                equipment = actions.upsertEquipment(snapshot)
              }
            }
            if (equipment == null) {
              self.environment.console.warn(
                'Missing Equipment for WorkOrder Cache',
                woCacheData,
              )
              return null
            }

            let standardJob = self.standardJobs.find(
              (job) => job.code === woCacheData.standardJobCode,
            )

            if (standardJob == null) {
              const snapshot = cache.standardJobs.find(
                (job) => job.code === woCacheData.standardJobCode,
              )
              if (snapshot != null) {
                standardJob = actions.upsertStandardJob(snapshot)
              }
            }

            if (standardJob == null) {
              self.environment.console.warn(
                'Missing Equipment for WorkOrder Cache',
                woCacheData,
              )
              return null
            }

            return {
              ..._.omit(woCacheData, ['equipmentNumber', 'standardJob']),
              equipment: equipment.id,
              trainStockTypeId: equipment.stockType?.id,
              standardJob: standardJob.id,
              depot: woCacheData.depot?.id,
            }
          })
          .filter((data) => data !== null)

        workOrderSnapshots.forEach((workOrderData) => {
          const workOrder: WorkOrder = actions.upsertWorkOrder(
            _.omit(workOrderData, ['forms']),
          )
          workOrderData?.forms.forEach((woFormCache) => {
            const form = self.rootStore.formStore.upsertForm(woFormCache.form)
            if (!form) {
              self.environment.console.warn(
                'Missing Form for WorkOrder Cache',
                {
                  workOrderCache: workOrderData,
                  woFormCache,
                },
              )
            }
            const snapshot: SnapshotIn<WorkOrderForm> = {
              ...woFormCache,
              form: form.id,
            }
            workOrder.upsertWorkOrderForm(snapshot)
          })
        })
      },
      getEquipmentType: flow(function* getEquipmentType(
        equipmentNumber: string,
      ): Generator<
        any,
        { type: EquipmentType; isActive: boolean } | undefined,
        any
      > {
        const res: GetEquipmentType =
          yield self.environment.api.getEquipmentType(equipmentNumber)

        if (res.kind !== 'ok') throw new Error(res.kind)
        const equipmentType = res?.payload?.data?.category
        const isActive = res?.payload?.data?.isActive

        switch (equipmentType) {
          case 'Assembly':
            return { type: EquipmentType.Assembly, isActive }
          case 'RailAssure':
            return { type: EquipmentType.RailAssure, isActive }
          default:
            return undefined
        }
      }),
    }
    return actions
  })
  .actions((self) => {
    async function processWorkOrderPayload(
      woData: WorkOrderPayload,
    ): Promise<SnapshotIn<WorkOrder> | undefined> {
      const depot =
        self.rootStore.masterDataStore.findDepot(woData.depotID)?.id ||
        undefined

      let equipment: Equipment | undefined = self.equipments.find(
        (eq) => eq.number === woData.equipmentNo,
      )
      if (equipment == null && woData.equipmentNo != null)
        equipment = (await self.fetchEquipment(woData.equipmentNo)) ?? undefined

      if (equipment == null)
        throw new Error(`invalid-equipment|${woData.equipmentNo}`)

      let standardJob: StandardJob | undefined = self.standardJobs.find(
        (it) => it.code === woData.standardJobCode,
      )

      if (standardJob == null) {
        const standardJobSnapshot: SnapshotIn<StandardJob> = {
          code: woData.standardJobCode,
          title: woData.formGroupName,
          nature: self.rootStore.masterDataStore.workNatures.get(
            woData.workNature?.toLowerCase() ?? '',
          ).id,
        }

        try {
          standardJob =
            self.rootStore.worksStore.upsertStandardJob(standardJobSnapshot)
        } catch (error) {
          self.environment.console.log(
            'Cannot construct valid standard job',
            standardJobSnapshot,
            woData,
          )
          self.environment.console.reportError(error)
        }
      } else if (
        woData.formGroupName != null &&
        woData.formGroupName.length > 0
      ) {
        standardJob.setTitle(woData.formGroupName)
      }

      if (standardJob == null)
        throw new Error(`invalid-standard-job|${woData.standardJobCode}`)

      return {
        number: woData.workOrder,
        depot,
        equipment,
        standardJob: standardJob.id,
        startDate:
          woData.planStartDate != null
            ? moment(woData.planStartDate).toDate()
            : undefined,
        completeDate:
          woData.planCompleteDate != null
            ? moment(woData.planCompleteDate).toDate()
            : undefined,
        estimatedDuration: woData.estimateJobDuration ?? undefined,
        parentWorkOrderNumber: woData.parentWorkOrder ?? undefined,
        relation: ['PARENT', 'CHILD'].includes(woData.relationshipType ?? '')
          ? woData.relationshipType
          : undefined,
        workGroupId: woData.workGroupID,
        workGroupCode: woData.workGroupCode,
        status: getWorkOrderSnapshotStatus({
          status: woData.status,
          isReadyToComplete: woData.isReadyToComplete,
        }),
        remoteId: woData.formGroupDataObjectID,
        cmDetailRemoteId: woData.cmWorkOrderRequestObjectID ?? undefined,
        description: woData.workOrderDescription ?? undefined,
        createDate:
          woData.workOrderCreateDate != null
            ? moment(woData.workOrderCreateDate).toDate()
            : undefined,
        lastUpdateDate:
          (woData.lastUpdateDate ?? '').length > 0
            ? moment(woData.lastUpdateDate).toDate()
            : undefined,
        trainStockTypeId: woData.trainStockTypeID.toString(),
      }
    }

    const actions = {
      fetchApprovalWorkOrders: flow(function* fetchWorkOrders(
        pageIndex: number = 0,
        pageSize: number = 250,
        filterOptions: WorkOrderSearchCriteria = {},
        sortOptions: WorkOrderSortCriteria = {},
      ): Generator<any, WorkOrder[], any> {
        const dateFormat = 'YYYY-MM-DD HH:mm:ss'
        const whereClause = [
          filterOptions.standardJobCode &&
            `StandardJobCode LIKE '%${filterOptions.standardJobCode.trim()}%'`,
          filterOptions.workOrderNumber &&
            `WorkOrder LIKE '%${filterOptions.workOrderNumber.trim()}%'`,
          filterOptions.equipmentNumber &&
            `EquipmentNo LIKE '%${filterOptions.equipmentNumber.trim()}%'`,
          filterOptions.depot && `DepotId = '${filterOptions.depot.id}'`,
          filterOptions.trainStockType &&
            `TrainStockTypeId = ${filterOptions.trainStockType.id}`,
          filterOptions.status === WorkOrderStatus.requestedForComplete &&
            `status = '1' `,
          filterOptions.status === WorkOrderStatus.pending && `status = '2'`,
          filterOptions.status === WorkOrderStatus.readyForComplete &&
            `status = '2'`,
          filterOptions.status === WorkOrderStatus.completed &&
            `status = '11' `,
          filterOptions.parentWorkOrder != null &&
            `parentWorkOrder = '${filterOptions.parentWorkOrder}'`,
          filterOptions.maintenanceCategory &&
            `workNature = '${filterOptions.maintenanceCategory.id}'`,
          filterOptions.createdDate &&
            `CreateDate BETWEEN '${filterOptions.createdDate[0].format(
              dateFormat,
            )}' AND '${filterOptions.createdDate[1].format(dateFormat)}'`,
          filterOptions.updateDate &&
            `UpdateDate BETWEEN '${filterOptions.updateDate[0].format(
              dateFormat,
            )}' AND '${filterOptions.updateDate[1].format(dateFormat)}'`,
        ].filter((it) => !!it)
        const sortExpression = [
          sortOptions.workOrderNumber &&
            `WorkOrder ${sortOptions.workOrderNumber
              .slice(0, 1)
              .toUpperCase()
              .concat(
                sortOptions.workOrderNumber
                  .slice(1, sortOptions.workOrderNumber.length)
                  .toLowerCase(),
              )}`,
          sortOptions.createdDate &&
            `CreateDate ${sortOptions.createdDate
              .slice(0, 1)
              .toUpperCase()
              .concat(
                sortOptions.createdDate
                  .slice(1, sortOptions.createdDate.length)
                  .toLowerCase(),
              )}`,
          sortOptions.updateDate &&
            `UpdateDate ${sortOptions.updateDate
              .slice(0, 1)
              .toUpperCase()
              .concat(
                sortOptions.updateDate
                  .slice(1, sortOptions.updateDate.length)
                  .toLowerCase(),
              )}`,
        ].filter((it) => !!it)

        const res: GeneralApiResponse<WorkOrderMain> =
          yield self.environment.api.getReviewWorkOrders(
            pageIndex,
            pageSize,
            sortExpression.length === 0
              ? 'UpdateDate Desc'
              : `${sortExpression.join(',')}`,
            whereClause.length === 0
              ? undefined
              : `AND ${whereClause.join(' AND ')}`,
            filterOptions.imte,
          )

        if (res.kind !== 'ok') throw new Error(res.kind)
        if ((res.message?.result ?? 0) === 0)
          throw Error(res.message?.message ?? 'empty-message')
        if (res.payload == null) throw new Error('no-data')

        const items: ReviewWorkOrderPayloadItem[] = res.payload.rows
        return self.upsertWorkOrders(
          (
            (yield Promise.all(
              items
                .map(async (item) => {
                  try {
                    const ret = await processWorkOrderPayload(item)

                    return {
                      ...ret,
                      formCount: item.formCount,
                      pendingCount: item.pendingCount,
                      fillingCount: item.fillingCount,
                      approvedCount: item.approvedCount,
                      rejectedCount: item.rejectedCount,
                      voidedCount: item.voidedCount,
                      optionalFormCount: item.optionalFormCount,
                    }
                  } catch (err) {
                    self.environment.console.log(
                      'Failed in parsing review work order',
                      item,
                    )
                    self.environment.console.reportError(err)
                    return null
                  }
                })
                .filter(notEmpty),
            )) || []
          ).filter(notEmpty),
        )
      }),

      fetchWorkOrder: flow(function* fetchWorkGroupByFormGroupDataId(
        params:
          | { formGroupDataId: string; workOrderNumber?: never }
          | { formGroupDataId?: never; workOrderNumber: string },
      ): Generator<any, WorkOrder, any> {
        const res: GeneralApiResponse<WorkOrderPayload> =
          params.formGroupDataId != null
            ? yield self.environment.api.getWorkOrder(params.formGroupDataId)
            : yield self.environment.api.workOrders.fetchWorkOrderByNumber(
                params.workOrderNumber,
              )

        if (res.kind !== 'ok') throw new Error(res.kind)
        if ((res.message?.result ?? 0) === 0)
          throw Error(res.message?.message ?? 'empty-message')

        if (res.payload == null) throw new Error('no-data')

        return self.upsertWorkOrder(yield processWorkOrderPayload(res.payload))
      }),
    }

    return actions
  })
  .actions((self) => {
    async function processStandardJobPayloads(
      payload: AttunityStandardJobPayload[],
      opts?: { equipment?: Equipment; workOrderNumber?: string },
    ): Promise<WorkOrderSearchReturnType> {
      const standardJobs: StandardJob[] = []
      const workOrders: WorkOrder[] = []

      await Promise.all(
        payload.map(async (standardJobPayload) => {
          const standardJob = self.upsertStandardJob({
            code: standardJobPayload.standardJobCode,
            title: standardJobPayload.formGroupName,
            nature: self.rootStore.masterDataStore.workNatures.get(
              (
                standardJobPayload.workOrders?.[0]?.workNature ?? 'pm'
              ).toLowerCase(),
            ).id,
          })

          if (standardJob == null) return
          standardJobs.push(standardJob)

          const workOrderSnapshots: SnapshotIn<WorkOrder> = (
            await Promise.all(
              standardJobPayload.workOrders.map(
                async (
                  workOrderPayload,
                ): Promise<SnapshotIn<WorkOrder> | null> => {
                  const { equipmentCode } = workOrderPayload
                  if (
                    opts?.equipment != null &&
                    equipmentCode !== opts?.equipment?.number
                  ) {
                    return null
                  }

                  const equipment =
                    opts?.equipment ??
                    self.equipments.find((e) => e.number === equipmentCode) ??
                    (await self.fetchEquipment(workOrderPayload.equipmentCode))

                  if (equipment == null) {
                    return null
                  }

                  return processAttunityWorkOrderPayloadItem(
                    workOrderPayload,
                    standardJob,
                    equipment,
                    self.rootStore.masterDataStore.findDepot(
                      workOrderPayload.depotID,
                    ),
                  )
                },
              ),
            )
          ).filter(notEmpty)

          workOrders.push(...self.upsertWorkOrders(workOrderSnapshots))
        }),
      )

      return {
        standardJobs,
        workOrders: workOrders.filter((it) => it.allowedForMaintenance),
      }
    }

    return {
      fetchAttunityWorkOrdersByEquipment: flow(
        function* fetchAttunityWorkOrdersByEquipment(
          equipment?: Equipment,
        ): Generator<any, WorkOrderSearchReturnType, any> {
          try {
            const res: GetStandardJobs =
              yield self.environment.api.getStandardJobs(equipment)
            if (res.kind !== 'ok') throw new Error(res.kind)
            if (res.message.result === 0)
              throw Error(res.message.message ?? 'empty-message')

            return yield processStandardJobPayloads(res.payload, { equipment })
          } catch (err) {
            self.environment.console.reportError(err)
            throw err
          }
        },
      ),

      fetchAttunityWorkOrdersByNumber: flow(
        function* fetchAttunityWorkOrdersByNumber(
          workOrderNumber: string,
        ): Generator<any, WorkOrderSearchReturnType, any> {
          try {
            const res: GeneralApiResponse<AttunityStandardJobPayload[]> =
              yield self.environment.api.getStandardJobByWorkOrder(
                workOrderNumber,
              )

            if (res.kind !== 'ok') throw new Error(res.kind)
            if ((res.message?.result ?? 0) === 0)
              throw Error(res.message?.message ?? 'empty-message')
            if (res.payload == null) throw new Error('no-data')

            return yield processStandardJobPayloads(res.payload, {
              workOrderNumber,
            })
          } catch (err) {
            self.environment.console.reportError(err)
            throw err
          }
        },
      ),

      fetchAttunityWorkOrdersByWorkGroups: flow(
        function* fetchAttunityWorkOrdersByWorkGroups(
          /** work group ids */
          workGroups: string[],
        ): Generator<any, WorkOrderSearchReturnType, any> {
          try {
            const res: GeneralApiResponse<AttunityStandardJobPayload[]> =
              yield self.environment.api.getStandardJobByWorkGroups(workGroups)

            if (res.kind !== 'ok') throw new Error(res.kind)
            if ((res.message?.result ?? 0) === 0)
              throw Error(res.message?.message ?? 'empty-message')
            if (res.payload == null) throw new Error('no-data')

            return yield processStandardJobPayloads(res.payload)
          } catch (err) {
            self.environment.console.reportError(err)
            throw err
          }
        },
      ),

      fetchFormRelatedWorkOrders: flow(function* fetchFormRelateWorkOrder(
        workOrder: WorkOrder,
        formNumbers: string[],
      ): Generator<
        any,
        { workOrder: WorkOrder; workOrderForms?: WorkOrderForm[] }[],
        any
      > {
        const workOrderNumber =
          workOrder.relation === 'PARENT'
            ? workOrder.number
            : workOrder.parentWorkOrderNumber

        if (workOrderNumber == null) throw new Error('no-parent')

        try {
          const res: GeneralApiResponse<AttunityStandardJobPayload[]> =
            yield self.environment.api.workOrderForms.getFormRelatedWorkOrders(
              workOrderNumber,
              formNumbers,
            )
          if (res.kind !== 'ok') throw new Error(res.kind)
          const { payload } = res
          if (payload == null) throw new Error('empty-payload')

          const {
            workOrders,
          }: { standardJobs: StandardJob[]; workOrders: WorkOrder[] } =
            yield processStandardJobPayloads(payload)

          const ret = yield Promise.all(
            workOrders.map(async (relatedWorkOrder) => {
              const formGroupAvailable =
                await relatedWorkOrder.fetchFormGroupInfo()
              if (!formGroupAvailable) return { workOrder: relatedWorkOrder }

              await relatedWorkOrder.fetchReviewFormList()
              await Promise.all(
                relatedWorkOrder.forms.map(async (form) =>
                  form.fetchFormDefinition(),
                ),
              )
              return {
                workOrder: relatedWorkOrder,
                workOrderForms: relatedWorkOrder.forms.filter((form) =>
                  formNumbers.includes((form.form as Form).number),
                ),
              }
            }),
          )

          return ret
        } catch (err) {
          self.environment.console.reportError(err)
          throw err
        }
      }),
    }
  })

  // CM Details
  .props({
    cmDetails: types.array(CMDetailModel),
  })
  .actions((self) => {
    const upsertCMDetail = (snapshot: SnapshotIn<CMDetail>) => {
      let ret: CMDetail = self.cmDetails.find(
        (cmDetail) => cmDetail.remoteId === snapshot.remoteId,
      )

      if (ret) {
        ret.apply(snapshot)
      } else {
        ret = CMDetailModel.create(snapshot)
        self.cmDetails.push(ret)
      }
      return ret
    }

    const actions = {
      getCMWorkOrderPersonInChargeOptions: flow(
        function* getCMWorkOrderPersonInChargeOptions() {
          const res: GetCMWorkOrderPersonInChargeOptions =
            yield self.environment.api.cmDetails.getCMWorkOrderPersonInChargeOptions()
          if (res == null) throw new Error('empty-response')
          if (res.kind !== 'ok') throw new Error(res.kind)
          if (res.message?.result !== 1)
            throw new Error(res.message.message ?? 'empty-message')
          return res.payload
        },
      ),

      getUserStandardJobCode: flow(function* getUserStandardJobCode(
        filterByUser: boolean,
      ) {
        const res: GetUserStandardJobCode =
          yield self.environment.api.cmDetails.getUserStandardJobCode(
            filterByUser,
          )
        if (res == null) throw new Error('empty-response')
        if (res.kind !== 'ok') throw new Error(res.kind)
        if (res.message?.result !== 1)
          throw new Error(res.message.message ?? 'empty-message')
        return res.payload
      }),
      getCMAssociatedCodes: flow(function* getCMAssociatedCodes(
        equipmentClass: string,
      ) {
        const res: GetCMAssociatedCodes =
          yield self.environment.api.cmDetails.getCMAssociatedCodes(
            equipmentClass,
          )
        if (res == null) throw new Error('empty-response')
        if (res.kind !== 'ok') throw new Error(res.kind)
        if (res.message?.result !== 1)
          throw new Error(res.message.message ?? 'empty-message')

        const data: CMAssociatedCodeItem[] = res.payload.map((it) =>
          _.mapKeys(it, (val, key) => _.camelCase(key.toUpperCase())),
        )

        // self.environment.console.log(data, _.uniq(data.map((it) => it.type)))
        // 0:COMPONENT_CODE
        // 1:THE COMPLETE ASSET
        // 2:CONTRACT_TYPE
        // 3:FAILURE_ACTION_CODE
        // 4:FAILURE_CODE
        // 5:JOB_OTHER_COST
        // 6:OTHERS
        // 7:FAILURE_SYMPTOM_CODE
        // 8:FAILURE_CAUSE_CODE
        return {
          failureCode: data.filter((it) => it.type === 'FAILURE_CODE'),
          failureActionCode: data.filter(
            (it) => it.type === 'FAILURE_ACTION_CODE',
          ),
          failureSymptomCode: data.filter(
            (it) => it.type === 'FAILURE_SYMPTOM_CODE',
          ),
          failureCauseCode: data.filter(
            (it) => it.type === 'FAILURE_CAUSE_CODE',
          ),
          componentCode: data.filter((it) => it.type === 'COMPONENT_CODE'),
          // failureCode: data.filter(it => it.type === 'FAILURE_CODE'),
        }
        // return data
      }),
      getAllWorkGroups: flow(function* getAllWorkGroups() {
        const res: GetWorkGroups = yield self.environment.api.getAllWorkGroups()
        if (res.kind !== 'ok') throw new Error(res.kind)
        if (res.message?.result !== 1)
          throw new Error(res.message.message ?? 'empty-message')
        return res.payload
      }),
      submitCMDetails: flow(function* submitCMDetails(
        cmDetailAction: 'create' | 'edit' | 'complete',
        content: DeepNullable<DeepPartial<SnapshotIn<CMDetailContent>>>,
        opts?: { parentWorkOrder?: WorkOrder; cmDetail?: CMDetail },
      ) {
        const payload = mapCMDetailContentToPayload(content)
        const res: PostCMDetail =
          yield self.environment.api.cmDetails.postCMRequest({
            ...payload,
            workNatureLevel1: 'CM',
            cmWorkOrderRequestObjectID: opts?.cmDetail?.remoteId ?? undefined,
            action: cmDetailAction,
          })

        if (res.kind !== 'ok') throw new Error(res.kind)
        if (res.message?.result !== 1)
          throw new Error(res.message.message ?? 'empty-message')
        if (!res.payload.success) throw new Error(res.payload.errorMessage)

        // const retSnapshot = mapPayloadToCMDetailContent(res.payload.data)
        // return upsertCMDetail(retSnapshot)
      }),

      getCMDetails: flow(function* getCMDetails(remoteId: string) {
        const mergedRes = (yield self.environment.api.cmDetails.getCMDetails(
          remoteId,
          true,
        )) as GeneralApiResponse<CMDetailPayload[]>
        if (mergedRes.kind !== 'ok') throw new Error(mergedRes.kind)
        if (mergedRes.payload == null) throw new Error('empty-payload')

        const mergedPayload = mergedRes.payload[0]
        const mergedDetail = mapPayloadToCMDetailContent(mergedPayload)

        const historyRes = (yield self.environment.api.cmDetails.getCMDetails(
          remoteId,
          false,
        )) as GeneralApiResponse<CMDetailPayload[]>
        if (historyRes.kind !== 'ok') throw new Error(historyRes.kind)

        const histories = (historyRes.payload ?? []).map(
          mapPayloadToCMDetailContent,
        )

        return upsertCMDetail({
          remoteId: mergedPayload.cmWorkOrderRequestObjectID,
          workOrderNumber: mergedPayload.cmWorkOrder ?? undefined,
          merged: mergedDetail,
          histories,
        })
      }),

      getCMDetailStatus: flow(function* getCMDetailsStatus(
        remoteId: string,
        cmDetailAction: 'create' | 'edit' | 'complete',
      ) {
        const res = (yield self.environment.api.cmDetails.getCMDetailStatus(
          remoteId,
          cmDetailAction,
        )) as GeneralApiResponse<CMDetailStatusPayload>
        if (res.kind !== 'ok') throw new Error(res.kind)
        if (res.payload == null) throw new Error('empty-payload')
        return res.payload
      }),
      getCMRequestsFromWorkOrder: flow(function* getCMRequestsByWorkOrder(
        workOrder: WorkOrder,
      ): Generator<any, CMDetail[], any> {
        const res: GeneralApiResponse<CMRequestItem[]> =
          yield self.environment.api.cmDetails.getCMRequestsByWorkOrder(
            workOrder.number,
          )
        if (res.kind !== 'ok') throw new Error(res.kind)

        const cmDetails: (CMDetail | undefined)[] = yield Promise.all(
          (res.payload ?? []).map((payload) =>
            actions.getCMDetails(payload.cmWorkOrderRequestObjectID),
          ),
        )

        return cmDetails.filter(notEmpty)
      }),
      shouldShowDelayFields: flow(function* shouldShowDelayFields(
        equipmentClass: string,
        workNatureLv1: string,
        workNatureLv2: string,
        standardJobCode: string,
      ) {
        const res: GeneralApiResponse<boolean> =
          yield self.environment.api.cmDetails.getShouldShowDelayFields(
            equipmentClass,
            workNatureLv1,
            workNatureLv2,
            standardJobCode,
          )
        if (res.kind !== 'ok') throw new Error(res.kind)
        return res.payload
      }),
    }
    return actions
  })

  // WICN
  .props({
    wicnTasks: types.array(WICNTaskModel),
    wicns: types.array(WICNModel),
    wiReviewTasks: types.array(WIReviewTaskModel),
    wiReviews: types.array(WIReviewModel),
  })
  .actions((self) => {
    const upsertWICNTask = (snapshot: SnapshotIn<WICNTask>) => {
      let ret: WICNTask = self.wicnTasks.find(
        (wicnTask) => wicnTask.wicnTaskId === snapshot.wicnTaskId,
      )

      if (ret) {
        ret.apply(snapshot)
      } else {
        ret = WICNTaskModel.create(snapshot)
        self.wicnTasks.push(ret)
      }
      return ret
    }
    const upsertWICN = (snapshot: SnapshotIn<WICN>) => {
      let ret: WICN = self.wicns.find((wicn) => wicn.wicnId === snapshot.wicnId)

      if (ret) {
        ret.apply(snapshot)
      } else {
        ret = WICNModel.create(snapshot)
        self.wicns.push(ret)
      }
      return ret
    }
    const upsertWIReviewTask = (snapshot: SnapshotIn<WIReviewTask>) => {
      let ret: WIReviewTask = self.wiReviewTasks.find(
        (wiReviewTask) =>
          wiReviewTask.wiReviewTaskId === snapshot.wiReviewTaskId,
      )

      if (ret) {
        ret.apply(snapshot)
      } else {
        ret = WIReviewTaskModel.create(snapshot)
        self.wiReviewTasks.push(ret)
      }
      return ret
    }
    const upsertWIReview = (snapshot: SnapshotIn<WIReview>) => {
      let ret: WIReview = self.wiReviews.find(
        (wiReview) => wiReview.wiReviewId === snapshot.wiReviewId,
      )

      if (ret) {
        ret.apply(snapshot)
      } else {
        ret = WIReviewModel.create(snapshot)
        self.wiReviews.push(ret)
      }
      return ret
    }
    return {
      getWICNData: flow(function* getWICNData(wicnId: string) {
        const res: GeneralApiResponse<WICNDataPayload> =
          yield self.environment.api.wiWorkflow.getWICNData(wicnId)

        if (res.kind !== 'ok') throw new Error(res.kind)
        if ((res.message?.result ?? 0) === 0)
          throw Error(res.message?.message ?? 'empty-message')
        if (res.payload == null) throw new Error('no-data')

        if (res.payload.errorMessage === 'No Permission.') {
          throw Error(
            `We could not retrieve data because you do not have permission (error: ${res.payload.errorMessage})`,
          )
        }

        const item: WICN = mapPayloadToWICN(res.payload)

        return upsertWICN(item)
      }),

      fetchToDoWICNTask: flow(function* fetchToDoWICNTask(
        pageIndex: number = 0,
        pageSize: number = 10,
        searchCriteria: WITaskSearchCriteria = {},
        sortExpression: string = '',
      ) {
        const res: GeneralApiResponse<{
          total: number
          rows: WICNTaskPayloadItem[]
        }> = yield self.environment.api.wiWorkflow.getToDoWICNTask(
          searchCriteria.searchText ?? '',
          getWICNStatusNumberByValue(
            searchCriteria.currentStatus as WICNStatus,
          ),
          searchCriteria.checkPending,
          pageIndex,
          pageSize,
          sortExpression,
        )

        if (res.kind !== 'ok') throw new Error(res.kind)
        if ((res.message?.result ?? 0) === 0)
          throw Error(res.message?.message ?? 'empty-message')
        if (res.payload == null) throw new Error('no-data')

        const items: WICNTask[] = (res.payload?.rows ?? []).map(
          mapPayloadToWICNTask,
        )
        return {
          total: res.payload.total,
          taskList: items.map((it) => upsertWICNTask(it)),
        }
      }),

      submitWICN: flow(function* submitWICN(
        submitData: string,
        workflowData: string,
        wicnId: string,
        part: number,
        wicnWorkflowAction: number,
        currentStatus: number,
      ) {
        const res: PostWIProcess =
          yield self.environment.api.wiWorkflow.submitWICN(
            submitData,
            workflowData,
            wicnId,
            part,
            wicnWorkflowAction,
            currentStatus,
            false,
          )
        if (res.kind !== 'ok') throw new Error(res.kind)

        if (res.payload.success !== true)
          throw new Error(res.payload.errorMessage)
      }),

      rejectWICN: flow(function* rejectWICN(
        wicnId: string,
        currentPartEnum: number,
        rejectComment: string,
        currentStatus: number,
      ) {
        const res: PostWIProcess =
          yield self.environment.api.wiWorkflow.rejectWICN(
            wicnId,
            currentPartEnum,
            rejectComment,
            currentStatus,
          )
        if (res.kind !== 'ok') throw new Error(res.kind)

        if (res.payload.success !== true)
          throw new Error(res.payload.errorMessage)
      }),

      forwardWITask: flow(function* forwardWITask(
        taskType: TaskType,
        wiTaskId: string,
        partEnum: number,
        forwardUser: ActionBy,
        groupName: string,
        subFormId?: string,
      ) {
        let res: PostWIProcess
        if (taskType === TaskType.wicn) {
          res = yield self.environment.api.wiWorkflow.forwardWICN(
            wiTaskId,
            partEnum,
            forwardUser,
            groupName,
            subFormId ?? '',
          )
        } else {
          res = yield self.environment.api.wiWorkflow.forwardWIReview(
            wiTaskId,
            partEnum,
            forwardUser,
            groupName,
            subFormId ?? '',
          )
        }
        if (res.kind !== 'ok') throw new Error(res.kind)

        if (res.payload.success !== true)
          throw new Error(res.payload.errorMessage)
      }),

      consultWITask: flow(function* consultWITask(
        taskType: TaskType,
        wiTaskId: string,
        partEnum: number,
        consultUser: ActionBy,
        subFormId?: string,
      ) {
        let res: PostWIProcess
        if (taskType === TaskType.wicn) {
          res = yield self.environment.api.wiWorkflow.consultWICN(
            wiTaskId,
            partEnum,
            consultUser,
            subFormId ?? '',
          )
        } else {
          res = yield self.environment.api.wiWorkflow.consultWIReview(
            wiTaskId,
            partEnum,
            consultUser,
            subFormId ?? '',
          )
        }
        if (res.kind !== 'ok') throw new Error(res.kind)

        if (res.payload.success !== true)
          throw new Error(res.payload.errorMessage)
      }),

      returnWITask: flow(function* returnWITask(
        taskType: TaskType,
        wiTaskId: string,
        partEnum: number,
        comment: string,
        subFormId?: string,
      ) {
        let res: PostWIProcess
        if (taskType === TaskType.wicn) {
          res = yield self.environment.api.wiWorkflow.returnWICN(
            wiTaskId,
            partEnum,
            comment,
            subFormId ?? '',
          )
        } else {
          res = yield self.environment.api.wiWorkflow.returnWIReview(
            wiTaskId,
            partEnum,
            comment,
            subFormId ?? '',
          )
        }
        if (res.kind !== 'ok') throw new Error(res.kind)

        if (res.payload.success !== true)
          throw new Error(res.payload.errorMessage)
      }),

      getWIReviewData: flow(function* getWIReviewData(wiReviewId: string) {
        const res: GeneralApiResponse<WIReviewDataPayload> =
          yield self.environment.api.wiWorkflow.getWIReviewData(wiReviewId)

        if (res.kind !== 'ok') throw new Error(res.kind)
        if ((res.message?.result ?? 0) === 0)
          throw Error(res.message?.message ?? 'empty-message')
        if (res.payload == null) throw new Error('no-data')

        if (res.payload.errorMessage === 'No Permission.') {
          throw Error(
            `We could not retrieve data because you do not have permission (error: ${res.payload.errorMessage})`,
          )
        }

        const item: WIReview = mapPayloadToWIReview(res.payload)

        return upsertWIReview(item)
      }),

      fetchToDoWIReviewTask: flow(function* fetchToDoWIReviewTask(
        pageIndex: number = 0,
        pageSize: number = 10,
        searchCriteria: WITaskSearchCriteria = {},
        sortExpression: string = '',
      ) {
        const res: GeneralApiResponse<{
          total: number
          rows: WIReviewTaskPayloadItem[]
        }> = yield self.environment.api.wiWorkflow.getToDoWIReviewTask(
          searchCriteria.searchText ?? '',
          getWIReviewStatusNumberByValue(
            searchCriteria.currentStatus as WIReviewStatus,
          ),
          searchCriteria.checkPending,
          pageIndex,
          pageSize,
          sortExpression,
        )

        if (res.kind !== 'ok') throw new Error(res.kind)
        if ((res.message?.result ?? 0) === 0)
          throw Error(res.message?.message ?? 'empty-message')
        if (res.payload == null) throw new Error('no-data')

        const items: WIReviewTask[] = (res.payload?.rows ?? []).map(
          mapPayloadToWIReviewTask,
        )
        return {
          total: res.payload.total,
          taskList: items.map((it) => upsertWIReviewTask(it)),
        }
      }),

      getRelatedWICN: flow(function* getRelatedWICN(wiReviewId: string) {
        const res: GetRelatedWICNData =
          yield self.environment.api.wiWorkflow.getRelatedWICN(wiReviewId)
        if (res.kind !== 'ok') throw new Error(res.kind)

        return mapPayloadToRelatedWICN(res.payload)
      }),
    }
  })

export type WorksStore = Instance<typeof WorksStoreModel>
