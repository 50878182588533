export type WorkOrderFormOptions = {
  isOptional: boolean
  isUnique: boolean
  allowMultiple: boolean
}

export function mapFormAttributesByPayload(data: {
  formDataType: number
}): Omit<WorkOrderFormOptions, 'isOptional'> {
  return {
    isUnique: data.formDataType !== 2,
    allowMultiple: data.formDataType === 3,
  }
}
