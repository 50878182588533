import _ from 'lodash'

const processEnv = process.env

type EnvironmentVariables = {
  version: string
  name: string

  target: 'SDO'
  environment: 'DEV' | 'SIT' | 'UAT' | 'PROD'

  configUrl: string
  configKey: string

  nodeEnvironment: typeof process.env.NODE_ENV
}

/**
 * Environment variables imported by dotenv
 * To add new entry, add REACT_APP_(key)=(value) into .env.* file
 * REACT_APP_ prefix will be removed will the key converted to camelCase in dictionary
 */
export const env: EnvironmentVariables = Object.keys(processEnv).reduce(
  (acc: {}, key: string) => {
    if (key.startsWith('REACT_APP_')) {
      return {
        ...acc,
        [_.camelCase(key.replace(/^REACT_APP_/, ''))]: processEnv[key],
      }
    }
    return acc
  },
  {
    nodeEnvironment: process.env.NODE_ENV,
  },
) as EnvironmentVariables

export const isDev = process.env.NODE_ENV === 'development'
