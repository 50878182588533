import { Theme } from '@mtr-SDO/theme-core'

export const formats: Theme['formats'] = {
  date: 'DD/MM/YYYY',
  dateInput: 'dd/MM/yyyy',
  datetimeWithSeconds: 'DD/MM/YYYY HH:mm:ss',
  datetime: 'DD/MM/YYYY HH:mm',
  dateReverse: 'YYYY-MM-DD',
  month: 'MM/yyyy',
  monthReverse: 'YYYY-MM',
  time: 'HH:mm',
  timeWithSeconds: 'HH:mm:ss',
  timeIn12Hour: 'hh:mm:a',
  hour: 'h',
}
