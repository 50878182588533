import { TIMEOUT_ERROR } from 'apisauce'
import axios, { AxiosPromise, AxiosResponse } from 'axios'
import { Base64 } from 'js-base64'
import qs from 'qs'
// @ts-ignore no type
import secureRandom from 'secure-random'
import shajs from 'sha.js'
import url from 'url'
import { v4 as uuid } from 'uuid'
import { OAuthConfig, AuthToken } from './oauth'

type ApiConfig = { baseUrl: string; apiKey?: string }

type PersistentStorage = {
  set(key: string, value: string): void
  get(key: string): string
  clear(key: string): void
}

const KEY_AUTH_STATE = 'adfs.auth-state'
const KEY_CODE_VERIFIER = 'adfs.code-verifier'

const loginEndpoint = '/oauth2/authorize'
const tokenEndpoint = '/oauth2/token'

function base64Encode(u8: Uint8Array): string {
  return Base64.btoa(String.fromCharCode(...u8))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
}

export const login = async (
  authConfig: Omit<OAuthConfig, 'timeout'>,
  storage: PersistentStorage,
): Promise<string> => {
  if (!authConfig) throw new Error('config-unavailable')

  const authState = uuid()
  storage.set(KEY_AUTH_STATE, authState)

  const codeVerifier = base64Encode(await secureRandom(32))
  storage.set(KEY_CODE_VERIFIER, codeVerifier)

  const hashedCodeVerifier = shajs('sha256').update(codeVerifier).digest('hex')
  const codeChallenge = base64Encode(
    new Uint8Array(
      (hashedCodeVerifier.match(/.{1,2}/g) ?? []).map((byte: string) =>
        parseInt(byte, 16),
      ),
    ),
  )

  const loginUrl = url.format({
    protocol: 'https',
    host: authConfig.host,
    pathname: loginEndpoint,
    query: {
      response_type: 'code',
      client_id: authConfig.clientId,
      resource: authConfig.resource,
      redirect_uri: authConfig.redirectUri,
      prompt: 'login',
      state: authState,
      code_challenge_method: 'S256',
      code_challenge: codeChallenge,
    },
  })

  return loginUrl
}

export const getAccessToken = async (
  { code, state }: { code: string; state: string },
  authConfig: OAuthConfig,
  storage: PersistentStorage,
  apiConfig?: ApiConfig,
  endPoint?: string,
): Promise<AuthToken> => {
  if (!authConfig) throw new Error('config-unavailable')

  const authState = storage.get(KEY_AUTH_STATE)
  const codeVerifier = storage.get(KEY_CODE_VERIFIER)

  if (!authState) throw Error('adfs-state-idle') // not logging in ... ignore
  if (state !== authState) {
    console.warn('Unmatched state. Ignore')
    throw Error('adfs-state-mismatch')
  }

  const timeoutDuration = authConfig.timeout || 1000 * 60

  if (!code) throw Error('code-unavailable')

  try {
    const responsePromise = axios({
      method: 'POST',
      baseURL:
        apiConfig == null ? `https://${authConfig.host}` : apiConfig?.baseUrl,
      url: endPoint ?? tokenEndpoint,
      data: qs.stringify({
        client_id: authConfig.clientId,
        grant_type: 'authorization_code',
        redirect_uri: authConfig.redirectUri,
        code,
        code_verifier: codeVerifier,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Api-Key': apiConfig?.apiKey,
      },
    })

    const timeoutPromise: AxiosPromise<any> = new Promise((resolve) =>
      setTimeout(
        () =>
          resolve({
            statusText: TIMEOUT_ERROR,
            data: null,
            status: 418, // to show that this is not a real server response
            headers: null,
            config: authConfig,
          } as AxiosResponse<any>),
        30000,
      ),
    )

    const response = await Promise.race([responsePromise])

    storage.clear(KEY_AUTH_STATE)
    storage.clear(KEY_CODE_VERIFIER)

    const { data, status, statusText } = response
    if (status >= 300) {
      console.warn({
        name: 'getAuthenication failed',
        value: { response, code, state, authConfig },
        preview: `Status: ${response.status}`,
        important: true,
      })
      // if (status === 418 && statusText === TIMEOUT_ERROR) {
      //   throw new Error(ErrorCode.LoginAuthCodeRequestTimeout.toString())
      // }
      throw new Error(data ? data.error : statusText)
    }

    return {
      accessToken: data.access_token,
      tokenType: data.token_type,
      expiresIn: data.expires_in,
      refreshToken: data.refresh_token,
    }
  } catch (err) {
    storage.clear(KEY_AUTH_STATE)
    storage.clear(KEY_CODE_VERIFIER)
    throw err
  }
}

export const refreshToken = async (
  token: string,
  authConfig: OAuthConfig,
  apiConfig?: ApiConfig,
): Promise<AuthToken> => {
  if (!authConfig) throw new Error('config-unavailable')
  const timeoutDuration = authConfig.timeout || 1000 * 60

  if (!token) throw Error('reftoken-unavailable')

  const responsePromise = axios({
    method: 'POST',
    baseURL:
      apiConfig == null ? `https://${authConfig.host}` : apiConfig?.baseUrl,
    url: tokenEndpoint,
    data: qs.stringify({
      client_id: authConfig.clientId,
      grant_type: 'refresh_token',
      redirect_uri: authConfig.redirectUri,
      refresh_token: token,
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Api-Key': apiConfig?.apiKey,
    },
  })

  const timeoutPromise: AxiosPromise<any> = new Promise((resolve) =>
    setTimeout(
      () =>
        resolve({
          statusText: TIMEOUT_ERROR,
          data: null,
          status: 418, // to show that this is not a real server response
          headers: null,
          config: authConfig,
        } as AxiosResponse<any>),
      timeoutDuration,
    ),
  )

  const response = await Promise.race([timeoutPromise, responsePromise])

  const { data, status, statusText } = response
  if (status >= 300) {
    console.warn({
      name: 'getAuthenication failed',
      value: { response, token, authConfig },
      preview: `Status: ${response.status}`,
      important: true,
    })
    // if (status === 418 && statusText === TIMEOUT_ERROR) {
    //   throw new Error(ErrorCode.LoginAuthCodeRequestTimeout.toString())
    // }
    throw new Error(data ? data.error : statusText)
  }

  return {
    accessToken: data.access_token,
    tokenType: data.token_type,
    expiresIn: data.expires_in,
  }
}
