import { ApiBase } from '../api-base'
import {
  PostCreateOrderBookItem,
  DepotListType,
  OrderBookItemType,
  VehicleListType,
  WorkTypeListType,
  FormControlPayload,
  TabControlPayload,
  CreatedBookType,
} from './order-book.types'

export class ApiOrderBook {
  private api: ApiBase

  constructor(api: ApiBase) {
    this.api = api
  }

  getDepotList = async () =>
    this.api.generalApi<DepotListType>(
      this.api.apisauce.get('/Form/OrderBookItem/getDepotList'),
    )

  getWorkTypeListByDepotID = async (
    depotID: number
  ) =>
    this.api.generalApi<WorkTypeListType>(
      this.api.apisauce.get('/Form/OrderBookItem/getWorkTypeListByDepotID', { depotID }),
    )

  getVehicleListByDepotID = async (
    depotID: number
  ) =>
    this.api.generalApi<VehicleListType>(
      this.api.apisauce.get('/Form/OrderBookItem/getVehicleListByDepotID', { depotID }),
    )

  createOrderBookItem = async (
    orderBookItem: OrderBookItemType
  ) =>
    this.api.generalApi<PostCreateOrderBookItem>(
      this.api.apisauce.post('/Form/OrderBookItem/OrderBookItemCreate', orderBookItem),
    )

  createBookOrder = async (bookPayload: any) =>
    this.api.generalApi<CreatedBookType>(
      this.api.apisauce.post('Form/OrderBookItem/OrderBookItemCreate', bookPayload),
    )

  getFormControlByFormObjectID = async (formObjectID: string, status?: number) =>
    this.api.generalApi<FormControlPayload>(
      this.api.apisauce.get('/Form/Form/GetFormControlByFormObjectID', {
        formObjectID,
        status
      }),
    )

    GetTabControlInWorkflow = async (
    formDataObjectID: string,
    formDataStatus: number,
  ) =>
    this.api.generalApi<TabControlPayload>(
      this.api.apisauce.get('/Form/FormData/GetTabControlInWorkflow', {
        formDataObjectID,
        formDataStatus,
      }),
    )
}
