import {
  ADUser,
  PostWIProcess,
  WIReviewDataPayload,
  formats,
} from '@mtr-SDO/apis'
import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import _ from 'lodash'
import { flow, Instance, SnapshotIn, types } from 'mobx-state-tree'
import moment from 'moment'
import { isUpnMatched } from '@mtr-SDO/utils'
import { v4 as uuid } from 'uuid'
import {
  Attachment,
  getFormTypeByCode,
  getPriorityByDeadline,
  mapPayloadToWIWorkflowPart,
  mapPayloadToWorkflowHistory,
  TaskPriority,
  User,
  WIWorkflowApproveUser,
  WorkflowHistory,
  WorkflowHistoryModel,
  WICommentFormStatus,
  CommentFormForwardList,
  mapPayloadToCurrentUserUpnList,
  buildAttachmentPayload,
} from '../wi-workflow'
import {
  stringToDate,
  stringToNumber,
  stringToString,
} from '../common-function'
import {
  mapPayloadToWIReviewDetail,
  WIReviewDetailModel,
} from './wi-review-detail.model'
import {
  getWIReviewSnapShotStatus,
  WiReviewNodeId,
  WIReviewStatus,
} from './wi-review-status'
import {
  getWIReviewWorkflowApproveUser,
  WIReviewWorkflowModel,
} from './wi-review-workflow.model'
import {
  WIReviewPartJRecordFormResult,
  WIReviewPartJWIResult,
  WIReviewUploaded,
} from './wi-review-selection-item'
import { WIReviewCommentForm } from './wi-review-detail-comment.model'

type Assignee = {
  DisplayName: string | null
  Title: string | null
  UPN: string | null
  Email: string | null
}

export type CommentFormResponse = {
  id: number | undefined
  response: string | undefined
}

export const WIReviewModel = types
  .model({
    wiReviewId: types.optional(types.identifier, uuid),
    isCancelled: types.boolean,

    wiReviewDetail: WIReviewDetailModel,

    currentStatus: types.number,
    currentUser: types.maybe(types.string),
    currentUserUpnList: types.array(types.string),
    currentUserGroup: types.maybe(types.string),

    createdDate: types.Date,
    createdBy: types.maybe(types.string),
    updatedDate: types.maybe(types.Date),
    updatedBy: types.maybe(types.string),

    workflowData: WIReviewWorkflowModel,
    workflowHistory: types.array(WorkflowHistoryModel),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .actions((self) => ({
    apply(snapshot: any) {
      _.keys(_.omit(snapshot, 'wiReviewId')).forEach((key) => {
        if (snapshot[key] == null) {
          return
        }
        ;(self as any)[key] = snapshot[key]
      })
    },

    submitPartD: flow(function* submitPartD(
      wicnWorkflowAction: number,
      selectedUserList: ADUser[],
      decision?: string,
    ) {
      const res: PostWIProcess =
        yield self.environment.api.wiWorkflow.submitWIReview(
          JSON.stringify({
            isSipCommnet: _.capitalize(decision),
          }),
          JSON.stringify({
            ChildNodeActionByList:
              selectedUserList.map((user) => ({
                Upn: user.upn,
                DisplayName: user.displayName,
                Title: user.jobTitle,
                Email: user.email,
              })) ?? [],
          }),
          self.wiReviewId,
          WiReviewNodeId.partD,
          wicnWorkflowAction,
          self.currentStatus,
          false,
        )
      if (res.kind !== 'ok') throw new Error(res.kind)

      if (res.payload.success !== true)
        throw new Error(res.payload.errorMessage)
    }),
    submitPartE: flow(function* submitPartE(
      wicnWorkflowAction: number,
      childFormList?: CommentFormResponse[],
      attachments?: Attachment[],
    ) {
      const res: PostWIProcess =
        yield self.environment.api.wiWorkflow.submitWIReview(
          JSON.stringify({
            ChildFormList: childFormList,
            Attachments: buildAttachmentPayload(attachments),
          }),
          JSON.stringify({}),
          self.wiReviewId,
          WiReviewNodeId.partE,
          wicnWorkflowAction,
          self.currentStatus,
          false,
        )
      if (res.kind !== 'ok') throw new Error(res.kind)

      if (res.payload.success !== true)
        throw new Error(res.payload.errorMessage)
    }),
    submitPartF: flow(function* submitPartF(
      wiWorkflowAction: number,
      firstEndorser: Assignee,
      secondEndorser: Assignee,
      approver: Assignee,
      decision?: string,
      comment?: string,
    ) {
      const res: PostWIProcess =
        yield self.environment.api.wiWorkflow.submitWIReview(
          JSON.stringify({
            RequireOne: firstEndorser.UPN != null,
            RequireTwo: secondEndorser.UPN != null,
            RequireDefault: true,
            CheckerComment: comment,
            Decision: decision,
          }),
          JSON.stringify({
            Next1NodeActionBy: firstEndorser,
            Next2NodeActionBy: secondEndorser,
            Next3NodeActionBy: approver,
            Comment: comment,
          }),
          self.wiReviewId,
          WiReviewNodeId.partF,
          wiWorkflowAction,
          self.currentStatus,
          false,
        )
      if (res.kind !== 'ok') throw new Error(res.kind)

      if (res.payload.success !== true)
        throw new Error(res.payload.errorMessage)
    }),
    submitApproval: flow(function* submitApproval(
      action: number,
      part: number,
      comment: string,
      decision?: string,
    ) {
      const res: PostWIProcess =
        yield self.environment.api.wiWorkflow.submitWIReview(
          JSON.stringify({
            EndorserComment: comment,
            Decision: decision,
          }),
          JSON.stringify({
            Comment: comment,
          }),
          self.wiReviewId,
          part,
          action,
          self.currentStatus,
          false,
        )
      if (res.kind !== 'ok') throw new Error(res.kind)

      if (res.payload.success !== true)
        throw new Error(res.payload.errorMessage)
    }),
    submitPartJ: flow(function* submitPartJ(
      wiWorkflowAction: number,
      wiResult?: WIReviewPartJWIResult,
      wiResultRemarks?: string,
      recordResult?: WIReviewPartJRecordFormResult,
      recordResultRemarks?: string,
      comment?: string,
      attachments?: Attachment[],
    ) {
      const res: PostWIProcess =
        yield self.environment.api.wiWorkflow.submitWIReview(
          JSON.stringify({
            NewIssue: wiResult === WIReviewPartJWIResult.newIssue,
            Review: wiResult === WIReviewPartJWIResult.review,
            NoChange: wiResult === WIReviewPartJWIResult.noChange,
            Delete: wiResult === WIReviewPartJWIResult.delete,
            DeltetRemark: wiResultRemarks,
            Update: recordResult === WIReviewPartJRecordFormResult.update,
            NoChangeRecord:
              recordResult === WIReviewPartJRecordFormResult.noChange,
            NA: recordResult === WIReviewPartJRecordFormResult.na,
            DeleteRecord: recordResult === WIReviewPartJRecordFormResult.delete,
            DeleteRemarkRecord: recordResultRemarks,
            Remarks: comment,
            Attachments: buildAttachmentPayload(attachments),
          }),
          JSON.stringify({
            Comment: comment,
          }),
          self.wiReviewId,
          WiReviewNodeId.partJ,
          wiWorkflowAction,
          self.currentStatus,
          false,
        )
      if (res.kind !== 'ok') throw new Error(res.kind)

      if (res.payload.success !== true)
        throw new Error(res.payload.errorMessage)
    }),

    submitPartK: flow(function* submitPartK(
      wiWorkflowAction: number,
      uploaded?: WIReviewUploaded,
    ) {
      const res: PostWIProcess =
        yield self.environment.api.wiWorkflow.submitWIReview(
          JSON.stringify({
            Yes: uploaded === WIReviewUploaded.yes,
            NoChange: uploaded === WIReviewUploaded.noChange,
            Deleted: uploaded === WIReviewUploaded.deleted,
          }),
          JSON.stringify({}),
          self.wiReviewId,
          WiReviewNodeId.partK,
          wiWorkflowAction,
          self.currentStatus,
          false,
        )
      if (res.kind !== 'ok') throw new Error(res.kind)

      if (res.payload.success !== true)
        throw new Error(res.payload.errorMessage)
    }),
    reject: flow(function* rejectWICN(
      currentPartEnum: number,
      rejectComment: string,
    ) {
      const res: PostWIProcess =
        yield self.environment.api.wiWorkflow.rejectWIReview(
          self.wiReviewId,
          currentPartEnum,
          rejectComment,
          self.currentStatus,
        )
      if (res.kind !== 'ok') throw new Error(res.kind)

      if (res.payload.success !== true)
        throw new Error(res.payload.errorMessage)
    }),
    updateWIReviewComment: flow(function* commentWIReview(
      formId?: number,
      reviewData?: {
        isImplementor?: boolean
        partADecision?: number
        comment?: string
        partBDecision?: number
        implementationDate?: moment.Moment
        partCDecision?: number
        attachments?: Attachment[]
        partCAttachments?: Attachment[]
      },
    ) {
      const res: PostWIProcess =
        yield self.environment.api.wiWorkflow.submitWIReviewComment(
          JSON.stringify({
            Id: formId,
            PartEChildFormData: {
              IsImplementor: reviewData?.isImplementor,
              IsCheckedRadio: reviewData?.partADecision,
              MyComments: reviewData?.comment,
              IsCheckedRadioPartB: reviewData?.partBDecision,
              ImplementationByDate: reviewData?.implementationDate?.format(
                formats.dateTimeLocalSeconds,
              ),
              IsCheckedRadioPartC: reviewData?.partCDecision,
              Attachments: buildAttachmentPayload(reviewData?.attachments),
              PartCAttachments: buildAttachmentPayload(
                reviewData?.partCAttachments,
              ),
            },
          }),
          self.wiReviewId,
        )
      if (res.kind !== 'ok') throw new Error(res.kind)

      if (res.payload.success !== true)
        throw new Error(res.payload.errorMessage)
    }),
  }))
  .views((self) => ({
    get priority(): TaskPriority {
      return getPriorityByDeadline(
        moment(self.wiReviewDetail.partAData.targetCompletionDate),
      )
    },
    get status(): WIReviewStatus {
      return getWIReviewSnapShotStatus(self.currentStatus)
    },
    get refNo(): string {
      return self.wiReviewDetail.partAData.refNo ?? '-'
    },
    get wiNo(): string {
      return self.wiReviewDetail.partAData.wiNo ?? '-'
    },
    get wiTitle(): string {
      return self.wiReviewDetail.partAData.wiTitle ?? '-'
    },
    get dueDate(): moment.Moment {
      return moment(self.wiReviewDetail.partAData.targetCompletionDate)
    },
    get commentingWorkflows(): WICommentFormStatus[] | [] {
      return self.workflowData.partEWorkflow.commentFormList
    },
    get forwardingWorkflows(): CommentFormForwardList[] | [] {
      return self.workflowData.partEWorkflow.commentFormForwardList
    },
    get commentingUsers(): ADUser[] | [] {
      if (self.workflowData.partEWorkflow == null) {
        return []
      }
      return self.workflowData.partEWorkflow.commentFormList.map((form) => ({
        upn: form.user?.upn,
        displayName: form.user?.displayName,
        email: form.user?.email,
        jobTitle: form.user?.jobTitle,
      }))
    },
    get workflowAssignee(): WIWorkflowApproveUser[] {
      return getWIReviewWorkflowApproveUser(self.workflowData)
    },
    get wiReviewEndorser(): (User | undefined)[] {
      const firstEndorser =
        self.workflowData.partFWorkflow.next1NodeActionBy?.upn == null
          ? undefined
          : self.workflowData.partFWorkflow.next1NodeActionBy
      const secondEndorser =
        self.workflowData.partFWorkflow.next2NodeActionBy?.upn == null
          ? undefined
          : self.workflowData.partFWorkflow.next2NodeActionBy

      return [firstEndorser, secondEndorser]
    },
    get wiReviewApprover(): User[] | undefined {
      if (self.workflowData.partFWorkflow.next3NodeActionBy == null) {
        return undefined
      }

      return [self.workflowData.partFWorkflow.next3NodeActionBy]
    },
    getLatestAction(step: WIReviewStatus): WorkflowHistory {
      const historyNodes = self.workflowHistory.map((history) =>
        getWIReviewSnapShotStatus(history.status),
      )
      const idx = historyNodes.lastIndexOf(step)

      return self.workflowHistory[idx]
    },
    getCommentIdByUser(upn?: string): string {
      if (upn == null) {
        return ''
      }

      // Do not return commentId if it is not Part E
      if (
        getWIReviewSnapShotStatus(self.currentStatus) !== WIReviewStatus.partE
      ) {
        return ''
      }
      const assignedUpnList = (
        self.workflowData.partEWorkflow.commentFormList ?? []
      ).find(
        (form) =>
          isUpnMatched(form.user?.upn, upn) &&
          self.wiReviewDetail.partEData.commentForms.some(
            (it) => it.subFormId === form.formId && !it.isApproved,
          ),
      )
      if (assignedUpnList != null) {
        return assignedUpnList.formId
      }

      const forwardedForm =
        self.workflowData.partEWorkflow.commentFormForwardList.find(
          (form) =>
            form.forwardList.find(
              (it) => it.upn?.toLowerCase() === upn.toLowerCase(),
            ) != null &&
            self.wiReviewDetail.partEData.commentForms.some(
              (it) => it.subFormId === form.formId && !it.isApproved,
            ),
        )

      if (forwardedForm != null) {
        return forwardedForm.formId
      }

      return ''
    },
    getCommentIdByConsultee(upn?: string): string {
      if (upn == null) {
        return ''
      }

      // Do not return commentId if it is not Part E
      if (
        getWIReviewSnapShotStatus(self.currentStatus) !== WIReviewStatus.partE
      ) {
        return ''
      }

      const consultedForm =
        self.workflowData.partEWorkflow.commentFormList.find(
          (form) =>
            form.consultList.find((it) => it.user?.upn && !it.isReturned) !=
            null,
        )

      if (consultedForm != null) {
        return consultedForm.formId
      }

      return ''
    },
    getCommentFormStatusById(formId: string): WICommentFormStatus | undefined {
      return self.workflowData.partEWorkflow.commentFormList.find(
        (it) => it.formId === formId,
      )
    },
    getCommentFormById(formId: string): WIReviewCommentForm | undefined {
      return self.wiReviewDetail.partEData.commentForms.find(
        (it) => it.subFormId === formId,
      )
    },
    getCommentIdBySubFormId(subFormId: string | null): number | undefined {
      if (subFormId == null) {
        return undefined
      }

      const subForm = self.wiReviewDetail.partEData.commentForms.find(
        (form) => form.subFormId === subFormId,
      )

      if (subForm != null) {
        return subForm.id
      }

      return undefined
    },
    isCommentApproved(subFormId: string): boolean {
      return self.wiReviewDetail.partEData.commentForms.some(
        (it) => it.subFormId === subFormId && it.isApproved,
      )
    },
    isCurrentWorkFlowConsulting(): boolean {
      const workFlowList = [
        self.workflowData.partAWorkflow,
        self.workflowData.partBWorkflow,
        self.workflowData.partCWorkflow,
        self.workflowData.partDWorkflow,
        self.workflowData.partEWorkflow,
        self.workflowData.partFWorkflow,
        self.workflowData.partGWorkflow,
        self.workflowData.partHWorkflow,
        self.workflowData.partIWorkflow,
        self.workflowData.partJWorkflow,
        self.workflowData.partKWorkflow,
      ]
      const currentWorkFlow = workFlowList.find(
        (it) => it.currentStatusId === self.currentStatus,
      )
      if (currentWorkFlow == null) return false

      const currentConsultingList = currentWorkFlow.consultUserList.find(
        (it) => !it.isReturned,
      )
      return currentConsultingList != null
    },
    isCurrentWorkFlowForwarded(): boolean {
      const workFlowList = [
        self.workflowData.partAWorkflow,
        self.workflowData.partBWorkflow,
        self.workflowData.partCWorkflow,
        self.workflowData.partDWorkflow,
        self.workflowData.partEWorkflow,
        self.workflowData.partFWorkflow,
        self.workflowData.partGWorkflow,
        self.workflowData.partHWorkflow,
        self.workflowData.partIWorkflow,
        self.workflowData.partJWorkflow,
        self.workflowData.partKWorkflow,
      ]
      const currentWorkFlow = workFlowList.find(
        (it) => it.currentStatusId === self.currentStatus,
      )
      if (currentWorkFlow == null) return false

      const currentForwardList = currentWorkFlow.forwardList
      return currentForwardList.length !== 0
    },
    isAllSubformApproved(): boolean {
      return (
        self.wiReviewDetail.partEData.commentForms.find(
          (form) => !form.isApproved,
        ) == null
      )
    },
  }))

export type WIReview = Instance<typeof WIReviewModel>

export function mapPayloadToWIReview(
  payload: WIReviewDataPayload,
): SnapshotIn<WIReview> {
  return {
    wiReviewId: payload.objectId,
    isCancelled: payload.isCancel,

    wiReviewDetail: mapPayloadToWIReviewDetail(
      payload.wiControlProcessFormData,
    ),
    currentStatus: stringToNumber(payload.currentStatus),
    currentUser: stringToString(payload.currentUserUPN),
    currentUserUpnList: mapPayloadToCurrentUserUpnList(
      payload.currentUserUPNList,
    ),
    currentUserGroup: stringToString(payload.currentUserGroup),

    formType: getFormTypeByCode(payload.formType),
    createdDate: stringToDate(payload.createDate),
    createdBy: stringToString(payload.createBy),
    updatedDate: stringToDate(payload.updateDate),
    updatedBy: stringToString(payload.updateBy),

    workflowData: {
      partAWorkflow: mapPayloadToWIWorkflowPart(
        payload.wiControlProcessWorkflowData.partAWorkflow,
      ),
      partBWorkflow: mapPayloadToWIWorkflowPart(
        payload.wiControlProcessWorkflowData.partBWorkflow,
      ),
      partCWorkflow: mapPayloadToWIWorkflowPart(
        payload.wiControlProcessWorkflowData.partCWorkflow,
      ),
      partDWorkflow: mapPayloadToWIWorkflowPart(
        payload.wiControlProcessWorkflowData.partDWorkflow,
      ),
      partEWorkflow: mapPayloadToWIWorkflowPart(
        payload.wiControlProcessWorkflowData.partEWorkflow,
      ),
      partFWorkflow: mapPayloadToWIWorkflowPart(
        payload.wiControlProcessWorkflowData.partFWorkflow,
      ),
      partGWorkflow: mapPayloadToWIWorkflowPart(
        payload.wiControlProcessWorkflowData.partGWorkflow,
      ),
      partHWorkflow: mapPayloadToWIWorkflowPart(
        payload.wiControlProcessWorkflowData.partHWorkflow,
      ),
      partIWorkflow: mapPayloadToWIWorkflowPart(
        payload.wiControlProcessWorkflowData.partIWorkflow,
      ),
      partJWorkflow: mapPayloadToWIWorkflowPart(
        payload.wiControlProcessWorkflowData.partJWorkflow,
      ),
      partKWorkflow: mapPayloadToWIWorkflowPart(
        payload.wiControlProcessWorkflowData.partKWorkflow,
      ),
    },
    workflowHistory: mapPayloadToWorkflowHistory(payload.workflowHistoryList),
  }
}
