export const format: {
  [key: string]: string
} = {
  date: 'DD/MM/YYYY',
  datetime: 'DD/MM/YYYY HH:mm:ss',
  datetime_nos: 'DD/MM/YYYY HH:mm',
  month: 'MM/YYYY',
  monthInput: 'YYYY-MM',
  time: 'HH:mm',
}
