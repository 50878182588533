import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Link, Breadcrumbs, Typography } from '@mui/material'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { breadcrumbItemList } from './breadcrumb-item-list'
import { Icon } from '../icon'
import { icons } from '../../theme'

const HOME_PATH_NAME = 'home'

const Text = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSize[2]}px;

  @media only screen and (min-width: 800px) {
    font-size: ${({ theme }) => theme.fontSize[4]}px;
  }

  @media only screen and (min-width: 900px) {
    font-size: ${({ theme }) => theme.fontSize[6]}px;
  }
`

const Root = styled.div`
  .BoldText.MuiTypography-body1 {
    font-weight: bold;
  }
`

const StyledBreadcrumbs = styled(Breadcrumbs)`
  font-size: ${({ theme }) => theme.fontSize[6]}px;
`

const BreadcrumbIcon = styled(Icon)`
  color: ${({ theme }) => theme.palettes.general.corporateBlue};
  width: ${({ theme }) => theme.fontSize[6]}px;
  height: ${({ theme }) => theme.fontSize[6]}px;
`

export const Breadcrumb = observer(() => {
  const location = useLocation()

  const breadcrumbItems: { name: string; path: string }[] = useMemo(() => {
    const items: string[] = location.pathname
      .split('/')
      .filter((name) => name !== '')
      .filter((name) => breadcrumbItemList[name] != null)

    if (items.filter((name) => name === HOME_PATH_NAME).length === 0)
      items.unshift(HOME_PATH_NAME)

    return items.map((it) => ({
      name: breadcrumbItemList[it].displayName,
      path: breadcrumbItemList[it].path,
    }))
  }, [location.pathname])

  return (
    <Root>
      <StyledBreadcrumbs separator={<BreadcrumbIcon {...icons.rightArrow} />}>
        {breadcrumbItems.map((item, index) =>
          index === breadcrumbItems.length - 1 ? (
            <Text className="BoldText">{item.name}</Text>
          ) : (
            <Link component={RouterLink} to={item.path} underline="none">
              {item.name}
            </Link>
          ),
        )}
      </StyledBreadcrumbs>
    </Root>
  )
})
