import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'

import { mdiSend } from '@mdi/js'
import Icon from '@mdi/react'

import { icons } from '@mtr-SDO/theme-sdo/src/icons'
import { Icon as Ticon } from '@/components'

const Maindiv = styled.div`
  width: 100%;
  height: 100%;
`

// ---------------Create new
const StyledDemo = styled.button`
  border: none;
  outline: none;
  border-radius: 50%;
  width: 80px;
  height: 60px;
  background-color: #00224f;
  color: #fff;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0px;
  text-align: center;
  white-space: nowrap;
`

// ---------------submit
const SubmitButton = styled.button`
  outline: none;
  border: none;
  padding: 0px;
  height: 60px;
  width: 100px;
  line-height: 60px;
  text-align: center;
  background-color: #00224f;
  color: #fff;
`
const SubmitIcon = styled(Icon)`
  color: #faf0a0;
  transform: scale(0.6);
  vertical-align: -7px;
`

// ---------------next
const NextButton = styled.button`
  outline: none;
  border: none;
  padding: 0px;
  background-color: #367fa9;
  color: #fff;
  height: 40px;
  width: 100%;
  max-width: 600px;
  /* aspect-ratio: 10/1; */
  border-radius: 5px;
  font-size: 16px;
`

// ---------------take a picture
const CameraButton = styled.button`
  outline: none;
  border: none;
  padding: 0px;
  height: 80px;
  width: 100px;
  background-color: #f1f0f5;
`
const CameraTicon = styled(Ticon)`
  color: #cfd4da;
  transform: scale(0.7);
  transform-origin: top;
`
const CameraSpan = styled.span`
  margin-top: -44px;
  display: block;
`
export const Demo = observer(() => (
  <Maindiv>
    {/* ---------------Create new */}
    <StyledDemo>Create New</StyledDemo>

    {/* ---------------submit */}
    <SubmitButton>
      <span>Submit</span>
      <SubmitIcon path={mdiSend} size={1} />
    </SubmitButton>

    {/* ---------------next */}
    <NextButton>Next</NextButton>

    {/* ---------------take a picture */}
    <CameraButton>
      <CameraTicon {...icons.camera} />
      <CameraSpan>take a picture</CameraSpan>
    </CameraButton>
  </Maindiv>
))
