import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { FormControl, Button } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { TFunction, useTranslation } from 'react-i18next'
import { useState } from 'react'
import { DialogMessage, Spinner, SystemHeader, TextInput } from '@/components'
import Captcha from './captcha'
import { useCreateUser } from '@/queries'

const StyledContainer = styled.div`
  width: 100%;
  display: block;
  padding: 40px 0;
`
const StyledPageTitle = styled.span`
  font-size: 16px;
  display: block;
  width: 70%;
  min-width: 300px;
  margin: 12px auto;
`
const StyledFormControl = styled(FormControl)`
  display: flex;
  width: 70%;
  min-width: 300px;
  margin: 0 auto;
`
const StyledDiv = styled.div`
  margin-top: 18px;
  margin-bottom: 15px;
`
const StyledButtonGroup = styled.div`
  float: right;
`
const StyledButton = styled(Button)`
  display: inline-block;
  width: 100px;
  aspect-ratio: 7/3;
  margin-left: 15px;
  text-transform: none;
  border-radius: 8px;
  font-size: 16px;
  &.styledPrimary {
    background-color: #367fa9;
    color: #f8f3f3;
  }
  &.styledDefault {
    background-color: #f2f0f0;
    color: #222121;
  }
`
const StyledSpinner = styled(Spinner)`
  margin: 20px;
`
const StyledFlexDiv = styled.div`
  display: flex;
  column-gap: 20px;
`
const StyledCaptchaInput = styled(TextInput)`
  flex: 1 1 50%;
`
const StyledCaptcha = styled.div`
  margin-top: 4px;
`

interface IFormInput {
  email: string
  contactNumber: string
  companyName: string
  hkid: string
}

type FormInputType = {
  [propName in keyof IFormInput]: {
    label: string
    register: {}
    helperText?: string
    [propName: string]: any
  }
}

const getFormInputModel: (
  t: TFunction<'common', undefined>,
) => FormInputType = (t) => ({
  email: {
    label: t('registerPage.email.label'),
    register: {
      required: t('registerPage.email.required'),
      maxLength: { value: 50, message: t('registerPage.email.maxLength') },
      pattern: {
        value: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
        message: t('registerPage.email.pattern'),
      },
    },
    helperText: t('registerPage.email.helperText'),
  },
  contactNumber: {
    label: t('registerPage.contactNumber.label'),
    register: {
      required: t('registerPage.contactNumber.required'),
      maxLength: {
        value: 50,
        message: t('registerPage.contactNumber.maxLength'),
      },
      pattern: {
        value: /^([4-9])\d{7}$/,
        message: t('registerPage.contactNumber.pattern'),
      },
    },
  },
  companyName: {
    label: t('registerPage.companyName.label'),
    register: {
      required: t('registerPage.companyName.required'),
      maxLength: {
        value: 50,
        message: t('registerPage.companyName.maxLength'),
      },
    },
  },
  hkid: {
    label: t('registerPage.hkid.label'),
    register: {
      required: t('registerPage.hkid.required'),
      maxLength: { value: 50, message: t('registerPage.hkid.maxLength') },
      validate(value: string) {
        function checkHKID(HKID: string) {
          const strValidChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
          let str = HKID
          if (str?.length < 8) {
            return false
          }

          // handling bracket
          if (
            str.charAt(str.length - 3) === '(' &&
            str.charAt(str.length - 1) === ')'
          ) {
            str = str.substring(0, str.length - 3) + str.charAt(str.length - 2)
          }

          // convert to upper case
          str = str.toUpperCase()
          // regular expression to check pattern and split
          const hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/
          const matchArray = str.match(hkidPat)
          // not match, return false
          if (matchArray === null) {
            return false
          }
          // the character part, numeric part and check digit part
          const charPart = matchArray[1]
          const numPart = matchArray[2]
          const checkDigit = matchArray[3]

          // calculate the checksum for character part
          let checkSum = 0
          if (charPart.length === 2) {
            checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)))
            checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)))
          } else {
            checkSum += 9 * 36
            checkSum += 8 * (10 + strValidChars.indexOf(charPart))
          }
          // calculate the checksum for numeric part
          for (let i = 0, j = 7; i < numPart.length; i += 1, j -= 1) {
            checkSum += j * +numPart.charAt(i)
          }
          // verify the check digit
          const remaining = checkSum % 11
          const verify = remaining === 0 ? 0 : 11 - remaining
          if (verify.toString() === checkDigit) {
            return true
          }
          if (verify === 10 && checkDigit === 'A') {
            return true
          }
          return false
        }
        return checkHKID(value) || t('registerPage.hkid.validate')
      },
    },

    helperText: t('registerPage.hkid.helperText'),
  },
})

export const RegisterPage = observer(() => {
  const { t } = useTranslation()
  const formInputModel = getFormInputModel(t)
  const history = useHistory()
  const { control, handleSubmit } = useForm<IFormInput & { validateCode: string }>()
  const [open, setOpen] = useState(false)
  const [dialogMessage, SetDialogMessage] = useState<string[]>([])
  const [isSuccess, setIsSuccess] = useState(false)
  const [requestID, setRequestID] = useState('')
  const { mutateAsync: createUserAsync, isLoading } = useCreateUser({
    onMutate(variables) {
      setOpen(true)
      return variables
    },
    onError(error) {
      SetDialogMessage([error.message])
    },
    onSuccess(data) {
      if (data.kind === 'ok') {
        SetDialogMessage([data.payload.data])
        setIsSuccess(true)
      }
    },
  })

  const handleSignIn = () => history.goBack()

  const handleClose = () => {
    setOpen(false)
    if (isSuccess) {
      handleSignIn()
    }
  }

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    try {
      await createUserAsync({ ...data, requestID })
    } catch {}
  }

  return (
    <StyledContainer>
      <SystemHeader 
        format="longer"
        titleStyle={{fontSize:'4vw'}}
      />
      <StyledPageTitle>({t(`registerPage.title`)})</StyledPageTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormControl>
          {Object.keys(formInputModel).map((i) => {
            const key: keyof IFormInput = i as keyof IFormInput
            const { label, register, helperText = ' ' } = formInputModel[key]
            return (
              <Controller
                key={key}
                name={key}
                rules={register}
                control={control}
                render={({ field, formState: { errors } }) => {
                  const inputError = errors[key]
                  return (
                    <TextInput
                      label={label}
                      error={!!inputError}
                      textHelper={inputError?.message ?? helperText}
                      {...field}
                    />
                  )
                }}
              />
            )
          })}
          <Controller
            name="validateCode"
            rules={{
              required: t('registerPage.validateCode.required'),
            }}
            control={control}
            render={({ field, formState: { errors } }) => {
              const inputError = errors.validateCode
              return (
                <StyledFlexDiv>
                  <StyledCaptchaInput
                    outlinedInputProps={{ style: { minWidth: '50%' } }}
                    error={!!inputError}
                    textHelper={
                      inputError?.message ??
                      t('registerPage.validateCode.helperText')
                    }
                    {...field}
                  />
                  <StyledCaptcha>
                    <Captcha
                      width={100}
                      height={50}
                      updateHandle={setRequestID}
                    />
                  </StyledCaptcha>
                </StyledFlexDiv>
              )
            }}
          />

          <StyledDiv>
            <StyledButtonGroup>
              <StyledButton
                disableElevation
                variant="contained"
                className="styledDefault"
                onClick={handleSignIn}
              >
                {t(`cancel`)}
              </StyledButton>
              <StyledButton
                disableElevation
                variant="contained"
                className="styledPrimary"
                type="submit"
              >
                {t(`submit`)}
              </StyledButton>
            </StyledButtonGroup>
          </StyledDiv>
        </StyledFormControl>
      </form>
      <DialogMessage
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        contentTextArray={dialogMessage}
        confirmButtonProps={{ children: t('ok'), onClick: handleClose }}
        {...{
          ...(isLoading
            ? {
                customContent: <StyledSpinner />,
                onClose: undefined,
                customDialogActions: <></>,
              }
            : { fullWidth: true, maxWidth: 'xs' }),
        }}
      />
    </StyledContainer>
  )
})
