import _ from 'lodash'
import { Instance, types } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import { TrainStockTypeModel } from './train-stock-type.model'

export const EquipmentModel = types
  .model({
    id: types.optional(types.identifier, uuid),
    number: types.string,
    stockType: types.maybe(types.reference(TrainStockTypeModel)),
    subtype: types.maybe(types.string),
  })
  .actions((self) => ({
    apply(snapshot: {}) {
      _.keys(_.omit(snapshot, 'id')).forEach((key) => {
        if (snapshot[key] == null) return
        self[key] = snapshot[key]
      })
    },
  }))

export type Equipment = Instance<typeof EquipmentModel>

export enum EquipmentType {
  RailAssure = 'RailASSURE',
  Assembly = 'Assembly',
}
