import { Theme } from '@mtr-SDO/theme-core'

export const icons: Theme['icons'] = {
  maintenance: { type: 'material-community', name: 'hammer' },
  review: { type: 'material-community', name: 'file-find' },
  settings: { type: 'material', name: 'settings' },
  search: { type: 'material', name: 'search' },
  filter: { type: 'material-community', name: 'filter-variant' },
  sort: { type: 'material-community', name: 'sort' },

  standardJob: { type: 'material-community', name: 'content-copy' },
  equipment: { type: 'material-community', name: 'train' },
  workOrders: {
    type: 'material-community',
    name: 'text-box-multiple-outline',
  },
  workOrder: { type: 'material-community', name: 'text-box-outline' },
  workOrderNumber: { type: 'material-community', name: 'numeric' },
  forms: {
    type: 'material-community',
    name: 'text-box-multiple-outline',
  },
  notification: { type: 'material-community', name: 'bell-outline' },
  formUpload: { type: 'material-community', name: 'file-upload-outline' },
  upload: { type: 'material-community', name: 'upload' },
  verify: { type: 'material-community', name: 'send' },
  endorse: { type: 'material-community', name: 'check-all' },
  're-work': { type: 'material-community', name: 'archive-arrow-up' },

  workInstruction: {
    type: 'material-community',
    name: 'clipboard-list-outline',
  },

  fitdefit: { type: 'material-community', name: 'swap-vertical-bold' },
  fit: { type: 'material-community', name: 'arrow-up-bold' },
  defit: { type: 'material-community', name: 'arrow-down-bold' },

  back: { type: 'material-community', name: 'arrow-left' },
  rightArrowLight: { type: 'material-community', name: 'arrow-right' },
  leftArrow: { type: 'material-community', name: 'arrow-left-thick' },
  rightArrow: { type: 'material-community', name: 'arrow-right-thick' },

  error: { type: 'material', name: 'error-outline' },
  file: { type: 'material', name: 'folder-open' },
  snapShot: { type: 'material', name: 'camera' },
  rightChevron: { type: 'material-community', name: 'chevron-right' },
  expand: { type: 'material', name: 'expand-more' },
  collapse: { type: 'material', name: 'expand-less' },
  expandHidden: { type: 'material-community', name: 'unfold-more-horizontal' },
  info: { type: 'material', name: 'info-outline' },
  infoMaterialCommunity: {
    type: 'material-community',
    name: 'information-outline',
  },
  formAttachment: { type: 'material-community', name: 'file-document-outline' },
  itemChecked: {
    type: 'material-community',
    name: 'checkbox-marked-circle-outline',
  },
  unfilled: {
    type: 'material-community',
    name: 'checkbox-blank-circle-outline',
  },
  flagged: { type: 'material-community', name: 'alert-circle-outline' },
  close: { type: 'material', name: 'close' },
  pm: { type: 'material-community', name: 'shield-search' },
  cm: { type: 'material-community', name: 'tools' },
  overhaul: { type: 'material-community', name: 'hand-saw' },
  sync: { type: 'material-community', name: 'sync' },
  checked: { type: 'material-community', name: 'check' },
  checkedBold: { type: 'material-community', name: 'check-bold' },
  minus: { type: 'material-community', name: 'minus' },
  doubleChecked: { type: 'material-community', name: 'check-all' },
  submit: { type: 'material', name: 'send' },
  saveForSubmit: { type: 'material-community', name: 'archive-arrow-down' },
  cross: { type: 'material', name: 'clear' },
  crossCircle: { type: 'material-community', name: 'close-circle' },
  reject: { type: 'fontisto', name: 'arrow-return-left' },
  delete: { type: 'material', name: 'delete' },
  void: { type: 'material', name: 'delete' },
  unfrozen: { type: 'material-community', name: 'content-save-edit' },
  history: { type: 'material', name: 'history' },
  relatedForms: { type: 'material-community', name: 'file-multiple-outline' },
  form: { type: 'material', name: 'assignment' },
  remark: { type: 'material-community', name: 'pencil' },
  summary: { type: 'material-community', name: 'ballot' },
  depot: { type: 'material-community', name: 'garage' },
  train: { type: 'material-community', name: 'train' },
  preview: { type: 'material-community', name: 'file-find' },
  logout: { type: 'material-community', name: 'logout' },
  approve: { type: 'material-community', name: 'check' },
  worker: { type: 'material-community', name: 'account-hard-hat' },
  approver: { type: 'material-community', name: 'account-tie' },
  user: { type: 'material', name: 'person' },
  // quickAction Bar
  ruler: { type: 'material-community', name: 'ruler' },
  infoCard: { type: 'material-community', name: 'card-bulleted-outline' },
  attachment: { type: 'material-community', name: 'attachment' },
  camera: { type: 'material-community', name: 'camera' },
  pencil: { type: 'material-community', name: 'pencil' },
  home: { type: 'material-community', name: 'home' },
  circlePlay: {
    type: 'material-community',
    name: 'arrow-right-drop-circle-outline',
  },
  innerItemIndicator: { type: 'material-community', name: 'book-open-outline' },
  gridView: { type: 'material', name: 'view-module' },
  listView: { type: 'material', name: 'view-headline' },
  structuralConfig: { type: 'material-community', name: 'file-tree' },

  scanQR: { type: 'material-community', name: 'qrcode-scan' },
  scanBarcode: { type: 'material-community', name: 'barcode-scan' },

  inventory: { type: 'material-community', name: 'package-variant-closed' },

  detail: {
    type: 'material-community',
    name: 'dots-horizontal-circle-outline',
  },
  more: { type: 'material', name: 'more-vert' },
  moreHorizontal: { type: 'material-community', name: 'dots-horizontal' },

  alertBox: { type: 'material-community', name: 'alert-box-outline' },
  alertClipboard: { type: 'material-community', name: 'clipboard-alert' },
  uncachedFormDefinition: {
    type: 'material-community',
    name: 'file-hidden',
  },

  position: { type: 'material-community', name: 'crosshairs-gps' },
  time: { type: 'material-community', name: 'clock-outline' },

  formUnique: { type: 'material-community', name: 'file' },
  formIndependent: { type: 'material-community', name: 'file-multiple' },
  formMultiple: { type: 'material-community', name: 'file-plus' },

  checkCircle: { type: 'material-community', name: 'check-circle' },
  minusCircle: { type: 'material-community', name: 'minus-circle' },
  alertCircle: { type: 'material-community', name: 'alert-circle' },

  workflowEndorseApprove: {
    type: 'material-community',
    name: 'account-multiple-check',
  },
  workflowApprovalOnly: { type: 'material-community', name: 'account-check' },

  alert: { type: 'material-community', name: 'alert' },
  workGroups: { type: 'material-community', name: 'home-group' },
  autosave: { type: 'material-community', name: 'progress-wrench' },

  recordVideo: { type: 'material-community', name: 'record' },
  recordVideoStop: { type: 'material-community', name: 'stop' },
  recordIndicator: { type: 'material-community', name: 'record-rec' },

  checkboxEmpty: { type: 'material-community', name: 'checkbox-blank-outline' },
  checkboxMultipleEmpty: {
    type: 'material-community',
    name: 'checkbox-multiple-blank-outline',
  },
  checkboxChecked: { type: 'material-community', name: 'checkbox-marked' },
  checkboxNo: { type: 'material-community', name: 'close-box' },
  checkboxFlagged: { type: 'material-community', name: 'alert-box' },
  checkboxFlaggedLegacy: { type: 'material-community', name: 'minus-box' },
  checkboxNa: {
    type: 'material-community',
    name: 'checkbox-blank-off-outline',
  },
  crop: { type: 'material-community', name: 'crop' },
  cmProceedArrow: { type: 'material-community', name: 'menu-right' },

  offlineMode: { type: 'material-community', name: 'access-point-network-off' },
  offlineModeCache: { type: 'material-community', name: 'download-network' },
  noCloud: { type: 'material', name: 'cloud-off' },
  uploadCloud: { type: 'material', name: 'cloud-upload' },
  doneCloud: { type: 'material', name: 'cloud-done' },
  download: { type: 'material', name: 'file-download' },

  offlineAvailable: { type: 'material-community', name: 'cloud-check' },

  workNatureLv2: {
    type: 'material-community',
    name: 'numeric-2-circle-outline',
  },
  failureCode: { type: 'material-community', name: 'message-alert' },
  failureCauseCode: { type: 'material-community', name: 'fire' },
  failureActionCode: { type: 'material-community', name: 'fire-extinguisher' },

  qualification: { type: 'material-community', name: 'school' },
  pdf: { type: 'material-community', name: 'file-pdf' },
  pdfAttachment: { type: 'material-community', name: 'file-pdf-outline' },
  excel: { type: 'material-community', name: 'file-excel-outline' },
  doc: { type: 'material-community', name: 'file-word-outline' },
  powerpoint: { type: 'material-community', name: 'file-powerpoint-outline' },
  image: { type: 'material-community', name: 'file-image-outline' },
  video: { type: 'material-community', name: 'file-video-outline' },
  audio: { type: 'material-community', name: 'file-music-outline' },
  others: { type: 'material-community', name: 'file-question-outline' },
  youtube: { type: 'material-community', name: 'youtube' },

  completedAlert: { type: 'material-community', name: 'alert-octagon' },
  dropdown: { type: 'material-community', name: 'menu-down' },

  assemblyItemParent: {
    type: 'font-awesome',
    name: 'cogs',
  },
  railAssure: {
    type: 'material-community',
    name: 'tram-side',
  },

  patch: { type: 'material-community', name: 'bandage' },
  open: { type: 'material-community', name: 'open-in-new' },

  torchOff: { type: 'material-community', name: 'flashlight-off' },
  torchOn: { type: 'material-community', name: 'flashlight' },

  flashOff: { type: 'material-community', name: 'flash-off' },
  flashOn: { type: 'material-community', name: 'flash' },
  flashAuto: { type: 'material-community', name: 'flash-auto' },

  checkboxFirstChecker: {
    type: 'material-community',
    name: 'checkbox-marked-outline',
  },
  checkboxSecondChecker: {
    type: 'material-community',
    name: 'checkbox-multiple-marked',
  },

  calendarStart: {
    type: 'material-community',
    name: 'calendar-account',
  },
  calendarEnd: {
    type: 'material-community',
    name: 'calendar-check',
  },

  clockStart: { type: 'material-community', name: 'clock-start' },
  clockEnd: { type: 'material-community', name: 'clock-end' },

  plugOff: { type: 'material-community', name: 'power-plug-off-outline' },
  symptom: { type: 'material-community', name: 'thermometer-alert' },

  personInCharge: { type: 'material-community', name: 'account-hard-hat' },

  failure: { type: 'material-community', name: 'message-alert' },
  failureCause: { type: 'material-community', name: 'sticker-alert' },
  failureAction: { type: 'material-community', name: 'restore' },
  quantity: { type: 'material-community', name: 'counter' },
  failureComponent: { type: 'material-community', name: 'fan-alert' },
  failureReplacedComponent: {
    type: 'material-community',
    name: 'fan-chevron-up',
  },

  save: { type: 'material', name: 'save' },
  calendarAlert: { type: 'material-community', name: 'calendar-alert' },
  reporter: { type: 'material-community', name: 'account-edit' },
  root: { type: 'material-community', name: 'file-tree' },

  referenceDelay: { type: 'material-community', name: 'clock-alert-outline' },
  accumulatedDelay: { type: 'material-community', name: 'timer-outline' },

  priorityPending: {
    type: 'material-community',
    name: 'clock-time-three-outline',
  },
  priorityUpcoming: {
    type: 'material-community',
    name: 'clock-time-ten-outline',
  },
  priorityOverdue: { type: 'material-community', name: 'clock-alert-outline' },
  priorityUnknown: {
    type: 'material-community',
    name: 'clock-time-twelve-outline',
  },

  plus: {
    type: 'material-community',
    name: 'plus',
  },

  restoreDeleted: {
    type: 'material-community',
    name: 'delete-restore',
  },

  collapseLeft: {
    type: 'material-community',
    name: 'arrow-collapse-left',
  },

  windowCollapse: {
    type: 'material-community',
    name: 'arrow-collapse',
  },
  windowExpand: {
    type: 'material-community',
    name: 'arrow-expand',
  },
  windowFullscreen: {
    type: 'material-community',
    name: 'fullscreen',
  },

  tag: { type: 'material-community', name: 'tag-outline' },
  tags: { type: 'material-community', name: 'tag-multiple-outline' },

  // notification type
  read: {
    type: 'material-community',
    name: 'email-open-outline',
  },
  unread: {
    type: 'material-community',
    name: 'email-outline',
  },
  goto: { type: 'material-community', name: 'arrow-right-bold-circle-outline' },

  fillMultiple: {
    type: 'material-community',
    name: 'pencil-box-multiple-outline',
  },
}

export const iconsVariant: {
  [key: string]: { [key: string]: IconProps }
} = {
  assemblyItem: {
    outlined: { type: 'material-community', name: 'cog-outline' },
    filled: { type: 'material-community', name: 'cog' },
  },
}

export const iconsList: {
  [key: string]: (IconProps | { [key: string]: IconProps })[]
} = {
  checkbox: [icons.checkboxEmpty, icons.checked, icons.minus, icons.checkboxNa],
  secondChecker: [icons.checkboxEmpty],
}

export type IconTypes = keyof typeof icons
