import { ActionBy } from '@mtr-SDO/apis'
import { WiReviewNodeId } from '@mtr-SDO/datamodels'
import { withEnvironment } from '@mtr-SDO/models-core'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import {
  mapPayloadToUser,
  WIWorkflowApproveUser,
  WIWorkflowPart,
  WIWorkflowPartModel,
} from '../wi-workflow'

export const WIReviewWorkflowModel = types
  .model({
    partAWorkflow: WIWorkflowPartModel,
    partBWorkflow: WIWorkflowPartModel,
    partCWorkflow: WIWorkflowPartModel,
    partDWorkflow: WIWorkflowPartModel,
    partEWorkflow: WIWorkflowPartModel,
    partFWorkflow: WIWorkflowPartModel,
    partGWorkflow: WIWorkflowPartModel,
    partHWorkflow: WIWorkflowPartModel,
    partIWorkflow: WIWorkflowPartModel,
    partJWorkflow: WIWorkflowPartModel,
    partKWorkflow: WIWorkflowPartModel,
  })
  .extend(withEnvironment)

export type WIReviewWorkflow = Instance<typeof WIReviewWorkflowModel>

function convertWorkflowUserToApproveUser(
  workflow: WIWorkflowPart,
): SnapshotIn<WIWorkflowApproveUser> {
  const consultingUser = workflow.consultUserList.find((it) => !it.isReturned)
  if (consultingUser != null) {
    return {
      partId: workflow.nodeId,
      user: mapPayloadToUser(consultingUser.originalUser),
      groupName: workflow.currentUserGroup,
    }
  }
  if (workflow.currentActionBy?.upn == null) {
    return {
      partId: workflow.nodeId,
      user: undefined,
      groupName: workflow.currentUserGroup,
    }
  }
  return {
    partId: workflow.nodeId,
    user: mapPayloadToUser(workflow.currentActionBy),
    groupName: workflow.currentUserGroup,
  }
}

function convertWorkflowNodeToApproveUser(
  action: ActionBy,
  partId: number,
  workflow: WIWorkflowPart,
): SnapshotIn<WIWorkflowApproveUser> {
  if (action?.upn == null) {
    return {
      partId,
      user: undefined,
      groupName: undefined,
    }
  }
  return convertWorkflowUserToApproveUser(workflow)
}

export function getWIReviewWorkflowApproveUser(
  workflow: WIReviewWorkflow,
): SnapshotIn<WIWorkflowApproveUser[]> {
  return [
    convertWorkflowUserToApproveUser(workflow.partAWorkflow),
    convertWorkflowUserToApproveUser(workflow.partBWorkflow),
    convertWorkflowUserToApproveUser(workflow.partCWorkflow),
    convertWorkflowUserToApproveUser(workflow.partDWorkflow),
    convertWorkflowUserToApproveUser(workflow.partEWorkflow),
    convertWorkflowUserToApproveUser(workflow.partFWorkflow),
    convertWorkflowNodeToApproveUser(
      workflow.partFWorkflow.next1NodeActionBy,
      WiReviewNodeId.partG,
      workflow.partGWorkflow,
    ),
    convertWorkflowNodeToApproveUser(
      workflow.partFWorkflow.next2NodeActionBy,
      WiReviewNodeId.partH,
      workflow.partHWorkflow,
    ),
    convertWorkflowNodeToApproveUser(
      workflow.partFWorkflow.next3NodeActionBy,
      WiReviewNodeId.partI,
      workflow.partIWorkflow,
    ),
    convertWorkflowUserToApproveUser(workflow.partJWorkflow),
    convertWorkflowUserToApproveUser(workflow.partKWorkflow),
  ]
}
