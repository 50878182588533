import { ApiResponse, ApisauceInstance } from 'apisauce'
import { AxiosRequestConfig } from 'axios'
import moment from 'moment'
import { AttunityWorkOrderStatus } from '../attunity'
import { ApiBase, ApiRawResponse } from './api-base'
import { GeneralApiResponse } from './api-base.types'
import { ApiConfig } from './api-config'
import * as Types from './api.types'
import { postFile } from './azure-blob-storage'
import { BlobHandler } from './blob-handler.types'
import { ApiCMDetails } from './cm-details'
import { ApiForms } from './forms'
import { ApiHealthChecks } from './health-checks'
import { ApiOrderBook } from './order-book'
import { ApiUserOperation } from './user-operation'
import { ApiUserProfile } from './user-profile'
import { ApiWIWorkflow } from './wi-workflow'
import { ApiWorkOrderForms } from './work-order-forms'
import { ApiWorkOrders } from './work-orders'

export class Api {
  private apiBase: ApiBase

  public healthChecks: ApiHealthChecks

  public cmDetails: ApiCMDetails

  public userProfile: ApiUserProfile

  public forms: ApiForms

  public workOrderForms: ApiWorkOrderForms

  public workOrders: ApiWorkOrders

  public wiWorkflow: ApiWIWorkflow

  public userOperation: ApiUserOperation

  public orderBook: ApiOrderBook

  constructor(
    config: ApiConfig,
    blobHandler?: BlobHandler,
    unauthorizedHandler?: () => void,
  ) {
    this.apiBase = new ApiBase(config, unauthorizedHandler, blobHandler)
    this.cmDetails = new ApiCMDetails(this.apiBase)
    this.userProfile = new ApiUserProfile(this.apiBase)
    this.workOrderForms = new ApiWorkOrderForms(this.apiBase)
    this.forms = new ApiForms(this.apiBase)
    this.workOrders = new ApiWorkOrders(this.apiBase)
    this.healthChecks = new ApiHealthChecks(this.apiBase)
    this.wiWorkflow = new ApiWIWorkflow(this.apiBase)
    this.userOperation = new ApiUserOperation(this.apiBase)
    this.orderBook = new ApiOrderBook(this.apiBase)
  }

  setUnauthorizedHandler(handler: () => void) {
    this.apiBase.setUnauthorizedHandler(handler)
  }

  get config(): ApiConfig {
    return this.apiBase.config
  }

  get apisauce(): ApisauceInstance {
    return this.apiBase.apisauce
  }

  get blobHandler(): BlobHandler | undefined {
    return this.apiBase.blobHandler
  }

  public setAuthHeader(authHeader: string): void {
    this.apiBase.setAuthHeader(authHeader)
  }

  public clearAuthHeader(): void {
    this.apiBase.clearAuthHeader()
  }

  public async generalApi<T>(
    apiPromise: Promise<ApiResponse<ApiRawResponse<T>>>,
  ): Promise<GeneralApiResponse<T>> {
    return this.apiBase.generalApi<T>(apiPromise)
  }

  getEquipmentInfo = async (number: string) =>
    this.generalApi<Types.EquipmentPayload>(
      this.apisauce.get('/Common/Attunity/GetEquipmentInfoByEquipmentCode', {
        equipmentCode: number,
      }),
    )

  getStandardJob = async (jobcode: string) =>
    this.generalApi<Types.AttunityStandardJobPayload>(
      this.apisauce.get('/Common/Attunity/GetStandardJob', {
        jobcode,
      }),
    )

  getStandardJobs = async (equipment?: { number: string }) =>
    this.generalApi<Types.AttunityStandardJobPayload[]>(
      this.apisauce.get('/Common/Attunity/GetStandardJobByEquipmentCode', {
        equipmentCode: equipment?.number,
      }),
    )

  getIMTEStatus = async (imteNumber: string) =>
    this.generalApi<Types.IMTEStatusPayload>(
      this.apisauce.get('/Common/Attunity/ValiadationIMTE', {
        IMTECode: imteNumber,
      }),
    )

  getTrainTypes = async () =>
    this.generalApi<Types.GetTrainStockTypePayloadList>(
      this.apisauce.get('/Common/Attunity/GetTrainType'),
    )

  getDepots = async () =>
    this.generalApi<Types.GetDepots>(
      this.apisauce.get('/Common/Attunity/GetDepotList'),
    )

  getWorkNatures = async () =>
    this.generalApi<Types.GetWorkNaturePayloadItem>(
      this.apisauce.get('/Common/Attunity/GetWorkNature'),
    )

  getAllWorkGroups = async () =>
    this.generalApi<Types.GetAllWorkGroups>(
      this.apisauce.get('/Common/Attunity/GetWorkGroup'),
    )

  getWorkOrderFormList = async (workOrder: {
    number: string
    depot: { id: string }
    standardJob: {
      code: string
    }
    equipment: {
      number: string
    }
    nature: { name: string }
    workGroupId: string | undefined
    startDate: Date | undefined
    completeDate: Date | undefined
    estimatedDuration: number | undefined
    workOrderDescription: string | undefined
    createDate: Date | undefined
    parentWorkOrderNumber: string | undefined
    relation: 'PARENT' | 'CHILD' | undefined
    trainStockTypeId: string | undefined
  }) =>
    this.generalApi<Types.WrappedWorkOrderFormListPayloadItem>(
      this.apisauce.get('/Form/FormData/GetFormDataListInGroup', {
        standardJobCode: workOrder.standardJob.code,
        workOrder: workOrder.number,
        equipmentNo: workOrder.equipment.number,
        depotID: workOrder.depot?.id,
        workNature: workOrder.nature.name,
        workGroupID: workOrder.workGroupId,
        planStartDate:
          workOrder.startDate == null
            ? undefined
            : moment(workOrder.startDate).format(
                moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
              ),
        planCompleteDate:
          workOrder.completeDate == null
            ? undefined
            : moment(workOrder.completeDate).format(
                moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
              ),
        estimateJobDuration: workOrder.estimatedDuration,
        workOrderDescription: workOrder.workOrderDescription,
        workOrderCreateDate:
          workOrder.createDate == null
            ? undefined
            : moment(workOrder.createDate).format(
                moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
              ),
        parentWorkOrder: workOrder.parentWorkOrderNumber,
        relationshipType: workOrder.relation,
        trainTypeId: workOrder.trainStockTypeId,
      }),
    )

  // 提交附件前的时候，使用此api
  // get token and container file path
  requestAttachmentContainer = async () =>
    this.generalApi<Types.AttachmentContainerRequestPayload>(
      this.apisauce.get('Common/Attachment/GetAzureStorageInfo'),
    )

  // only get token used in show image, video or can be viewed attachment
  requestAttachmentViewToken = async () =>
    this.generalApi<Types.AttachmentViewTokenRequestPayload>(
      this.apisauce.get('Common/AzureStorage/token'),
    )

  uploadAttachment = async (
    attachment: {
      // id: string
      title: string | undefined
      mime: string
      type: string | undefined
    } & (
      | {
          platform: 'app'
          temporaryPath: string
        }
      | {
          platform: 'web'
          file: File
        }
    ),
    blobStorageConfig: {
      endpoint: string
      sasToken: string
      timeout: number
    },
    progressCallback?: (written: number, total: number) => void,
  ) => {
    if (this.apiBase.blobHandler == null) throw new Error('unsupported')

    const onProgress =
      progressCallback ??
      ((written: number, total: number) => {
        console.info(`Upload Progress: ${written}/${total}`)
      })
    const configRes = await this.requestAttachmentContainer()
    if (configRes.kind !== 'ok') throw Error(configRes.kind)
    const configPayload = configRes.payload
    if (configPayload == null) throw Error('empty-response')
    const { sasToken, containerName: container } = configPayload
    return postFile(
      attachment,
      container,
      {
        ...blobStorageConfig,
        sasToken,
      },
      this.apiBase.blobHandler,
      onProgress,
    )
  }

  uploadFormData = async (uploadPayload: any) =>
    this.generalApi<Types.UploadFormData>(
      this.apisauce.post('Form/FormData/SubmitFormData', uploadPayload),
    )

  getWorkOrder = async (formGroupDataID: string) =>
    this.generalApi<Types.WorkOrderPayload>(
      this.apisauce.get('Common/Attunity/GetWorkOrder', { formGroupDataID }),
    )

  getReviewWorkOrders = async (
    pageIndex?: number,
    pageSize?: number,
    sortExpression?: string,
    whereClause?: string,
    imteCode?: string,
  ) =>
    this.generalApi<Types.ReviewWorkOrderPayloadItem[]>(
      this.apisauce.get('Form/Review/GetFormGroupDataList', {
        pageSize,
        pageIndex,
        sortExpression,
        whereClause,
        IMTECode: imteCode,
      }),
    )

  getOrderBookItemPage = async (pageIndex?: number, pageSize?: number) =>
    this.generalApi<Types.BookOrderMain>(
      this.apisauce.get('Form/OrderBookItem/GetOrderBookItemPage', {
        pageSize,
        pageIndex,
      }),
    )

  getFormDataListByOrderBookItemID = async (
    orderBookItemID?: string,
    workTypeID?: string,
    code?:string,
  ) =>
    this.generalApi<Types.BookOrderListItemPayload[]>(
      this.apisauce.get('Form/FormData/GetFormDataListByOrderBookItemID', {
        orderBookItemID,
        workTypeID,
        code
      }),
    )

  getReviewWorkOrderForms = async ({ remoteId }: { remoteId: string }) =>
    this.generalApi<Types.GetReviewWorkOrderForms>(
      this.apisauce.get('Form/Review/GetFormDataList', {
        formGroupDataObjectID: remoteId,
      }),
    )

  getFormData = async (
    remoteId: string,
    upn?: string,
    historyObjectId?: string,
  ) =>
    this.generalApi<Types.GetFormData>(
      this.apisauce.get('/Form/FormData/GetFormDataByObjectID', {
        formDataObjectID: remoteId,
        userUPN: upn,
        historyObjectId,
      }),
    )

  getApproverList = async (remoteId: string, roleId: number) =>
    this.generalApi<Types.GetApproverList>(
      this.apisauce.get('/Workflow/Workflow/GetReviewList', {
        formDataObjectID: remoteId,
        roleId,
      }),
    )

  completeWorkOrder = async (
    { remoteId }: { remoteId: string },
    currentUser: { username: string; upn: string },
    date?: moment.Moment,
  ) =>
    this.generalApi<Types.PostCompleteWorkOrder>(
      this.apisauce.post('/Workflow/Workflow/CompleteForm', {
        formGroupDataObjectID: remoteId,
        completeUserName: currentUser.username,
        completeUserUPN: currentUser.upn,
        actualCompleteDate: date?.format(
          moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
        ),
      }),
    )

  submitFormDataForEndorse = async (
    { remoteId }: { remoteId: string },
    currentUser: { username: string; upn: string },
    endorser: { username: string; upn: string },
    summary: { total: number; filled: number; flagged: number },
    metadata: { [key: string]: any },
    remark?: string,
  ) =>
    this.generalApi<Types.PostSubmitFormData>(
      this.apisauce.post('/Workflow/Workflow/SubmitFormToEndorse', {
        formDataObjectID: remoteId,
        userName: currentUser.username,
        userUPN: currentUser.upn,
        endorseName: endorser.username,
        endorseUPN: endorser.upn,
        summary,
        teamLeaderRemark: remark,
        metadata: JSON.stringify(metadata),
      }),
    )

  submitFormDataForApprove = async (
    { remoteId }: { remoteId: string },
    currentUser: { username: string; upn: string },
    approver: { username: string; upn: string },
    summary: { total: number; filled: number; flagged: number },
    metadata?: { [key: string]: any },
    remark?: string,
  ) =>
    this.generalApi<Types.PostSubmitFormData>(
      this.apisauce.post('/Workflow/Workflow/SubmitFormToApprove', {
        formDataObjectID: remoteId,
        userName: currentUser.username,
        userUPN: currentUser.upn,
        approverName: approver.username,
        approverUPN: approver.upn,
        summary,
        teamLeaderRemark: remark,
        metadata: JSON.stringify(metadata),
      }),
    )

  approveFormData = async (
    { remoteId }: { remoteId: string },
    currentUser: { username: string; upn: string },
    approver?: { username: string; upn: string },
  ) =>
    this.generalApi<Types.PostApproveFormData>(
      this.apisauce.post('/Workflow/Workflow/ApproveForm', {
        formDataObjectID: remoteId,
        userName: currentUser.username,
        userUPN: currentUser.upn,
        approverName: approver ? approver.username : undefined,
        approverUPN: approver ? approver.upn : undefined,
        // approvalLevel: approvalLevel || 0,
      }),
    )

  rejectFormData = async (
    { remoteId }: { remoteId: string },
    reason: string,
    currentUser: { username: string; upn: string },
    approver?: { username: string; upn: string },
    approvalLevel?: number,
  ) =>
    this.generalApi<Types.PostRejectFormData>(
      this.apisauce.post('/Workflow/Workflow/RejectForm', {
        formDataObjectID: remoteId,
        userName: currentUser.username,
        userUPN: currentUser.upn,
        approverName: approver ? approver.username : undefined,
        approverUPN: approver ? approver.upn : undefined,
        approvalLevel: approvalLevel ?? 0,
        rejectReason: reason,
      }),
    )

  voidFormData = async (
    { remoteId }: { remoteId: string },
    reason: string,
    currentUser: { username: string; upn: string },
    approver?: { username: string; upn: string },
    approvalLevel?: number,
  ) =>
    this.generalApi<Types.PostVoidFormData>(
      this.apisauce.post('/Workflow/Workflow/VoidForm', {
        formDataObjectID: remoteId,
        userName: currentUser.username,
        userUPN: currentUser.upn,
        approverName: approver ? approver.username : undefined,
        approverUPN: approver ? approver.upn : undefined,
        approvalLevel: approvalLevel ?? 0,
        voidReason: reason,
      }),
    )

  hasUploadedForm = async (remoteId: string) =>
    this.generalApi<Types.GetHasUploadedForm>(
      this.apisauce.get('/Workflow/Workflow/HadUploadForm', {
        formDataObjectID: remoteId,
      }),
    )

  changeApprover = async (
    { remoteId }: { remoteId: string },
    currentUser: { username: string; upn: string },
    reviewer: { username: string; upn: string },
    target: 'approver' | 'endorser',
  ) =>
    this.generalApi<Types.PostChangeApproverData>(
      this.apisauce.post('/Workflow/Workflow/ChangeReviewer', {
        formDataObjectID: remoteId,
        userName: currentUser.username,
        userUPN: currentUser.upn,
        reviewerName: reviewer ? reviewer.username : undefined,
        reviewerUPN: reviewer ? reviewer.upn : undefined,
        changeType: target === 'endorser' ? 2 : 1,
      }),
    )

  getUser = async () =>
    this.generalApi<Types.GetUser>(
      this.apisauce.get('/Common/Account/GetCurrentUser'),
    )

  getIsNeedHKID= async () =>
  this.generalApi<Types.GetIsNeedHKID>(
    this.apisauce.get('/Common/Account/GetIsNeedHKID'),
  )
  
  // AAD获取用户信息
  getAuthTokenForAAD = async (accessTokenStr: string) =>
    this.generalApi<Types.GetAuthToken>(
      this.apisauce.post(
        '/Common/Account/GetAADAuthToken',
        {
          accessToken: accessTokenStr,
        },
        // {
        //   headers: {
        //     Authorization: `${tokenType} ${accessToken}`,
        //   },
        // },
      ),
    )

  getAuthToken = async ({
    accessToken,
    tokenType,
  }: {
    accessToken: string
    tokenType: string
  }) =>
    this.generalApi<Types.GetAuthToken>(
      this.apisauce.post('/Common/Account/GetAuthToken', null, {
        headers: {
          Authorization: `${tokenType} ${accessToken}`,
        },
      }),
    )

  getAuthTokenForWeb = async ({
    accessToken,
    tokenType,
  }: {
    accessToken: string
    tokenType: string
  }) =>
    this.generalApi<Types.GetAuthToken>(
      this.apisauce.post('/Common/Account/GetAuthTokenForWebApp', null, {
        headers: {
          Authorization: `${tokenType} ${accessToken}`,
        },
      }),
    )

  patchUserInfo = async ({ draftFormData }: Types.UserPatchArgument) =>
    this.generalApi<Types.PatchUserInfo>(
      this.apisauce.patch('/Common/Account/UpdateUser', {
        draftFormData,
      }),
    )

  CompareQualification = async (HKID: string) =>
    this.generalApi<Types.CompareQualification>(
      this.apisauce.post('/Common/Account/CompareQualification', { HKID }),
    )

  getMasterRecord = async (dataType: string) =>
    this.generalApi<Types.GetMasterRecord>(
      this.apisauce.get('/Common/Attunity/GetDataSource', { dataType }),
    )

  withdrawFormData = async (
    { remoteId, remoteHistoryId },
    currentUser: { upn: string },
  ) =>
    this.generalApi<Types.PostWithdrawal>(
      this.apisauce.post('/Form/FormData/WithdrawFormData', {
        historyObjectID: remoteHistoryId,
        formDataObjectID: remoteId,
        userUPN: currentUser.upn,
      }),
    )

  getWorkOrderStatus = async ({ number }: { number: string }) =>
    this.generalApi<Types.GetWorkOrderStatus>(
      this.apisauce.get('/Form/Review/GetFormGroupDataStatus', {
        workOrder: number,
      }),
    )

  submitFitment = async (payload: Types.FitmentSubmitRequestPayload) =>
    this.generalApi<Types.SubmitFitment>(
      this.apisauce.post('/Form/FormData/SubmitFitDefitmentFormData', payload),
    )

  submitAssemblyFitment = async (
    payload: Types.AssemblyFitmentSubmitRequestPayload,
  ) =>
    this.generalApi<Types.SubmitAssemblyFitment>(
      this.apisauce.post(
        '/Form/FormData/SubmitAssemblyFitDefitmentFormData',
        payload,
      ),
    )

  getFitmentList = async (
    workOrder: string,
    fitOrDefitEquipmentNo?: string,
    parentOrEquipmentNo?: string,
    position?: string,
    defitmentBy?: string,
    pageIndex?: number,
    pageSize?: number,
    sortExpression?: string,
  ) =>
    this.generalApi<Types.GetFitmentList>(
      this.apisauce.post('/Form/FormData/GetFitDefitmentFormDatas', {
        workOrder,
        fitOrDefitEquipmentNo,
        parentOrEquipmentNo,
        position,
        defitmentBy,
        pageSize,
        pageIndex,
        sortExpression,
      }),
    )

  searchEquipmentDetail = async (equipmentNumber: string) =>
    this.generalApi<Types.SearchEquipmentDetail>(
      this.apisauce.get('/Common/Attunity/GetEquipmentDetail', {
        equipmentCode: equipmentNumber,
      }),
    )

  searchEquipmentWorkOrders = async (
    equipmentNumber: string,
    searchCriteria: {
      status: AttunityWorkOrderStatus
      planStartDate1?: string
      planStartDate2?: string
      planCompleteDate1?: string
      planCompleteDate2?: string
      actualStartDate1?: string
      actualStartDate2?: string
      actualCompleteDate1?: string
      actualCompleteDate2?: string
    },
    pageSize: number,
    pageIndex: number,
  ) =>
    this.generalApi<Types.SearchEquipmentWorkOrders>(
      this.apisauce.get('/Common/Attunity/GetEquipmentWorkOrder', {
        equipmentCode: equipmentNumber,
        ...searchCriteria,
        pageSize,
        pageIndex,
      }),
    )

  searchEquipmentFitments = async (
    equipmentNumber: string,
    pageSize: number,
    pageIndex: number,
  ) =>
    this.generalApi<Types.SearchEquipmentWorkOrders>(
      this.apisauce.get('/Common/Attunity/GetEquipmentFitDefitHistory', {
        equipmentCode: equipmentNumber,
        pageSize,
        pageIndex,
      }),
    )

  getEquipmentStructure = async (equipmentNumber: string) =>
    this.generalApi<Types.GetEquipmentStructure>(
      this.apisauce.get('/Common/Attunity/GetSingleEquipmentStruce', {
        equipmentCode: equipmentNumber,
      }),
    )

  getEquipmentChilds = async (equipmentNumber: string) =>
    this.generalApi<Types.GetEquipmentChilds>(
      this.apisauce.get('/Common/Attunity/GetChildEquipmentStruce', {
        equipmentCode: equipmentNumber,
      }),
    )

  searchWorkOrderDetail = async (workOrderNumber: string) =>
    this.generalApi<Types.SearchWorkOrderDetail>(
      this.apisauce.get('/Common/Attunity/GetWorkOrderDetail', {
        workOrder: workOrderNumber,
      }),
    )

  getInventoryStock = async (stockCode: string) =>
    this.generalApi<Types.GetInventoryStock>(
      this.apisauce.get('/Common/Attunity/SearchInvertory', {
        stockCode,
      }),
    )

  validateFitmentStructure = async ({
    workOrderNumber,
    equipmentNumber,
    defitEquipmentNumber,
    fitEquipmentNumber,
  }: {
    workOrderNumber: string
    equipmentNumber?: string
    defitEquipmentNumber?: string
    fitEquipmentNumber?: string
  }) =>
    this.generalApi<Types.ValidateDefitEquipment>(
      this.apisauce.get('/Common/Attunity/ValidEquipmentStruce', {
        workOrder: workOrderNumber,
        equipmentNo: equipmentNumber,
        defitEquipmentCode: defitEquipmentNumber,
        fitEquipmentCode: fitEquipmentNumber,
      }),
    )

  getWorkGroups = async () =>
    this.generalApi<Types.GetWorkGroups>(
      this.apisauce.get('/Common/Account/GetWorkGroups'),
    )

  getCombinedWorkGroups = async () =>
    this.generalApi<Types.GetCombinedWorkGroups>(
      this.apisauce.get('/Common/Account/GetCominbeWorkGroups'),
    )

  getMasterRecordsForCache = async (config?: AxiosRequestConfig) =>
    this.generalApi<Types.GetMasterRecordForCache>(
      this.apisauce.get('/Common/Attunity/GetOfflineDataSource', {}, config),
    )

  getIMTEsForCache = async (config?: AxiosRequestConfig) =>
    this.generalApi<Types.GetIMTEsForCache>(
      this.apisauce.get('/Common/Attunity/GetOfflineIMTEs', {}, config),
    )

  getEquipmentsForCache = async (config?: AxiosRequestConfig) =>
    this.generalApi<Types.GetEquipmentsForCache>(
      this.apisauce.get('/Common/Attunity/GetOfflineEquipmentInfo', {}, config),
    )

  getStandardJobsForCache = async (config?: AxiosRequestConfig) =>
    this.generalApi<Types.GetStandardJobsForCache>(
      this.apisauce.get(
        '/Common/Attunity/GetOfflineStandardJobAndFormList',
        {},
        config,
      ),
    )

  getFormDataRemoteId = async (opts: {
    standardJobCode: string
    workOrderNumber: string
    workGroupId: string | undefined
    equipmentNumber: string
    formId: string
    workNature?: string
    depotId?: string
    startDate: Date | undefined
    completeDate: Date | undefined
    estimatedDuration: number | undefined
    description: string | undefined
    createDate: Date | undefined
    parentWorkOrderNumber: string | undefined
    relation: 'PARENT' | 'CHILD' | undefined
    trainTypeId: string | undefined
  }) =>
    this.generalApi<Types.GetFormDataRemoteId>(
      this.apisauce.get('/Form/FormData/GetOfflineFormDataObjectID', {
        standardJobCode: opts.standardJobCode,
        workOrder: opts.workOrderNumber,
        workGroupID: opts.workGroupId,
        equipmentNo: opts.equipmentNumber,
        formObjectID: opts.formId,
        workNature: opts.workNature ?? '',
        depotID: opts.depotId ?? '',
        planStartDate:
          opts.startDate == null
            ? undefined
            : moment(opts.startDate).format(
                moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
              ),
        planCompleteDate:
          opts.completeDate == null
            ? undefined
            : moment(opts.completeDate).format(
                moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
              ),
        estimateJobDuration: opts.estimatedDuration,
        workOrderDescription: opts.description,
        workOrderCreateDate:
          opts.createDate == null
            ? undefined
            : moment(opts.createDate).format(
                moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
              ),
        parentWorkOrder: opts.parentWorkOrderNumber,
        relationshipType: opts.relation,
        trainTypeId: opts.trainTypeId,
      }),
    )

  getFormDataReportList = async (formDataObjectID: string) =>
    this.generalApi<Types.GetFormDataReportList>(
      this.apisauce.get(
        '/Form/FormData/GetFormDataReportListByFormDataObjectID',
        {
          formDataObjectID,
        },
      ),
    )

  fetchFormDataReport = (params: {
    formDataObjectID: string
    reportType: string
    fileName: string
  }) => {
    const url = encodeURI(
      `${this.config.url}Form/FormData/FormApprovePrintForMobile?ObjectID=${params.formDataObjectID}&reportType=${params.reportType}&fileName=${params.fileName}`,
    )
    if (this.apiBase.blobHandler == null) throw new Error('unsupported')
    return this.apiBase.blobHandler.get(url, this.apiBase.httpHeader)
  }

  getOutstandingFitmentCount = async (workOrder: string) =>
    this.generalApi<Types.GetOutstandingFitmentCount>(
      this.apisauce.get('/Form/FormData/GetOutStandingFitmentCount', {
        workOrder,
      }),
    )

  getCarNumbersForEquipment = async (equipmentNo: string) =>
    this.generalApi<Types.GetCarNumbersForEquipment>(
      this.apisauce.get('/Form/FormData/GetCurrentCarNoSelectList', {
        equipmentNo,
      }),
    )

  getEquipmentType = async (equipmentNo: string) =>
    this.generalApi<Types.GetEquipmentType>(
      this.apisauce.get('/Form/FormData/GetFitDefitmentCategory', {
        equipOrComponentNo: equipmentNo,
      }),
    )

  searchEquipmentAssemblyDetail = async (serialNo: string) =>
    this.generalApi<Types.SearchEquipmentAssemblyDetail>(
      this.apisauce.get('/Form/FormData/GetAssemblyItemDetail', {
        serialNo,
      }),
    )

  getEquipmentAssemblyChilds = async (componentNo: string) =>
    this.generalApi<Types.GetEquipmentAssemblyChilds>(
      this.apisauce.get('/Form/FormData/GetChildAssemblyItems', {
        componentNo,
      }),
    )

  searchAssemblyEquipmentFitments = async (equipmentNumber: string) =>
    this.generalApi<Types.GetFitmentList>(
      this.apisauce.get(
        '/Form/FormData/GetFitDefitmentFormDatasByEquipmentNo',
        {
          componentNoOrAssemblySn: equipmentNumber,
        },
      ),
    )

  getIsWorkOrderFormGroupCreated = async (workOrder: {
    number: string
    depot?: { id: string }
    standardJob: {
      code: string
    }
    equipment: {
      number: string
    }
    nature: { name: string }
    workGroupId: string | undefined
  }) =>
    this.generalApi<Types.GetWorkOrderFormGroupCreated>(
      this.apisauce.get('/Form/FormData/IsFormGroupDataExist', {
        standardJobCode: workOrder.standardJob.code,
        workOrder: workOrder.number,
        equipmentNo: workOrder.equipment.number,
        depotID: workOrder.depot?.id,
        workNature: workOrder.nature.name,
        workGroupID: workOrder.workGroupId,
      }),
    )

  getFormsInWorkOrderByIMTE = async (
    formGroupDataObjectID: string,
    imte: string,
  ) =>
    this.generalApi<Types.GetFormsInWorkOrderByIMTE>(
      this.apisauce.get('/Form/Review/GetFormDataObjectIDsAccordingIMTE', {
        formGroupDataObjectID,
        imte,
      }),
    )

  getStandardJobByWorkOrder = async (
    workOrder: string,
  ): Promise<GeneralApiResponse<Types.AttunityStandardJobPayload[]>> =>
    this.generalApi<Types.AttunityStandardJobPayload[]>(
      this.apisauce.get('/Common/Attunity/GetStandardJobByWorkOrder', {
        workOrder,
      }),
    )

  getStandardJobByWorkGroups = async (
    workGroupIds: string[],
  ): Promise<GeneralApiResponse<Types.AttunityStandardJobPayload[]>> =>
    this.generalApi<Types.AttunityStandardJobPayload[]>(
      this.apisauce.get('/Common/Attunity/GetStandardJobByWorkGroupIDs', {
        workGroupIds,
      }),
    )

  getFormDataTag = async () =>
    this.generalApi<Types.GetFormList>(
      this.apisauce.get('/Common/Attunity/GetDataTag', {
        Category: 'Form Data',
      }),
    )

  getFormVersionListByFormId = async (
    formObjectId: string,
  ): Promise<GeneralApiResponse<Types.GetFormVersionListByFormId[]>> =>
    this.generalApi<Types.GetFormVersionListByFormId[]>(
      this.apisauce.get('/Form/Form/GetFormVersion', {
        formObjectId,
      }),
    )
}
