import { observer } from 'mobx-react-lite'
import { useState, useCallback } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import styled from '@emotion/styled'
import { routeItems } from '../route-items'
import { AppBar, SideBar } from '../components'
import { BasePage } from '../pages'
import { useView, ScreenSize, useWindowHeight } from '../utils'

const Root = styled.div<{ $view: ScreenSize; $innerHeight: number }>`
  background: ${({ theme }) => theme.palettes.general.white};
  height: ${({ $view, $innerHeight }) =>
    $view === ScreenSize.desktop ? '100vh' : `${$innerHeight}px`};
`

export const ContentRouter = observer(() => {
  const [open, setOpen] = useState(false)
  const view = useView()

  const toggleSidebar = useCallback(() => setOpen(!open), [open])
  const windowInnerHeight = useWindowHeight()

  return (
    <Root $view={view} $innerHeight={windowInnerHeight}>
      <Router>
        <AppBar handleMenuOnClick={toggleSidebar} />
        <SideBar open={open} toggleSidebar={toggleSidebar} />
        <BasePage>
          <Switch>
            {routeItems.map((it) => (
              <Route key={it.key} path={it.path} component={it.component} />
            ))}
            <Redirect to="/user-information-page" />
          </Switch>
        </BasePage>
      </Router>
    </Root>
  )
})
