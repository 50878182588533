import { RuleModes } from '@mtr-SDO/datamodels'
import { withRootStore } from '@mtr-SDO/models-core'
import { delay } from '@mtr-SDO/utils'
import _ from 'lodash'
import { flow, Instance, SnapshotIn, types } from 'mobx-state-tree'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { withEnvironment } from '../../../../core'
import { NetworkFileModel } from '../../network-file.model'
import { Form } from '../base/form.model'
import { formItemFilterDefaultOptions, isAttachmentCachable } from '../helpers'
import { FormDefinitionAttachmentFileModel } from './form-definition-attachment-file.model'
import { FormDefinitionAttachmentModel } from './form-definition-attachment.model'
import {
  FormDefinitionItemGroup,
  FormDefinitionItemGroupModel,
} from './form-definition-item-group.model'
import { FormDefinitionItem } from './form-definition-item.model'
import { parsePMFormXml } from './parse'
import { constructParametersRelationMap } from './validate-chain-map'

const FORM_DEFINITION_COLLAPSE_DELAY = 10000
export enum FormDefinitionExpandStatus {
  notExpanded = 'notExpanded',
  expanded = 'expanded',
  expanding = 'expanding',
}

export const FormDefinitionModel = types
  .model({
    id: types.optional(types.identifier, uuid),

    orderBookID: types.maybe(types.string),
    bookItemID: types.maybe(types.string),
    /** form remote id */
    formId: types.string,
    version: types.number,

    issueDate: types.maybe(types.Date),
    publishDate: types.maybe(types.Date),
    issueNumber: types.number,
    revisionNumber: types.number,

    checkType: types.maybe(types.string),
    formRefNumber: types.maybe(types.string),

    isUnique: types.maybe(types.boolean),
    allowMultiple: types.maybe(types.boolean),

    /** Raw qualification string from API payload */
    qualifications: types.maybe(types.string),

    /** Indicate it is a preview definition or not */
    isPreview: types.optional(types.boolean, false),

    xmlFilename: types.maybe(types.string),
    xml: types.maybe(types.string),

    // --- parsed results
    referenceNumber: types.maybe(types.string),

    parserVersion: types.maybe(types.number),
    groups: types.array(FormDefinitionItemGroupModel),

    pdfUrl: types.maybe(types.string),
    okMallUrl: types.maybe(types.string),

    displayOptions: types.optional(
      types.model({
        gridAvailable: types.optional(types.boolean, false),
        gridLength: types.maybe(types.number),
        gridDirection: types.maybe(
          types.union(types.literal('horizontal'), types.literal('vertical')),
        ),
        batchFillAvailable: types.optional(types.boolean, true),
      }),
      {},
    ),

    attachments: types.array(FormDefinitionAttachmentModel),
    attachmentFiles: types.array(FormDefinitionAttachmentFileModel),
    isSpecialForm: types.boolean
  })
  .volatile(() => ({
    expandUsageCount: 0,
    expandStatus: FormDefinitionExpandStatus.notExpanded,
    validateRelationMap: undefined as { [key: string]: string[] } | undefined,
    autofillRelationMap: undefined as { [key: string]: string[] } | undefined,
  }))
  .extend(withRootStore)
  .extend(withEnvironment)
  .views((self) => {
    const views = {
      get form(): Form | undefined {
        const ret = (self.rootStore.formStore.forms as Form[]).find(
          (it) => self.formId === it.remoteId,
        )
        return ret
      },
      get displayGroups(): FormDefinitionItemGroup[] {
        if (self.expandStatus !== FormDefinitionExpandStatus.expanded) {
          throw new Error('not expanded')
        }
        return self.groups
          .filter((g) => g.isShown !== false)
          .slice()
          .sort((a, b) => a.displayOrder - b.displayOrder)
      },
      get uploadGroups(): FormDefinitionItemGroup[] {
        if (self.expandStatus !== FormDefinitionExpandStatus.expanded) {
          throw new Error('not expanded')
        }
        return self.groups
          .filter((g) => g.needUpload === true)
          .slice()
          .sort((a, b) => a.displayOrder - b.displayOrder)
      },
      /**
       * @deprecated
       * Return all items under the current form
       * Use with cautious: filter the return value of this getter by work order form lost parent-child relationship in filtering
       */
      get itemsRecursively(): FormDefinitionItem[] {
        return views.displayGroups.reduce(
          (acc, group) => [...acc, ...group.itemsRecursively],
          [] as FormDefinitionItem[],
        )
      },

      itemsRecursivelyForWorkOrderForm(
        workOrderForm?: any,
        opts = formItemFilterDefaultOptions,
      ) {
        return views.displayGroups.reduce(
          (acc, group) => [
            ...acc,
            ...group.itemsRecursivelyForWorkOrder(workOrderForm, opts),
          ],
          [] as FormDefinitionItem[],
        )
      },
      itemsRecursivelyNeedUpload(
        workOrderForm?: any,
        opts = formItemFilterDefaultOptions,
      ) {
        return views.uploadGroups.reduce(
          (acc, group) => [
            ...acc,
            ...group.itemsRecursivelyForWorkOrder(workOrderForm, opts),
          ],
          [] as FormDefinitionItem[],
        )
      },
      get cachePayload() {
        if (views.form == null) return undefined
        return {
          formNumber: views.form.number,
          formRemoteId: views.form.remoteId,
          version: self.version,

          issueDate:
            self.issueDate == null ? undefined : moment(self.issueDate).unix(),

          publishDate:
            self.publishDate == null
              ? undefined
              : moment(self.publishDate).unix(),

          issueNumber: self.issueNumber,
          revisionNumber: self.revisionNumber,
          checkType: self.checkType,
          formRefNumber: self.formRefNumber,
          isUnique: self.isUnique,
          allowMultiple: self.allowMultiple,
          qualifications: self.qualifications,

          xmlFilename: self.xmlFilename,
          xml: self.xml,
        }
      },

      findItemWithKey(key: string) {
        return views.itemsRecursively.find((item) => key === item.inputId)
      },
      itemCountForWorkOrderForm(
        workOrderForm?: any,
        opts = formItemFilterDefaultOptions,
      ) {
        return self.groups.reduce(
          (acc, group) =>
            acc + group.itemCountForWorkOrderForm(workOrderForm, opts),
          0,
        )
      },
      groupsForWorkOrderForm(workOrderForm?: any) {
        return views.displayGroups.filter(
          (group) =>
            group.itemCountForWorkOrderForm(workOrderForm, {
              fillableOnly: false,
              level: 0,
            }) > 0,
        )
      },
      get isEmpty() {
        return self.groups.length === 0 && !self.xml
      },
      get qualificationCodes(): string[] | undefined {
        if ((self.qualifications ?? '').length === 0) return undefined
        return self.qualifications?.split(',').map((it) => it.trim())
      },
      get isQualified(): boolean {
        const { qualifications } = self.rootStore.userProfileStore
        if (views.qualificationCodes == null) return true
        return views.qualificationCodes.reduce(
          (acc, cqa) => acc || qualifications.includes(cqa),
          false,
        )
      },
    }
    return views
  })
  .actions((self) => ({
    setUnique(unique: boolean) {
      self.isUnique = unique
    },
    setAllowMultiple(allowMultiple: boolean) {
      self.allowMultiple = allowMultiple
    },
    apply(snapshot: {}) {
      _.keys(_.omit(snapshot, 'id', 'groups')).forEach((key) => {
        if (snapshot[key] == null) return
        self[key] = snapshot[key]
      })
    },
    parseXml: flow(function* parseXml(inXml?: string) {
      try {
        const xml = inXml ?? self.xml
        if (xml == null) throw new Error('empty-xml')

        const ret = yield parsePMFormXml(xml, self.environment.xml2Js)
        const target = _.pick(
          ret,
          Object.keys(self).filter((key) => {
            if (['groups', 'attachmentFiles', 'attachments'].includes(key)) {
              return true
            }
            if (key === 'displayOptions') return true // omit original (default) display option
            return self[key] == null || self[key].length === 0
          }),
        )

        _.assign(self, target) // we overwrite parsed info by self
      } catch (err) {
        try {
          self.groups.replace([])
        } catch {}
        throw err
      }
    }),
    saveXmlToStorage: flow(function* saveXml(): Generator<any, void, any> {
      if (!self.environment.storageHandler?.text.supportLargeFile)
        throw new Error('unsupported')

      if (self.xmlFilename != null) return
      if (self.xml == null) throw new Error('xml-unavailable')

      self.xmlFilename = `forms/${uuid()}`
      yield self.environment.storageHandler.text.save(
        self.xmlFilename,
        self.xml,
      )
      self.xml = undefined
    }),
  }))
  .actions((self) => ({
    expandXml: flow(function* expandXml(
      reExpand?: boolean,
      previewForceUpdateDefinition?: boolean,
    ): Generator<any, void, any> {
      if (reExpand) {
        // only reexpand when it was expanded
        if (self.expandStatus !== FormDefinitionExpandStatus.expanded) return
      } else {
        // not reexpand, increase counter
        self.expandUsageCount += 1

        while (self.expandStatus === FormDefinitionExpandStatus.expanding) {
          yield delay(300)
        }

        if (self.expandStatus !== FormDefinitionExpandStatus.notExpanded) {
          return
        }
      }

      self.expandStatus = FormDefinitionExpandStatus.expanding
      const loadXmlFromStorage =
        self.environment.storageHandler?.text.supportLargeFile ?? false

      let { xml } = self
      if (xml == null && loadXmlFromStorage) {
        if (self.xmlFilename == null) throw new Error('xmlfile-notavailable')
        xml = yield self.environment.storageHandler?.text.read(self.xmlFilename)
      }

      try {
        if (
          (!self.groups.length && xml != null) ||
          previewForceUpdateDefinition
        )
          yield self.parseXml(xml)
      } catch (error) {
        self.expandStatus = FormDefinitionExpandStatus.notExpanded
        throw error
      }

      if (loadXmlFromStorage) {
        try {
          if (self.xmlFilename == null) yield self.saveXmlToStorage()
        } catch (error) {
          self.environment.console.warn('Failed in storing XML to storage')
          self.environment.console.reportError(error)
        }
      }

      self.expandStatus = FormDefinitionExpandStatus.expanded
    }),
    collapseXml: flow(function* collapseXml(
      delayTime: number = FORM_DEFINITION_COLLAPSE_DELAY,
    ) {
      yield delay(delayTime)
      // self.expandUsageCount -= 1
      // if (
      //   self.expandUsageCount > 0 ||
      //   self.expandStatus !== FormDefinitionExpandStatus.expanded
      // ) {
      //   return
      // }

      // self.groups.replace([])
      // self.referenceNumber = undefined
      // self.parserVersion = undefined
      // self.pdfUrl = undefined
      // self.okMallUrl = undefined
      // // self.displayOptions = undefined

      // self.expandStatus = FormDefinitionExpandStatus.notExpanded
    }),
    async afterAttach() {
      if (self.xml != null) {
        try {
          await self.saveXmlToStorage()
        } catch (error) {
          self.environment.console.reportError(error)
        }
      }
    },
  }))
  /* Properties and actions for download */
  .props({
    networkFile: types.optional(NetworkFileModel, {}),
  })
  .views((self) => ({
    get localFilePath() {
      return self.networkFile.decryptedPath
    },
  }))
  .actions((self) => {
    const actions = {
      bindNetworkFile() {
        if (!self.networkFile) self.networkFile = NetworkFileModel.create({})
        if (self.pdfUrl) self.networkFile.url = self.pdfUrl
      },
      cacheAttachment: flow(function* download() {
        if (self.pdfUrl == null) return
        if (!isAttachmentCachable(self.pdfUrl)) throw Error('not-cachable')
        if (self.localFilePath != null) return

        actions.bindNetworkFile()
        yield self.networkFile.download()
      }),
      decryptAttachment: flow(function* decrypt() {
        yield self.networkFile.decrypt()
      }),
      getLocalPath: flow(function* generateLocalFile(): Generator<
        any,
        string,
        any
      > {
        actions.bindNetworkFile()
        yield actions.decryptAttachment()
        return self.networkFile.decryptedPath as string
      }),

      cacheAllAttachments: flow(function* cacheAllAttachments(): Generator<
        any,
        void,
        any
      > {
        const attachments = self.attachmentFiles
          .map((it) => it.cache())
          // .reduce((acc, item) => acc.concat(item.map(it => it.cache())), [
          //   actions.cacheAttachment(),
          // ])
          .map((promise) =>
            promise.catch((error) => {
              console.error(error)
            }),
          )
        yield Promise.all(attachments)
      }),
      constructFieldRelationMapping: flow(
        function* constructFieldRelationMapping(): Generator<any, void, any> {
          if ((self.validateRelationMap?.length ?? 0) === 0) {
            const chainValidateRelationMapping =
              yield constructParametersRelationMap(
                self as FormDefinition,
                RuleModes.validationRule,
              )
            self.validateRelationMap = chainValidateRelationMapping
          }

          if ((self.autofillRelationMap?.length ?? 0) === 0) {
            const chainAutofillRelationMapping =
              yield constructParametersRelationMap(
                self as FormDefinition,
                RuleModes.autofillRule,
              )
            self.autofillRelationMap = chainAutofillRelationMapping
          }
        },
      ),
    }
    return actions
  })
  .postProcessSnapshot((snapshot) => _.omit(snapshot, ['groups']))

export type FormDefinition = Instance<typeof FormDefinitionModel>

export function parseFormDefinitionCachePayload(
  cache: NonNullable<FormDefinition['cachePayload']>,
): Omit<SnapshotIn<FormDefinition>, 'formId'> {
  return {
    ...cache,
    issueDate:
      cache.issueDate != null
        ? moment.unix(cache.issueDate).toDate()
        : undefined,
    publishDate:
      cache.publishDate != null
        ? moment.unix(cache.publishDate).toDate()
        : undefined,
  }
}
