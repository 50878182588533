import { RelatedWICNDataPayload } from '@mtr-SDO/apis'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import {
  stringToDate,
  stringToNumber,
  stringToString,
} from '../common-function'
import {
  getWICNSnapShotStatus,
  WICNStatusEnum,
} from '../wi-change-notice/wicn-status'

export const WIReviewRelatedWICNModel = types.model({
  wiReviewId: types.maybe(types.string),
  wicnId: types.maybe(types.string),

  wiNo: types.maybe(types.string),
  wicnNo: types.maybe(types.string),
  linkageBy: types.maybe(types.string),
  linkageDate: types.maybe(types.Date),
  status: WICNStatusEnum,
  createdDate: types.maybe(types.Date),
})

export type WIReviewRelatedWICN = Instance<typeof WIReviewRelatedWICNModel>

function trimWINumber(wiNo: string | undefined): string {
  if (wiNo == null) {
    return ''
  }
  if ((wiNo ?? '').split(',').length > 2) {
    return (wiNo ?? '').split(',').slice(0, 2).join(', ').concat('...')
  }

  return (wiNo ?? '').split(',').slice(0, 2).join(', ')
}

export function mapPayloadToRelatedWICN(
  payload: RelatedWICNDataPayload[] | undefined,
): SnapshotIn<WIReviewRelatedWICN[] | undefined> {
  if (payload == null) return undefined
  return payload.map((it) => ({
    wiReviewId: it.wicpObjectid,
    wicnId: it.wicnObjectid,

    wiNo: trimWINumber(stringToString(it.wiNo)),
    wicnNo: stringToString(it.wicnNumber),
    linkageBy: stringToString(it.linkBy),
    linkageDate: stringToDate(it.linkDate),
    status: getWICNSnapShotStatus(stringToNumber(it.wicnCurrentStatus)),
    createdDate: stringToDate(it.wicnCreateDate),
  }))
}
