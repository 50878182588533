import { Instance, types } from 'mobx-state-tree'

export const AuthConfigModel = types.model({
  clientId: types.string,
  tenantId: types.string,
  timeout: types.optional(types.number, 10000),
}).
views(self=>({
  get host(){
    return `login.microsoftonline.com/${self.tenantId}`
  }
}))

export type AuthConfig = Instance<typeof AuthConfigModel>
