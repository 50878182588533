import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { Spinner } from '../../components'

const LoadingRoot = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.primaryDark};
`

export const LoadingPage = observer(() => (
  <LoadingRoot>
    <Spinner color="secondary" size={96} />
  </LoadingRoot>
))
