import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { useStores } from '../models'
import {
  HealthCheckPage,
  // LandingPage,
  AuthorizePage,
  LoadingPage,
  SignInPage,
  RegisterPage,
} from '../pages'
import { ContentRouter } from './content-router'
import { storage } from '../local-storage'

export const RootRouter = observer(() => {
  const { userProfileStore, uiStore } = useStores()

  const { data, isFetching, isError, error } = useQuery(
    ['fetch-user-profile'],
    userProfileStore.fetchUserProfile,
    {
      enabled: !!userProfileStore.sessionInfo?.accessToken,
      refetchOnWindowFocus: false,
    },
  )

  const { isLoading: isLoadingUserInfo } = useQuery(
    ['apply-user-info'],
    () => {
      if (data != null) {
        userProfileStore.applyUserInfo(data)
        uiStore.setLoggedIn(true)
      }
    },
    {
      enabled:
        userProfileStore.sessionInfo?.accessToken != null && data != null,
    },
  )

  useEffect(() => {
    if (isError) {
      console.error(error)
      storage.clearAccessToken()
    }
  }, [isError, error])

  return (
    <Router>
      <Switch>
        <Route path="/health-check">
          <HealthCheckPage />
        </Route>
        {/* <ContentRouter /> */}
        {isFetching || isLoadingUserInfo ? (
          <LoadingPage />
        ) : uiStore.isLoggedIn ? (
          <ContentRouter />
        ) : (
          <Switch>
            {/* <Route exact path="/" component={LandingPage} /> */}
            <Route exact path="/" component={SignInPage} />
            <Route path="/register-page" component={RegisterPage} />
            <Route path="/authorize" component={AuthorizePage} />
            <Redirect to="/" />
          </Switch>
        )}
      </Switch>
    </Router>
  )
})
