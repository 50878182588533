import { ActionBy, ADUser } from '@mtr-SDO/apis'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { stringToNumber, stringToString } from '../common-function'

export const UserModel = types.model({
  upn: types.maybe(types.string),
  role: types.maybe(types.number),
  displayName: types.maybe(types.string),
  email: types.maybe(types.string),
  jobTitle: types.maybe(types.string),
  group: types.maybe(types.string),
})

export type User = Instance<typeof UserModel>

export function mapPayloadToUser(
  payload: ActionBy | undefined,
): SnapshotIn<User | undefined> {
  if (payload == null) return undefined
  return {
    upn: stringToString(payload.upn),
    role: stringToNumber(payload.userRole),
    displayName: stringToString(payload.displayName),
    email: stringToString(payload.email),
    jobTitle: stringToString(payload.title),
    group: stringToString(payload.group),
  }
}

export function mapPayloadToUserList(
  payload: ActionBy[] | undefined,
): SnapshotIn<User[] | undefined> {
  if (payload == null) return undefined
  return payload.map((it) => ({
    upn: stringToString(it.upn),
    role: stringToNumber(it.userRole),
    displayName: stringToString(it.displayName),
    email: stringToString(it.email),
    jobTitle: stringToString(it.title),
    group: stringToString(it.group),
  }))
}

export function mapUserToADUser(payload: User | undefined): SnapshotIn<ADUser> {
  if (payload == null)
    return {
      upn: undefined,
      displayName: undefined,
      email: undefined,
      jobTitle: undefined,
    }
  return {
    upn: payload.upn ?? undefined,
    displayName: payload.displayName ?? undefined,
    email: payload.email ?? undefined,
    jobTitle: payload.jobTitle ?? undefined,
  }
}
