import { Instance, types } from 'mobx-state-tree'

export enum ValidationOperator {
  greaterThan = '>',
  greaterThanOrEqualTo = '>=',
  lessThanOrEqualTo = '<=',
  lessThan = '<',
  equalTo = '=',
  notEqualTo = '!=',
  rangeBetween = '[]',
}

const ValidationOperatorEnum = types.enumeration(
  Object.values(ValidationOperator),
)

export const FormDefinitionValidationRuleModel = types
  .model({
    operator: ValidationOperatorEnum,
    attribute: types.maybe(types.string),
    value: types.maybeNull(types.union(types.string, types.number)),
    lowerValue: types.maybeNull(types.string),
    upperValue: types.maybeNull(types.string),
  })
  .preProcessSnapshot((snapshot) => ({
    ...snapshot,
    attribute: snapshot.attribute ?? undefined,
  }))

export type FormDefinitionValidationRule = Instance<
  typeof FormDefinitionValidationRuleModel
>

export const FormDefinitionExpressionParameterModel = types.model({
  name: types.maybe(types.string),
  refInputId: types.maybe(types.string),
})

export const FormDefinitionAutoFillFormulaModel = types.model({
  value: types.maybeNull(types.string),
})

export type FormDefinitionExpressionParameter = Instance<
  typeof FormDefinitionExpressionParameterModel
>
