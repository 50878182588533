import { Instance, types } from 'mobx-state-tree'
import moment from 'moment'

export const FormDataRemarkModel = types.model({
  objectId: types.maybe(types.string),
  content: types.maybe(types.string),
  createby: types.maybe(types.string),
  createddate: types.optional(types.Date, () => moment().toDate()),
  lastmoddate: types.optional(types.Date, () => moment().toDate()),
})

export type FormDataRemark = Instance<typeof FormDataRemarkModel>
