import { Theme } from '@mtr-SDO/theme-core'

export const colors: Theme['colors'] = {
  primary: '#3c8dbc',
  primaryTransparent: '#3c8dbccc',
  primaryLight: '#3c8dbc',
  primaryDark: '#3c8dbc',

  secondary: '#f1f0f5',
  secondaryLight: '#ffffff',
  secondaryDark: '#d8d6d6',


  // primary: '#002251',
  // primaryTransparent: '#002251cc',
  // primaryLight: '#38497e',
  // primaryDark: '#000029',

  // secondary: '#002251',
  // secondaryLight: '#38497e',
  // secondaryDark: '#000029',

  // secondary: '#7b6100',
  // secondaryLight: '#ae8e36',
  // secondaryDark: '#4c3700',

  // tertiary: '#7b2f00',
  // tertiaryLight: '#b05b2c',
  // tertiaryDark: '#4c0000',
}
