export type IconProps = {
  type: 'material-community'
  name: string
}

export const icons: { [key: string]: IconProps } = {
  home: {
    type: 'material-community',
    name: 'home-variant',
  },
  leftArrow: {
    type: 'material-community',
    name: 'chevron-left',
  },
  rightArrow: {
    type: 'material-community',
    name: 'chevron-right',
  },
  upArrow: {
    type: 'material-community',
    name: 'chevron-up',
  },
  downArrow: {
    type: 'material-community',
    name: 'chevron-down',
  },

  login: {
    type: 'material-community',
    name: 'login',
  },

  review: { type: 'material-community', name: 'file-find' },
  change: { type: 'material-community', name: 'bulletin-board' },
  setting: { type: 'material-community', name: 'cog' },
  logout: { type: 'material-community', name: 'logout' },
  search: { type: 'material-community', name: 'magnify' },
  save: { type: 'material-community', name: 'content-save' },

  menu: {
    type: 'material-community',
    name: 'menu',
  },
  user: {
    type: 'material-community',
    name: 'account',
  },
  userGroup: {
    type: 'material-community',
    name: 'account-group',
  },
  notification: {
    type: 'material-community',
    name: 'bell',
  },

  goto: {
    type: 'material-community',
    name: 'arrow-right-bold-circle-outline',
  },

  mail: {
    type: 'material-community',
    name: 'email-outline',
  },

  forward: {
    type: 'material-community',
    name: 'share',
  },

  edit: {
    type: 'material-community',
    name: 'pencil',
  },

  ticker: {
    type: 'material-community',
    name: 'check',
  },

  return: { type: 'material-community', name: 'keyboard-return' },
  consult: { type: 'material-community', name: 'account-edit' },
  cancel: { type: 'material-community', name: 'delete' },
  reRoute: { type: 'material-community', name: 'account-convert' },

  add: { type: 'material-community', name: 'plus' },
  remove: { type: 'material-community', name: 'close' },
  attachment: { type: 'material-community', name: 'paperclip' },
  document: { type: 'material-community', name: 'file-document-outline' },

  warn: { type: 'material-community', name: 'alert' },
  comment: { type: 'material-community', name: 'comment-outline' },

  attachmentEdit: { type: 'material-community', name: 'pencil' },
  delete: { type: 'material-community', name: 'delete' },

  sort: { type: 'material-community', name: 'sort' },
  ascending: { type: 'material-community', name: 'sort-ascending' },
  descending: { type: 'material-community', name: 'sort-descending' },

  history: { type: 'material-community', name: 'history' },

  clock: { type: 'material-community', name: 'clock-time-ten-outline' },

  filter: { type: 'material-community', name: 'filter-variant' },
  more: { type: 'material-community', name: 'dots-horizontal' },
  approved: { type: 'material-community', name: 'comment-check' },
  endorsed: { type: 'material-community', name: 'check-all' },
  rejected: { type: 'material-community', name: 'comment-remove' },
  pending: { type: 'material-community', name: 'account-clock' },
}
