import { Instance, types } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import { withRootStore } from '../../../../core'
import { FormDefinitionAttachmentFileModel } from './form-definition-attachment-file.model'

export const FormDefinitionAttachmentModel = types
  .model({
    id: types.optional(types.identifier, uuid),
    name: types.string,
    displayOrder: types.optional(types.number, 0),
    file: types.reference(FormDefinitionAttachmentFileModel),
  })
  .extend(withRootStore)
  .views((self) => ({
    get sasTokenUrl() {
      return self.file?.sasTokenUrl
    },
    get type() {
      return self.file?.type
    },
    get url() {
      return self.file?.url
    },
    get cacheable() {
      return self.file?.cacheable ?? false
    },
    get openWithBrowser() {
      return self.file?.openWithBrowser ?? true
    },
  }))
  .preProcessSnapshot((snapshot) => ({
    ...snapshot,
    displayOrder: snapshot.displayOrder ?? undefined,
  }))

export type FormDefinitionAttachment = Instance<
  typeof FormDefinitionAttachmentModel
>
