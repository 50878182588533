import { PostCreateUser, PostUpdateUser,UserCreateType, UserUpdateType, UserInfoType, ValidateCodeImgType } from '@mtr-SDO/apis'
import {
  QueryKey,
  useMutation, useQuery,
} from 'react-query'
import {
  UseMutationOptions, UseQueryOptions,
} from 'react-query/types/react/types'
import { useStores } from '../models'

const QUERY_CACHE_KEY = 'user-operation'

export const useCreateUser = (
  opts?: UseMutationOptions<PostCreateUser, Error, UserCreateType>,
) => {
  const { userOperationStore } = useStores()
  return useMutation(
    (userData) => userOperationStore.createUser(userData),
    {
      ...opts,
    },
  )
}

export const useUpdateUser = (
  opts?: UseMutationOptions<PostUpdateUser, Error, UserUpdateType>,
) => {
  const { userOperationStore } = useStores()
  return useMutation(
    (userData) => userOperationStore.updateUser(userData),
    {
      ...opts,
    },
  )
}

export const useFetchUserInfo = (
  opts?: UseQueryOptions<unknown, Error, UserInfoType>,
) => {
  const { userOperationStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY, 'fetch-user-profile'] as QueryKey,
    () => userOperationStore.fetchUserInfo(),
    opts,
  )
}

export const useFetchValidateCodeImg = (
  duration: number = 500,
  opts?: UseQueryOptions<unknown, Error, ValidateCodeImgType>
) => {
  const { userOperationStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY, 'fetch-user-profile'] as QueryKey,
    () => new Promise(resolve => {
      setTimeout(() => {
        resolve(userOperationStore.fetchValidateCodeImg())
      }, duration)
    }),
    opts,
  )
}