import type { DeepNullable, DeepPartial } from '@mtr-SDO/tsconfig-node'
import { CMDetailPayload } from '@mtr-SDO/apis'
import { withRootStore } from '@mtr-SDO/models-core'
import { notEmpty } from '@mtr-SDO/utils'
import _ from 'lodash'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { WorkNature } from '../base-attunity'
import { WorkOrder } from './work-order.model'
import {
  dateToString,
  stringToDate,
  stringToNumber,
  stringToString,
} from '../common-function'

export enum CMDetailRequestStatus {
  pending = 'pending',
  created = 'created',
}

const CMDetailFailureItemModel = types.model('CM-Detail-Failure', {
  // cmDetailFailureItemId: types.identifier,
  remoteId: types.identifier,

  code: types.maybe(types.string),
  action: types.maybe(types.string),
  component: types.maybe(types.string),
  replacedComponent: types.maybe(types.string),
  quantity: types.maybe(types.number),
  cause: types.maybe(types.string),
  description: types.maybe(types.string),
  root: types.maybe(types.boolean),

  isDisabled: types.optional(types.boolean, false),
})

const CMDetailContentModel = types
  .model('CM-Detail-Content', {
    remoteId: types.identifier,

    parentWorkOrderNumber: types.maybe(types.string),
    parentEquipmentNumber: types.maybe(types.string),

    workInstructionItemNumber: types.maybe(types.string),
    equipmentNumber: types.maybe(types.string),

    standardJobCode: types.maybe(types.string),
    workGroup: types.maybe(types.string),
    workGroupCode: types.maybe(types.string),
    workNatureLv2: types.maybe(types.string),
    workOrderDescription: types.maybe(types.string),

    // reporterLanId: types.maybe(types.string),
    reporterUserId: types.maybe(types.number),
    reportDate: types.maybe(types.Date),
    symptomCode: types.maybe(types.string),
    personInChargeUserId: types.maybe(types.number),
    // personInChargeLanId: types.maybe(types.string),

    plannedStartTime: types.maybe(types.Date),
    plannedCompleteTime: types.maybe(types.Date),
    actualStartTime: types.maybe(types.Date),
    startWorkTime: types.maybe(types.Date),
    finishWorkTime: types.maybe(types.Date),
    shutdownTime: types.maybe(types.Date),

    remark: types.maybe(types.string),
    referenceDelayTime: types.maybe(types.number),
    accumulatedDelayTime: types.maybe(types.number),

    failureItems: types.array(CMDetailFailureItemModel),

    createdBy: types.maybe(types.string),
    createdTime: types.maybe(types.Date),
  })
  .views((self) => ({
    get reportDateMoment() {
      if (self.reportDate == null) return undefined
      return moment(self.reportDate)
    },
    get plannedStartTimeMoment() {
      if (self.plannedStartTime == null) return undefined
      return moment(self.plannedStartTime)
    },
    get plannedCompleteTimeMoment() {
      if (self.plannedCompleteTime == null) return undefined
      return moment(self.plannedCompleteTime)
    },
    get actualStartTimeMoment() {
      if (self.actualStartTime == null) return undefined
      return moment(self.actualStartTime)
    },
    get startWorkTimeMoment() {
      if (self.startWorkTime == null) return undefined
      return moment(self.startWorkTime)
    },
    get finishWorkTimeMoment() {
      if (self.finishWorkTime == null) return undefined
      return moment(self.finishWorkTime)
    },
    get shutdownTimeMoment() {
      if (self.shutdownTime == null) return undefined
      return moment(self.shutdownTime)
    },
    get createdTimeMoment() {
      if (self.createdTime == null) return undefined
      return moment(self.createdTime)
    },
  }))

export const CMDetailModel = types
  .model('CM-Detail', {
    cmDetailId: types.optional(types.identifier, uuid),
    remoteId: types.string,
    workOrderNumber: types.maybe(types.string), // created work order

    merged: CMDetailContentModel,
    histories: types.array(CMDetailContentModel),
  })
  .extend(withRootStore)
  .actions((self) => ({
    apply(snapshot: any) {
      _.keys(_.omit(snapshot, ['cmDetailId', 'remoteId'])).forEach((key) => {
        if (snapshot[key] == null) return
        self[key] = snapshot[key]
      })
    },
  }))
  .views((self) => {
    const getWorkOrder = (
      number: string | undefined,
    ): WorkOrder | undefined => {
      if (!number) return undefined
      return self.rootStore.worksStore.findWorkOrder(number) ?? null
    }

    const views = {
      get requestStatus(): CMDetailRequestStatus {
        return self.workOrderNumber == null
          ? CMDetailRequestStatus.pending
          : CMDetailRequestStatus.created
      },
      get parentWorkOrder() {
        return getWorkOrder(self.merged.parentWorkOrderNumber)
      },
      get workOrder() {
        return getWorkOrder(self.workOrderNumber)
      },
      get workNatureLevel2Object(): WorkNature {
        return self.rootStore.masterDataStore.workNatureLv2.get(
          self.merged.workNatureLv2,
        )
      },
      get createdBy(): string | undefined {
        return self.merged.createdBy
      },
      get createdTime(): Date | undefined {
        return self.merged.createdTime
      },
      get createdTimeMoment(): moment.Moment | undefined {
        return views.createdTime != null ? moment(views.createdTime) : undefined
      },
    }
    return views
  })

export type CMDetailFailureItem = Instance<typeof CMDetailFailureItemModel>
export type CMDetailContent = Instance<typeof CMDetailContentModel>
export type CMDetail = Instance<typeof CMDetailModel>

export function mapCMDetailContentToPayload(
  cmDetailContent: DeepNullable<DeepPartial<SnapshotIn<CMDetailContent>>>,
): Omit<DeepNullable<DeepPartial<CMDetailPayload>>, 'action'> {
  return {
    pmWorkOrder: cmDetailContent.parentWorkOrderNumber,
    equipmentNo: cmDetailContent.parentEquipmentNumber,
    wiItems:
      cmDetailContent.workInstructionItemNumber != null
        ? [{ wiItemNo: cmDetailContent.workInstructionItemNumber }]
        : [],
    cmEquipmentNo: cmDetailContent.equipmentNumber,
    standardJobCode: cmDetailContent.standardJobCode,
    workGroup: cmDetailContent.workGroup,
    workNatureLevel1: 'CM',
    workNatureLevel2: cmDetailContent.workNatureLv2,
    workOrderDescription: cmDetailContent.workOrderDescription,
    reporterUserID: cmDetailContent.reporterUserId,
    reportDate: dateToString(cmDetailContent.reportDate),
    symptom: cmDetailContent.symptomCode,
    plannedStartDate: dateToString(cmDetailContent.plannedStartTime),
    plannedCompletionDate: dateToString(cmDetailContent.plannedCompleteTime),
    actualStartDate: dateToString(cmDetailContent.actualStartTime),
    // actualCompleteDate: dateToISOString(cmDetailContent.actual,
    startWorkDate: dateToString(cmDetailContent.startWorkTime),
    finishWorkDate: dateToString(cmDetailContent.finishWorkTime),
    shutDownDate: dateToString(cmDetailContent.shutdownTime),
    remark: cmDetailContent.remark,
    refInitalDelay: cmDetailContent.referenceDelayTime,
    accumulatedDelay: cmDetailContent.accumulatedDelayTime,

    personInChargeUserID: cmDetailContent.personInChargeUserId,

    failureDetails: cmDetailContent.failureItems
      ?.map((item) =>
        item == null
          ? undefined
          : {
              objectID: item.remoteId,
              failureCode: item.code,
              failureComponent: item.component,
              action: item.action,
              replacedComponent: item.replacedComponent,
              quantity: item.quantity,
              failureCause: item.cause,
              root: item.root,
              extendedDescription: item.description,
              isDeleted: item.isDisabled,
            },
      )
      .filter(notEmpty),
  }
}

export function mapPayloadToCMDetailContent(
  payload: CMDetailPayload,
): SnapshotIn<CMDetailContent> {
  return {
    remoteId: payload.objectID,

    parentWorkOrderNumber: stringToString(payload.pmWorkOrder),
    parentEquipmentNumber: stringToString(payload.equipmentNo),
    workInstructionItemNumber: stringToString(payload.wiItems?.[0]?.wiItemNo),
    equipmentNumber: stringToString(payload.cmEquipmentNo),
    standardJobCode: stringToString(payload.standardJobCode),
    workGroup: stringToString(payload.workGroup),
    workGroupCode: stringToString(payload.workGroupCode),
    workNatureLv2: stringToString(payload.workNatureLevel2),
    workOrderDescription: stringToString(payload.workOrderDescription),
    reporterUserId: stringToNumber(payload.reporterUserID),
    reportDate: stringToDate(payload.reportDate),
    symptomCode: stringToString(payload.symptom),
    plannedStartTime: stringToDate(payload.plannedStartDate),
    plannedCompleteTime: stringToDate(payload.plannedCompletionDate),
    actualStartTime: stringToDate(payload.actualStartDate),
    startWorkTime: stringToDate(payload.startWorkDate),
    finishWorkTime: stringToDate(payload.finishWorkDate),
    shutdownTime: stringToDate(payload.shutDownDate),
    remark: stringToString(payload.remark),
    referenceDelayTime: stringToNumber(payload.refInitalDelay),
    accumulatedDelayTime: stringToNumber(payload.accumulatedDelay),

    personInChargeUserId: stringToNumber(payload.personInChargeUserID),

    failureItems: payload.failureDetails.map((detailPayload) => ({
      remoteId: detailPayload.objectID,
      code: stringToString(detailPayload.failureCode),
      action: stringToString(detailPayload.action),
      component: stringToString(detailPayload.failureComponent),
      replacedComponent: stringToString(detailPayload.replacedComponent),
      quantity: stringToNumber(detailPayload.quantity),
      cause: stringToString(detailPayload.failureCause),
      description: stringToString(detailPayload.extendedDescription),
      root: detailPayload.root ?? undefined,
      isDisabled: detailPayload.isDeleted,
    })),

    createdBy: stringToString(payload.createDisplayName),
    createdTime: stringToDate(payload.createDate),
  }
}
