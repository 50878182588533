import { withRootStore } from '@mtr-SDO/models-core'
import { notEmpty } from '@mtr-SDO/utils'
import { getParent, IAnyModelType, Instance, types } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import { MasterDataRecord } from '../../base-attunity'
import { MockWorkOrderForm as WorkOrderForm } from '../../work-orders/mock-types'
import {
  formatItemNumber,
  FormDataItemValue,
  FormDefinitionBaseItem,
  FormDefinitionFieldType,
  formItemFilterDefaultOptions,
  FormItemFilterOptions,
} from '../helpers'
import { FormDefinitionAttachmentModel } from './form-definition-attachment.model'
import {
  FormDefinitionItemOption,
  FormDefinitionItemOptionModel,
} from './form-definition-item-option.model'
import { FormDefinitionItemQualificationModel } from './form-definition-item-qualification.model'
import {
  FormDefinitionAutoFillFormulaModel,
  FormDefinitionExpressionParameterModel,
  FormDefinitionValidationRuleModel,
} from './form-definition-item-validation-rule.model'
import { MockFormDefinitionItem } from './mock-type'

const FormDefinitionFieldTypeEnum = types.enumeration(
  Object.values(FormDefinitionFieldType),
)

export enum FormItemNavigationStyle {
  expand = 'expand',
  push = 'push',
  default = 'default',
}

const FormItemNavigationStyleEnum = types.enumeration(
  Object.values(FormItemNavigationStyle),
)

const fillableInputTypes = [
  FormDefinitionFieldType.none,
  FormDefinitionFieldType.textArea,
  FormDefinitionFieldType.unsupported,
]

const flagableInputTypes = [FormDefinitionFieldType.checkFlagBox]

export const FormDefinitionItemModel = types
  .model({
    id: types.optional(types.identifier, uuid),
    number: types.maybeNull(types.string),
    name: types.string,
    displayOrder: types.optional(types.number, 0),
    gridDisplayOrder: types.maybe(types.number),
    showInGrid: types.optional(types.boolean, true),
    gridLabel: types.maybe(types.string),
    subitems: types.array(
      types.late((): IAnyModelType => FormDefinitionItemModel),
    ),
    attachments: types.array(FormDefinitionAttachmentModel),
    suitableEquipmentSubtypes: types.maybe(types.array(types.string)),
    inputId: types.maybe(types.string),
    inputType: types.optional(
      FormDefinitionFieldTypeEnum,
      FormDefinitionFieldType.none,
    ),
    inputOptions: types.array(FormDefinitionItemOptionModel),
    navigationStyle: types.maybe(FormItemNavigationStyleEnum),
    masterDataType: types.maybe(types.string),
    validationRules: types.array(FormDefinitionValidationRuleModel),
    autofillRules: types.maybe(FormDefinitionAutoFillFormulaModel),
    parameters: types.array(FormDefinitionExpressionParameterModel),
    isMandatory: types.optional(types.boolean, false),
    /** WARN XML field. Use `naAllowed` for computed value */
    isNaAllowed: types.optional(types.boolean, false),
    qualifications: types.maybe(
      types.array(FormDefinitionItemQualificationModel),
    ),

    level: types.number,
    relatedFormNumbers: types.array(types.string),
  })
  .preProcessSnapshot((snapshot) => ({
    ...snapshot,
    inputType: snapshot.inputType?.toLowerCase() as FormDefinitionFieldType,
  }))
  .extend(withRootStore)
  .views((self) => {
    const views = {
      get parent(): FormDefinitionBaseItem | undefined {
        return getParent(self, 2)
      },
      get numberDisplay() {
        if (self.number == null) return undefined
        return formatItemNumber(self.number)
      },
      get gridLabelDisplay() {
        if (self.gridLabel == null) return undefined
        return formatItemNumber(self.gridLabel)
      },
      get hasSubitem() {
        return self.subitems.length > 0
      },
      get hasAttachment() {
        return self.attachments.length > 0
      },
      get fillable() {
        return !fillableInputTypes.includes(self.inputType)
      },
      get flaggable() {
        return flagableInputTypes.includes(self.inputType)
      },
      get displaySubitems(): MockFormDefinitionItem[] {
        return self.subitems
          .slice()
          .sort((a, b) => a.displayOrder - b.displayOrder)
      },
      /**
       * Return all items under the current group
       * Use with caution: filter the return value of this getter by work order form lost parent-child relationship in filtering
       */
      get itemsRecursively() {
        return self.subitems
          .reduce<any[]>(
            (acc, subitem) => acc.concat(subitem.itemsRecursively),
            self.subitems,
          )
          .filter(notEmpty)
      },
      get parents(): FormDefinitionBaseItem[] {
        return [views.parent ?? null, ...(views.parent?.parents ?? [])].filter(
          notEmpty,
        )
      },
      get options(): FormDefinitionItemOption[] {
        if (self.masterDataType == null) return self.inputOptions

        return self.inputOptions.concat(
          (
            self.rootStore.masterDataStore.masterDatas.get(
              self.masterDataType,
            ) || {
              records: [],
            }
          ).records.map((record: MasterDataRecord) => ({
            value: record.remoteId,
            label: record.value,
          })),
        )
      },
      get naAllowed() {
        if (!views.fillable || self.inputId == null) {
          return false
        }

        if (self.isMandatory) {
          return false
        }

        return self.isNaAllowed
      },
      get naAllowedFromExpanded() {
        if (views.naAllowed) return true
        if (self.navigationStyle !== FormItemNavigationStyle.expand) {
          return false
        }
        return self.subitems.reduce(
          (acc, it) => acc || it.naAllowedFromExpanded,
          false,
        )
      },
      /**
       * Return whether the current user is qualified for inputting the given value
       * @param value Value to check against with
       * @returns Boolean to determine whether the user
       */
      userQualifiedFor(value: FormDataItemValue) {
        if (self.qualifications == null) return true

        return self.qualifications.reduce(
          (acc, rule) =>
            acc &&
            (rule.value == null || rule.value === value
              ? rule.isQualified
              : true),
          true,
        )
      },
    }
    return views
  })
  .views((self) => {
    const views = {
      suitWorkOrderForm(workOrderForm?: WorkOrderForm) {
        if (workOrderForm == null) return true
        if (
          self.parent?.suitWorkOrderForm != null &&
          !self.parent.suitWorkOrderForm(workOrderForm)
        ) {
          return false
        }
        const equipment = workOrderForm?.workOrder?.equipment
        if (equipment == null || self.suitableEquipmentSubtypes == null) {
          return true
        }
        let subtype = equipment?.subtype?.toLowerCase()
        if (subtype == null) return true

        if (subtype === 'comdummycartype') return true
        if (subtype === 'mc2') subtype = 'm2'

        const suitableSubtypes = self.suitableEquipmentSubtypes
          .map((item) => item.toLowerCase())
          .map((it) => (it === 'mc2' ? 'm2' : it))

        return suitableSubtypes.includes(subtype)
      },

      /**
       * Get list of subitem (directly attached to current item) with work order form filter
       * @param workOrderForm Filtering criteria
       * @returns List of direct subitems of current item
       */
      subitemsForWorkOrderForm(workOrderForm?: WorkOrderForm) {
        return self.displaySubitems.filter((subitem) =>
          subitem.suitWorkOrderForm(workOrderForm),
        )
      },

      /**
       * Get list of all items under current item with work order form filter
       * @param workOrderForm Filtering criteria
       * @returns List of all subsidiary items
       */
      itemsRecursivelyWithSelfForWorkOrder(
        workOrderForm?: WorkOrderForm,
        opts = formItemFilterDefaultOptions,
      ): typeof self[] {
        if (opts.level < 0) return []
        if (!views.suitWorkOrderForm(workOrderForm)) return []

        return self.subitems
          .map((it) =>
            it.itemsRecursivelyWithSelfForWorkOrder(workOrderForm, {
              ...opts,
              level: opts.level - 1,
            }),
          )
          .reduce((acc, items) => [...acc, ...items], [self])
          .filter(
            (item: typeof self) => opts.fillableOnly === false || item.fillable,
          )
      },

      /**
       * Number of subsidiary items, excluding self
       * @param workOrderForm Filtering criteria
       * @param opts Filtering options
       * @returns Count of subsidiary items
       */
      subsidiaryItemCountForWorkOrder(
        workOrderForm?: WorkOrderForm,
        opts = formItemFilterDefaultOptions,
      ) {
        return views
          .itemsRecursivelyWithSelfForWorkOrder(workOrderForm, opts)
          .filter((it) => it.id !== self.id).length
      },
      containSubitemForWorkOrderForm(
        workOrderForm: WorkOrderForm | undefined = undefined,
      ) {
        return views.subsidiaryItemCountForWorkOrder(workOrderForm) > 0
      },
    }
    return views
  })

type BaseFormDefinitionItem = Instance<typeof FormDefinitionItemModel>

export type FormDefinitionItem = Omit<
  BaseFormDefinitionItem,
  | 'subitems'
  | 'displaySubitems'
  | 'itemsRecursively'
  | 'subitemsForWorkOrderForm'
  | 'itemsRecursivelyWithSelfForWorkOrder'
> & {
  subitems: FormDefinitionItem[]
  displaySubitems: FormDefinitionItem[]
  itemsRecursively: FormDefinitionItem[]
  subitemsForWorkOrderForm(workOrderForm?: WorkOrderForm): FormDefinitionItem[]
  itemsRecursivelyWithSelfForWorkOrder(
    workOrderForm?: WorkOrderForm,
    opts?: FormItemFilterOptions,
  ): FormDefinitionItem[]
}
