import { FitmentPayload } from '@mtr-SDO/apis'
import { withRootStore } from '@mtr-SDO/models-core'
import _ from 'lodash'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { Equipment, EquipmentType } from '../base-attunity'

export const FitmentModel = types
  .model('Fitment', {
    fitmentId: types.optional(types.identifier, uuid),
    fitEquipmentNumber: types.maybe(types.string),
    defitEquipmentNumber: types.maybe(types.string),
    assemblyParent: types.maybe(types.string),
    position: types.maybe(types.string),
    isPatch: types.optional(types.boolean, false),
    remark: types.maybe(types.string),
    carNumber: types.maybe(types.string),
    parentEquipmentNumber: types.maybe(types.string),
    fitmentAuthorUpn: types.maybe(types.string),
    fitmentAuthorDisplayName: types.maybe(types.string),
    fitmentTime: types.maybe(types.Date),
    defitmentAuthorUpn: types.maybe(types.string),
    defitmentAuthorDisplayName: types.maybe(types.string),
    defitmentTime: types.maybe(types.Date),
    remoteId: types.string,
    equipmentType: types.enumeration(Object.values(EquipmentType)),
    // authorUpn: types.maybe(types.string),
    // authorDisplayName: types.maybe(types.string),
    // createddate: types.Date,
  })
  .extend(withRootStore)
  .views((self) => ({
    get hasFitment() {
      return (
        self.fitEquipmentNumber != null && self.fitEquipmentNumber.length > 0
      )
    },
    get hasDefitment() {
      return (
        self.defitEquipmentNumber != null &&
        self.defitEquipmentNumber.length > 0
      )
    },
    get fitEquipment(): Equipment | undefined {
      if (!self.fitEquipmentNumber) return undefined
      return (
        self.rootStore.worksStore.findEquipment(self.fitEquipmentNumber) || null
      )
    },
    get defitEquipment(): Equipment | undefined {
      if (!self.defitEquipmentNumber) return undefined
      return (
        self.rootStore.worksStore.findEquipment(self.defitEquipmentNumber) ||
        null
      )
    },
    get isIncomplete(): boolean {
      return self.fitEquipmentNumber == null
    },
  }))
  .preProcessSnapshot((snapshot) => ({
    ...snapshot,
    fitEquipmentNumber: snapshot.fitEquipmentNumber ?? undefined,
    defitEquipmentNumber: snapshot.defitEquipmentNumber ?? undefined,

    fitmentAuthorUpn: snapshot.fitmentAuthorUpn ?? undefined,
    fitmentAuthorDisplayName: snapshot.fitmentAuthorDisplayName ?? undefined,
    // fitmentTime: snapshot.fitmentTime ? moment(snapshot.fitmentTime).toDate() : undefined,

    defitmentAuthorUpn: snapshot.defitmentAuthorUpn ?? undefined,
    defitmentAuthorDisplayName:
      snapshot.defitmentAuthorDisplayName ?? undefined,
    // defitmentTime: snapshot.defitmentTime ? moment(snapshot.defitmentTime).toDate() : undefined,

    carNumber: snapshot.carNumber ?? undefined,
    parentEquipmentNumber: snapshot.parentEquipmentNumber ?? undefined,

    // authorUpn: snapshot.authorUpn ?? undefined,
    // authorDisplayName: snapshot.authorDisplayName ?? undefined,
    assemblyParent: snapshot.assemblyParent ?? undefined,
    position: snapshot.position ?? undefined,
    remark: snapshot.remark ?? undefined,
  }))
  .actions((self) => ({
    apply(snapshot: {}) {
      _.keys(_.omit(snapshot, 'fitmentId')).forEach((key) => {
        if (snapshot[key] == null) return
        self[key] = snapshot[key]
      })
    },
  }))

export type Fitment = Instance<typeof FitmentModel>

export function fitmentSnapshotFromPayload(
  item: FitmentPayload,
): SnapshotIn<Fitment> {
  return {
    fitEquipmentNumber: item.fitmentEquipmentNo,
    defitEquipmentNumber: item.defitmentEquipmentNo,
    position: item.position,
    remark: item.description,

    defitmentAuthorUpn: item.defitmentBy,
    defitmentAuthorDisplayName: item.defitmentDisplayName,
    defitmentTime:
      item.defitmentDate == null
        ? undefined
        : moment(item.defitmentDate).toDate(),

    fitmentAuthorUpn: item.fitmentBy,
    fitmentAuthorDisplayName: item.fitmentByDisplayName,
    fitmentTime:
      item.fitmentDate == null ? undefined : moment(item.fitmentDate).toDate(),

    carNumber: item.equipmentNo,
    parentEquipmentNumber: item.parentEquipmentNo,

    remoteId: item.objectID,

    equipmentType:
      item.category === 'RailAssure'
        ? EquipmentType.RailAssure
        : EquipmentType.Assembly,

    isPatch: item.isPatch,
  }
}
