import { DepotListType, OrderBookItemType, PostCreateOrderBookItem, VehicleListType, WorkTypeListType } from '@mtr-SDO/apis'
import {
  QueryKey,
  useQuery, useMutation
} from 'react-query'
import {
  UseMutationOptions,
  UseQueryOptions,
} from 'react-query/types/react/types'
import { useStores } from '../models'

const QUERY_CACHE_KEY = 'order-book'

export const useFetchDepotList = (
  opts?: UseQueryOptions<unknown, Error, DepotListType>,
) => {
  const { orderBookStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY, 'fetch-depot-list'] as QueryKey,
    () => orderBookStore.fetchDepotList(),
    opts,
  )
}
export const useFetchWorkTypeList = (
  depotID: number,
  opts?: UseQueryOptions<unknown, Error, WorkTypeListType>,
) => {
  const { orderBookStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY, 'fetch-work-type-list', depotID] as QueryKey,
    () => orderBookStore.fetchWorkTypeListByDepotID(depotID),
    opts,
  )
}
export const useFetchVehicleList = (
  depotID: number,
  opts?: UseQueryOptions<unknown, Error, VehicleListType>,
) => {
  const { orderBookStore } = useStores()
  return useQuery(
    [QUERY_CACHE_KEY, 'fetch-vehicle-list', depotID] as QueryKey,
    () => orderBookStore.fetchVehicleListByDepotID(depotID),
    opts,
  )
}
export const useCreateOrderBookItem = (
  opts?: UseMutationOptions<PostCreateOrderBookItem, Error, OrderBookItemType>,
) => {
  const { orderBookStore } = useStores()
  return useMutation(
    (orderBookItem) => orderBookStore.createOrderBookItem(orderBookItem),
    {
      ...opts,
    },
  )
}
// export const useFetchWTAndV = (
//   depotID: number,
//   opts: UseQueryOptions<unknown, unknown, unknown, QueryKey>
// ) => {
//   const { orderBookStore } = useStores()
//   return useQueries([
//     {
//       queryKey: [QUERY_CACHE_KEY, 'fetch-work-type-list', depotID],
//       queryFn: () => orderBookStore.fetchWorkTypeListByDepotID(depotID),
//       ...opts
//     }, {
//       queryKey: [QUERY_CACHE_KEY, 'fetch-vehicle-list', depotID],
//       queryFn: () => orderBookStore.fetchVehicleListByDepotID(depotID),
//       ...opts
//     }
//   ])
// }