import {
  FormDataPayload,
  FormDefinitionPayload,
  FormPayload,
  FormVersionPayload,
  GeneralApiResponse,
  GetFormData,
  PreviewFormPayload,
  WorkInstructionFormPayload,
  WorkInstructionPayload,
} from '@mtr-SDO/apis'
import {
  ApprovalWorkflow,
  Form,
  FormData,
  FormDataAttachment,
  FormDataAttachmentType,
  FormDataItem,
  FormDataModel,
  FormDataRemark,
  FormDataStatus,
  FORMDATA_ITEM_DATE_FORMAT,
  FormDefinition,
  FormDefinitionFieldType,
  FormDefinitionModel,
  FormModel,
  FormVersionInformation,
  mapFormAttributesByPayload,
  NA_VALUE,
  parseFormDefinitionCachePayload,
  WorkInstruction,
  WorkInstructionModel,
  WorkOrderForm,
} from '@mtr-SDO/datamodels'
import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import { notEmpty } from '@mtr-SDO/utils'
import _ from 'lodash'
import { autorun } from 'mobx'
import { addDisposer, flow, Instance, SnapshotIn, types } from 'mobx-state-tree'
import moment from 'moment'
import { NIL as NIL_UUID } from 'uuid'
import {
  dehydrateFormCache,
  hydrateFormCache,
  LocalFormCache,
} from './hydration'

export const FormStoreModel = types
  .model('FormStore')
  .props({
    forms: types.array(FormModel),
    formDefinitions: types.array(FormDefinitionModel),

    formDatas: types.array(FormDataModel),
    previewFormData: types.maybe(FormDataModel),

    workInstructions: types.array(WorkInstructionModel),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({
    get localFormCache() {
      const data: LocalFormCache = {
        forms: self.forms
          .filter((it) => !it.isRemoved)
          .map((form) => form.cachePayload),
        definitions: self.formDefinitions
          .filter(
            (definition) =>
              !definition.form?.isRemoved && !definition.isPreview,
          )
          .map((definition) => definition.cachePayload)
          .filter(notEmpty),
      }
      return data
    },
  }))
  .actions((self) => {
    function upsertForm(snapshot: SnapshotIn<Form>): Form {
      const form = self.forms.find(
        (f) => f.number === snapshot.number && f.remoteId === snapshot.remoteId,
      )
      if (form) {
        form.apply(snapshot)
        return form
      }

      const ret = FormModel.create(snapshot)
      self.forms.push(ret)
      return ret
    }

    function upsertForms(snapshots: SnapshotIn<Form>[]): Form[] {
      return snapshots.map(upsertForm)
    }

    const actions = {
      upsertForms,
      upsertForm,

      upsertFormDefinition(
        snapshot: SnapshotIn<FormDefinition>,
      ): FormDefinition {
        let formDefinition = self.formDefinitions.find(
          (def) =>
            def.form?.remoteId === snapshot.formId &&
            def.version === snapshot.version &&
            def.isPreview === (snapshot.isPreview ?? false),
        )

        if (formDefinition) {
          if (snapshot.isPreview) {
            // only update if it is preview
            // if not preview, it suppose won't change
            formDefinition.apply(snapshot)
            formDefinition.expandXml(true, true)
          }
        } else {
          formDefinition = FormDefinitionModel.create(snapshot)
          self.formDefinitions.push(formDefinition)
        }

        return formDefinition
      },
      upsertFormData(snapshot: SnapshotIn<FormData>): FormData {
        let formData = self.formDatas.find(
          (data) =>
            (data.remoteFormGroupId != null &&
            snapshot.remoteFormGroupId != null
              ? data.remoteFormGroupId === snapshot.remoteFormGroupId
              : data.workOrderNumber === snapshot.workOrderNumber) && // same wo
            data.remoteFormId === snapshot.remoteFormId && // same form
            data.remoteId === snapshot.remoteId && // same wo form
            data.isMerged === snapshot.isMerged && // both are merged / history
            data.freezeTime === snapshot.freezeTime && // should be freezed in the same time, or not freezed
            data.isMerged === snapshot.isMerged &&
            (data.isMerged ||
              data.remoteHistoryId === snapshot.remoteHistoryId),
        )

        if (formData) {
          formData.apply(snapshot)
        } else {
          formData = FormDataModel.create(snapshot)
          self.formDatas.push(formData)
        }

        return formData
      },
      upsertFormDefinitionPayload(
        payload: FormDefinitionPayload,
        isPreview?: boolean,
      ) {
        let form = self.forms.find((it) => it.remoteId === payload.formObjectID)

        form = actions.upsertForm({
          number: payload.formNo,
          title: payload.formName,
          remoteId: payload.formObjectID,
          trainStockTypeIds: payload.trainStockTypeIDs.map((it) =>
            it.toString(),
          ),
        })

        const snapshot: SnapshotIn<FormDefinition> = {
          formId: form.remoteId,
          version: +payload.formVersionNo,
          issueDate:
            payload.issueDate != null
              ? moment(payload.issueDate).toDate()
              : undefined,
          publishDate:
            payload.publishDate != null
              ? moment(payload.publishDate).toDate()
              : undefined,
          issueNumber: payload.issueNo,
          revisionNumber: payload.revNo,

          checkType: payload.checkType ?? undefined,
          formRefNumber: payload.formRefNo ?? undefined,

          qualifications: payload.qualificationCodes ?? undefined,
          isPreview: isPreview ?? false,
          xml: payload.formXML,
        }

        const formDefinition = actions.upsertFormDefinition(snapshot)

        if (payload.formDataType != null) {
          const attributes = mapFormAttributesByPayload({
            formDataType: payload.formDataType,
          })

          if (formDefinition.isUnique == null) {
            formDefinition.setUnique(attributes.isUnique)
          }

          if (formDefinition.allowMultiple == null) {
            formDefinition.setAllowMultiple(attributes.allowMultiple)
          }
        }

        return formDefinition
      },
      upsertFormDataApiPayload(
        payload: FormDataPayload,
        workOrderForm: WorkOrderForm,
        isMerged: boolean,
      ) {
        let ret = [] as FormData[]
        const { formDefinition } = workOrderForm

        if (Array.isArray(payload.formHistrotyDatas)) {
          ret = _.flatten(
            ret.concat(
              // @ts-ignore
              payload.formHistrotyDatas.map((history) =>
                actions.upsertFormDataApiPayload(history, workOrderForm, false),
              ),
            ),
          )
        }

        const { itemsRecursively: formDefinitionItems } = formDefinition
        const formDataItems: SnapshotIn<FormDataItem>[] = []

        if (payload.formSignatures.length > 0) {
          payload.formSignatures.forEach((signature) => {
            const targetItem = formDefinitionItems.find(
              (item) => item.number === signature.wiItemNo && item.level === 0,
            )
            if (!targetItem) {
              self.environment.console.display({
                name: 'Parse Form Data - Signature',
                preview: 'Cannot find corresponding definition item. Skipped',
                value: [signature, formDefinitionItems],
                important: true,
              })
              return
            }

            formDataItems.push({
              inputId: targetItem.inputId,
              value:
                signature.status === 2
                  ? 'flagged'
                  : signature.status === 1
                  ? 'checked'
                  : undefined,
            })
          })
        }

        if (payload.formJsonData) {
          try {
            if (!formDefinition.xml && formDefinitionItems.length === 0) {
              self.environment.console.display({
                name: 'Parse Form Data - JSON',
                preview: 'DefinitionXML is missing, skipped',
                value: { formDefinitionItems, formDefinition },
                important: true,
              })
            } else {
              const data = JSON.parse(payload.formJsonData.trim())
              Object.keys(data).forEach((key) => {
                const targetItem = formDefinitionItems.find(
                  (item) => item.inputId === key,
                )

                if (targetItem?.inputId == null) {
                  self.environment.console.display({
                    name: 'Parse Form Data - JSON',
                    preview:
                      'Cannot find corresponding definition item. Skipped',
                    value: [key, data[key], formDefinitionItems],
                    important: true,
                  })
                  return
                }

                let value = data[key]

                if (
                  [
                    FormDefinitionFieldType.date,
                    FormDefinitionFieldType.datetime,
                  ].includes(targetItem.inputType) &&
                  value != null &&
                  value !== NA_VALUE
                ) {
                  value = moment(value, FORMDATA_ITEM_DATE_FORMAT).toISOString()
                }

                formDataItems.push({
                  inputId: targetItem.inputId,
                  value: value === NA_VALUE ? undefined : value,
                  notApplicableSelf: value === NA_VALUE,
                })
              })
            }
          } catch (error) {
            self.environment.console.reportError(error)
            self.environment.console.display({
              name: 'Parse Form Data - JSON',
              preview: 'Invalid JSON data',
              value: [payload.formJsonData, error],
              important: true,
            })
            // throw error
          }
        }

        const remark: SnapshotIn<FormDataRemark> = { content: undefined }
        let attachments: SnapshotIn<FormDataAttachment>[] = []
        if (!isMerged) {
          const remarkObject =
            payload.formRemarks &&
            payload.formRemarks.length > 0 &&
            payload.formRemarks[0]
          if (remarkObject) {
            remark.content = remarkObject.remarkText || undefined
            attachments = (remarkObject.formAttachments || [])
              .map((attachment) => ({
                title: attachment.attachmentName,
                remoteId: attachment.attachmentPath,
                mime: attachment.attachmentFormat,
                type:
                  attachment.attachmentType === 1
                    ? FormDataAttachmentType.photo
                    : attachment.attachmentType === 2
                    ? FormDataAttachmentType.video
                    : FormDataAttachmentType.file,
                itemInputId: attachment.itemInputId ?? undefined,
              }))
              .filter((snapshot) => snapshot.mime)
          }
        } else {
          // * before code is remark.content = payload.teamLeaderRemark || undefined
          // * 现在不会把verifer填写的remark填充到quick-action-remark-screen显示
          remark.content = undefined
        }

        const formDataSnapshot: SnapshotIn<FormData> = {
          workOrderNumber: workOrderForm.workOrder.number,
          remoteFormGroupId: workOrderForm.workOrder.remoteId,
          remoteFormId: workOrderForm.form.remoteId,
          formVersion: workOrderForm.formDefinition.version,
          isOptional: workOrderForm.isOptional,

          equipmentNumber: workOrderForm.workOrder.equipment.number,

          workNatureId: workOrderForm.workOrder.nature.id,

          isMerged,

          createddate: moment(payload.startDate || undefined).toDate(),
          lastmoddate: moment(
            payload.endDate || payload.startDate || undefined,
          ).toDate(),
          uploaddate: moment(payload.createDate || undefined).toDate(),

          remoteId: payload.formDataObjectID,
          remoteHistoryId: !isMerged
            ? payload.formDataHistoryObjecID
            : undefined,
          referenceNumber: payload.formReferenceNo,

          items: formDataItems,

          approvalWorkflow:
            payload.workflow === 2
              ? ApprovalWorkflow.endorseAndApprove
              : payload.workflow === 1
              ? ApprovalWorkflow.approveOnly
              : undefined,

          uploaderName:
            payload.updateDisplayName == null
              ? undefined
              : payload.updateDisplayName,
          uploaderUpn: payload.updateBy || undefined,
          updateTime: payload.updateDate || undefined,

          approverName:
            payload.approverName == null ? undefined : payload.approverName,
          approverUpn: payload.approverUPN || undefined,
          teamLeaderName:
            payload.teamLeaderName == null ? undefined : payload.teamLeaderName,
          teamLeaderUpn: payload.teamLeaderUPN || undefined,
          endorserName:
            payload.endoserName == null ? undefined : payload.endoserName,
          endorserUpn: payload.endoserUPN || undefined,

          submissionTime: payload.submitDate
            ? moment(payload.submitDate).toDate()
            : undefined,
          approvalTime: payload.approveDate
            ? moment(payload.approveDate).toDate()
            : undefined,
          endorsementTime: payload.endorseDate
            ? moment(payload.endorseDate).toDate()
            : undefined,

          approvalRejectUsername:
            payload.approveRejectByDisplayName ?? undefined,
          approvalRejectUpn: payload.approveRejectBy ?? undefined,
          approvalRejectReason: payload.approveRejectReason ?? undefined,
          approvalRejectTime: payload.approveRejectDate
            ? moment(payload.approveRejectDate).toDate()
            : undefined,

          endorseRejectUsername:
            payload.endorseRejectByDisplayName ?? undefined,
          endorseRejectUpn: payload.endorseRejectBy ?? undefined,
          endorseRejectReason: payload.endorseRejectReason ?? undefined,
          endorseRejectTime: payload.endorseRejectDate
            ? moment(payload.endorseRejectDate).toDate()
            : undefined,

          voidUsername: payload.voidByDisplayName ?? undefined,
          voidUpn: payload.voidBy ?? undefined,
          voidReason: payload.voidReason ?? undefined,
          voidTime: payload.voidDate
            ? moment(payload.voidDate).toDate()
            : undefined,

          remark,
          tags:
            payload.formDataTags?.map((tag) => ({
              tag: tag.tag,
              objectid: tag.dataTagObjectID,
              createdby: tag.createBy,
              createddate: moment(tag.createDate).toDate(),
            })) ?? [],
          attachments,

          instruments: payload.formDataIMTEs.map((imte) => ({
            imteNumber: imte.code,
            expiryDate: imte.nextExamDate
              ? moment(imte.nextExamDate).toDate()
              : undefined,
            createdDate: moment(imte.recordDate).toDate(),
          })),
          // 判断是否为非doer upload formData
          isManager: payload.isManager ?? false,
        }

        return ret.concat(actions.upsertFormData(formDataSnapshot))
      },

      upsertWorkInstruction(
        snapshot: SnapshotIn<WorkInstruction>,
      ): WorkInstruction {
        const existedWorkInstruction = self.workInstructions.find(
          (item) =>
            item.remoteId === snapshot.remoteId &&
            item.workInstructionNumber === snapshot.workInstructionNumber,
        )

        if (existedWorkInstruction) {
          existedWorkInstruction.apply(snapshot)
          return existedWorkInstruction
        }

        const ret = WorkInstructionModel.create(snapshot)
        self.workInstructions.push(ret)
        return ret
      },
    }
    return actions
  })
  .actions((self) => {
    const actions = {
      fetchAllForms: flow(function* fetchAllForms(prune?: boolean) {
        const res: GeneralApiResponse<FormPayload[]> =
          yield self.environment.api.forms.getAvailableFormList()

        if (res.kind !== 'ok') throw new Error(res.kind)
        const { payload } = res

        if (payload == null) throw new Error('empty-payload')

        const snapshots = payload.map(
          (it): SnapshotIn<Form> => ({
            number: it.formNo,
            title: it.formName,
            remoteId: it.formObjectID,
            trainStockTypeIds: it.trainStockTypeIDs.map((id) => id.toString()),
            wiRemoteId: it.wiObjectID === NIL_UUID ? undefined : it.wiObjectID,
          }),
        )

        if (prune) {
          const formIds = payload.map((it) => it.formObjectID)
          self.forms.forEach((form) => {
            if (!formIds.includes(form.remoteId)) {
              form.remove()
            }
          })
        }

        return self.upsertForms(snapshots)
      }),

      fetchDefinition: flow(function* fetchFormDefinition(
        remoteId: string,
        version: number,
        forceUpsert = false,
      ): Generator<any, FormDefinition, any> {
        try {
          let form = self.forms.find((it) => it.remoteId === remoteId)
          const existing = form?.getDefinition(version)

          if (!forceUpsert && existing != null) {
            self.environment.console.log('already exist. will not download')
            return existing as unknown as FormDefinition
          }

          const res: GeneralApiResponse<FormDefinitionPayload> =
            yield self.environment.api.forms.getFormDefinition(
              remoteId,
              version,
            )
          if (res.kind !== 'ok') throw new Error(res.kind)

          const { payload } = res

          if (payload == null) throw new Error('empty-payload')
          if (!payload || typeof payload !== 'object')
            throw Error(JSON.stringify(res))
          if (payload.formObjectID !== remoteId)
            throw Error('response-id-mismatch')
          if (version !== +payload.formVersionNo)
            throw Error('response-version-mismatch')

          form = self.upsertForm({
            number: payload.formNo,
            title: payload.formName,
            remoteId: payload.formObjectID,
            trainStockTypeIds: payload.trainStockTypeIDs?.map((it) =>
              it.toString(),
            ),
          })

          return self.upsertFormDefinitionPayload(payload)
        } catch (err) {
          self.environment.console.reportError(err)
          self.environment.console.display({
            important: true,
            name: 'Fetch form definition',
            preview: `Error ${remoteId} v${version}`,
            value: { remoteId, version, err },
          })
          throw err
        }
      }),

      fetchPreviewFormDefinition: flow(function* fetchPreviewFormDefinition(
        form: Form,
      ) {
        try {
          const res: GeneralApiResponse<FormDefinitionPayload> =
            yield self.environment.api.forms.getPreviewFormDefinition(
              form.remoteId,
            )
          if (res.kind !== 'ok') throw new Error(res.kind)

          const { payload } = res

          if (payload == null) throw new Error('empty-payload')

          return self.upsertFormDefinitionPayload(payload, true)
        } catch (err) {
          self.environment.console.reportError(err)
          throw err
        }
      }),

      pruneRemovedForms: flow(function* pruneRemovedForms(): Generator<
        any,
        void,
        any
      > {
        const availableForms: GeneralApiResponse<FormPayload[]> =
          yield self.environment.api.forms.getAvailableFormList()
        if (availableForms.kind === 'ok') {
          const formIds = (availableForms.payload ?? []).map(
            (it) => it.formObjectID,
          )
          self.forms.forEach((form) => {
            if (!formIds.includes(form.remoteId)) {
              form.remove()
            }
          })
        }
      }),

      fetchFormVersions: flow(function* fetchFormVersions(
        form: Form,
      ): Generator<any, FormVersionInformation[], any> {
        const res: GeneralApiResponse<FormVersionPayload[]> =
          yield self.environment.api.forms.getFormVersions(form.remoteId)
        if (res.kind !== 'ok') throw new Error(res.kind)
        if (res.payload == null) throw new Error('empty-payload')

        const mappedResult = res.payload
          .map((item) => _.mapKeys(item, (val, key) => _.camelCase(key)))
          .map((it) => ({
            version: +it.versionNo,
            issueDate: it.issueDate != null ? moment(it.issueDate) : undefined,
            publishDate:
              it.publishDate != null ? moment(it.publishDate) : undefined,
            issueNumber: it.issueNo,
            revisionNumber: it.revNo,
            formRefNumber: it.formRefNo ?? undefined,
            wiRemoteId:
              it.WIObjectID === NIL_UUID
                ? undefined
                : it.WIObjectID ?? undefined,
          }))
        form.setAvailableVersions(mappedResult)
        return form.availableVersions
      }),

      fetchAllFormVersions(): void {
        self.forms.forEach((it) => this.fetchFormVersions(it))
      },

      fetchLatestDefinitions: flow(
        function* fetchLatestDefinitions(): Generator<
          any,
          FormDefinition[],
          any
        > {
          try {
            const res: GeneralApiResponse<FormDefinitionPayload[]> =
              yield self.environment.api.forms.getFormDefinitions()
            if (res.kind !== 'ok') throw new Error(res.kind)

            const { payload } = res

            if (payload == null) throw new Error('empty-payload')

            return payload.map((item) => self.upsertFormDefinitionPayload(item))
          } catch (err) {
            self.environment.console.reportError(err)
            throw err
          }
        },
      ),

      fetchFormData: flow(function* fetchFormData(
        workOrderForm: WorkOrderForm,
        upn?: string,
      ): Generator<any, FormData[], any> {
        try {
          if (workOrderForm.remoteFormDataId == null) {
            self.environment.console.log(
              'No remote form data id. Will not fetch',
              self,
            )
            throw new Error('remote-id-not-available')
          }

          const res: GetFormData = yield self.environment.api.getFormData(
            workOrderForm.remoteFormDataId,
            upn,
          )
          if (res.kind !== 'ok') throw new Error(res.kind)

          const { payload } = res

          if (payload == null) {
            throw new Error('empty-payload')
          }

          const existing = self.formDatas.filter(
            (it) => it.remoteId === workOrderForm.remoteFormDataId,
          )

          yield workOrderForm.formDefinition.expandXml()

          const all = self.upsertFormDataApiPayload(
            payload,
            workOrderForm,
            upn == null,
          )

          existing.forEach((formData) => {
            if (all.includes(formData)) return
            if (
              [FormDataStatus.filling, FormDataStatus.frozen].includes(
                formData.status,
              )
            ) {
              return
            }

            if (formData.status === FormDataStatus.filling) return // ? Avoid removing ongoing maintenance form data
            if (formData.status !== FormDataStatus.empty) {
              self.environment.console.warn(
                'Removing form data',
                formData,
                formData.status,
              )
            }
            self.formDatas.remove(formData)
          })

          workOrderForm.formDefinition.collapseXml()
          return all
        } catch (err) {
          self.environment.console.reportError(err)
          throw err
        }
      }),

      discardFormData(formData: FormData) {
        self.formDatas.remove(formData)
      },

      clearForms() {
        self.formDefinitions.clear()
      },
      createPreviewFormData(
        formDefinition: FormDefinition,
        isPreview?: boolean,
      ): FormData {
        if (formDefinition.form == null) {
          throw new Error('unknown-form')
        }
        if (formDefinition.version == null) {
          throw new Error('unknown-form-version')
        }

        const newFormData = FormDataModel.create({
          remoteFormId: formDefinition.form.remoteId,
          formVersion: formDefinition.version,
          isOptional: false,
          equipmentNumber: '',
          workNatureId: 'PM',
          isMerged: false,
          remoteHistoryId: undefined,
          isPreview: isPreview ?? false,
        })
        self.previewFormData = newFormData
        return newFormData
      },

      fetchPreviewForms: flow(function* fetchPreviewForms() {
        try {
          const res: GeneralApiResponse<PreviewFormPayload[]> =
            yield self.environment.api.forms.getPreviewForms()
          if (res.kind !== 'ok') throw new Error(res.kind)
          const { payload } = res

          if (payload == null) throw new Error('empty-payload')

          return payload.map((it) => {
            const form = self.upsertForm({
              number: it.formNo,
              title: it.formName,
              remoteId: it.formObjectID,
              trainStockTypeIds: it.trainStockTypeIDs?.map((id) =>
                id.toString(),
              ),
            })

            form.previewDetail = {
              version: +it.formVersionNo,
              issueDate:
                it.issueDate != null ? moment(it.issueDate) : undefined,
              publishDate:
                it.publishDate != null ? moment(it.publishDate) : undefined,
              issueNumber: it.issueNo,
              revisionNumber: it.revNo,
              formRefNumber: it.formRefNo ?? undefined,
              wiRemoteId:
                it.wiObjectID === NIL_UUID ? undefined : it.wiObjectID,
            }
            return form
          })
        } catch (err) {
          self.environment.console.reportError(err)
          throw err
        }
      }),

      fetchAllWorkInstructions: flow(
        function* fetchAllWorkInstructions(): Generator<
          any,
          WorkInstruction[],
          any
        > {
          try {
            const res: GeneralApiResponse<WorkInstructionPayload[]> =
              yield self.environment.api.forms.getAllWorkInstructions()
            if (res.kind !== 'ok') throw new Error(res.kind)
            const { payload } = res
            if (payload == null) throw new Error('empty-payload')
            return payload.map((workInstructionItem) =>
              self.upsertWorkInstruction({
                remoteId: workInstructionItem.objectID,
                workInstructionNumber: workInstructionItem.wiNo,
                workInstructionName: workInstructionItem.wiName,
                formType:
                  workInstructionItem.type === 'Normal'
                    ? workInstructionItem.type
                    : workInstructionItem.type === 'Special'
                    ? workInstructionItem.type
                    : undefined,
                issueDate: workInstructionItem.issueDate ?? undefined,
                publishDate: workInstructionItem.publishDate ?? undefined,
                issueNumber: workInstructionItem.issueNo ?? undefined,
                revisionNumber: workInstructionItem.revNo ?? undefined,
              }),
            )
          } catch (err) {
            self.environment.console.reportError(err)
            throw err
          }
        },
      ),

      fetchFormsByWorkInstructionNumber: flow(
        function* fetchFormsByWorkInstructionNumber(
          workInstructionNumber: string,
        ): Generator<any, Form[], any> {
          try {
            const res: GeneralApiResponse<WorkInstructionFormPayload[]> =
              yield self.environment.api.forms.getFormsByWorkInstruction(
                workInstructionNumber,
              )

            if (res.kind !== 'ok') throw new Error(res.kind)
            const { payload } = res

            if (payload == null) throw new Error('empty-payload')

            const forms = payload.map((it) =>
              self.forms.find((form) => form.remoteId === it.formObjectID),
            )
            if (forms.filter((it) => it == null).length === 0) {
              return forms as Form[]
            }

            yield actions.fetchAllForms()

            return payload
              .map((it) =>
                self.forms.find((form) => form.remoteId === it.formObjectID),
              )
              .filter(notEmpty)
          } catch (err) {
            self.environment.console.reportError(err)
            throw err
          }
        },
      ),
    }
    return actions
  })
  .actions((self) => ({
    hydrateCache: flow(function* hydrateCache() {
      try {
        const cache: LocalFormCache = yield hydrateFormCache(self.environment)
        if (cache == null) return false

        const { forms, definitions } = cache
        self.forms.replace(forms.map((it) => FormModel.create(it)))
        self.formDefinitions.replace(
          definitions
            .map((definitionCache) => {
              const form = self.forms.find(
                (it) => it.remoteId === definitionCache.formRemoteId,
              )
              if (!form) {
                self.environment.console.display({
                  name: 'HydrateForm',
                  preview: `Cannot find form ${definitionCache.formNumber}. Ignore`,
                  value: [definitionCache, self.forms],
                  important: true,
                })
                return null
              }

              return {
                ...parseFormDefinitionCachePayload(definitionCache),
                formId: form.remoteId,
              }
            })
            .filter(notEmpty)
            .map((it) => FormDefinitionModel.create(it)),
        )
        return true
      } catch (error) {
        self.environment.console.reportError(error)
        self.environment.console.display({
          name: 'HydrateForm',
          preview: `error=${error}`,
          value: [error, self],
          important: true,
        })
        self.forms.clear()
        self.formDefinitions.clear()
        return false
      }
    }),
    /**
     * Add forms for offline mode by upserting data from local offline cache
     * */
    /*
    upsertFormByCache(cache: LocalFormCache) {
      try {
        if (cache == null) throw Error('no-cache')
        const { forms, definitions } = cache
        forms.forEach(f => actions.upsertForm(f))
        definitions.forEach(definitionCache => {
          const form = self.forms.find(it => it.remoteId === definitionCache.formRemoteId)
          if (!form) {
            self.environment.console.logImportant('Cannot restore form definition from local cache', {
              definitionCache,
            })
            return
          }
          const snapshot = {
            ...definitionCache,
            form: form.id,
            publishDate: moment.unix(definitionCache.publishDate).toDate(),
          }
          actions.upsertFormDefinition(snapshot)
        })
      } catch (error) {
        self.environment.console.reportError(error)
        self.environment.console.display({
          name: 'Upsert Form cache local',
          preview: `error=${error}`,
          value: [error, self],
          important: true,
        })
        self.forms.clear()
        self.formDefinitions.clear()
        throw error
      }
    },
    */
    afterCreate() {
      addDisposer(
        self,
        autorun(
          () => {
            if (!self.rootStore.autosaveReady) return
            const data: LocalFormCache = self.localFormCache
            dehydrateFormCache(data, self.environment)
          },
          { delay: 5000 },
        ),
      )
    },
    clearFormCache() {
      return dehydrateFormCache(null, self.environment)
    },
  }))

export type FormStore = Instance<typeof FormStoreModel>
