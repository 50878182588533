import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import { flow, getParent, Instance, types } from 'mobx-state-tree'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { NetworkFileModel } from '../../network-file.model'
import { FormDefinitionItem } from '../definition'

export enum FormDataAttachmentType {
  file = 'file',
  photo = 'photo',
  video = 'video',
}

export const FormDataAttachmentTypeEnum = types.enumeration(
  Object.values(FormDataAttachmentType),
)

export const FormDataAttachmentModel = types
  .model({
    id: types.optional(types.identifier, uuid),
    title: types.maybe(types.string),
    type: types.maybe(FormDataAttachmentTypeEnum),
    mime: types.string,
    remoteId: types.maybe(types.string), // fill when uploaded to server
    createddate: types.optional(types.Date, () => moment().toDate()),
    lastmoddate: types.optional(types.Date, () => moment().toDate()),
    networkFile: types.maybe(NetworkFileModel),
    itemInputId: types.maybe(types.string),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .views((self) => ({
    get sasTokenUrl() {
      return self.networkFile.sasTokenUrl
    },
    get uploadProgress() {
      if (self.remoteId != null) return 1
      return self.networkFile?.uploadProgress
    },
    get decryptedPath() {
      return self.networkFile?.decryptedPath
    },
    get formUploadPayload() {
      if (self.remoteId == null) {
        self.environment.console.warn(
          'Requesting form upload payload for non-uploaded attachment',
          self,
        )
        return undefined
      }

      return {
        attachmentPath: self.remoteId,
        attachmentFormat: self.mime,
        attachmentType:
          self.type === FormDataAttachmentType.photo
            ? 1
            : self.type === FormDataAttachmentType.video
            ? 2
            : 3,
        attachmentName: self.title,
        itemInputId: self.itemInputId,
      }
    },
    get uploadProps() {
      return {
        title: self.title ?? '',
        mime: self.mime,
        type: self.type,
      }
    },
    get relatedDefinitionItem(): FormDefinitionItem | undefined {
      if (self.itemInputId == null) return undefined
      const defItem =(
        (getParent(self, 2) as any)
          .availableDefinitionItems as FormDefinitionItem[]
      ).find((it) => it.inputId === self.itemInputId)
      if(defItem !== undefined){
        return (
          (getParent(self, 2) as any)
            .availableDefinitionItems as FormDefinitionItem[]
        ).find((it) => it.inputId === self.itemInputId)
      }
      const bookItemID = (getParent(self, 2) as any).bookItemID
      const group = self.rootStore.bookFormsStore.findFormDefinition(bookItemID)?.groups.find((g)=>g.groupId === self.itemInputId)
      if(group !== undefined){
        const gDefItem = group.items[group.items.length-1]as FormDefinitionItem
       return gDefItem
      }
      return undefined
    },
  }))
  .actions((self) => ({
    changeTitle(text: string) {
      self.title = text
    },
  }))
  .actions((self) => ({
    afterAttach: flow(function* afterAttach() {
      if (
        self.networkFile == null ||
        !(yield self.networkFile.isLocalFileExist())
      ) {
        self.networkFile = NetworkFileModel.create()
        self.networkFile.initialize({ url: self.remoteId })
      } else if (self.networkFile.url != null) {
        self.remoteId = self.networkFile.url
      }
    }),
    bindNetworkFileWithLocalFile(filename: string) {
      if (self.networkFile == null) {
        self.networkFile = NetworkFileModel.create()
      }
      self.networkFile.filename = filename
    },
    decrypt: () => self.networkFile!.decrypt(),
    upload: flow(function* upload() {
      yield self.networkFile!.upload(self.uploadProps)
      self.remoteId = self.networkFile!.url
      self.rootStore.synchronizeAutosave()
    }),
    download: () => self.networkFile?.download(),
  }))

export type FormDataAttachment = Instance<typeof FormDataAttachmentModel>
