import { ApiBase } from '../api-base'
import { PaginationParams } from '../api-base.types'

export type NotificationPayload = {
  objectID: string
  title: string
  message?: string
  payload?: string
  payloadAction?: 'Open_Review_FormData' | string
  notificationType?:
    | 'VerifierToEndorser'
    | 'EndorserRejectVerifier'
    | 'WheelCheckFormValidation'
    | string
  hasRead: 'True' | 'False'
  createDate: string
}

export class ApiUserProfile {
  private api: ApiBase

  constructor(api: ApiBase) {
    this.api = api
  }

  getNotifications = async (upn: string, pagination?: PaginationParams) =>
    this.api.generalApi<{ total: number; rows: NotificationPayload[] }>(
      this.api.apisauce.get('/Form/Notifications/GetNotifications', {
        receiver: upn,
        ...(pagination ?? {}),
      }),
    )

  postNotificationRead = async (upn: string, id: string) =>
    this.api.generalApi<void>(
      this.api.apisauce.get('/Form/Notifications/UpdateNotificationsHasRead', {
        objectID: id,
        receiver: upn,
      }),
    )

  getUnreadNotificationCount = async (upn: string) =>
    this.api.generalApi<number>(
      this.api.apisauce.get(
        '/Form/Notifications/getGetNotificationsUnreadCount',
        {
          receiver: upn,
        },
      ),
    )
}
