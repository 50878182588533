import { types, flow, SnapshotIn, Instance } from 'mobx-state-tree'
import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import { Base64 } from 'js-base64'
import { action } from 'mobx'
import moment from 'moment'

import {
  GeneralApiResponse,
  BookOrderMain,
  BookOrderPayloadItem,
  GetDepotList, 
  GetVehicleList,
  GetWorkTypeList,
  CreatedBook,
} from '@mtr-SDO/apis'
import { BookOrder, BookOrderModel } from '@mtr-SDO/datamodels'

export const BooksStoreModel = types
  .model('BooksStore')
  .props({
    bookOrders: types.array(BookOrderModel),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  .views((self) => ({
    findBookOrder(orderBookItemID: string) {
      return self.bookOrders.find(
        (it) => it.orderBookItemID === orderBookItemID,
      )
    },
  }))
  .actions((self) => {
    const actions = {
      fetchBookOrders: flow(function* fetchWorkOrders(
        pageIndex: number = 1,
        pageSize: number = 100,
      ) {
        const res: GeneralApiResponse<BookOrderMain> =
          yield self.environment.api.getOrderBookItemPage(pageIndex, pageSize)
        if (res.kind !== 'ok') throw new Error(res.kind)
        if ((res.message?.result ?? 0) === 0)
          throw Error(res.message?.message ?? 'empty-message')
        if (res.payload == null) throw new Error('no-data')
        const items: BookOrderPayloadItem[] = res.payload.rows
        self.bookOrders.clear()
        items.map(async (item) => {
          if (
            self.bookOrders.find(
              (bookOrder) => bookOrder.orderBookItemID === item.orderBookItemID,
            ) == null
          ) {
            self.bookOrders.push({
              orderBookItemID: item.orderBookItemID,
              workTypeID: item.workTypeID,
              workTypeName: item.workTypeName??'',
              depotID: item.depotID,
              depotCode: item.depotCode,
              vehicleNos: item.vehicleNos,
              trackNo: item.trackNo,
              woNo: item.woNo,
              eventID: item.eventID,
              submitTime: item.submitTime != null ? item.submitTime : '',
              isCompleted: item.isCompleted,
              isDeleted: item.isDeleted,
              createBy: item.createBy,
              createDate:
                item.createDate != null
                  ? moment(item.createDate).toDate()
                  : undefined,
              updateBy: item.updateBy != null ? item.updateBy : '',
              updateDate:
                item.updateDate != null
                  ? moment(item.createDate).toDate()
                  : undefined,
            })
          }
        })
        // self.upsertBookOrders(l
        //   yield Promise.all(
        //     items.map(async (item) => {
        //       try {
        //         console.warn(item)
        //         return {
        //           orderBookItemID: item.orderBookItemID,
        //           workTypeID: item.workTypeID,
        //           depotID: item.depotID,
        //           depotCode: item.depotCode,
        //           vehicleNos: item.vehicleNos,
        //           trackNo: item.trackNo,
        //           woNo: item.woNo,
        //           eventID: item.eventID,
        //           submitTime: item.submitTime,
        //           isCompleted: item.isCompleted,
        //           isDeleted: item.isDeleted,
        //           createBy: item.createBy,
        //           createDate:
        //             item.createDate != null
        //               ? moment(item.createDate).toDate()
        //               : undefined,
        //           updateBy: item.updateBy != null ? item.updateBy : '',
        //           updateDate:
        //             item.updateDate != null
        //               ? moment(item.createDate).toDate()
        //               : undefined,
        //         }
        //       } catch (err) {
        //         self.environment.console.log(
        //           'Failed in parsing review work order',
        //           item,
        //         )
        //         self.environment.console.reportError(err)
        //         return null
        //       }
        //     }),
        //   ),
        // )
      }),
    }
    return actions
  }).actions((self) => {
    const actions = {
      getDepotList: flow(function* getDepotList() {
        const res: GetDepotList = yield self.environment.api.orderBook.getDepotList()
        if (res.kind !== 'ok') throw new Error(`fetch-depot-list|${res.kind}`)
        const data = []
        res.payload?.map((item)=>{
          data.push({
            id: item.id,
            name:item.depotName,
            depotCode:item.depotCode
          })
        })
        return data
      }),
      getWorkTypeListByDepotID: flow(function* getWorkTypeListByDepotID(depotID: number) {
        const res: GetWorkTypeList = yield self.environment.api.orderBook.getWorkTypeListByDepotID(depotID)
        if (res.kind !== 'ok') throw new Error(`fetch-work-type-list|${res.kind}`)
        const data = []
        res.payload?.map((item) => {
          data.push({
            id: item.workTypeID,
            name:item.workTypeName
          })
        })
        return data
      }),
      getVehicleListByDepotID: flow(function* getVehicleListByDepotID(depotID: number) {
        const res: GetVehicleList = yield self.environment.api.orderBook.getVehicleListByDepotID(depotID)
        if (res.kind !== 'ok') throw new Error(`fetch-vehicle-list|${res.kind}`)
        const data = []
        res.payload?.map((item) => {
          data.push({
            id: item,
            name:item
          })
        })
        return data
      }),
      createBook:flow(function* createBook(
        depotID: number,
        depotCode:string,
        workTypeID:string,
        vehicleNos:string,
        trackNo:string,
        woNo:string,
        HKID:string,
        ) {

        const code = Base64.toBase64(HKID)
        const book = {
          depotID,
          depotCode,
          workTypeID,
          vehicleNos,
          trackNo,
          woNo,
          code
        }
        const res: CreatedBook = yield self.environment.api.orderBook.createBookOrder(book)
        if (res.kind !== 'ok') throw new Error(`fetch-vehicle-list|${res.kind}`)
        return res.payload
      }),
    }
    return actions
  })

export type BooksStore = Instance<typeof BooksStoreModel>
