import { withEnvironment } from '@mtr-SDO/models-core'
import { applySnapshot, Instance, types } from 'mobx-state-tree'
import moment from 'moment'
import { format } from '../../theme'
import { ApiConfigModel } from './api-config'
import { AuthConfigModel } from './auth-config'
import { BlobStorageConfigModel } from './blob-storage-config'

// ! not yet being used in app
export const ConfigStoreModel = types
  .model('ConfigStore')
  .props({
    apiConfig: ApiConfigModel,
    authConfig: AuthConfigModel,
    blobStorageConfig: types.maybe(BlobStorageConfigModel),

    timestamp: types.number,

    videoResolution: types.optional(
      types.enumeration(['480p', '720p', '1080p', '2160p']),
      '1080p',
    ),

    // a number between (0, 1]
    photoQuality: types.number,
    maxAttachmentByteSize: types.number,
  })
  .extend(withEnvironment)
  .views((self) => ({
    get versionTimeMoment() {
      if (self.timestamp != null) {
        return moment.unix(self.timestamp)
      }
      return undefined
    },
    get versionText() {
      if (self.timestamp)
        return moment.unix(self.timestamp).format(format.datetime)
      return 'Built-in ⚠️'
    },
  }))
  .actions((self) => ({
    applyConfig(snapshot: { timestamp: number }) {
      if (self.timestamp != null && snapshot.timestamp <= self.timestamp) {
        self.environment.console.display({
          name: 'Config',
          preview: 'Not applying older config file',
          value: [self, snapshot],
        })
        return
      }

      applySnapshot(self, snapshot)
    },
  }))

export type ConfigStore = Instance<typeof ConfigStoreModel>
