import {
  FormDefinition,
  FormDefinitionExpressionParameter,
  FormDefinitionItem,
  RuleModes,
  FormData,
  FormDataItem,
} from '@mtr-SDO/datamodels'

function extractAffectedParam(
  item: FormDefinitionItem,
  ruleMode: RuleModes,
): FormDefinitionExpressionParameter[] {
  // find out all validation parameters name (not ID)
  // filter the id out
  const affectedParameters = item.parameters?.filter((param) =>
    ruleMode === RuleModes.validationRule
      ? item.validationRules?.some((r) =>
          (r.value as string)?.includes(`{{${param.name}}}`),
        ) ||
        item.validationRules?.some((r) =>
          (r.lowerValue as string)?.includes(`{{${param.name}}}`),
        ) ||
        item.validationRules?.some((r) =>
          (r.upperValue as string)?.includes(`{{${param.name}}}`),
        )
      : item.autofillRules?.value?.includes(`{{${param.name}}}`),
  )
  return affectedParameters
}

export async function constructParametersRelationMap(
  formDefinition: FormDefinition,
  ruleMode: RuleModes,
): Promise<{ [key: string]: string[] } | undefined> {
  const resultMap: { [key: string]: string[] } = {}

  formDefinition.groups.forEach((grp) => {
    grp.itemsRecursively.forEach((item) => {
      // find out all validation parameters name (not ID)
      // filter the id out
      const affectedParameters = extractAffectedParam(
        item as unknown as FormDefinitionItem,
        ruleMode,
      )

      affectedParameters?.forEach((p) => {
        if (p.refInputId != null && item.inputId != null) {
          // create new array attach to key
          if (resultMap[p.refInputId] == null) {
            const newHostInputIdList: string[] = [item.inputId]
            resultMap[p.refInputId] = newHostInputIdList
          } else {
            // push directly if array is attached already
            resultMap[p.refInputId].push(item.inputId)
          }
        }
      })
    })
  })
  return Promise.resolve(resultMap)
}

export async function constructFieldDataMap(formData: FormData) {
  const newFieldItemMap: { [key: string]: FormDataItem } = {}
  formData.items.forEach((dataItem) => {
    newFieldItemMap[dataItem.inputId] = dataItem
  })
  return newFieldItemMap
}
