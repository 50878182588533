import { useEffect, useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import url from 'url'
import styled from '@emotion/styled'
import { useLocation, useHistory } from 'react-router-dom'
import { CircularProgress, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { B2BWEB } from '@mtr-SDO/oauth'
import { useStores } from '../../models'
import { storage } from '../../local-storage'

const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryDark};
  width: 100vw;
  height: 100vh;
  display: flex !important;
  align-items: center;
`

const Spinner = styled(CircularProgress)`
  margin: auto;
`

const StyledContainer = styled.div`
  margin: auto;
  color: white;
  display: flex;
  flex-direction: column;
`

const Message = styled(Typography)`
  color: white;
`

export const AuthorizePage = observer(() => {
  const { configStore, userProfileStore, uiStore } = useStores()
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    if (!window.location.pathname.includes('authorize')) {
      history.replace('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [error, setError] = useState<string>()

  const goBack = useCallback(() => history.replace('/'), [history])

  const getAccessToken = useCallback(
    ({ code, state }: { code: string; state: string }) => {
      const redirectUri = url.format({
        protocol: window.location.protocol,
        host: window.location.host,
        pathname: '/authorize',
      })
      const authConfig = { 
        ...configStore.authConfig,
        host : configStore.authConfig.host,
        redirectUri 
      }
      const apiConfig = {
        baseUrl: configStore.apiConfig.url,
        apiKey: configStore.apiConfig.apiKey,
      }
      return B2BWEB.getAccessToken(
        { code, state },
        authConfig,
        storage,
        apiConfig,
      )
    },
    [configStore],
  )

  useEffect(() => {
    ;(async () => {
      const {
        query: { code, state },
      } = url.parse(location.search, true)

      if (code == null || state == null) {
        goBack()
        return
      }

      try {
        const accessTokenResponse = await getAccessToken({
          code: code as string,
          state: state as string,
        })

        const base64Url = accessTokenResponse.accessToken.split('.')[1]
        const base64 = base64Url.replace(/-/g,'+').replace(/_/g,'/')
        const accessTokenObj = JSON.parse(window.atob(base64))
        
        const loginTokenQuery = window.btoa(JSON.stringify({
          accessToken: window.btoa(accessTokenResponse.accessToken),
          upn: window.btoa(accessTokenObj)
        }))
        
        await userProfileStore.login({...accessTokenResponse,accessToken:loginTokenQuery}, 'web')

        const useInfo = await userProfileStore.fetchUserProfile()
        userProfileStore.applyUserInfo(useInfo)
        uiStore.setLoggedIn(true)

        storage.clearAccessToken()
        if (userProfileStore.sessionInfo) {
          // Store access type and token into a single key (accessToken = 'tokenType accessToken')
          storage.setAccessToken(
            `${userProfileStore.sessionInfo?.tokenType} ${userProfileStore.sessionInfo.accessToken}`,
          )
        }
      } catch (err) {
        console.error(err)
        setError(err instanceof Error ? err.message : '')
      }
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Background>
      {error ? (
        <StyledContainer>
          <Message>{t('errorMessage', { error })}</Message>
          <Button color="secondary" variant="contained" onClick={goBack}>
            {t('back')}
          </Button>
        </StyledContainer>
      ) : (
        <Spinner color="secondary" />
      )}
    </Background>
  )
})
