import { WIChildForm } from '@mtr-SDO/apis'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'
import {
  AttachmentModel,
  mapPayloadToUser,
  mapPayloadToWIAttachment,
  UserModel,
} from '../wi-workflow'
import {
  stringToDate,
  stringToNumber,
  stringToString,
} from '../common-function'

export const WICNCommentFormModel = types
  .model({
    id: types.maybe(types.number),
    subFormId: types.optional(types.identifier, uuid),
    user: types.maybe(UserModel),
    comment: types.maybe(types.string),
    date: types.maybe(types.Date),
    attachmentList: types.array(AttachmentModel),
    isApprove: types.maybe(types.boolean),
  })
  .views((self) => ({
    get hasComment(): boolean {
      return self.comment != null
    },
  }))

export type WICNCommentForm = Instance<typeof WICNCommentFormModel>

export function mapPayloadToWICNWIChildForm(
  payload: WIChildForm[] | undefined,
): SnapshotIn<WICNCommentForm[] | []> {
  if (payload == null) return []
  return payload.map((it) => ({
    id: stringToNumber(it.id),
    subFormId: it.subFormId,
    user: mapPayloadToUser(it.userModel),
    comment: stringToString(it.comments, true),
    date: stringToDate(it.date),
    attachmentList: mapPayloadToWIAttachment(it.attachLists),
    isApprove: it.isApprove,
  }))
}
