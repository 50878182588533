import { FillStatus } from '../helpers'

export function combineFillStatus(a: FillStatus, b: FillStatus): FillStatus {
  if (a === 'missing' || b === 'missing') return 'missing'
  if (a === 'flagged' || b === 'flagged') return 'flagged'
  if (a === 'unfilled' || b === 'unfilled') return 'unfilled'
  if (a === 'passed' || b === 'passed') return 'passed'
  if (a === 'notApplicable' || b === 'notApplicable') return 'notApplicable'
  return a || b
}
