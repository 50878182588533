export const en = {
    signInPage: {
        signInStatusOption: {
            lanidUser: 'LANID user',
            contractorUser: 'Contractor / Non-LANID user',
        },
        sgin: 'Sign in',
        register: 'Register/New Account'
    },
    registerPage: {
        title: '為了節省紙張及提高效率，何東樓車廠(HTD)車廠控制中心(DCC)已實施電子表格（e-form）流程已取代現有的紙張系統，EC22, EC36, EC37表格已率先電子化。承辦商在應用系統之前必須以自己的電郵地址作一次性註冊成為SDO帳戶，而每次登入SDO系統填寫電子表格之時，亦須已登記電郵獲取一次性密碼。Depot Control Centre (DCC) of Ho Tung Lau Depot (HTD) has implemented the SDO System to replace hardcopy forms by phases for improvement of efficiency. Phase 1  has covered EC22, EC36 and EC37. Contractors are required to use their own Email address to register once in below form. When login to SDO, a One-Time-Password (OTP) will send to the registered Email address for verification.',
        email: {
            label: '電郵地址 (Email Address)',
            required: 'The Email Address field is required.',
            maxLength: 'The length cannot exceed 50',
            pattern: 'The correct mailbox specification is not met',
            helperText: `每次登入SDO，系統會發送一次性密碼到此登記電郵。\nWhen login to SDO, One-Time-Password (OTP) will be sent to this Email address.`
        },
        contactNumber: {
            label: '電話號碼 (Contact Phone Number)',
            required: 'The Contact Number field is required.',
            maxLength: 'The length cannot exceed 50',
            pattern: `The phone number format must start with '4', '5','6','7','8' or '9', followed with 7 digits`,
        },
        companyName: {
            label: '公司名稱 (Company Name)',
            required: 'The Company field is required.',
            maxLength: 'The length cannot exceed 50',
        },
        hkid: {
            label: '香港身份證 (HKID)',
            required: 'The HKID field is required.',
            maxLength: 'The length cannot exceed 50',
            validate: 'Non-compliant Hong Kong resident ID number',
            helperText: `請輸入香港身份證號碼以作CQA系統進行即時資格檢查，例如香港身份證號碼是 A123456(7) ，請輸入 A1234567。完成檢查後，本系統將不會保留閣下的香港身份証號碼記錄。\nPlease input HKID for CQA qualification check. For example, HKID is A123456(7). Please input A1234567. After checked, your HKID will not be stored in the system.`
        },
        validateCode: {
            label: 'Captcha',
            text: "refresh",
            required: "Please enter the verification code",
            refresh: "Refresh the verification code",
            validate: "Verification code error",
            helperText: `請輸入右手邊的驗証碼。\nPlease enter the verification code shown on the right.`
        }
    },
    userInformationPage: {
        userName: '用戶名稱 (User Name)',
        userRole: '用戶身份 (User Role)',
        email: '電郵地址 (Email Address)',
        contactNumber: {
            label: '電話號碼 (Contact Phone Number)',
            required: 'The Contact Number field is required.',
            maxLength: 'The length cannot exceed 50',
            pattern: `The phone number format must start with '4', '5','6','7','8' or '9', followed with 7 digits`,
        },
        companyName: {
            label: '公司名稱 (Company Name) ',
            required: 'The Company field is required.',
            maxLength: 'The length cannot exceed 50',
        },
        update:'Update',
        save:'Save',
        back:'Back',
        updateSucess:'Update user sucessfully.',
        tips : {
            part1 : `用戶名稱來源自用戶的CQA系統資料，如需更新，請聯絡CQA系統負責人。\nUser Name is come from the user’s CQA record, please contact CQA Co-Ordinator for update if required. `,
            part2 : `如需更改電話號碼或公司名稱，請按右手邊的Update按鈕更新。\nPlease click the “Update” button in the right-hand side for update if required.`
        }
    },
    signInPageApp: {
        sgin: 'Sign in',
    },
    mfaSignInPageApp: {
        title: `This Identity Provider needs to validate you identity. Please login to
        your existing account here so that we can return verification back to
        your local service.`
    },
    bookCreatePageAPP: {
        create: 'Create',
        title: `Order Book Item Create`
    }
    ,
    sdo: {
        short: 'SDO',
        long: 'Smart Depot Operations System',
        longer: 'Smart Depot Operations (SDO) System'
    },
}
