import { MockWorkOrderForm } from '../../work-orders/mock-types'

export interface FormItemFilterOptions {
  level: number
  fillableOnly: boolean
}

export const formItemFilterDefaultOptions: FormItemFilterOptions = {
  level: Infinity,
  fillableOnly: false,
}

export type FormDefinitionBaseItem = {
  id: string
  number?: string
  displayOrder: number
  name: string
  item: any[]
  parents: undefined | FormDefinitionBaseItem[]
  suitWorkOrderForm:
    | undefined
    | ((workOrderForm?: MockWorkOrderForm) => boolean)
}
