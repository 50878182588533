import { ApiBase } from '../api-base'

export class ApiHealthChecks {
  private api: ApiBase

  constructor(api: ApiBase) {
    this.api = api
  }

  getServer = async () =>
    this.api.generalApi<string>(
      this.api.apisauce.get('/Common/Attunity/KeepALive'),
    )

  getCommonServer = async () =>
    this.api.generalApi<string>(
      this.api.apisauce.get('/Common/Attunity/CommonAPIVersion'),
    )
}
