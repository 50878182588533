import {
  AssemblyFitmentSubmitRequestPayload,
  FitmentPayload,
  GeneralApiResponse,
  GetCarNumbersForEquipment,
  GetFormsInWorkOrderByIMTE,
  GetOutstandingFitmentCount,
  GetReviewWorkOrderForms,
  GetWorkOrderFormGroupCreated,
  GetWorkOrderStatus,
  OtherFormPayload,
  PostCompleteWorkOrder,
  SubmitAssemblyFitment,
  SubmitFitment,
  ValidateDefitEquipment,
  WorkOrderPayload,
  WrappedWorkOrderFormListPayloadItem,
} from '@mtr-SDO/apis'
import { withEnvironment, withRootStore } from '@mtr-SDO/models-core'
import { notEmpty } from '@mtr-SDO/utils'
import _ from 'lodash'
import {
  flow,
  getSnapshot,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types,
} from 'mobx-state-tree'
import moment from 'moment'
import { NIL, v4 as uuid } from 'uuid'
import {
  DepotModel,
  EquipmentModel,
  EquipmentType,
  StandardJobModel,
  WorkNature,
} from '../base-attunity'
import { ApprovalWorkflow, Form } from '../forms'
import {
  Fitment,
  FitmentModel,
  fitmentSnapshotFromPayload,
} from './fitment.model'
import { mapFormAttributesByPayload, WorkOrderFormOptions } from './helpers'
import { AssemblyFitmentParam, OtherFormEntry } from './types'
import { WorkOrderForm, WorkOrderFormModel } from './work-order-form.model'
import { WorkOrderPriority } from './work-order-priority'
import {
  getWorkOrderFormSnapshotStatus,
  getWorkOrderSnapshotStatus,
  WorkOrderFormMaintenanceStatus,
  WorkOrderFormStatus,
  WorkOrderStatus,
} from './work-order-status'

const WorkOrderStatusEnum = types.enumeration(Object.values(WorkOrderStatus))

const workOrderPreprocessSnapshot = (
  snapshot: any & { number: string; equipment: string; standardJob: string },
): any => ({
  ..._.omit(snapshot, ['id']),
  startDate: snapshot.startDate ?? undefined,
  completeDate: snapshot.completeDate ?? undefined,
  estimatedDuration: snapshot.estimatedDuration ?? undefined,
  status: snapshot.status ?? undefined,
  workGroupId: snapshot.workGroupId ?? undefined,
  workGroupCode: snapshot.workGroupCode ?? undefined,
  remoteId: snapshot.remoteId ?? undefined,
  cmDetailRemoteId: snapshot.cmDetailRemoteId ?? undefined,
  parentWorkOrderNumber: snapshot.parentWorkOrderNumber ?? undefined,
  description: snapshot.description ?? undefined,
  createDate: snapshot.createDate ?? undefined,
})

export const WorkOrderModel = types
  .model('WorkOrder', {
    id: types.optional(types.identifier, uuid),
    number: types.string,
    depot: types.maybe(types.reference(DepotModel)),
    equipment: types.reference(EquipmentModel),
    standardJob: types.reference(StandardJobModel),
    startDate: types.maybe(types.Date),
    completeDate: types.maybe(types.Date),
    createDate: types.maybe(types.Date),
    estimatedDuration: types.maybe(types.number), // in days
    status: types.maybe(WorkOrderStatusEnum),
    forms: types.array(WorkOrderFormModel),
    workGroupId: types.maybe(types.string), // TODO confirm if this can be undefined
    workGroupCode: types.maybe(types.string),
    remoteId: types.maybe(types.string), // formGroupDataId
    fitments: types.array(FitmentModel),

    formCount: types.maybe(types.number),
    pendingCount: types.maybe(types.number),
    fillingCount: types.maybe(types.number),
    approvedCount: types.maybe(types.number),
    rejectedCount: types.maybe(types.number),
    voidedCount: types.maybe(types.number),
    optionalFormCount: types.maybe(types.number),

    lastUpdateDate: types.maybe(types.Date),
    offlineCacheExpiry: types.maybe(types.Date),

    cmDetailRemoteId: types.maybe(types.string),
    description: types.maybe(types.string),
    relation: types.maybe(types.enumeration(['CHILD', 'PARENT'])),
    parentWorkOrderNumber: types.maybe(types.string),
    trainStockTypeId: types.maybe(types.string),
  })
  .preProcessSnapshot(workOrderPreprocessSnapshot)
  .extend(withEnvironment)
  .extend(withRootStore)
  .volatile(() => ({
    lastMaintenanceUnixTime: undefined as number | undefined,
  }))
  .views((self) => {
    const views = {
      get priority(): WorkOrderPriority | undefined {
        if (self.completeDate == null) {
          return undefined
        }

        const plannedCompletionDate = moment(self.completeDate)
        if (moment().isSameOrAfter(plannedCompletionDate)) {
          return WorkOrderPriority.overdue
        }

        if (self.estimatedDuration != null) {
          const latestStartDate = moment(plannedCompletionDate).add(
            -self.estimatedDuration,
            'd',
          )
          if (moment().isSameOrAfter(latestStartDate)) {
            return WorkOrderPriority.upcoming
          }
        }

        return WorkOrderPriority.pending
      },
      get nature(): WorkNature {
        return self.standardJob.nature
      },
      get title() {
        return self.standardJob.title
      },
      get allowedForMaintenance() {
        if (self.status == null) return false
        return [
          WorkOrderStatus.pending,
          WorkOrderStatus.readyForComplete,
        ].includes(self.status)
      },
      get isReadyForComplete() {
        return self.status === WorkOrderStatus.readyForComplete
      },
      get containsFilling(): boolean {
        return self.forms.reduce<boolean>(
          (acc, form) =>
            acc ||
            form.maintenanceStatus === WorkOrderFormMaintenanceStatus.filling,
          false,
        )
      },
      get containsFrozen(): boolean {
        return self.forms.reduce<boolean>(
          (acc, form) =>
            acc ||
            form.maintenanceStatus === WorkOrderFormMaintenanceStatus.frozen,
          false,
        )
      },
      get fillingLastUpdate(): moment.Moment | undefined {
        return self.forms
          .filter((it) => !!it.fillingLastUpdate)
          .reduce<moment.Moment | undefined>(
            (acc, formData) =>
              acc == null
                ? formData.fillingLastUpdate
                : formData.fillingLastUpdate != null
                ? moment.max(acc, formData.fillingLastUpdate)
                : acc,
            undefined,
          )
      },
      get formCounts(): {
        [key: string]: {
          total: number | undefined
          required: number | undefined
          optional: number | undefined
        }
      } {
        const ret: {
          [key: string]: {
            total: number | undefined
            optional: number | undefined
          }
        } = {
          total: { total: self.formCount, optional: self.optionalFormCount },
          pending: { total: self.pendingCount, optional: 0 },
          filling: { total: self.fillingCount, optional: 0 },
          submitted: {
            total:
              (self.formCount ?? 0) -
              (self.fillingCount ?? 0) -
              (self.pendingCount ?? 0),
            optional: 0,
          },
          approved: { total: self.approvedCount, optional: 0 },
          rejected: { total: self.rejectedCount, optional: 0 },
          voided: { total: self.voidedCount, optional: 0 },
        }
        return Object.keys(ret).reduce(
          (acc, item) => ({
            ...acc,
            [item]: {
              ...ret[item],
              required:
                ret[item].total != null && ret[item].optional != null
                  ? ret[item].total! - ret[item].optional!
                  : undefined,
            },
          }),
          {},
        )
      },
      get lastMaintenanceTime() {
        if (views.fillingLastUpdate && self.lastMaintenanceUnixTime) {
          return moment.max(
            views.fillingLastUpdate,
            moment.unix(self.lastMaintenanceUnixTime),
          )
        }
        if (views.fillingLastUpdate) return views.fillingLastUpdate
        return self.lastMaintenanceUnixTime
          ? moment.unix(self.lastMaintenanceUnixTime)
          : undefined
      },
      get withinWorkGroup() {
        return self.rootStore.userProfileStore.combinedWorkGroups
          .map((workGroup) => workGroup.id)
          .includes(self.workGroupId)
      },
      get offlineCacheExpiryMoment() {
        if (self.offlineCacheExpiry == null) return undefined
        return moment(self.offlineCacheExpiry)
      },
      offlineAvailable() {
        if (self.offlineCacheExpiry == null) return false
        return moment().isBefore(views.offlineCacheExpiryMoment)
      },
      /**
       * An offline cache data that does not rely on mst reference
       */
      get offlineCache() {
        const cache = {
          ...getSnapshot(self),
          forms: self.forms.map((workOrderForm: WorkOrderForm) => ({
            ...getSnapshot(workOrderForm),
            form: _.pick(workOrderForm.form, [
              'number',
              'remoteId',
              'title',
              'trainStockTypeId',
            ]),
          })),
          equipmentNumber: self.equipment.number,
          standardJobCode: self.standardJob.code,
        }
        return cache
      },
      get checkTypes(): string[] {
        return self.forms
          .filter(
            (it) =>
              !it.optional ||
              it.maintenanceStatus !== WorkOrderFormMaintenanceStatus.pending,
          )
          .map((it) => it.formDefinition?.checkType)
          .filter(notEmpty)
      },
      get requestedCMs() {
        return self.rootStore.worksStore.cmDetails.filter(
          (it) => it.parentWorkOrderNumber === self.number,
        )
      },
      get displayStatus(): WorkOrderStatus {
        if (self.status === WorkOrderStatus.pending && self.remoteId == null) {
          return WorkOrderStatus.notStarted
        }
        return self.status ?? WorkOrderStatus.pending
      },
      get trainStockType() {
        if (self.trainStockTypeId == null) {
          return self.equipment.stockType
        }
        return self.rootStore.worksStore.findEquipment(self.trainStockTypeId)
      },
    }
    return views
  })
  .actions((self) => {
    const upsertForm = (formSnapshot: SnapshotIn<Form>): Form => {
      const form = self.rootStore.formStore.forms.find(
        (it: Form) => it.remoteId === formSnapshot.remoteId,
      )
      if (form) return form
      return self.rootStore.formStore.upsertForm(formSnapshot)
    }
    const actions = {
      apply(inSnapshot: {}) {
        const snapshot = workOrderPreprocessSnapshot(inSnapshot)
        _.keys(snapshot).forEach((key) => {
          if (snapshot[key] == null) return
          self[key] = snapshot[key]
        })
      },
      upsertWorkOrderForm(
        snapshot: SnapshotIn<any>,
        mergeUnuploaded = true,
      ): any {
        const form: WorkOrderForm | undefined = self.forms.find(
          (f) =>
            f.form.id === snapshot.form &&
            snapshot.version === f.version &&
            (f.remoteFormDataId === snapshot.remoteFormDataId ||
              (mergeUnuploaded &&
                (snapshot.remoteFormDataId == null ||
                  f.remoteFormDataId == null) &&
                (snapshot.isUnique ?? true) &&
                (!(f.allowMultiple ?? false)
                  ? true
                  : snapshot.rejected === f.rejected &&
                    [
                      WorkOrderFormStatus.pending,
                      WorkOrderFormStatus.uploaded,
                    ].includes(snapshot.status)) &&
                (f.isUnique ?? true))),
        )

        if (form) {
          form.apply(snapshot)
          return form
        }

        const len = self.forms.length
        self.forms.unshift(snapshot)

        if (len === self.forms.length) return null
        return self.forms[0]
      },
      upsertWorkOrderForms(
        snapshots: SnapshotIn<any>[],
        mergeUnuploaded = true,
      ): any {
        return snapshots.map((it) =>
          actions.upsertWorkOrderForm(it, mergeUnuploaded),
        )
      },
      setStatus(status: WorkOrderStatus) {
        self.status = status
      },
      fetchFormGroupInfo: flow(function* fetchFormGroupInfo() {
        const res: GeneralApiResponse<WorkOrderPayload> =
          yield self.environment.api.workOrders.fetchWorkOrderByNumber(
            self.number,
          )

        if (res.kind !== 'ok') throw new Error(res.kind)
        if (res.payload == null) {
          return false
        }

        if (res.payload.objectID == null || res.payload.objectID === NIL) {
          return false
        }

        self.remoteId = res.payload.objectID
        self.status = getWorkOrderSnapshotStatus({
          status: res.payload.status,
          isReadyToComplete: res.payload.isReadyToComplete,
        })
        self.startDate =
          res.payload.planStartDate != null
            ? moment(res.payload.planStartDate).toDate()
            : undefined
        self.completeDate =
          res.payload.planCompleteDate != null
            ? moment(res.payload.planCompleteDate).toDate()
            : undefined
        self.estimatedDuration = res.payload.estimateJobDuration ?? undefined
        self.createDate =
          res.payload.workOrderCreateDate != null
            ? moment(res.payload.workOrderCreateDate).toDate()
            : undefined
        self.description = res.payload.workOrderDescription ?? undefined
        self.parentWorkOrderNumber = res.payload.parentWorkOrder ?? undefined
        self.relation = ['PARENT', 'CHILD'].includes(
          res.payload.relationshipType ?? '',
        )
          ? res.payload.relationshipType
          : undefined

        self.cmDetailRemoteId =
          res.payload.cmWorkOrderRequestObjectID ?? undefined
        self.trainStockTypeId = res.payload.trainStockTypeID.toString()
        return true
      }),
      fetchIsFormGroupCreated: flow(function* fetchIsFormGroupCreated() {
        const res: GetWorkOrderFormGroupCreated =
          yield self.environment.api.getIsWorkOrderFormGroupCreated(self)
        if (res.kind !== 'ok') throw new Error(res.kind)

        const { payload } = res
        return payload
      }),
      fetchFormList: flow(function* fetchFormList(): Generator<
        any,
        WorkOrderForm[],
        any
      > {
        const res: GeneralApiResponse<WrappedWorkOrderFormListPayloadItem> =
          yield self.environment.api.getWorkOrderFormList(self)
        if (res.kind !== 'ok') throw new Error(res.kind)
        if (res.message?.result !== 1)
          throw new Error(res.message?.message ?? 'invalid-result')

        const { payload } = res

        if (payload == null) throw new Error('empty-payload')
        if (payload.Message != null && payload.Message.length > 0)
          throw new Error(payload.Message)

        if (self.remoteId == null) {
          self.remoteId = payload.FormGroupDataObjectID ?? undefined
        }

        if (self.cmDetailRemoteId == null) {
          self.cmDetailRemoteId =
            payload.CMWorkOrderRequestObjectID ?? undefined
        }

        // eslint-disable-next-line
        const forms = (res.payload.FormDataList ?? [])
          .map((item) => {
            if (
              self.remoteId != null &&
              self.remoteId !== item.FormGroupDataObjectID
            ) {
              self.environment.console.warn(
                'Mismatched form group id. Ignore',
                self,
                item,
              )
              return undefined
            }

            const form = upsertForm({
              number: item.FormNo,
              title: item.FormName,
              remoteId: item.FormObjectID,
              trainStockTypeId: self.equipment?.stockType?.id,
            })

            const snapshot: SnapshotIn<WorkOrderForm> = {
              form: form.id,
              optional: !!item.IsOptional,
              rejected: !!item.IsRejected,
              version: item.VersionNo,
              remoteFormDataId: item.FormDataObjectID,
              status: getWorkOrderFormSnapshotStatus(item.Status),
              referenceNumber: item.FormReferenceNo,
              author: item.SubmitUserUPN,
              lastUpdateDate: item.LastUpdateDate
                ? moment(item.LastUpdateDate).toDate()
                : undefined,
              ...mapFormAttributesByPayload({
                formDataType: item.FormDataType,
              }),
            }

            if (
              item.FormGroupDataObjectID != null &&
              item.FormGroupDataObjectID.length > 0
            ) {
              self.remoteId = item.FormGroupDataObjectID
            }

            return actions.upsertWorkOrderForm(snapshot)
          })
          .filter((item) => !!item)

        return forms
      }),
      fetchOtherFormList: flow(function* fetchOtherFormList(): Generator<
        any,
        OtherFormEntry[],
        GeneralApiResponse<OtherFormPayload[]>
      > {
        const res: GeneralApiResponse<OtherFormPayload[]> =
          yield self.environment.api.forms.getFormListForEquipment(
            self.equipment.number,
          )
        if (res.kind !== 'ok') throw new Error(res.kind)

        return (res.payload ?? [])
          .map((item) => {
            try {
              const form = upsertForm({
                number: item.formNo,
                title: item.formName,
                remoteId: item.formObjectID,
                trainStockTypeId: self.equipment?.stockType?.id,
              })

              const version = item.versionNo

              return {
                form,
                version,
                workflow:
                  item.workflowType === 2
                    ? ApprovalWorkflow.endorseAndApprove
                    : item.workflowType === 1
                    ? ApprovalWorkflow.approveOnly
                    : ApprovalWorkflow.approveOnly, // ? default use approve only
                maintenanceStatus: undefined,
                referenceNumber: undefined,
                checkType: item.checkType ?? undefined,
                formRefNumber: item.formRefNo ?? undefined,
                ...mapFormAttributesByPayload(item),
              }
            } catch (err) {
              self.environment.console.warn('Invalid optional form', item)
              self.environment.console.reportError(err)
              return null
            }
          })
          .filter(notEmpty)
      }),
      fetchReviewFormList: flow(function* fetchForms(): Generator<
        any,
        WorkOrderForm[],
        GetReviewWorkOrderForms
      > {
        try {
          if (self.remoteId == null) throw new Error('empty-remote-id')

          const res: GetReviewWorkOrderForms =
            yield self.environment.api.getReviewWorkOrderForms(
              self as { remoteId: string },
            )
          if (res.kind !== 'ok') throw new Error(res.kind)

          return res.payload
            .map((item) => {
              const form = upsertForm({
                number: item.formNo,
                title: item.formName,
                remoteId: item.formObjectID,
                trainStockTypeId: self.equipment?.stockType?.id,
              })

              const snapshot: SnapshotIn<WorkOrderForm> = {
                form: form.id,
                optional: !!item.isOptional,
                rejected: !!item.isRejected,
                version: item.versionNo,
                remoteFormDataId: item.formDataObjectID,
                status: getWorkOrderFormSnapshotStatus(item.status),
                referenceNumber: item.formReferenceNo,
                author: item.submitUserUPN,
                lastUpdateDate: item.lastUpdateDate
                  ? moment(item.lastUpdateDate).toDate()
                  : undefined,
                ...mapFormAttributesByPayload(item),
              }

              return actions.upsertWorkOrderForm(snapshot)
            })
            .filter((item) => item != null)
        } catch (err) {
          self.environment.console.reportError(err)
          throw err
        }
      }),
      createForm(
        form: Form,
        formVersion: number,
        opts: Partial<WorkOrderFormOptions>,
      ): WorkOrderForm | null {
        const len = self.forms.length

        self.forms.unshift({
          form: form.id,
          version: formVersion,
          optional: opts.isOptional ?? true,
          isUnique: opts.isUnique ?? true,
          allowMultiple: opts.allowMultiple ?? false,
          author: self.rootStore.userProfileStore.userInfo.upn,
        })

        if (len === self.forms.length) return null
        return self.forms[0]
      },
      refresh: flow(function* refresh() {
        yield self.rootStore.worksStore.fetchWorkOrder({
          formGroupDataId: self.remoteId,
        })
      }),
      fetchStatus: flow(function* fetchStatus() {
        const res: GetWorkOrderStatus =
          yield self.environment.api.getWorkOrderStatus(self)
        if (res.kind !== 'ok') throw new Error(res.kind)
        const { payload } = res
        self.status = getWorkOrderSnapshotStatus({
          status: payload.Status,
          isReadyToComplete: payload.IsReadyToComplete,
        })
        return self.status
      }),
      fetchFitmentList: flow(function* fetchFitmentList(
        workOrder: string,
        fitOrDefitEquipmentNo?: string,
        parentOrEquipmentNo?: string,
        position?: string,
        defitmentBy?: string,
        pageIndex: number = 1,
        pageSize: number = 5,
        sortExpression?: string,
      ) {
        const res: GeneralApiResponse<{
          count: number
          list: FitmentPayload[]
        }> = yield self.environment.api.getFitmentList(
          workOrder,
          fitOrDefitEquipmentNo,
          parentOrEquipmentNo,
          position,
          defitmentBy,
          pageIndex,
          pageSize,
          sortExpression,
        )
        if (res.kind !== 'ok') throw new Error(res.kind)
        if ((res.message?.result ?? 0) === 0)
          throw Error(res.message?.message ?? 'empty-message')
        if (res.payload == null) throw new Error('no-data')

        return res.payload.list.map((item) => {
          const target = self.fitments.find(
            (it) => it.remoteId === item.objectID,
          )
          if (target == null) {
            self.fitments.push(fitmentSnapshotFromPayload(item))
            return self.fitments[self.fitments.length - 1]
          }
          target.apply(fitmentSnapshotFromPayload(item))
          return target
        })
      }),
      submitFitment: flow(function* submitFitment(
        data: {
          carNumber: string
          fitEquipmentNumber?: string
          defitEquipmentNumber?: string
          position?: string
          remark: string
          defitChildAssemblies: string[]
        },
        orig?: Fitment,
      ) {
        const res: SubmitFitment = yield self.environment.api.submitFitment({
          ObjectID: orig?.remoteId,

          workOrder: self.number,
          workGroupID: self.workGroupId!,
          equipmentNo: data.carNumber ?? self.equipment?.number ?? undefined,
          position: data.position,
          description: data.remark,

          fitmentEquipmentNo: data.fitEquipmentNumber?.toUpperCase(),

          fitmentBy: data.fitEquipmentNumber
            ? self.rootStore.userProfileStore.userInfo.upn
            : undefined,

          defitmentEquipmentNo:
            orig?.defitEquipmentNumber ??
            data.defitEquipmentNumber?.toUpperCase(),
          defitmentBy:
            orig?.defitmentAuthorUpn ?? data.defitEquipmentNumber
              ? self.rootStore.userProfileStore.userInfo.upn
              : undefined,
          DefitChildAssemblyItemNos: data.defitChildAssemblies,
        })

        if (res.kind !== 'ok') throw new Error(res.kind)
        const { payload } = res

        if (payload.success) return null
        return { message: payload.errorMessage }
      }),
      submitAssemblyFitment: flow(function* submitFitment(
        data: AssemblyFitmentParam,
        orig?: Fitment,
        patchRecords?: AssemblyFitmentParam[],
        // isPatch?: boolean,
      ) {
        const mapPayload = (
          params: AssemblyFitmentParam,
          paramOrig?: Fitment,
        ): AssemblyFitmentSubmitRequestPayload => ({
          ObjectID: paramOrig?.remoteId,

          workOrder: self.number,
          workGroupID: self.workGroupId!,
          equipmentNo: params.carNumber ?? self.equipment?.number ?? undefined,
          position: params.position,
          description: params.remark,

          fitmentEquipmentNo: params.fitEquipmentNumber?.toUpperCase(),

          fitmentBy: params.fitEquipmentNumber
            ? self.rootStore.userProfileStore.userInfo.upn
            : undefined,

          defitmentEquipmentNo:
            paramOrig?.defitEquipmentNumber ??
            params.defitEquipmentNumber?.toUpperCase(),
          defitmentBy:
            paramOrig?.defitmentAuthorUpn ?? params.defitEquipmentNumber
              ? self.rootStore.userProfileStore.userInfo.upn
              : undefined,

          parentEquipmentNo: params.assemblyParent!,
          isPatch: paramOrig?.isPatch ?? false,
        })

        const res: SubmitAssemblyFitment =
          yield self.environment.api.submitAssemblyFitment({
            ...mapPayload(data, orig),
            patchRecords: patchRecords?.map((it) => ({
              ...mapPayload(it),
              isPatch: true,
            })),
          })

        if (res.kind !== 'ok') throw new Error(res.kind)
        const { payload } = res

        if (payload.success) return null
        return {
          message: payload.errorMessage,
          data: payload.data?.map(
            (it) =>
              ({
                carNumber: it.equipmentNo,
                fitEquipmentNumber: it.fitmentEquipmentNo,
                defitEquipmentNumber: it.defitmentEquipmentNo,
                assemblyParent: it.parentEquipmentNo,
                position: it.position,
                tip: it.tip,
                equipmentType: EquipmentType.Assembly,
              } as AssemblyFitmentParam & { tip: string[] }),
          ),
        }
      }),

      validateDefitEquipment: flow(function* validateDefitEquipment(
        defitEquipmentNumber?: string,
        fitEquipmentNumber?: string,
      ) {
        const res: ValidateDefitEquipment =
          yield self.environment.api.validateFitmentStructure({
            workOrderNumber: self.number,
            equipmentNumber: self.equipment?.number,
            defitEquipmentNumber,
            fitEquipmentNumber,
          })
        if (res.kind !== 'ok') throw new Error(res.kind)
        if (res.payload.status === 1) return null
        return res.payload.message
      }),
      requestComplete: flow(function* requestComplete(date?: moment.Moment) {
        if (self.remoteId == null) throw new Error('empty-remote-id')

        const res: PostCompleteWorkOrder =
          yield self.environment.api.completeWorkOrder(
            self as { remoteId: string },
            self.rootStore.userProfileStore.userInfo,
            date,
          )
        if (res.kind !== 'ok') throw new Error(res.kind)

        if (res.payload.status !== 1) throw new Error(res.payload.message)
        self.status = WorkOrderStatus.requestedForComplete
        return true
      }),
      setLastMaintenanceUnixTime(unixTime: number) {
        const originalTime = self.lastMaintenanceTime
        if (originalTime != null) {
          self.lastMaintenanceUnixTime = Math.max(originalTime.unix(), unixTime)
        }
        self.lastMaintenanceUnixTime = unixTime
      },
      onMaintenanceUploaded() {
        self.lastMaintenanceUnixTime = moment().unix()
      },
      setOfflineCacheExpiry(expiry: moment.Moment) {
        self.offlineCacheExpiry = expiry?.toDate()
      },
      fetchCMRequests: () =>
        self.rootStore.worksStore.getCMRequestsFromWorkOrder(self),
      fetchCMDetail: () =>
        self.rootStore.worksStore.getCMDetails(self.cmDetailRemoteId),
      fetchCMDetailStatus: (action: 'create' | 'edit' | 'complete') =>
        self.rootStore.worksStore.getCMDetailStatus(
          self.cmDetailRemoteId,
          action,
        ),
      onRemoteIdAssigned(remoteId: string) {
        if (self.remoteId != null) {
          if (remoteId !== self.remoteId) {
            self.environment.console.warn(
              'mismatched work order / form group data id. Ignore the new one',
              self.remoteId,
              remoteId,
            )
          }
          return
        }
        self.remoteId = remoteId
      },

      getCarNumbersForEquipment: flow(function* getCarNumbersForEquipment() {
        const res: GetCarNumbersForEquipment =
          yield self.environment.api.getCarNumbersForEquipment(
            self.equipment.number,
          )
        if (res.kind !== 'ok') throw new Error(res.kind)
        return res.payload
      }),

      getOutstandingFitmentRecords: flow(
        function* getOutstandingFitmentRecords() {
          const res: GetOutstandingFitmentCount =
            yield self.environment.api.getOutstandingFitmentCount(self.number)
          if (res.kind !== 'ok') throw new Error(res.kind)
          return res.payload
        },
      ),

      getRelatedFormsByIMTE: flow(function* getRelatedFormsByIMTE(
        imte: string | undefined,
      ) {
        if (self.remoteId == null || imte == null) {
          return undefined
        }
        const res: GetFormsInWorkOrderByIMTE =
          yield self.environment.api.getFormsInWorkOrderByIMTE(
            self.remoteId,
            imte,
          )
        if (res.kind !== 'ok') throw new Error(res.kind)
        return res.payload
      }),
    }
    return actions
  })

export type WorkOrder = Instance<typeof WorkOrderModel>

export type WorkOrderOfflineCache = Omit<
  SnapshotOut<WorkOrder>,
  'forms' | 'depot'
> & {
  depot?: any
  forms: any[]
  equipmentNumber: string
  standardJobCode: string
  startDate?: number
  completeDate?: number
  estimatedDuration?: number
}
