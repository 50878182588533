import { AttachmentViewTokenRequestPayload } from '@mtr-SDO/apis'
import { withEnvironment } from '@mtr-SDO/models-core'
import JwtDecode from 'jwt-decode'
import { autorun } from 'mobx'
import { addDisposer, flow, Instance, types } from 'mobx-state-tree'
import moment from 'moment'

export const UserSessionInfoModel = types
  .model('UserSessionInfo')
  .props({
    azureAccount: types.frozen(),
    accessToken: types.string,
    idToken: types.optional(types.string, ''),
    tokenType: types.optional(types.string, 'Bearer'),
    // Azure attachment upload or view need this token
    azureSasToken: types.optional(types.string, ''),
    // last get token timestamp
    azureSasTokenTimestamplastGot: types.optional(types.number, 0),
  })
  .extend(withEnvironment)
  .views((self) => ({
    // whether need call api be subject to time stamp
    get isNeedCallAzureSasTokenApi() {
      // token expiry time is 23 hours
      // so this code 23 hours will be get a new one
      return moment().diff(self.azureSasTokenTimestamplastGot, 'hours') > 23
    },
    get expiryMoment() {
      const { exp = 0 } = JwtDecode<any>(self.accessToken)
      return moment(exp * 1000)
    },
    get notBeforeMoment() {
      const { nbf = 0 } = JwtDecode<any>(self.accessToken)
      return moment(nbf * 1000)
    },
    get authenticationHeader() {
      return `${self.tokenType} ${self.accessToken}`
    },
    get decodedToken(): { [key: string]: string } {
      return JwtDecode(self.accessToken)
    },
    get userRole() {
      const tokenObj: { RoleName?: string } = JwtDecode(self.accessToken)
      return tokenObj.RoleName
    },
  }))
  .actions((self) => {
    // export actions
    const actions = {
      // call api get token to view attachment or other thing
      getAzureSasToken: flow(function* getAzureSasToken(): Generator<
        any,
        string,
        any
      > {
        // if less than 23 hours return cache token
        if (!self.isNeedCallAzureSasTokenApi) {
          return self.azureSasToken
        }
        const res: AttachmentViewTokenRequestPayload =
          yield self.environment.api.requestAttachmentViewToken()
        if (res.kind !== 'ok') throw Error(res.kind)

        const { payload } = res
        if (payload == null) throw Error('empty-payload')
        // set token and last time stamp
        self.azureSasToken = payload
        self.azureSasTokenTimestamplastGot = moment().valueOf()
        // return token
        return self.azureSasToken
      }),
      afterAttach() {
        self.environment.api?.setAuthHeader(self.authenticationHeader)
      },
      beforeDetach() {
        self.environment.api?.clearAuthHeader()
      },
      isExpired() {
        return self.expiryMoment.isBefore(moment())
      },
    }
    return actions
  })
  .actions((self) => ({
    afterCreate() {
      addDisposer(
        self,
        autorun(
          () => {
            // check was had accessToken to call api
            if (self.accessToken) self.getAzureSasToken()
          },
          {
            delay: 5000,
            requiresObservable: true,
          },
        ),
      )
    },
  }))

export type UserSessionInfo = Instance<typeof UserSessionInfoModel>
