import { ApiBase } from '../api-base'
import {
  FormDefinitionPayload,
  FormPayload,
  FormVersionPayload,
  OtherFormPayload,
  PreviewFormPayload,
  WorkInstructionFormPayload,
  WorkInstructionPayload,
} from './forms.types'

export class ApiForms {
  private api: ApiBase

  constructor(api: ApiBase) {
    this.api = api
  }

  getAvailableFormList = async () =>
    this.api.generalApi<FormPayload[]>(
      this.api.apisauce.get('/Form/Form/GetAllAvailableFormList'),
    )

  getFormVersions = async (formRemoteId: string) =>
    this.api.generalApi<FormVersionPayload[]>(
      this.api.apisauce.get('/Form/Form/GetFormVersion', {
        formObjectID: formRemoteId,
      }),
    )

  getFormDefinition = async (remoteId: string, version: number) =>
    this.api.generalApi<FormDefinitionPayload>(
      this.api.apisauce.get('Form/Form/GetFormStructure', {
        formObjecID: remoteId, // NOTE: Intended typo defined by API
        formVersion: version,
      }),
    )

  getFormDefinitions = async () =>
    this.api.generalApi<FormDefinitionPayload[]>(
      this.api.apisauce.get('Form/Form/GetFormStructures'),
    )

  getPreviewForms = async () =>
    this.api.generalApi<PreviewFormPayload[]>(
      this.api.apisauce.get('/Form/Preview/GetPreviewFormList'),
    )

  getPreviewFormDefinition = async (remoteId: string) =>
    this.api.generalApi<FormDefinitionPayload>(
      this.api.apisauce.get('/Form/Preview/GetPreviewForm', {
        objectID: remoteId,
      }),
    )

  getFormListForEquipment = async (equipmentNumber: string) =>
    this.api.generalApi<OtherFormPayload[]>(
      this.api.apisauce.get('Form/Form/GetOptionFormList', {
        equipmentCode: equipmentNumber,
      }),
    )

  getAllWorkInstructions = async () =>
    this.api.generalApi<WorkInstructionPayload[]>(
      this.api.apisauce.get('/Form/Form/GetAllWINo'),
    )

  getFormsByWorkInstruction = async (workInstructionNumber: string) =>
    this.api.generalApi<WorkInstructionFormPayload[]>(
      this.api.apisauce.get('/Form/Form/GetFormByWINo', {
        wiNo: workInstructionNumber,
      }),
    )
}
