import { Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'

const EmptyMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const Message = styled(Typography)`
  font-size: ${({ theme }) => theme.fontSize[2]}px;
  font-weight: 600;
  font-style: italic;
  color: ${({ theme }) => theme.palettes.general.lightGrey};
`

type EmptyMessageProps = {
  message: string
}

export const EmptyMessage = observer<EmptyMessageProps>(({ message }) => (
  <EmptyMessageContainer>
    <Message>{message}</Message>
  </EmptyMessageContainer>
))
