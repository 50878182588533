import { ApiBase } from '../api-base'
import { GeneralApiResponse } from '../api-base.types'
import {
  ActionBy,
  GetADUser,
  GetRelatedWICNData,
  GetToDoWICNTask,
  GetToDoWIReviewTask,
  GetWICNData,
  GetWIReviewData,
  PostWIProcess,
  PostWIProcessResponsePayload,
} from './api.types'

export class ApiWIWorkflow {
  private api: ApiBase

  constructor(api: ApiBase) {
    this.api = api
  }

  getADUsers = async (
    search: string,
    groupName: string,
  ): Promise<GeneralApiResponse<GetADUser>> =>
    this.api.generalApi<GetADUser>(
      this.api.apisauce.get('/Common/Account/GetADUsers', {
        search,
        groupName,
      }),
    )

  getWICNData = async (
    objectId: string,
  ): Promise<GeneralApiResponse<GetWICNData>> =>
    this.api.generalApi<GetWICNData>(
      this.api.apisauce.get('/WIWorkFolw/WIChangeNotice/GetWICNWorkflow', {
        objectId,
      }),
    )

  getToDoWICNTask = async (
    searchText?: string,
    currentStatus?: number,
    isToDo?: boolean,
    pageIndex?: number,
    pageSize?: number,
    sortExpression?: string,
  ) =>
    this.api.generalApi<GetToDoWICNTask>(
      this.api.apisauce.post('/WIWorkFolw/WIChangeNotice/GetToDoListData', {
        combinationSearchText: searchText,
        currentStatus,
        isToDo,
        isToDoVal: isToDo,
        pageSize,
        pageIndex,
        sortExpression,
      }),
    )

  submitWICN = async (
    submitData: string,
    workflowData: string,
    objectId: string,
    part: number,
    action: number,
    currentStatus: number,
    isAdd: boolean,
  ) =>
    this.api.generalApi<PostWIProcessResponsePayload>(
      this.api.apisauce.post('/WIWorkFolw/WIChangeNotice/SubmitWICNWorkflow', {
        submitData,
        workflowData,
        objectId,
        part,
        action,
        currentStatus,
        isAdd,
      }),
    )

  rejectWICN = async (
    objectId: string,
    currentPartEnum: number,
    rejectComment: string,
    currentStatus: number,
  ): Promise<GeneralApiResponse<PostWIProcessResponsePayload>> =>
    this.api.generalApi<PostWIProcessResponsePayload>(
      this.api.apisauce.post('/WIWorkFolw/WIChangeNotice/Reject', {
        objectId,
        currentPartEnum,
        rejectComment,
        currentStatus,
      }),
    )

  forwardWICN = async (
    objectId: string,
    partEnum: number,
    forwardUser: ActionBy,
    groupName: string,
    subFormId: string,
  ): Promise<GeneralApiResponse<PostWIProcessResponsePayload>> =>
    this.api.generalApi<PostWIProcessResponsePayload>(
      this.api.apisauce.post('/WIWorkFolw/WIChangeNotice/Forward', {
        objectId,
        partEnum,
        forwardUser,
        groupName,
        subFormId,
      }),
    )

  getToDoWIReviewTask = async (
    searchText?: string,
    currentStatus?: number,
    isToDo?: boolean,
    pageIndex?: number,
    pageSize?: number,
    sortExpression?: string,
  ) =>
    this.api.generalApi<GetToDoWIReviewTask>(
      this.api.apisauce.post(
        '/WIWorkFolw/WIControlProcess/GetToDoListReviewData',
        {
          combinationSearchText: searchText,
          currentStatus,
          isToDo,
          isToDoVal: isToDo,
          pageSize,
          pageIndex,
          sortExpression,
        },
      ),
    )

  getWIReviewData = async (
    objectId: string,
  ): Promise<GeneralApiResponse<GetWIReviewData>> =>
    this.api.generalApi<GetWIReviewData>(
      this.api.apisauce.post('/WIWorkFolw/WIControlProcess/GetReviewDataById', {
        objectId,
      }),
    )

  getRelatedWICN = async (
    objectId: string,
  ): Promise<GeneralApiResponse<GetRelatedWICNData>> =>
    this.api.generalApi<GetRelatedWICNData>(
      this.api.apisauce.post(
        '/WIWorkFolw/WIControlProcess/GetWIWorkflowLinkageById',
        {
          objectId,
        },
      ),
    )

  submitWIReview = async (
    submitData: string,
    workflowData: string,
    objectId: string,
    part: number,
    action: number,
    currentStatus: number,
    isAdd: boolean,
  ) =>
    this.api.generalApi<PostWIProcessResponsePayload>(
      this.api.apisauce.post('/WIWorkFolw/WIControlProcess/SubmitWIWorkflow', {
        submitData,
        workflowData,
        objectId,
        part,
        action,
        currentStatus,
        isAdd,
      }),
    )

  submitWIReviewComment = async (submitData: string, objectId: string) =>
    this.api.generalApi<PostWIProcessResponsePayload>(
      this.api.apisauce.post('/WIWorkFolw/WIControlProcess/SubmitCommentForm', {
        objectId,
        submitData,
      }),
    )

  rejectWIReview = async (
    objectId: string,
    currentPartEnum: number,
    rejectComment: string,
    currentStatus: number,
  ): Promise<GeneralApiResponse<PostWIProcessResponsePayload>> =>
    this.api.generalApi<PostWIProcessResponsePayload>(
      this.api.apisauce.post('/WIWorkFolw/WIControlProcess/RejectTask', {
        objectId,
        currentPartEnum,
        currentStatus,
        rejectComment,
      }),
    )

  forwardWIReview = async (
    objectId: string,
    partEnum: number,
    forwardUser: ActionBy,
    groupName: string,
    subFormId: string,
  ): Promise<GeneralApiResponse<PostWIProcessResponsePayload>> =>
    this.api.generalApi<PostWIProcessResponsePayload>(
      this.api.apisauce.post('/WIWorkFolw/WIControlProcess/ForwardTask', {
        objectId,
        partEnum,
        forwardUser,
        groupName,
        subFormId,
      }),
    )

  consultWICN = async (
    objectId: string,
    partEnum: number,
    consultUser: ActionBy,
    subFormId: string,
  ): Promise<GeneralApiResponse<PostWIProcess>> =>
    this.api.generalApi<PostWIProcess>(
      this.api.apisauce.post('/WIWorkFolw/WIChangeNotice/WICNConsult', {
        objectId,
        partEnum,
        consultUser,
        subFormId,
      }),
    )

  consultWIReview = async (
    objectId: string,
    partEnum: number,
    consultUser: ActionBy,
    subFormId: string,
  ): Promise<GeneralApiResponse<PostWIProcess>> =>
    this.api.generalApi<PostWIProcess>(
      this.api.apisauce.post('/WIWorkFolw/WIControlProcess/Consult', {
        objectId,
        partEnum,
        consultUser,
        subFormId,
      }),
    )

  updateWICNComment = async (submitData: string, objectId: string) =>
    this.api.generalApi<PostWIProcess>(
      this.api.apisauce.post(
        '/WIWorkFolw/WIChangeNotice/UpdateWICNPartEChildForm',
        {
          submitData,
          objectId,
        },
      ),
    )

  returnWICN = async (
    objectId: string,
    partEnum: number,
    comment: string,
    subFormId: string,
  ): Promise<GeneralApiResponse<PostWIProcess>> =>
    this.api.generalApi<PostWIProcess>(
      this.api.apisauce.post('/WIWorkFolw/WIChangeNotice/WICNReturn', {
        objectId,
        partEnum,
        comment,
        subFormId,
      }),
    )

  returnWIReview = async (
    objectId: string,
    partEnum: number,
    comment: string,
    subFormId: string,
  ): Promise<GeneralApiResponse<PostWIProcess>> =>
    this.api.generalApi<PostWIProcess>(
      this.api.apisauce.post('/WIWorkFolw/WIControlProcess/Return', {
        objectId,
        partEnum,
        comment,
        subFormId,
      }),
    )
}
