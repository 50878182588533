import { Divider as MuiDivider } from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'

type DividerProps = {
  marginTop?: boolean
  marginBottom?: boolean
  dividerColor?: string
}

const StyledDivider = styled(MuiDivider)<{
  $marginTop: boolean
  $marginBottom: boolean
  $dividerColor: string | undefined
}>`
  border-bottom: 1px solid
    ${({ theme, $dividerColor }) =>
      $dividerColor ?? theme.palettes.general.divider};
  margin-top: ${({ $marginTop, theme }) =>
    $marginTop ? theme.spacings[2] : 0}px;
  margin-bottom: ${({ $marginBottom, theme }) =>
    $marginBottom ? theme.spacings[2] : 0}px;
`

export const Divider = observer<DividerProps>(
  ({ marginTop = false, marginBottom = false, dividerColor }) => (
    <StyledDivider
      $marginTop={marginTop}
      $marginBottom={marginBottom}
      $dividerColor={dividerColor}
    />
  ),
)
