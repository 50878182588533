import { flow, Instance, types } from 'mobx-state-tree'
import { withEnvironment } from '../../../../core'
import { NetworkFileModel } from '../../network-file.model'
import {
  isAttachmentCachable,
  shouldAttachmentOpenWithBrowser,
} from '../helpers'

export const FormDefinitionAttachmentFileModel = types
  .model({
    id: types.identifier,
    type: types.string,
    url: types.string,
    cacheable: types.optional(types.boolean, false),
    networkFile: types.optional(NetworkFileModel, {}),
  })
  .extend(withEnvironment)
  .views((self) => ({
    get sasTokenUrl() {
      return self.networkFile.sasTokenUrl
    },
    get openWithBrowser() {
      if (self.environment.platform === 'web') return true
      return shouldAttachmentOpenWithBrowser(self.url)
    },
    get localFilePath() {
      return self.networkFile.decryptedPath
    },
  }))
  .actions((self) => {
    const action = {
      bindNetworkFile() {
        if (!self.networkFile) self.networkFile = NetworkFileModel.create({})
        self.networkFile.initialize({ url: self.url })
      },
    }
    return action
  })
  .actions((self) => ({
    cache: flow(function* download() {
      if (!isAttachmentCachable(self.url)) throw Error('not-cachable')
      if (self.localFilePath) return

      self.bindNetworkFile()
      const downloadSuccess = yield self.networkFile.download()
      if (!downloadSuccess) {
        self.environment.console.display({
          important: true,
          name: 'Download Failed',
          value: { attahcment: self, networkFile: { ...self.networkFile } },
          preview: self.url,
        })
      }
    }),
    decrypt: flow(function* decrypt() {
      yield self.networkFile.decrypt()
    }),
  }))
  .actions((self) => ({
    getDecryptedPath: flow(function* getDecryptedPath() {
      self.bindNetworkFile()
      // yield self.networkFile.download()
      yield self.decrypt()
      return self.networkFile.decryptedPath
    }),
  }))

export type FormDefinitionAttachmentFile = Instance<
  typeof FormDefinitionAttachmentFileModel
>
