import { Backdrop, CircularProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { ScreenSize, useView } from '../../utils'
import { drawerWidth } from '../sidebar'

const StyledBackdrop = styled(Backdrop)<{ $view: ScreenSize }>`
  z-index: 1100;
  color: ${({ theme }) => theme.palettes.general.white};
  background-color: ${({ theme }) => theme.palettes.general.corporateBlue};
  opacity: 0.9 !important;
  top: ${({ $view }) => ($view === ScreenSize.mobile ? 56 : 64)}px;
  left: ${({ $view }) => ($view === ScreenSize.mobile ? 0 : drawerWidth)}px;
  padding: 0px
    ${({ $view }) => ($view === ScreenSize.mobile ? 0 : drawerWidth)}px;
`

const Spinner = styled(CircularProgress)`
  z-index: 1300;
  margin: auto;
  color: ${({ theme }) => theme.palettes.general.white};
`

type SpinnerBackdropProps = {
  open: boolean
}

export const BackdropSpinner = observer<SpinnerBackdropProps>(({ open }) => {
  const view = useView()

  return (
    <StyledBackdrop $view={view} open={open}>
      <Spinner />
    </StyledBackdrop>
  )
})
