import { UserSessionInfoModel } from '@mtr-SDO/datastores'
import { SnapshotIn } from 'mobx-state-tree'
import { QueryClient } from 'react-query'
import { Environment, RootStore, RootStoreModel, ConfigStore } from '../models'
import { initI18n } from '../services'
import { env } from '../utils'
import { storage } from '../local-storage'
import { allConfig } from '../config'

export type InitResult = { rootStore: RootStore; queryClient: QueryClient }

// for a while don't use this function to get config
// async function fetchConfig(): Promise<SnapshotIn<ConfigStore>> {
//   const { data: encryptedData }: { data: string } = await Axios.get(
//     env.configUrl,
//     {
//       headers: { 'Cache-Control': 'no-cache' },
//     },
//   )

//   const [ivText, content] = encryptedData.split('\n')

//   const iv = utils.hex.toBytes(ivText.replace('iv=', ''))
//   const aesKey = utils.hex.toBytes(env.configKey)

//   // eslint-disable-next-line new-cap
//   const aesCbc = new ModeOfOperation.cbc(aesKey, iv)
//   const data = Buffer.from(content, 'base64')

//   const resultBytes = aesCbc.decrypt(data)
//   return JSON.parse(utils.utf8.fromBytes(padding.pkcs7.strip(resultBytes)))
// }

// 本地获取配置
function localConfig(): SnapshotIn<ConfigStore>{
  return allConfig[env.target][env.environment]
}

export async function init(): Promise<InitResult> {
  // 取消网络获取
  // const config: SnapshotIn<ConfigStore> = await fetchConfig()
  // eslint-disable-next-line prefer-const
  let config : SnapshotIn<ConfigStore> = localConfig()
  
  const queryClient = new QueryClient({
    defaultOptions: { queries: { structuralSharing: false } },
  })

  await initI18n()

  // 本地开发时候会开启中间代理
  if(env.nodeEnvironment === 'development'){
    // 中间代理地址
    config.apiConfig.url = 'http://localhost:3000/api'
  }

  const { accessToken, tokenType } = storage.getAccessToken()

  const sessionInfo = UserSessionInfoModel.create({
    accessToken,
    tokenType,
  })
  const rootStoreSnapshot = {
    configStore: config,
    userProfileStore: { sessionInfo },
  }

  const { apiConfig } = config

  const rootStore = RootStoreModel.create(
    rootStoreSnapshot,
    new Environment(apiConfig, queryClient),
  )

  return { rootStore, queryClient }
}
