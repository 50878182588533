import _ from 'lodash'
import { types } from 'mobx-state-tree'

export enum WIWorkflowAction {
  save = 'save',
  approve = 'approve',
  forward = 'forward',
  reject = 'reject',
  cancel = 'cancel',
  comment = 'comment',
  consult = 'consult',
  return = 'return',
  reRoute = 'reroute',
}

export function getWorkflowActionByCode(
  code: number | null | undefined,
): WIWorkflowAction | undefined {
  switch (code) {
    case 1:
      return WIWorkflowAction.save
    case 2:
      return WIWorkflowAction.approve
    case 3:
      return WIWorkflowAction.forward
    case 4:
      return WIWorkflowAction.reject
    case 5:
      return WIWorkflowAction.cancel
    case 6:
      return WIWorkflowAction.comment
    case 7:
      return WIWorkflowAction.consult
    case 8:
      return WIWorkflowAction.return
    case 9:
      return WIWorkflowAction.reRoute
    default:
      return undefined
  }
}

export function getCodeByWorkflowAction(action: string | undefined): number {
  switch (_.toLower(action)) {
    case WIWorkflowAction.save:
      return 1
    case WIWorkflowAction.approve:
      return 2
    case WIWorkflowAction.forward:
      return 3
    case WIWorkflowAction.reject:
      return 4
    case WIWorkflowAction.cancel:
      return 5
    case WIWorkflowAction.comment:
      return 6
    case WIWorkflowAction.consult:
      return 7
    case WIWorkflowAction.return:
      return 8
    case WIWorkflowAction.reRoute:
      return 9
    default:
      return 0
  }
}

export const WIWorkflowActionEnum = types.enumeration(
  Object.values(WIWorkflowAction),
)
