import {
  ApiResponse,
  ApisauceConfig,
  ApisauceInstance,
  create,
  TIMEOUT_ERROR,
} from 'apisauce'
import { AxiosRequestConfig } from 'axios'
import qs from 'qs'
import { GeneralApiResponse } from './api-base.types'
import { ApiConfig } from './api-config'
import { GeneralApiProblem, getGeneralApiProblem } from './api-problem'
import { BlobHandler } from './blob-handler.types'

export type ApiRawResponse<T> = {
  data: {
    content: T | null
    message: {
      result: number | null
      message: string | null
    } | null
  } | null
  status: number
  errorMessage?: string
}

export class ApiBase {
  public apisauce!: ApisauceInstance

  public blobHandler?: BlobHandler

  config: ApiConfig

  authHeader?: string

  unauthorizedHandler?: () => void

  setUnauthorizedHandler(handler: () => void) {
    this.unauthorizedHandler = handler
  }

  get httpHeader() {
    const ret: { [key: string]: string } = {}
    if (this.config.apiKey != null) {
      ret['Api-Key'] = this.config.apiKey
    }
    if (this.authHeader != null) {
      ret.Authorization = this.authHeader
    }
    return ret
  }

  constructor(
    config: ApiConfig,
    unauthorizedHandler?: () => void,
    blobHandler?: BlobHandler,
  ) {
    this.config = config
    this.unauthorizedHandler =
      unauthorizedHandler ??
      (() => {
        console.warn(
          'Unauthorized Error Catched By Default Unauthorized Handler',
        )
      })
    this.blobHandler = blobHandler
    this.setup()
  }

  // make timeout working on Android:
  // https://github.com/infinitered/apisauce/issues/163#issuecomment-441475858
  requestTimeout = <T>(
    promise: Promise<T>,
    axiosConfig: AxiosRequestConfig,
    args: [string, {}?, AxiosRequestConfig?],
  ): Promise<T> => {
    const { timeout } = this.config
    const config = { ...axiosConfig, ...(args[2] || {}) }
    const duration = config.timeout || timeout
    const timeoutPromise = new Promise<T>((resolve) =>
      setTimeout(
        () =>
          resolve({
            ok: false,
            problem: TIMEOUT_ERROR,
            originalError: 'REQUEST_TIMEOUT_ERROR',
            data: null,
            status: null,
            headers: null,
            config,
            duration,
          } as unknown as T),
        duration,
      ),
    )
    return Promise.race([timeoutPromise, promise]).then((res) => {
      if (res.originalError === 'REQUEST_TIMEOUT_ERROR') {
        console.warn({
          name: 'API Timeout',
          important: true,
          preview: `${args[0]} (${duration} ms)`,
          value: [res, args],
        })
      }
      return res as T
    })
  }

  buildApi = (config: ApisauceConfig): ApisauceInstance => {
    const api = create(config)
    const {
      axiosInstance: { defaults },
      get,
      delete: del,
      head,
      post,
      put,
      patch,
      link,
      unlink,
    } = api
    api.get = (...args) => this.requestTimeout(get(...args), defaults, args)
    api.delete = (...args) => this.requestTimeout(del(...args), defaults, args)
    api.head = (...args) => this.requestTimeout(head(...args), defaults, args)
    api.post = (...args) => this.requestTimeout(post(...args), defaults, args)
    api.put = (...args) => this.requestTimeout(put(...args), defaults, args)
    api.patch = (...args) => this.requestTimeout(patch(...args), defaults, args)
    api.link = (...args) => this.requestTimeout(link(...args), defaults, args)
    api.unlink = (...args) =>
      this.requestTimeout(unlink(...args), defaults, args)
    return api
  }

  setup(): void {
    // construct the apisauce instance
    this.apisauce = this.buildApi({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json',
        'Api-Key': this.config.apiKey,
        Authorization: this.authHeader,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat' }),
    })
    this.apisauce.axiosInstance.interceptors.response.use(
      this.responseSuccessInterceptor,
      this.responseErrorInterceptor,
    )
  }

  responseErrorInterceptor = async (error: {
    response: { status: number }
    config: AxiosRequestConfig
  }): Promise<any> => {
    if (error.response && error.response.status === 401) {
      console.warn('Intercepted Error code 401', error)
      if (
        error.config.headers.Authorization &&
        !error.config.url?.includes('Account/Logout')
      ) {
        this.unauthorizedHandler?.()
      }
    }
    return error
  }

  responseSuccessInterceptor = async (value: any) => value

  setAuthHeader(authHeader: string): void {
    this.authHeader = authHeader
    this.setup()
  }

  clearAuthHeader(): void {
    this.authHeader = undefined
    this.setup()
  }

  // eslint-disable-next-line class-methods-use-this
  public async generalApi<T>(
    apiPromise: Promise<ApiResponse<ApiRawResponse<T>>>,
  ): Promise<GeneralApiResponse<T>> {
    let response: ApiResponse<ApiRawResponse<T>>
    try {
      response = await apiPromise
    } catch (err) {
      console.warn('API Failed', err)
      throw new Error('unknown')
    }

    if (!response || !response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) throw new Error(problem.kind)
    }

    try {
      const data = response.data as ApiRawResponse<T>
      if (data.data == null) {
        throw new Error('bad-data')
      }

      if (data.data.message != null && data.data.message.result !== 1) {
        throw new Error(data.data.message.message ?? 'erroneous-result')
      }
      if (data.data.content == null) throw new Error('empty-payload')

      return {
        kind: 'ok',
        payload: data.data.content,
        message: data.data?.message ?? undefined,
      }
    } catch (err) {
      console.warn('Failed in parsing response', response)
      throw new Error('bad-data')
    }
  }

  /** @deprecated */
  // eslint-disable-next-line class-methods-use-this
  public async plainApi<T>(
    apiPromise: Promise<ApiResponse<T>>,
  ): Promise<GeneralApiResponse<T>> {
    let response: ApiResponse<T> | GeneralApiProblem | null
    try {
      response = await apiPromise
    } catch (err) {
      console.warn('API Failed', err)
      throw new Error('unknown')
    }

    if (!response || !response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) throw new Error(problem.kind)
    }

    const payload = (response as ApiResponse<T>).data
    if (payload == null) throw new Error('empty-payload')

    return {
      kind: 'ok',
      payload,
      message: undefined,
    }
  }
}
