import { WIWorkflowHistory } from '@mtr-SDO/apis'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import {
  stringToDate,
  stringToNumber,
  stringToString,
} from '../common-function'
import { mapPayloadToUser, UserModel } from './user.model'
import { getCodeByWorkflowAction } from './wi-workflow-action'

export const WorkflowHistoryModel = types.model({
  action: types.maybe(types.number),
  actionByUser: types.maybe(UserModel),
  actionByGroup: types.maybe(types.string),
  nodeId: types.maybe(types.number),
  date: types.maybe(types.Date),
  comment: types.maybe(types.string),
  status: types.maybe(types.number),
})

export type WorkflowHistory = Instance<typeof WorkflowHistoryModel>

export function mapPayloadToWorkflowHistory(
  payload: WIWorkflowHistory[],
): SnapshotIn<WorkflowHistory[]> {
  return payload.map((history) => ({
    action: getCodeByWorkflowAction(history.action),
    actionByUser: mapPayloadToUser(history.actionUser),
    actionByGroup: stringToString(history.actionGroup),
    nodeId: stringToNumber(history.nodeId),
    date: stringToDate(history.date),
    comment: stringToString(history.comment),
    status: stringToNumber(history.currentStatus),
  }))
}
